import { BaseModel } from "./BaseModel";


export default class WatershedReportFormGroupStatus extends BaseModel {

    id: number;
    watershedReportingPeriodId: number;
    watershedManagementGroupId: number;
    watershedReportStatusId: number;
    active: number;
    editor: string | null;
    created: Date | string | null;
    updated: Date | string | null;


    constructor() {
        super("WatershedReportFormGroupStatus", "local")
    }
}