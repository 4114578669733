import { Component } from "react";
import { Dropdown } from "semantic-ui-react";
import { IDropdownLutH2O, DropdownOptionLut } from "./IDropdownLutProps";
import './DropdownLut.scss';

export default class DropdownLutH2O extends Component<IDropdownLutH2O> {

    state = {
        value: null,
        loading: true
    }

    componentDidMount = () => {

        if (this.props.dropdownItems.length > 0) {

            this.setState({
                loading: false,
                value: null
            });
        };
    };

    componentDidUpdate = (prevprops) => {
        if (prevprops !== this.props) {
            if (this.props.dropdownItems.length > 0) {
                var newValue: number = this.state.value;
                if (this.state.loading === true) {
                    if (this.props.initSelectId) {
                        newValue = this.props.initSelectId;
                    } else { newValue = null; }
                }

                this.setState({
                    loading: false,
                    value: newValue
                }, () => {
                });
            };
        }
    };

    manageChangeOnSingle = (event: any, data) => {
        this.setState({
            value: data.value,
        }, () => {
            this.props.onSelect(event, data);
        });
    }

    manageChangeOnMultiple = (event: any, data: any) => {
        this.props.onSelect(event, data);

    }


    createOptions = () => {
        const options: any[] = new Array<any>();
        if (this.props.nullLabel) { options.push({ key: null, text: this.props.nullLabel, value: null }); }
        if (this.props.dropdownItems) {
            this.props.dropdownItems.filter(item => item.active === 1).sort((a, b) => a.displayWeight - b.displayWeight).map((o: DropdownOptionLut, index: number) => {
                options.push({ key: index, text: o.displayName, value: o.id, id: o.id });
            });
            return options;
        } else {
            return [];
        }
    }

    render() {
        return (
            <div className="dropdown-h2o">

                {
                    this.props.isMultiselect &&
                    <div className="dropdown-title">
                        {this.props.dropdownTitle}
                        {
                            this.props.toolTipInfo &&
                            // this will become the entrance to the tooltip modal
                            <div className="tool-tip-entry-icon">
                                {/* <ToolTipModal toolTipName={this.props.toolTipInfo} theme={"primary"} size={"small"} /> */}
                            </div>
                        }
                        <Dropdown className={`${this.props.ddSlim ? "dropdown-element-slim" : "dropdown-element"}`}
                            placeholder={this.props.nullLabel}
                            options={this.createOptions()}
                            loading={this.state.loading}
                            onChange={(event, data) => { this.manageChangeOnMultiple(event, data) }}
                            multiple
                            selection
                            search={this.props.search ? this.props.search : false}
                            value={this.props.selectedValues}
                            style={{ width: this.props.width ? this.props.width : "" }}
                        >
                        </Dropdown>
                    </div>
                }
                {
                    !this.props.isMultiselect &&
                    <div>
                        <div className="dropdown-title">
                            {this.props.dropdownTitle}
                            {
                                this.props.toolTipInfo &&
                                // this will become the entrance to the tooltip modal
                                <div className="tool-tip-entry-icon">
                                    {/* <ToolTipModal toolTipName={this.props.toolTipInfo} theme={"primary"} size={"small"} /> */}
                                </div>
                            }
                        </div>
                        <Dropdown className={`${this.props.ddSlim ? "dropdown-element-slim" : "dropdown-element"}`}
                            placeholder={this.props.nullLabel}
                            options={this.createOptions()}
                            loading={this.state.loading}
                            onChange={(event, data) => { this.manageChangeOnSingle(event, data) }}
                            value={this.props.parentId ? this.props.parentId : this.state.value}
                            selection
                            search={this.props.search ? this.props.search : false}
                            style={{ width: this.props.width ? this.props.width : "" }}
                        >
                        </Dropdown>
                    </div>
                }
            </div>
        )
    }
}