import DocumentModel from "./DocumentModel";

export class UploadGroupOutputModel extends DocumentModel {
    lutLanguageId: number;
    watershedManagementGroupId: number;
    reportingPeriodId: number;
    lutWatershedGroupOutputTypeId: number;

    constructor(endpoint: string, lutLanguageId: number, watershedManagementGroupId: number, reportingPeriodId: number, lutWatershedGroupOutputTypeId: number) {
        super(endpoint)
        this.active = 1;
        this.lutLanguageId = lutLanguageId;
        this.watershedManagementGroupId = watershedManagementGroupId;
        this.reportingPeriodId = reportingPeriodId;
        this.lutWatershedGroupOutputTypeId = lutWatershedGroupOutputTypeId;
        this.attachmentFileType = "application/pdf";
    }
}

