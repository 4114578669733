import Map, { GeoJSON, Layer, LayerType, MapProps, WMS } from "@paradigmh2o/react-map";
import { useCallback, useEffect, useMemo, useState } from "react";
import Config from "../../../../../config/Config";
import WmgMapConfig from "./WmgMapConfig";
import inProgressWCM from '../../../../../images/InProgressWCMMarker.png';
import completeWCM from '../../../../../images/CompleteWCMMarker.png';
import newRedev from '../../../../../images/newRedevMarker.png';
import { ClassDisplayOptions, WatershedReportPublicFacingConfig } from "../../../../../models/viewModels/WatershedReportPublicFacingConfigViewModel";
import { WatershedReportSnapshotBmp } from "../../../../../models/WatershedReportSnapshot";
import Extent from "@arcgis/core/geometry/Extent";
import { GeoJsonService } from "../../../../../services/GeoJsonService";
import { Legend } from "./Legend";
import { useAuth } from "react-oidc-context";
import { BmpClassId } from "../../../../../models/lutModels/LutBmpClass";

export interface IProjectDetailsMapProps {
    wmgPublicInfo: WatershedReportPublicFacingConfig;
    wmgPublicData: Array<WatershedReportSnapshotBmp>;
    wmgId: number;
    width: number;
    setSidebarProjectInfoObj: Function;
    dispatchSidebar: Function;
    handleDetailsClick: Function;
    useLiveData?: boolean;
}

enum GeoJsonEndpoints {
    GeoJsonSnapshotPublic = "GeoJsonSnapshotPublic", // For use on public facing - uses snapshot and only works when dashboard is published
    GeoJsonSnapshot = "GeoJsonSnapshot", // For using snapshot data on private-facing side. Ignores if the dashboard is published so that it can be previewed
    GeoJsonLive = "GeoJsonLive" // For using live data on private-facing side. Ignores if the dashboard is published so that it can be previewed
}

export const ProjectDetailsMap = (props: IProjectDetailsMapProps) => {
    const auth = useAuth();
    const geoJsonService = useMemo(() => new GeoJsonService(auth.user?.access_token), [auth]);

    const sublayers = useMemo(() => {
        return [
            { name: "Storm Drains", viz: false },
            { name: "Channels(Drainage)", viz: true },
            { name: "Major Watersheds", viz: false },
            { name: "Safe Clean Water Program Watershed Areas", viz: false },
            { name: "Subwatersheds", viz: true },
            { name: "Jurisdictions", viz: true },
            { name: "Watershed Management Groups (WMGs)", viz: true }
        ];
    }, [])

    const endpoint: string = useMemo(() => {
        if (!auth.user?.access_token) {
            return GeoJsonEndpoints.GeoJsonSnapshotPublic
        }
        else {
            if (props.useLiveData) {
                return GeoJsonEndpoints.GeoJsonLive
            }
            else {
                return GeoJsonEndpoints.GeoJsonSnapshot
            }
        }
    }, [auth, props.useLiveData])

    const geoJsonLayers: GeoJSON[] = useMemo(() => {
        return [new GeoJSON(
            "New & Redevelopment Projects",
            "New and Redevelopment",
            `${Config.api_endpoint}GeoJson/${endpoint}/${props.wmgId}/${BmpClassId.NewAndRedevelopment}`,
            true,
            false,
            {
                type: "simple",
                symbol: {
                    type: "picture-marker",
                    url: newRedev,
                    width: "18px",
                    height: "18px"
                }
            },
            null,
            null,
            null,
            auth.user?.access_token ? { Authorization: "Bearer " + auth.user?.access_token } : null
        ),
        new GeoJSON(
            "inProgressWCMs",
            "In-Progress WCMs",
            `${Config.api_endpoint}GeoJson/${endpoint}/${props.wmgId}/1000`,
            true,
            true,
            {
                type: "simple",
                symbol: {
                    type: "picture-marker",
                    url: inProgressWCM,
                    width: "38px",
                    height: "38px"
                }
            },
            (e) => props.handleDetailsClick(e, props.wmgPublicData, props.setSidebarProjectInfoObj, props.dispatchSidebar),
            null,
            null,
            auth.user?.access_token ? { Authorization: "Bearer " + auth.user?.access_token } : null
        ),
        new GeoJSON(
            "completedWCMs",
            "Completed WCMs",
            `${Config.api_endpoint}GeoJson/${endpoint}/${props.wmgId}/1001`,
            true,
            true,
            { //renderer
                type: "simple",
                symbol: {
                    type: "picture-marker",
                    url: completeWCM,
                    width: "38px",
                    height: "38px"
                }
            },
            (e) => props.handleDetailsClick(e, props.wmgPublicData, props.setSidebarProjectInfoObj, props.dispatchSidebar),
            null,
            null,
            auth.user?.access_token ? { Authorization: "Bearer " + auth.user?.access_token } : null
        )]
    }, [auth, endpoint, props.wmgPublicData, props.wmgId])

    const [layers, setLayers] = useState<Layer[]>([
        ...sublayers.map((sublayer, i) => {
            return new WMS(
                i.toString(),
                sublayer.name,
                Config.map_connection_url,
                sublayer.viz,
                [
                    sublayer.name
                ],
            );
        }),
        ...Array.from(geoJsonLayers)
    ]);

    useEffect(() => {
        // Update layers with the new endpoint
        // Remove old ones and add new
        setLayers([...layers.filter(x => x.type !== LayerType.GeoJSON), ...Array.from(geoJsonLayers)]);
    }, [props.useLiveData])

    const filteredLayers = useMemo(() => {
        if (props.wmgPublicInfo.projectDetailsMapClass === 0) {
            return layers;//[...wmsSublayers, allNR, inProgressWCMs, completedWCMs]
        } else if (props.wmgPublicInfo.projectDetailsMapClass === 1) {
            return layers.filter((l) => l.id !== 'New & Redevelopment Projects');// [...wmsSublayers, inProgressWCMs, completedWCMs]
        }

    }, [props.wmgPublicInfo.projectDetailsMapClass, layers])

    const toggleLayerVisibility = useCallback((layer: Layer) => {
        layers
            .filter(l => l === layer)
            .forEach(l => l.visible = !l.visible);
        setLayers([...layers]);
    }, [layers, setLayers]);

    const [extent, setExtent] = useState<Extent>();
    useEffect(() => {
        (async function () {
            var extent = await geoJsonService.getExtent(props.wmgId);
            if (extent.ymin === -extent.ymax) {
                return;
            }
            setExtent(extent);
        })();

    }, [props.wmgId])

    return (
        <>
            <div className="map-box">
                <div
                    className="map"
                    style={{ height: "700px", width: "100%" }}>
                    <Map {...WmgMapConfig as MapProps}
                        layers={[...filteredLayers]}
                        onMapViewCreated={() => {}}
                        zoomWidgetPosition="top-left"
                        extent={extent}
                        legend={(layers: Array<Layer>) => <Legend layers={layers} toggleVisibility={toggleLayerVisibility}
                            topUnhiddenLayers={props.wmgPublicInfo.projectDetailsMapClass === ClassDisplayOptions.All ? 3 : 2} />} />
                </div>
            </div>
        </>
    );
}
