import { BaseModel } from "./BaseModel";


export default class JurisdictionUser extends BaseModel {

    id: number;
    jurisdictionId: number;
    userID: string;
    active: number;
    editor: string;
    created: Date;
    updated: Date;

    constructor() {
        super("JurisdictionUser", "local")
    }
}