import { Translation } from "react-i18next";
import { IProjectEditPageProps } from "../../sidebars/editProjectPage/EditProjectPage";
import './ProjectEditPages.scss';


export const ProjGisData = (props: IProjectEditPageProps) => {
    // const onWatershedGroupDropdownSelect = (_: any, d: any) => {
    //     let stateCopy: any = { ...props.project };
    //     stateCopy.bmpGeneralGisData.watershedManagementGroupId = d.value;
    //     props.setProject(stateCopy);
    // }
    // const onReceivingWatersDropdownSelect = (_: any, d: any) => {
    //     let stateCopy: any = { ...props.project };
    //     stateCopy.bmpGeneralGisData.receivingWatersId = d.value;
    //     props.setProject(stateCopy);
    // }
    // const onJurisdictionDropdownSelect = (_: any, d: any) => {
    //     let stateCopy: any = { ...props.project };
    //     stateCopy.jurisdictionId = d.value;
    //     props.setProject(stateCopy);
    // }

    // const setGisData = useCallback((newGisData: BmpGeneralGisData) => {
    //     props.setProject({
    //         ...props.project,
    //         bmpGeneralGisData: newGisData
    //     });
    // }, [props]);

    return (
        <div className="edit-proj-gisdata">
            <Translation>
                {
                    t =>
                        <>
                            <div className="input-component">
                                <div className="field">
                                    <div className="basic-input-label-wrapper">
                                        <div className="basic-input-label">
                                            WMP Group:
                                        </div>
                                    </div>
                                    <div className="content">{props.lookups.getWatershedManagementGroupById(props.project.bmpGeneralGisData.watershedManagementGroupId)}
                                    </div>
                                </div>
                            </div>
                            <div className="input-component">
                                <div className="field">
                                    <div className="basic-input-label-wrapper">
                                        <div className="basic-input-label">
                                            Receiving Waters / Subwatershed:
                                        </div>
                                    </div>
                                    <div className="content">{props.lookups.getReceivingWatersById(props.project.bmpGeneralGisData.receivingWatersId)}
                                    </div>
                                </div>
                            </div>
                            <div className="input-component">
                                <div className="field">
                                    <div className="basic-input-label-wrapper">
                                        <div className="basic-input-label">
                                            Jurisdiction:
                                        </div>
                                    </div>
                                    <div className="content">{props.lookups.getJurisdictionById(props.project.bmpGeneralGisData.jurisdictionId)}
                                    </div>
                                </div>
                            </div>
                            <div className="input-component">
                                <div className="field">
                                    <div className="basic-input-label-wrapper">
                                        <div className="basic-input-label">
                                            Soil Infiltration Rate:
                                        </div>
                                    </div>
                                    <div className="content">{props.project.bmpGeneralGisData.soilsFinalKSAT}
                                    </div>
                                </div>
                            </div>
                        </>
                }
            </Translation>
        </div>
    )
}
