import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { Translation } from "react-i18next";
import { toast } from "react-toastify";
import { Dimmer, Loader, Segment } from "semantic-ui-react";
import BmpGeneral from "../../models/BmpGeneral";
import { WatershedReportPublicFacingConfig, WatershedReportPublicFacingConfigLinkInfo } from "../../models/viewModels/WatershedReportPublicFacingConfigViewModel";
import { WatershedReportSnapshot } from "../../models/WatershedReportSnapshot";
import { BmpSnapshotService } from "../../services/BmpSnapshotService";
import { WatershedReportPublicFacingConfigService } from "../../services/WatershedReportPublicFacingConfigService";
import { WMGPublicDashboard } from "../reporting/buildWatershedForm/wmgPublicDashboard/WMGPublicDashboard";
import "./Dashboard.scss";

export interface IDashboardProps {
    lookups: any;
    wsmgId: number;
    sidebarProjectInfoObj: BmpGeneral;
    setSidebarProjectInfoObj: Dispatch<SetStateAction<BmpGeneral>>;
    setSidebarVisible: Dispatch<SetStateAction<boolean>>;
}

export const Dashboard = (props: IDashboardProps) => {

    const bmpSnapshotService = useMemo(() => new BmpSnapshotService(), []);
    const [config, setConfig] = useState<WatershedReportPublicFacingConfig | undefined>(undefined);
    const [links, setLinks] = useState<WatershedReportPublicFacingConfigLinkInfo[]>([]);
    const [dataFinishedLoading, setDataFinishedLoading] = useState<boolean>(false);
    const [snapshot, setSnapshot] = useState<WatershedReportSnapshot | null>(null);
    const publicFacingConfigService = useMemo(() => new WatershedReportPublicFacingConfigService(), []);

    useEffect(() => {
        // Get Final Config on load
        (async () => {
            try {
                let finalViewModel = await publicFacingConfigService.getFinalizedConfig(props.wsmgId!);
                if (!finalViewModel || (!finalViewModel.watershedReportPublicFacingConfig && finalViewModel.watershedReportPublicFacingConfigLinkInfo.length === 0)) {
                    toast.error("Error Retrieving Config", { autoClose: false });
                    return;
                }
                if (finalViewModel.watershedReportPublicFacingConfig) {
                    setConfig(finalViewModel.watershedReportPublicFacingConfig);
                }
                if (finalViewModel.watershedReportPublicFacingConfigLinkInfo) {
                    setLinks(finalViewModel.watershedReportPublicFacingConfigLinkInfo);
                }
            }
            catch (e: any) {
                toast.error("Error Retrieving Config", { autoClose: false });
                console.error("final watershed public info caught error" + e)
            }
        })()
    }, [publicFacingConfigService, props.wsmgId]);

    useEffect(() => {
        //Get Bmp Snapshot Data
        setDataFinishedLoading(false);
        (async () => {
            try {
                let snapshotModel = await bmpSnapshotService.getSnapshot(props.wsmgId);
                if (!snapshotModel) {
                    // No Snapshot exists yet
                    toast.error("No Public Data Available, please check back later", { autoClose: false });
                }
                setSnapshot(snapshotModel);
                setDataFinishedLoading(true);
            }
            catch (response: any) {
                console.error(response);
                toast.error("Error Retrieving Published Data", { autoClose: false });
            }
        })()
    }, [props.wsmgId, bmpSnapshotService, setDataFinishedLoading])

    return (
        <Translation>
            {
                t =>
                    <>
                        <div className="dashboard">
                            {(!snapshot || !dataFinishedLoading || !config) &&
                                <Segment style={{ height: "32rem" }}>
                                    <Dimmer active inverted>
                                        <Loader size='big'>Loading</Loader>
                                    </Dimmer>
                                </Segment>
                            }
                            {dataFinishedLoading && config &&
                                <>
                                    <div className="snapshot-timestamp">
                                        Data Last Updated:
                                        <span>{`${(new Date(snapshot.snapshotDate)).toLocaleString('en-US', {
                                            year: 'numeric',
                                            month: '2-digit',
                                            day: '2-digit'
                                        })}`}
                                        </span>
                                    </div>
                                    <WMGPublicDashboard
                                        wmgId={props.wsmgId}
                                        wmgPublicData={snapshot.watershedReportBmpSnapshots}
                                        wmgPublicInfo={config}
                                        wmgPublicInfoLinks={links}
                                        lookups={props.lookups}
                                        dispatchSidebar={props.setSidebarVisible}
                                        setSidebarProjectInfoObj={props.setSidebarProjectInfoObj}
                                        setSidebarVisible={props.setSidebarVisible}
                                        publicOrInternal="public"
                                        snapshotDate={new Date(snapshot.snapshotDate)}
                                    />
                                </>
                            }
                        </div>
                    </>
            }
        </Translation>
    )
}