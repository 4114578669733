import { useCallback, useState } from "react";
import { Table, Checkbox, Pagination, Icon, PaginationProps } from "semantic-ui-react";
import LookupFactory from "../../../lookups/LookupFactory";
import BmpGeneral from "../../../models/BmpGeneral";
import "./ProjectList.scss"
import { BmpClassId } from "../../../models/lutModels/LutBmpClass";
import { SidebarTypes } from "../../mainWrapper/MainWrapper";

interface IProjectListProps {
    lookups: LookupFactory;
    bmpGeneral: Array<BmpGeneral>;
    onInfoClick: (bmpId: number, viewType: SidebarTypes) => void;
    editFunctions?: IProjectListEditProps;
    maxPerPage?: number;
    prettyProjectButtonTitle: string;
    hideNonStructuralInfo?: boolean;
    publicOrInternal: "public" | "internal";
}

interface IProjectListEditProps {
    updateSelection: (id: number) => void; // add a checked project
    updateSelectionAll: () => void; // check all projects
    checkedSet: Set<number> // checked projects
}

export const ProjectList = (props: IProjectListProps) => {
    const [activePage, setActivePage] = useState<number>(1);

    const onPageChange = useCallback((event: React.MouseEvent<HTMLAnchorElement>, pageInfo: PaginationProps) => {
        setActivePage(pageInfo.activePage as number);
    }, [setActivePage])

    return (
        <>
            <div className="project-list">
                <Table celled fixed unstackable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width="2">Name</Table.HeaderCell>
                            <Table.HeaderCell width="1">Type</Table.HeaderCell>
                            <Table.HeaderCell width="1">Status</Table.HeaderCell>
                            <Table.HeaderCell width="1">Info</Table.HeaderCell>
                            {
                                props.editFunctions &&
                                <Table.HeaderCell width="1">
                                    <div className="select-clear">
                                        <span><Checkbox onClick={() => { props.editFunctions?.updateSelectionAll() }} checked={props.editFunctions.checkedSet.size === props.bmpGeneral.length && props.bmpGeneral.length > 0} /></span>
                                        <div className="text">Select All</div>
                                    </div>
                                </Table.HeaderCell>
                            }
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {
                            props.bmpGeneral.length === 0 &&
                            <Table.Row>
                                <Table.Cell warning={true} colSpan={props.editFunctions ? "5" : "4"} className="no-results">
                                    No results
                                </Table.Cell>
                            </Table.Row>
                        }
                        {
                            props.bmpGeneral
                                .slice(props.maxPerPage ? props.maxPerPage * (activePage - 1) : undefined, props.maxPerPage ? props.maxPerPage * activePage : undefined)
                                .map((item: BmpGeneral, index: number) => {
                                    return (
                                        <Table.Row key={index}>
                                            <Table.Cell>{item.name}</Table.Cell>
                                            <Table.Cell>
                                                {item.bmpTypeId
                                                    ? props.lookups.getBmpTypeById(item.bmpTypeId)
                                                    : "N/A"}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {item.bmpStatusId
                                                    ? props.lookups.getBmpStatusById(item.bmpStatusId)
                                                    : "N/A"}
                                            </Table.Cell>
                                            <Table.Cell>
                                                <div className="summary-edit-wrapper">
                                                    {!(props.hideNonStructuralInfo && item.bmpClassId === BmpClassId.NonStructural) &&
                                                        <div className="summary"
                                                            onClick={() => props.onInfoClick(item.id, "PrettyProjectPage")}
                                                        >{props.prettyProjectButtonTitle}</div>
                                                    }
                                                    {props.editFunctions &&
                                                        <div className="edit"
                                                            onClick={() => props.onInfoClick(item.id, "EditProjectPage")}
                                                        >Edit</div>
                                                    }
                                                </div>
                                            </Table.Cell>
                                            {props.editFunctions &&
                                                <Table.Cell key={"selection" + item.id}><Checkbox onClick={() => props.editFunctions?.updateSelection(item.id)} checked={props.editFunctions.checkedSet.has(item.id)} /></Table.Cell>
                                            }
                                        </Table.Row>
                                    );
                                })
                        }
                    </Table.Body>
                </Table>
                {props.maxPerPage && props.bmpGeneral.length > 0 &&
                    <div className="pages">
                        <Pagination
                            totalPages={Math.ceil(props.bmpGeneral.length / props.maxPerPage)}
                            size='mini'
                            ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                            activePage={activePage}
                            onPageChange={onPageChange}
                        />
                    </div>
                }
            </div>
        </>
    )
}
