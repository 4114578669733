export interface WatershedReportPublicFacingConfigViewModel {
    watershedReportPublicFacingConfig: WatershedReportPublicFacingConfig | null;
    watershedReportPublicFacingConfigLinkInfo: WatershedReportPublicFacingConfigLinkInfo[];
}

export class WatershedReportPublicFacingConfig {
    watershedManagementGroupId: number;
    isDraft: boolean;
    programMessageEnabled: boolean;
    programMessageDescription: string | null;

    programSummaryVisualizationEnabled: boolean;
    programSummaryVisualizationDescription: string | null;

    programSummaryMultiBenefitsEnabled: boolean;
    programSummaryMultiBenefitsDescription: string | null;
    showCoBenefitCleanStreets: boolean | null;
    showCoBenefitParks: boolean | null;
    showCoBenefitHeatIsland: boolean | null;
    showCoBenefitFlooding: boolean | null;
    showCoBenefitWaterSupplyAugmentation: boolean | null;
    showCoBenefitNeighborhood: boolean | null;
    showCoBenefitJobCreation: boolean | null;
    showCoBenefitDisadvantaged: boolean | null;

    programProgressEnabled: boolean;
    programProgressDescription: string | null;
    programProgressClass: ClassDisplayOptions;

    programSummaryTableEnabled: boolean;
    programSummaryTableDescription: string | null;
    programSummaryTableClass: ClassDisplayOptions;
    programSummaryTableStatus: StatusDisplayOptions;

    projectDetailsMapEnabled: boolean;
    projectDetailsMapDescription: string | null;
    projectDetailsMapClass: ClassDisplayOptions;

    projectDetailsTableEnabled: boolean;
    projectDetailsTableDescription: string | null;
    projectDetailsTableClass: ClassDisplayOptions;

    additionalInfoEnabled: boolean;
    additionalInfoDescription: string | null;

    created: Date | string;
    updated: Date | string;

    constructor(
        wmgId: number,
        programMessageDefault: string,
        programSummaryVisualizationDescriptionDefault: string,
        programSummaryMultiBenefitsDescriptionDefault: string,
        programProgressDescriptionDefault: string,
        programSummaryTableDescriptionDefault: string,
        projectDetailsMapDescriptionDefault: string,
        projectDetailsTableDescriptionDefault: string,
        additionalInfoDescriptionDefault: string
    ) {
        this.watershedManagementGroupId = wmgId;
        this.isDraft = true;
        this.programMessageEnabled = true;
        this.programMessageDescription = programMessageDefault;

        this.programSummaryVisualizationEnabled = true;
        this.programSummaryVisualizationDescription = programSummaryVisualizationDescriptionDefault;

        this.programSummaryMultiBenefitsEnabled = true;
        this.programSummaryMultiBenefitsDescription = programSummaryMultiBenefitsDescriptionDefault;
        // only have 5 default active
        this.showCoBenefitCleanStreets = false;
        this.showCoBenefitParks = true;
        this.showCoBenefitHeatIsland = true;
        this.showCoBenefitFlooding = false;
        this.showCoBenefitWaterSupplyAugmentation = true;
        this.showCoBenefitNeighborhood = true;
        this.showCoBenefitJobCreation = false;
        this.showCoBenefitDisadvantaged = true;

        this.programProgressEnabled = true;
        this.programProgressDescription = programProgressDescriptionDefault;
        this.programProgressClass = ClassDisplayOptions.All;

        this.programSummaryTableEnabled = true;
        this.programSummaryTableDescription = programSummaryTableDescriptionDefault;
        this.programSummaryTableClass = ClassDisplayOptions.All;
        this.programSummaryTableStatus = StatusDisplayOptions.All;

        this.projectDetailsMapEnabled = true;
        this.projectDetailsMapDescription = projectDetailsMapDescriptionDefault;
        this.projectDetailsMapClass = ClassDisplayOptions.All;

        this.projectDetailsTableEnabled = true;
        this.projectDetailsTableDescription = projectDetailsTableDescriptionDefault;
        this.projectDetailsTableClass = ClassDisplayOptions.All;

        this.additionalInfoEnabled = true;
        this.additionalInfoDescription = additionalInfoDescriptionDefault;

        this.created = new Date();
        this.updated = new Date();
    }
}

export class WatershedReportPublicFacingConfigLinkInfo {
    watershedManagementGroupId: number;
    id: number | null;
    isDraft: boolean;
    linkUrl: string;
    linkText: string;
    linkDescription: string | null;
    displayWeight: number;
    tempId: number | null; // Only for frontend unsaved links, so that they can be located and deleted

    constructor(wmgId: number) {
        this.watershedManagementGroupId = wmgId;
        this.isDraft = true;
        this.displayWeight = 400;
        this.tempId = Date.now();
    }
}

function configIsEqual(first: WatershedReportPublicFacingConfig | undefined | null, other: WatershedReportPublicFacingConfig | undefined | null): boolean {
    if (!first || !other) return false;
    return objectsAreEqual(first, other);
}

function linkIsEqual(first: WatershedReportPublicFacingConfigLinkInfo | undefined | null, other: WatershedReportPublicFacingConfigLinkInfo | undefined | null): boolean {
    if (!first || !other) return false;

    return objectsAreEqual(first, other);
}

function listIsEqualTo(list1: Array<WatershedReportPublicFacingConfigLinkInfo>, list2: Array<WatershedReportPublicFacingConfigLinkInfo>): boolean {
    if (list1.length !== list2.length) {
        return false;
    }
    const list1Filtered = list1.filter(x => x.id); //exclude new links without IDs since that breaks this comparison
    const list2Filtered = list2.filter(x => x.id); //exclude new links without IDs since that breaks this comparison

    // Matches based on id
    return list1Filtered.every((link1) => linkIsEqual(link1, list2Filtered.find(link2 => link2.id === link1.id)));
}

// note: only does a shallow comparison!
function objectsAreEqual(obj1, obj2) {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
    if (keys1.length !== keys2.length) {
        return false;
    }
    return keys1.every(key => obj1[key] === obj2[key]);
}

export function compareConfigs(config1: WatershedReportPublicFacingConfig | null, config2: WatershedReportPublicFacingConfig | null, links1: Array<WatershedReportPublicFacingConfigLinkInfo>, links2: Array<WatershedReportPublicFacingConfigLinkInfo>): boolean {
    return (
        configIsEqual(config1, config2) && listIsEqualTo(links1, links2)
    )
}

export enum ClassDisplayOptions {
    All = 0,
    WCMOnly = 1
}

export enum StatusDisplayOptions {
    All = 0,
    CompleteOnly = 1,
    PlannedInProgressOnly = 2 // only used in frontend filters
}