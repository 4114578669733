import { useCallback } from "react";
import { Translation } from "react-i18next";
import { IProjectEditPageProps } from "../../sidebars/editProjectPage/EditProjectPage";
import BmpGeneralNewAndRedevelopment from "../../../models/BmpGeneralNewAndRedevelopment";
import { DatePickerComponent } from "../../../paradigmLib/domElements/datePickerComponent/DatePickerComponent";
import { InputComponent } from "../../../paradigmLib/domElements/inputComponent/InputComponent";
import { TextAreaComponent } from "../../../paradigmLib/domElements/textAreaComponent/TextAreaComponent";
import './ProjectEditPages.scss';

export const ProjNewAndRedevelopment = (props: IProjectEditPageProps) => {
    const setNewAndRedevelopment = useCallback((newNewAndRedevelopment: BmpGeneralNewAndRedevelopment) => {
        props.setProject({
            ...props.project,
            bmpGeneralNewAndRedevelopment: newNewAndRedevelopment
        });
    }, [props]);

    return props.project && props.project.bmpGeneralNewAndRedevelopment &&
        (
            <div className="edit-proj-additional">
                <Translation>
                    {
                        _ =>
                            <>
                                <InputComponent
                                    label="Municipal Project ID"
                                    placeHolder="12989"
                                    value={props.project.bmpGeneralNewAndRedevelopment.municipalProjectId}
                                    setValue={(value) => {
                                        const updated = props.project.bmpGeneralNewAndRedevelopment;
                                        updated.municipalProjectId = value;
                                        setNewAndRedevelopment(updated);
                                    }}
                                    inputType="string"
                                />
                                <div className="custom-date-picker-container">
                                    <DatePickerComponent
                                        label="Date of Acceptance"
                                        value={new Date(props.project.bmpGeneralNewAndRedevelopment.dateOfAcceptance)}
                                        setValue={(value) => {
                                            setNewAndRedevelopment({
                                                ...props.project.bmpGeneralNewAndRedevelopment,
                                                dateOfAcceptance: value
                                            })
                                        }}
                                    />
                                </div>
                                <div className="custom-date-picker-container">
                                    <DatePickerComponent
                                        label="Date of Maintenance Agreement"
                                        value={new Date(props.project.bmpGeneralNewAndRedevelopment.dateOfMaintenanceAgreement)}
                                        setValue={(value) => {
                                            setNewAndRedevelopment({
                                                ...props.project.bmpGeneralNewAndRedevelopment,
                                                dateOfMaintenanceAgreement: value
                                            })
                                        }}
                                    />
                                </div>
                                <TextAreaComponent
                                    label="Maintenance Records"
                                    placeHolder="Maintenance Records"
                                    value={props.project.bmpGeneralNewAndRedevelopment.maitenanceRecords}
                                    setValue={(value) => {
                                        setNewAndRedevelopment({
                                            ...props.project.bmpGeneralNewAndRedevelopment,
                                            maitenanceRecords: value
                                        })
                                    }}
                                    rows={5}
                                />
                                <TextAreaComponent
                                    label="1st Inspection Date and Summary"
                                    placeHolder="1st Inspection Date and Summary"
                                    value={props.project.bmpGeneralNewAndRedevelopment.firstInspectionDateAndSummary}
                                    setValue={(value) => {
                                        setNewAndRedevelopment({
                                            ...props.project.bmpGeneralNewAndRedevelopment,
                                            firstInspectionDateAndSummary: value
                                        })
                                    }}
                                    rows={2}
                                />
                                <TextAreaComponent
                                    label="1st Corrective Action"
                                    placeHolder="1st Corrective Action"
                                    value={props.project.bmpGeneralNewAndRedevelopment.firstCorrectiveAction}
                                    setValue={(value) => {
                                        setNewAndRedevelopment({
                                            ...props.project.bmpGeneralNewAndRedevelopment,
                                            firstCorrectiveAction: value
                                        })
                                    }}
                                    rows={5}
                                />
                                <div className="custom-date-picker-container">
                                    <DatePickerComponent
                                        label="Date Certificate of Occupancy Issued"
                                        value={new Date(props.project.bmpGeneralNewAndRedevelopment.dateCertificateOfOccupancyIssued)}
                                        setValue={(value) => {
                                            setNewAndRedevelopment({
                                                ...props.project.bmpGeneralNewAndRedevelopment,
                                                dateCertificateOfOccupancyIssued: value
                                            })
                                        }}
                                    />
                                </div>
                                <div className="custom-date-picker-container">
                                    <DatePickerComponent
                                        label="1st Replacement or Repair Date"
                                        value={new Date(props.project.bmpGeneralNewAndRedevelopment.firstReplacementOrRepairDate)}
                                        setValue={(value) => {
                                            setNewAndRedevelopment({
                                                ...props.project.bmpGeneralNewAndRedevelopment,
                                                firstReplacementOrRepairDate: value
                                            })
                                        }}
                                    />
                                </div>
                            </>
                    }
                </Translation>
            </div>)
}
