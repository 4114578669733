import { BaseModel } from "./BaseModel";


export default class WatershedReportForm1_3a extends BaseModel {

    id: number;
    watershedReportFormId: number;
    text: string;
    active: number;
    editor: string;
    created: Date;
    updated: Date;

    constructor() {
        super("WatershedReportForm1_3a", "local")
    }
}