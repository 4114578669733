import React, { useContext } from "react"
import { Message } from "semantic-ui-react"
import { GlobalAuthInfo, GlobalAuthInfoContext } from "../mainWrapper/MainWrapper";
import { useAuth } from "react-oidc-context";

export interface IPermissionsStatusProps {
    hasPermissions: boolean;
    children?: React.ReactNode;
}

/**
 * Allows a page to show the status of the authorization
 * easily:
 * - If the auth token is undefined then the page hasn't fully loaded yet
 * - If the auth token is defined but is null, th en the user hasn't logged in
 * - If the auth token is not null or undefined but we have no permissions, then we can also display that
 *
 * If this component has children they will only be displayed if the auth token is valid and the
 * hasPermissions property is set to true
 */
export const PermissionsStatusComponent: React.FC<IPermissionsStatusProps> = (props: React.PropsWithChildren<IPermissionsStatusProps>) => {
    const auth = useAuth();

    return (
        <>
            {
                auth.isLoading &&
                <div className="alert-msg-div">
                    <Message info>Loading...</Message>
                </div>
            }
            {
                auth.user == null && !auth.isLoading &&
                <div className="alert-msg-div">
                    <Message info>Please log in to access the jurisdiction or watershed management group info.</Message>
                </div>
            }
            {
                auth.user && !props.hasPermissions &&
                <div className="alert-msg-div">
                    <Message color='yellow'>You either do not have access to reporting or have not yet been assigned to a jurisdiction or watershed group. Please contact the system admin or the primary user for your jurisdiction to receive access.</Message>
                </div>
            }
            {
                auth.user && props.hasPermissions &&
                <>
                    {props.children}
                </>
            }
        </>
    )
}
