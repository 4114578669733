import { Translation } from "react-i18next";
import LookupFactory from "../../../lookups/LookupFactory";
import BmpGeneral from "../../../models/BmpGeneral";
import BmpGeneralNewAndRedevelopment from "../../../models/BmpGeneralNewAndRedevelopment";
import { DatePickerComponent } from "../../../paradigmLib/domElements/datePickerComponent/DatePickerComponent";
import { ToggleComponent } from "../../../paradigmLib/domElements/toggleComponent/ToggleComponent";
import DropdownLutH2O from "../../../paradigmLib/dropdownLut/DropdownLut";
import './ProjectEditPages.scss';
import { BmpClassId } from "../../../models/lutModels/LutBmpClass";

export interface IProjPlanningProps {
    lookups: LookupFactory;
    project: BmpGeneral;
    setProject: (project: BmpGeneral) => void;
}

export const ProjPlanningStatus: React.FC<IProjPlanningProps> = (props: IProjPlanningProps) => {
    const onStatusDropdownSelect = (_: any, data: any) => {
        let stateCopy: any = { ...props.project };
        stateCopy.bmpStatusId = data.value;
        props.setProject(stateCopy);
    }

    const onTechAssistanceDropdownSelect = (_: any, d: any) => {
        let stateCopy: any = { ...props.project };
        stateCopy.bmpTechnicalAssistanceId = d.value;
        props.setProject(stateCopy);
    }

    const setNewAndRedevelopment = (newNewAndRedevelopment: BmpGeneralNewAndRedevelopment) => {
        props.setProject({
            ...props.project,
            bmpGeneralNewAndRedevelopment: newNewAndRedevelopment
        });
    }

    return (

        <div className="edit-proj-planning-status">
            {props.project.bmpClassId === BmpClassId.NewAndRedevelopment &&
                <DropdownLutH2O
                    //Only show the generic Planned for New and Redev
                    dropdownItems={props.lookups ? props.lookups.lutBmpStatus.filter(x => x.internalName === "inProgress" || x.internalName === "complete" || x.internalName === "internal") : []}
                    dropdownTitle={"Status"}
                    isMultiselect={false}
                    nullLabel="Please select status"
                    onSelect={onStatusDropdownSelect}
                    parentId={props.project.bmpStatusId ? props.project.bmpStatusId : -1}
                />
            }
            {(props.project.bmpClassId === BmpClassId.WatershedControlMeasure || props.project.bmpClassId === BmpClassId.NonStructural) &&
                <DropdownLutH2O
                    // Dont show generic Planned for WCMs
                    dropdownItems={props.lookups ? props.lookups.lutBmpStatus.filter(x => x.internalName !== "inProgress") : []}
                    dropdownTitle={"Status"}
                    isMultiselect={false}
                    nullLabel="Please select status"
                    onSelect={onStatusDropdownSelect}
                    parentId={props.project.bmpStatusId ? props.project.bmpStatusId : -1}
                />
            }

            <div className="custom-date-picker-container">
                <DatePickerComponent
                    label="BMP Completion Date"
                    value={new Date(props.project.bmpCompletionDate)}
                    setValue={(value) => {
                        props.setProject({
                            ...props.project,
                            bmpCompletionDate: value
                        })
                    }}
                />
            </div>

            {props.project.bmpClassId === BmpClassId.WatershedControlMeasure &&
                <div className="custom-date-picker-container">
                    <DatePickerComponent
                        label="Required Completion Date in WMP"
                        value={new Date(props.project.requiredCompletionDate)}
                        setValue={(value) => {
                            props.setProject({
                                ...props.project,
                                requiredCompletionDate: value
                            })
                        }}
                    />
                </div>
            }

            <div className="subpage-item-wrapper mt-1point4">
                <div className="subpage-item-title">Additional Permittee(s) Collaborated with for Achievement of Milestones:</div>
                <div className="subpage-item-content">
                    {props.project.bmpGeneralAdditionalPermittees.map(({ jurisdictionId }, index) => {
                        return (
                            <>
                                <div key={index}>
                                    {props.lookups.getJurisdictionById(jurisdictionId)}
                                    {index < (props.project.bmpGeneralAdditionalPermittees.length - 1)
                                        ? ", "
                                        : ""}&nbsp;
                                </div>
                            </>
                        );
                    })}
                </div>
            </div>
            {/* JG - comes in as a list of ids that will have to be joined with Jurisdiction table. Also make a multiselect */}

            {(props.project.bmpClassId === BmpClassId.WatershedControlMeasure || props.project.bmpClassId === BmpClassId.NonStructural) &&
                <>
                    <div className="subpage-item-wrapper">
                        <div className="subpage-item-title">Non-Permittee(s) Collaborated with for Achievement of Milestones:</div>
                        <div className="subpage-item-content">
                            {props.project.bmpGeneralNonPermittees.map(({ nonPermittee }, index) => {
                                return (
                                    <>
                                        <div key={index}>
                                            {nonPermittee}
                                            {index < (props.project.bmpGeneralNonPermittees.length - 1)
                                                ? ", "
                                                : ""}&nbsp;
                                        </div>
                                    </>
                                );
                            })}
                        </div>
                    </div>
                    {/* <h5>Non-Permittee(s) Collaborated with for Achievement of Milestones</h5>
                    <p>
                        {props.projectNonPermittees.map(({ nonPermittee }) => (
                            nonPermittee + "; "
                        ))}
                    </p> */}
                </>
                // comes in as a list of strings. not sure how to have them edit this. mutliselect plus add?????
            }

            {(props.project.bmpClassId === BmpClassId.WatershedControlMeasure || props.project.bmpClassId === BmpClassId.NonStructural) &&
                <DropdownLutH2O
                    dropdownItems={props.lookups ? props.lookups.lutBmpTechnicalAssistance : []}
                    dropdownTitle={"Technical Assistance Received"}
                    isMultiselect={false}
                    nullLabel="Technical Assistance Received"
                    onSelect={onTechAssistanceDropdownSelect}
                    parentId={props.project.bmpTechnicalAssistanceId ? props.project.bmpTechnicalAssistanceId : -1}
                />
            }

            {props.project.bmpClassId === BmpClassId.NewAndRedevelopment &&
                <div className="pt-1">
                    <ToggleComponent
                        sideLabel="Is this a Priority Development Project?"
                        value={props.project.bmpGeneralNewAndRedevelopment.priorityDevelopmentProject === 1}
                        setValue={(value) => setNewAndRedevelopment({
                            ...props.project.bmpGeneralNewAndRedevelopment,
                            priorityDevelopmentProject: value ? 1 : 0
                        })}
                        theme={"primary"}
                    />
                </div>
            }
            {props.project.bmpClassId === BmpClassId.NewAndRedevelopment &&
                <div className="pt-1">
                    <ToggleComponent
                        sideLabel="Project Exempt From Priority Development Project Structural BMP Performance Requirements?"
                        value={props.project.bmpGeneralNewAndRedevelopment.exemptPriorityDevelopmentPerformanceRequirements === 1}
                        setValue={(value) => setNewAndRedevelopment({
                            ...props.project.bmpGeneralNewAndRedevelopment,
                            exemptPriorityDevelopmentPerformanceRequirements: value ? 1 : 0
                        })}
                        theme={"primary"}
                    />
                </div>
            }
            {props.project.bmpClassId === BmpClassId.NewAndRedevelopment &&
                <div className="pt-1">
                    <ToggleComponent
                        sideLabel="Project Requires Hydromodification Controls Within this Reporting Period?"
                        value={props.project.bmpGeneralNewAndRedevelopment.requiresHydromodificationControlsReportingPeriod === 1}
                        setValue={(value) => setNewAndRedevelopment({
                            ...props.project.bmpGeneralNewAndRedevelopment,
                            requiresHydromodificationControlsReportingPeriod: value ? 1 : 0
                        })}
                        theme={"primary"}
                    />
                </div>
            }
            {props.project.bmpClassId === BmpClassId.NewAndRedevelopment &&
                <div className="pt-1">
                    <ToggleComponent
                        sideLabel="Project Exempt From Implementation of Hydromodification Controls?"
                        value={props.project.bmpGeneralNewAndRedevelopment.exemptImplementationHydromodificationControls === 1}
                        setValue={(value) => setNewAndRedevelopment({
                            ...props.project.bmpGeneralNewAndRedevelopment,
                            exemptImplementationHydromodificationControls: value ? 1 : 0
                        })}
                        theme={"primary"}
                    />
                </div>
            }
        </div>
    )
}
