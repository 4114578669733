import { useContext, useEffect, useState } from "react";
import { Table } from "semantic-ui-react";
import { Translation } from "react-i18next";
import { GlobalAuthInfoContext, IGlobalAuthInfo } from "../../../mainWrapper/MainWrapper";
import LookupFactory from "../../../../lookups/LookupFactory";
import { InputComponent } from "../../../../paradigmLib/domElements/inputComponent/InputComponent";
import InterestedParties from "../../../../models/InterestedParties";
import { ButtonComponent } from "../../../../paradigmLib/domElements/buttonComponent/ButtonComponent";
import { ConfirmDeleteModal } from "../../../modals/ConfirmDeleteModal/ConfirmDeleteModal";
import "./ManageInterestedParties.scss";
import { GetAll, Post, Put } from "../../../../core/DownloadUtil";
import DropdownLutH2O from "../../../../paradigmLib/dropdownLut/DropdownLut";
import { useAuth } from "react-oidc-context";
export interface IManageInterestedPartiesProps {
    lookups: LookupFactory;
}

export const ManageInterestedParties: any = (props: IManageInterestedPartiesProps) => {
    const auth = useAuth();
    const globalAuthInfoContext = useContext(GlobalAuthInfoContext);

    const [errorMessage, setErrorMessage] = useState<boolean>(false);

    const [interestedParties, setInterestedParties] = useState<InterestedParties>(new InterestedParties());

    const [interestedPartiesToDelete, setInterestedPartiesToDelete] = useState<InterestedParties>(new InterestedParties());
    const [date, setDate] = useState(new Date());

    const [interestedPartiesList, setInterestedPartiesList] = useState<Array<InterestedParties>>(new Array<InterestedParties>());

    const getParties = async () => {
        let interestedParties = await GetAll<InterestedParties>(
            InterestedParties.endpoint,
            auth.user?.access_token,
            { active: 1 });
        setInterestedPartiesList(interestedParties);
    };

    const onSelect = (val: number) => {
        let paramCopy = { ...interestedParties }
        let saveEdit: boolean = false;
        paramCopy.jurisdictionId = val;
        saveEdit = true;

        if (saveEdit) {
            setInterestedParties(paramCopy);
        }
    };

    const onSave = async () => {
        if (
            interestedParties.fullName !== undefined &&
            interestedParties.emailAddress !== undefined &&
            interestedParties.emailAddress.includes("@") &&
            interestedParties.jurisdictionId !== undefined
        ) {
            let stateCopy: any = { ...interestedParties };
            stateCopy.active = 1;
            setInterestedParties(stateCopy);
            await Post(
                InterestedParties.endpoint,
                auth.user?.access_token,
                stateCopy
            );
            await getParties();

            setInterestedParties(new InterestedParties());

            setErrorMessage(false);
        } else {
            setErrorMessage(true);
        }
    };

    const onDeleteItem = async (intparty: InterestedParties) => {
        let objCopy: InterestedParties = intparty;
        objCopy.active = 0;
        await Put(InterestedParties.endpoint, auth.user?.access_token, objCopy);
        await getParties();
        setInterestedPartiesToDelete(intparty);
    }

    useEffect(() => {
        getParties()
    }, []);

    return (
        <div className="interested-parties">
            <Translation>
                {(t) => (
                    <>
                        <GlobalAuthInfoContext.Consumer>
                            {(authInfo: IGlobalAuthInfo) => {
                                return (
                                    <div className="content-div">
                                        <div className="page-subheader">
                                            Manage Interested Parties
                                        </div>
                                        <div key={`${date}`}>
                                            <InputComponent
                                                label="Full Name"
                                                placeHolder="Jane Smith"
                                                value={interestedParties.fullName}
                                                setValue={(value) => setInterestedParties({ ...interestedParties, fullName: value })}
                                                inputType="text"
                                            />
                                            <InputComponent
                                                label="Email"
                                                placeHolder="janesmith@123.com"
                                                value={interestedParties.emailAddress}
                                                setValue={(value) => setInterestedParties({ ...interestedParties, emailAddress: value })}
                                                inputType="email"
                                            />
                                            {authInfo.roles &&
                                                authInfo.visibleComponents.indexOf(
                                                    "buildWatershedFormJuris"
                                                ) > -1 && (
                                                    <div>
                                                        <DropdownLutH2O
                                                            dropdownItems={globalAuthInfoContext.jurisdictionList ?? []}
                                                            dropdownTitle="Jurisdiction"
                                                            isMultiselect={false}
                                                            nullLabel={null}
                                                            onSelect={(_, d) => onSelect(d.value)}
                                                            ddSlim={true}
                                                            search={false}
                                                            parentId={interestedParties.jurisdictionId}
                                                        />
                                                    </div>
                                                )}
                                            <div className="action-div">
                                                {errorMessage && (
                                                    <span className="text-danger mr-2 mb-point5">
                                                        ***Please fill out all
                                                        fields
                                                    </span>
                                                )}
                                                <div className="button-div">
                                                    <ButtonComponent
                                                        onBtnClick={() => onSave()}
                                                        label="Add"
                                                        theme={"primaryBtn"}
                                                        disabled={globalAuthInfoContext && globalAuthInfoContext.jurisdictionList.length === 0}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="interested-parties-table">
                                            {interestedPartiesList.length > 0 && (
                                                <Table celled fixed singleLine>
                                                    <Table.Header>
                                                        <Table.Row>
                                                            <Table.HeaderCell width="2">
                                                                Full Name
                                                            </Table.HeaderCell>
                                                            <Table.HeaderCell width="2">
                                                                Email
                                                            </Table.HeaderCell>
                                                            <Table.HeaderCell width="2">
                                                                Jurisdiction
                                                            </Table.HeaderCell>
                                                            <Table.HeaderCell
                                                                width="1"
                                                                textAlign="center"
                                                            >
                                                                Delete
                                                            </Table.HeaderCell>
                                                        </Table.Row>
                                                    </Table.Header>
                                                    <Table.Body>
                                                        {interestedPartiesList.map((intparty: InterestedParties) => {
                                                            return (
                                                                <Table.Row>
                                                                    <Table.Cell>
                                                                        {
                                                                            intparty.fullName
                                                                        }
                                                                    </Table.Cell>
                                                                    <Table.Cell>
                                                                        {
                                                                            intparty.emailAddress
                                                                        }
                                                                    </Table.Cell>
                                                                    <Table.Cell>
                                                                        {
                                                                            props.lookups.getJurisdictionById(intparty.jurisdictionId)
                                                                        }
                                                                    </Table.Cell>
                                                                    <Table.Cell textAlign="center">
                                                                        <ConfirmDeleteModal
                                                                            icon="trash"
                                                                            confirmDeleteFunc={onDeleteItem}
                                                                            itemToDelete={intparty}
                                                                            displayName={`${intparty.fullName}, ${intparty.emailAddress}`}
                                                                            theme="primary"
                                                                        />
                                                                    </Table.Cell>
                                                                </Table.Row>
                                                            );
                                                        }
                                                        )}
                                                    </Table.Body>
                                                </Table>
                                            )}
                                        </div>
                                    </div>
                                );
                            }}
                        </GlobalAuthInfoContext.Consumer>
                    </>
                )}
            </Translation>
        </div>
    );
};

