import { BaseModel } from "./BaseModel";


export default class WatershedGroupOutput extends BaseModel {


    id: number;
    watershedManagementGroupId: number;
    reportingPeriodId: number;
    lutWatershedGroupOutputTypeId: number;
    lutLanguageId: number;
    publicDocumentId: number;
    published: number;
    active: number;
    editor: string | null;
    created: Date;
    updated: Date;


    constructor() {
        super("WatershedGroupOutput", "local")
    }
}