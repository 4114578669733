import axios, { AxiosError, AxiosInstance, AxiosPromise, AxiosResponse } from "axios";
import { useEffect, useRef } from "react";
import Config from "../config/Config";
import LookupFactory from "../lookups/LookupFactory";
import { BaseModel } from "../models/BaseModel";
import { Get } from "./DownloadUtil";

export type ContentType = "application/json;charset=UTF-8" | "multipart/form-data";

function getAxiosBase(authToken: string, baseUrl: string, contentType?: ContentType): AxiosInstance {
    return axios.create({
        baseURL: baseUrl,
        headers: {
            "Content-Type": contentType ?? "application/json;charset=UTF-8",
            Accept: contentType,
            "Authorization": `Bearer ${authToken}`
        }
    });
}

function axiosExcelPost(authToken: string, formData: FormData, model: BaseModel): AxiosPromise {
    return getAxiosBase(authToken, model.baseUrl, 'multipart/form-data')({
        method: 'POST',
        url: model.endpoint,
        data: formData
    })
}

function axiosDelete(authToken: string, model: BaseModel) {
    return getAxiosFileBase(authToken, 'application/json;charset=UTF-8', model)({
        method: 'DELETE',
        url: model.endpoint + "/" + model.id,
    })
};

function getAxiosFileBase(authToken: string, contentType: ContentType, model: BaseModel): AxiosInstance {
    return axios.create({
        baseURL: model.baseUrl,
        url: model.endpoint + "/pdf",
        headers: {
            "Access-Control-Allow-Origin": '*',
            "Content-Type": contentType,
            "Authorization": `Bearer ${authToken}`
        }
    });
}

function axiosFetchAllLocal(authToken: string, model: BaseModel, params: any): AxiosPromise<BaseModel[]> {
    return getAxiosBase(authToken, model.baseUrl)({
        method: "GET",
        url: model.endpoint,
        params: params,
        // important, apply the type to the response
        transformResponse: (data) => {
            return JSON.parse(data)
                .filter(d => {
                    return d.active === 1 || d.active === true
                })
                .map(d => {
                    let modelCopy = Object.create(model);
                    let newObject = Object.assign(modelCopy, d);
                    newObject["endpoint"] = model.endpoint;
                    newObject["baseUrl"] = model.baseUrl;
                    return newObject;
                })
        }
    });
}

export function useDeleteModel(authToken: string, model: BaseModel, setModel: (model: any) => void, callback?: (response: AxiosResponse) => void) {
    useEffect(() => {
        if (model) {
            axiosDelete(authToken, model).then((r: any) => {
                setModel(null);
                if (callback) {
                    callback(r)
                };
            }).catch(() => {
                // didn't delete
            });
        };
    }, [model])
}

export function getAxiosApiBase(authToken: string) {
    return getAxiosBase(authToken, Config.api_endpoint);
}

function axiosHandlePutSingleLocal(authToken: string, model: BaseModel): AxiosPromise<BaseModel[]> {
    return getAxiosBase(authToken, model.baseUrl)({
        method: 'PUT',
        url: model.endpoint + "/" + model.id,
        data: model,
        // important, apply the type to the response 
        transformResponse: (data) => {
            return Object.assign(Object.create(model), JSON.parse(data))
        }
    });
}

function axiosHandlePostSingleLocal(authToken: string, model: BaseModel): AxiosPromise<BaseModel[]> {
    return getAxiosBase(authToken, model.baseUrl)({
        method: 'POST',
        url: model.endpoint,
        data: model,
        // important, apply the type to the response 
        transformResponse: (data) => {
            return Object.assign(Object.create(model), JSON.parse(data))
        }
    });
}

function axiosPost(authToken: string, model: BaseModel) {
    //reset model id, this could be reworked and models could have alternate identifier than id for post/put/delete
    model.id = 0;
    return getAxiosBase(authToken, model.baseUrl)({
        method: 'POST',
        url: model.endpoint,
        data: model
    })
}

function axiosPut(authToken: string, model: BaseModel) {
    return getAxiosBase(authToken, model.baseUrl)({
        method: 'PUT',
        url: model.endpoint + "/" + model.id,
        data: model
    })
}

function axiosPDFGet(authToken: string, model: BaseModel): AxiosPromise {
    return getAxiosFileBase(authToken, 'multipart/form-data', model)({
        method: 'GET',
        url: model.endpoint + "/pdf/" + model.id,
        responseType: 'blob'
    })
};

// params take query string filters. pass empty object if you dont need to filter anything. 
export function useAPIFetchAllLocal(authToken: string, model: BaseModel, fetching: boolean, setFetching: (fetching: any) => any, setResults: (data: any) => any, params: any, callback?: (data: any) => void) {
    useEffect(() => {
        if (fetching) {
            axiosFetchAllLocal(authToken, model, params)
                .then((r: AxiosResponse<BaseModel[]>) => {
                    setResults(r.data);
                    setFetching(false);
                    if (callback) {
                        callback(r.data);
                    }
                }).catch((d) => {
                    setFetching(false);
                    console.error("fail", d);
                });
        }
    }, [fetching])
}

export function useAPIGetByIdLocal(authToken: string, model: BaseModel, fetching: boolean, setFetching: (fetching: any) => any, setResults: (data: any) => any, params: any, callback?: (data: any) => void) {
    useEffect(() => {
        if (fetching) {
            axiosFetchAllLocal(authToken, model, params)
                .then((r: AxiosResponse<BaseModel[]>) => {
                    if (r != null && r.data != null && r.data.length > 0) {
                        setResults(r.data[0]);
                    }
                    setFetching(false);
                    if (callback) {
                        callback(r.data[0]);
                    }
                }).catch((d) => {
                    setFetching(false);
                    console.error("fail", d);
                });
        }
    }, [fetching])
}

export function usePutPostLocal<T extends BaseModel>(authToken: string, model: T, callback: (data: any) => void, dependencies: any[]) {
    const isLoaded = useRef(false);

    useEffect(() => {
        if (isLoaded.current) {
            if (model !== undefined) {
                if (model.id !== undefined && model.id > 0) {
                    axiosHandlePutSingleLocal(authToken, model)
                        .then((r) => {
                            callback(r.data);
                        })
                        .catch((r) => {
                            console.error("fail", r);
                        });
                } else if (model.id === undefined) {
                    axiosHandlePostSingleLocal(authToken, model)
                        .then((r) => {
                            callback(r.data);
                        })
                        .catch((r) => {
                            console.error("fail", r);
                        });
                };
            }
        } else {
            isLoaded.current = true;
        };

    }, dependencies)
}

export function usePutPostLocalOnlyWhenTrue<T extends BaseModel>(authToken: string, model: T, callback: (data: any) => void, dependencies: any[]) {
    const isLoaded = useRef(false);
    useEffect(() => {
        if (isLoaded.current) {
            if (dependencies[0]) {
                if (model !== undefined) {
                    if (model.id !== undefined && model.id > 0) {
                        axiosHandlePutSingleLocal(authToken, model)
                            .then((r) => {
                                callback(r.data);
                            })
                            .catch((r) => {
                                console.error("fail", r);
                            });
                    } else if (model.id === undefined) {
                        axiosHandlePostSingleLocal(authToken, model)
                            .then((r) => {
                                callback(r.data);
                            })
                            .catch((r) => {
                                console.error("fail", r);
                            });
                    };
                }
            }
        } else {
            isLoaded.current = true;
        };

    }, dependencies)
}

export function usePutPostLocalWithSet<T extends BaseModel>(authToken: string, model: T, setResults: (data: any) => any, fetching: boolean, setFetching: (fetching: any) => any, callback?: (data: any) => void) {
    useEffect(() => {
        if (fetching === true) {
            if (model !== undefined) {
                if (model.id !== undefined && model.id > 0) {
                    axiosHandlePutSingleLocal(authToken, model)
                        .then((r) => {
                            let modelCopy = Object.create(model);
                            let newObject = Object.assign(modelCopy, r.data);
                            newObject["endpoint"] = model.endpoint;
                            newObject["baseUrl"] = model.baseUrl;
                            setResults(newObject);
                            setFetching(false);
                            if (callback) {
                                callback(newObject);
                            }
                        })
                        .catch((r) => {
                            setFetching(false);
                            console.error("fail", r);
                        });
                } else if (model.id === undefined) {
                    axiosHandlePostSingleLocal(authToken, model)
                        .then((r) => {
                            let modelCopy = Object.create(model);
                            let newObject = Object.assign(modelCopy, r.data);
                            newObject["endpoint"] = model.endpoint;
                            newObject["baseUrl"] = model.baseUrl;
                            setResults(newObject);
                            setFetching(false);
                            if (callback) {
                                callback(newObject);
                            }
                        })
                        .catch((r) => {
                            setFetching(false);
                            console.error("fail", r);
                        });
                };
            }
        }
    }, [fetching])
}

export function useUpdateManyLocal(authToken: string, models: BaseModel[], setBaseModels: (updatedModels: any) => void, updatingModels: boolean, setUpdatingModels: (updatingModels: any) => void, callBack: (results: any) => void) {
    useEffect(() => {
        if (updatingModels) {
            let promiseArray = [];

            for (let i = 0; i < models.length; i++) {
                let currentModel: BaseModel = models[i];

                if (currentModel.id === undefined || currentModel.id === 0) {
                    promiseArray.push(axiosPost(authToken, currentModel));
                };

                if (currentModel.id !== undefined && currentModel.id > 0) {
                    promiseArray.push(axiosPut(authToken, currentModel));
                };
            };

            axios.all(promiseArray)
                .then((results) => {
                    let updatedModels = results.map(r => r.data)
                        .filter((d: any) => {
                            return d.active === 1
                        });

                    setUpdatingModels(false);
                    setBaseModels(updatedModels);
                    callBack(updatingModels);
                })
                .catch((r) => {
                    setUpdatingModels(false);
                    console.error(r, "error updating many");
                })
        }
    }, [updatingModels])
}

export function useDeleteModelLocal(authToken: string, model: BaseModel, setModel: (model: any) => void, callback?: (response: AxiosResponse) => void) {
    useEffect(() => {

        if (model) {
            axiosDelete(authToken, model).then((r) => {

                setModel(null);

                if (callback) {
                    callback(r)
                };

            }).catch((r) => {
                // didn't delete
            });
        };
    }, [model])
}

export function useAPIDownloadPdfLocal(authToken: string, model: BaseModel, fetching: boolean, setFetching: (fetching: any) => any, filename: string, callback?: (data: any) => void, mimetype: string = 'application/pdf') {
    // const userData = useContext(UserData);

    useEffect(() => {
        if (!fetching) {
            return;
        }

        axiosPDFGet(authToken, model)
            .then((r: AxiosResponse) => {
                setFetching(false);
                const blob = new Blob([r.data], { type: mimetype });
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob, filename);
                } else {
                    const blob = new Blob([r.data], { type: mimetype });
                    var link = document.createElement('a');
                    var href = window.URL.createObjectURL(blob);
                    window.open(href, "_blank");
                    /*
                    link.setAttribute('href', href);
                    link.setAttribute('download', filename);
                    link.setAttribute('target', '_blank');
                    document.body.appendChild(link);
                    link.click();
                    setTimeout(() => {
                        window.URL.revokeObjectURL(href);
                        document.body.removeChild(link);
                    }, 0)
                    */
                }

                if (callback) {
                    callback(model);
                }
            }).catch((d) => {
                setFetching(false);
                console.error("fail");
            });
    }, [fetching])
}

export function useAPIUploadExcelLocal<T extends BaseModel>(authToken: string, callback: (success: boolean, message: string) => void, type: new () => T, formData: FormData, dependencies: any[]) {
    const hasMounted = useRef(false);
    useEffect(() => {
        if (hasMounted.current) {
            axiosExcelPost(authToken, formData, new type())
                .then((r: AxiosResponse) => {
                    callback(true, r.statusText);
                })
                .catch((r: AxiosError) => {
                    console.error("excel post fail", r);
                    callback(false, r.response.data);
                });
        } else {
            hasMounted.current = true;
        };
    }, dependencies);
}

export function useAPIUploadExcelQueueLocal<T extends BaseModel>(authToken: string, callback: (success: boolean, message: string) => void, type: new () => T, formData: FormData, dependencies: any[]) {
    const hasMounted = useRef(false);
    useEffect(() => {
        if (hasMounted.current) {
            axiosExcelPost(authToken, formData, new type())
                .then((r: AxiosResponse) => {
                    callback(true, r.statusText);
                })
                .catch((r: AxiosError) => {
                    console.error("excel post fail", r);
                    callback(false, r.response.data);
                });
        } else {
            hasMounted.current = true;
        };
    }, dependencies);
}

function axiosPDFPostLocal(authToken: string, model: BaseModel, formData: FormData): AxiosPromise {
    return getAxiosFileBase(authToken, 'multipart/form-data', model)({
        method: 'POST',
        url: model.endpoint + "/pdf",
        data: formData,
        transformResponse: (data) => {
            let jsonHolder = JSON.parse(data);
            let modelCopy = Object.create(model);
            let newObject = Object.assign(modelCopy, jsonHolder);
            newObject["endpoint"] = model.endpoint;
            newObject["baseUrl"] = model.baseUrl;
            return newObject
        }
    })
};

function updateDataModelState(newModel: BaseModel, oldModel: BaseModel, setModel: (model: BaseModel) => void) {
    const updatedModel = Object.assign(oldModel, newModel);
    setModel(updatedModel);
}

export function usePDFPost(authToken: string, model: BaseModel, setPDFModel: (model: any) => void, formData: FormData, updatePDFModel: boolean, setUpdatePDFModel: (updateModel: boolean) => void, callback?: (model: BaseModel) => void) {
    useEffect(() => {
        if (!updatePDFModel) {
            return;
        }
        if (model.id !== undefined && model.id > 0) {
            return;
        }

        for (var key of Object.keys(model)) {
            if (model[key] && typeof model[key] !== "function") {
                formData.append(key, model[key]);
            }
        }

        //formData.append("attachmentData", JSON.stringify(model));
        axiosPDFPostLocal(authToken, model, formData).then((r) => {
            updateDataModelState(r.data, model, setPDFModel);
            if (callback) {
                callback(r.data);
            };
            setUpdatePDFModel(false);
        }).catch((r: any) => {
            if (callback) {
                callback(r.data);
            };
            setUpdatePDFModel(false);
        });
    }, [updatePDFModel])
}

export function useLookupTablesLocal(authToken: string, setFetchingLookup: Function, fetchingLookups: boolean, setResults: Function) {
    useEffect(() => {
        if (fetchingLookups) {
            Get<LookupFactory>("lookups", authToken).then((result) => {
                var lookups = Object.assign(new LookupFactory(), result);
                setResults(lookups);
                setFetchingLookup(false);
            }).catch(console.error);
        }
    }, [fetchingLookups])
}
