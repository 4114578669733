import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { ClassDisplayOptions, WatershedReportPublicFacingConfig, WatershedReportPublicFacingConfigLinkInfo } from "../../../../models/viewModels/WatershedReportPublicFacingConfigViewModel";
import { PublicElementSection } from "../previewPublic/PublicElementSection";
import { SnapshotToBmpGeneral, WatershedReportSnapshotBmp } from "../../../../models/WatershedReportSnapshot";
import { DashboardBubbles } from "./dashboardBubbles/DashboardBubbles";
import WelcomeViewModel from "../../../../models/viewModels/WelcomeViewModel";
import { PublicConfigLinks } from "../PublicConfigLinks";
import { ProgramSummaryTable } from "./ProgramSummaryTable";
import LookupFactory from "../../../../lookups/LookupFactory";
import { ProgramProgressGraph } from "./ProgramProgressGraph";
import { useDimensions } from "../../../../core/UseDimensions";
import { ProjectDetailsMap } from "./Map/ProjectDetailsMap";
import { ProgramSummaryVisual } from "./ProgramSummaryVisual";
import { ProjectList } from "../../manageProjectData/ProjectList";
import BmpGeneral from "../../../../models/BmpGeneral";
import "./WmgPublicDashboard.scss";
import { BmpClassId } from "../../../../models/lutModels/LutBmpClass";
import { PrettyProjectPageTypes } from "../../../mainWrapper/MainWrapper";

export interface IWMGPublicDashboard {
    wmgId: number;
    wmgPublicData: Array<WatershedReportSnapshotBmp>; // came from GenerateData
    wmgPublicInfo: WatershedReportPublicFacingConfig;
    wmgPublicInfoLinks: Array<WatershedReportPublicFacingConfigLinkInfo>;
    lookups: LookupFactory;
    setSidebarProjectInfoObj: Dispatch<SetStateAction<BmpGeneral>>;
    publicOrInternal: "public" | "internal";
    dispatchSidebar: Function;
    useLiveData?: boolean;
    setSidebarVisible?: Dispatch<SetStateAction<boolean>>;
    snapshotDate?: Date;
}

export const WMGPublicDashboard: React.FC<IWMGPublicDashboard> = (props: IWMGPublicDashboard) => {
    const [bubbleInfo, setBubbleInfo] = useState(new WelcomeViewModel());

    const [divRef, dimensions] = useDimensions();
    const chartWidth = useMemo(() => {
        if (!dimensions) return 0;

        return dimensions.width > 1000 ? 1000 : dimensions.width * 0.9
    }, [dimensions])

    const handleDetailsClick = (e: any, wmgPublicData, setSidebarProjectInfoObj, dispatchSidebar) => {
        if (!e.results[0] || e.results[0].layer.id === "New & Redevelopment Projects") {
            return;
        }
        const bmpSnap = wmgPublicData.find(project => project.bmpGeneralId === e.results[0]?.graphic.attributes.bmpGeneralId)
        const bmpGeneralData = SnapshotToBmpGeneral(bmpSnap, props.wmgId);
        setSidebarProjectInfoObj(bmpGeneralData);
        dispatchSidebar({
            type: "open",
            payload: { name: "PrettyProjectPage", content: {prettyProjectPageType: PrettyProjectPageTypes.BmpGeneral} },
        });
    }

    useEffect(() => {
        let newBubbleData: WelcomeViewModel = new WelcomeViewModel();
        let filteredBMPArray = props.wmgPublicData.filter((bmp) => (bmp.lutBmpClassId === BmpClassId.WatershedControlMeasure || bmp.lutBmpClassId === BmpClassId.NonStructural) && bmp.lutBmpStatusId === 1024);
        newBubbleData.numberOfProjects = filteredBMPArray.length;
        newBubbleData.numberParkBenefit = filteredBMPArray.reduce((sum, bmp) => { return sum + bmp.coBenefitParks }, 0);
        newBubbleData.numberHeatIslandBenefit = filteredBMPArray.reduce((sum, bmp) => { return sum + bmp.coBenefitHeatIsland }, 0);
        newBubbleData.numberWaterSupplyBenefit = filteredBMPArray.reduce((sum, bmp) => { return sum + bmp.coBenefitWaterSupplyAugmentation }, 0);
        newBubbleData.numberBeautificationBenefit = filteredBMPArray.reduce((sum, bmp) => { return sum + bmp.coBenefitNeighborhood }, 0);
        newBubbleData.numberDacBenefit = filteredBMPArray.reduce((sum, bmp) => { return sum + bmp.coBenefitDisadvantaged }, 0);
        setBubbleInfo(newBubbleData);
    }, [props.wmgPublicData]);

    // this is for Project Details Table
    const onProjectListInfoClick = async (selectedBmpId: number, viewType: string) => {
        props.setSidebarProjectInfoObj(SnapshotToBmpGeneral(props.wmgPublicData.find(x => x.bmpGeneralId === selectedBmpId)!, props.wmgId));
        if (props.publicOrInternal === "internal") {
            props.dispatchSidebar({
                type: "open",
                payload: { name: viewType, content: { showEditButton: false, show24HourCapacity: true, prettyProjectPageType: PrettyProjectPageTypes.BmpGeneral } },
            });
        } else {
            props.setSidebarVisible(true);
        }
    };

    const projectListBmps: BmpGeneral[] = useMemo(() => {
        // Dont bother with this ETL if not enabled
        if (!props.wmgPublicInfo.projectDetailsTableEnabled) {
            return [];
        }
        return props.wmgPublicData
            .filter(x => (props.wmgPublicInfo.projectDetailsTableClass === ClassDisplayOptions.All && x.lutBmpClassId === BmpClassId.NonStructural) || x.lutBmpClassId === BmpClassId.WatershedControlMeasure)
            .map(x => SnapshotToBmpGeneral(x, props.wmgId))
    }, [props.wmgId, props.wmgPublicData, props.wmgPublicInfo.projectDetailsTableClass, props.wmgPublicInfo.projectDetailsTableEnabled])

    return (
        <div className="wmg-public-dashboard">
            {/* PROGRAM MESSAGE SECTION */}
            <PublicElementSection
                sectionTitle="Program Description"
                wmgPublicInfo={props.wmgPublicInfo}
                sectionDescriptionProperty="programMessageDescription"
                visible={props.wmgPublicInfo.programMessageEnabled}
            />

            {/* PROGRAM SUMMARY VISUAL SECTION */}
            <PublicElementSection
                sectionTitle="Program Summary Visual"
                wmgPublicInfo={props.wmgPublicInfo}
                sectionDescriptionProperty={"programSummaryVisualizationDescription"}
                visible={props.wmgPublicInfo.programSummaryVisualizationEnabled}>
                <ProgramSummaryVisual
                    lookups={props.lookups}
                    wmgId={props.wmgId}
                    bmpData={props.wmgPublicData}
                    publicConfig={props.wmgPublicInfo}
                    graphHeight={"35rem"}
                />
            </PublicElementSection>

            {/* PROGRAM MULTIPLE BENEFITS SUMMARY SECTION */}
            <PublicElementSection
                sectionTitle="Program Community Co-Benefits Summary"
                wmgPublicInfo={props.wmgPublicInfo}
                sectionDescriptionProperty={"programSummaryMultiBenefitsDescription"}
                visible={props.wmgPublicInfo.programSummaryMultiBenefitsEnabled}
            >
                {props.wmgPublicData.length > 0 && (props.wmgPublicInfo.showCoBenefitHeatIsland || props.wmgPublicInfo.showCoBenefitNeighborhood || props.wmgPublicInfo.showCoBenefitWaterSupplyAugmentation
                    || props.wmgPublicInfo.showCoBenefitDisadvantaged || props.wmgPublicInfo.showCoBenefitParks || props.wmgPublicInfo.showCoBenefitCleanStreets
                    || props.wmgPublicInfo.showCoBenefitFlooding || props.wmgPublicInfo.showCoBenefitJobCreation)
                    ?
                    // Show if there are BMPs and at least one selected co-benefit
                    <div className="program-multibenefits-summary">
                        <DashboardBubbles
                            bubbleInfo={bubbleInfo}
                            showReducesHeatIslandEffect={props.wmgPublicInfo.showCoBenefitHeatIsland}
                            showNeighborhoodBeautification={props.wmgPublicInfo.showCoBenefitNeighborhood}
                            showWaterSupplyAugmentation={props.wmgPublicInfo.showCoBenefitWaterSupplyAugmentation}
                            showBenefitsToDacs={props.wmgPublicInfo.showCoBenefitDisadvantaged}
                            showProvidesParksAndGreenSpaces={props.wmgPublicInfo.showCoBenefitParks}
                            showCleanStreets={props.wmgPublicInfo.showCoBenefitCleanStreets}
                            showReducesFlooding={props.wmgPublicInfo.showCoBenefitFlooding}
                            showJobCreation={props.wmgPublicInfo.showCoBenefitJobCreation}
                        />
                    </div>
                    :
                    <div className="graph-no-data-wrapper">
                        <div className="graph-no-data">
                            No Co-Benefit Data
                        </div>
                    </div>
                }
            </PublicElementSection>

            {/* PROGRAM PROGRESS SECTION */}
            <div ref={divRef}> {/* for getting the width of the charts */}
                <PublicElementSection
                    sectionTitle="Program Progress"
                    wmgPublicInfo={props.wmgPublicInfo}
                    sectionDescriptionProperty={"programProgressDescription"}
                    visible={props.wmgPublicInfo.programProgressEnabled}
                >
                    <ProgramProgressGraph
                        lookups={props.lookups}
                        wmgId={props.wmgId}
                        bmpData={props.wmgPublicData}
                        publicConfig={props.wmgPublicInfo}
                        graphHeight={500}
                        snapshotDate={props.snapshotDate}
                    />
                </PublicElementSection>
            </div>
            {/* PROGRAM SUMMARY TABLE SECTION */}
            <PublicElementSection
                sectionTitle="Program Summary Table"
                wmgPublicInfo={props.wmgPublicInfo}
                sectionDescriptionProperty={"programSummaryTableDescription"}
                visible={props.wmgPublicInfo.programSummaryTableEnabled}
            >
                <ProgramSummaryTable
                    publicConfig={props.wmgPublicInfo}
                    wmgPublicData={props.wmgPublicData}
                    lookups={props.lookups}
                />
            </PublicElementSection>

            {/* PROJECT DETAILS MAP SECTION */}
            <PublicElementSection
                sectionTitle="Project Details Map"
                wmgPublicInfo={props.wmgPublicInfo}
                sectionDescriptionProperty={"projectDetailsMapDescription"}
                visible={props.wmgPublicInfo.projectDetailsMapEnabled}
            >
                <ProjectDetailsMap
                    wmgId={props.wmgId}
                    wmgPublicInfo={props.wmgPublicInfo}
                    wmgPublicData={props.wmgPublicData}
                    width={chartWidth}
                    setSidebarProjectInfoObj={props.setSidebarProjectInfoObj}
                    dispatchSidebar={props.dispatchSidebar}
                    handleDetailsClick={handleDetailsClick}
                    useLiveData={props.useLiveData}
                />
            </PublicElementSection>

            {/* PROJECT DETAILS TABLE SECTION */}
            <PublicElementSection
                sectionTitle="Project Details Table"
                wmgPublicInfo={props.wmgPublicInfo}
                sectionDescriptionProperty={"projectDetailsTableDescription"}
                visible={props.wmgPublicInfo.projectDetailsTableEnabled}
            >
                <div className="project-details-table">
                    <ProjectList
                        lookups={props.lookups}
                        bmpGeneral={projectListBmps}
                        onInfoClick={onProjectListInfoClick}
                        maxPerPage={10}
                        prettyProjectButtonTitle="Details"
                        hideNonStructuralInfo
                        publicOrInternal={props.publicOrInternal}
                    />
                </div>
            </PublicElementSection>

            {/* ADDITIONAL INFO SECTION */}
            <PublicElementSection
                sectionTitle="Additional Information"
                wmgPublicInfo={props.wmgPublicInfo}
                sectionDescriptionProperty={"additionalInfoDescription"}
                visible={props.wmgPublicInfo.additionalInfoEnabled}
            >
                <PublicConfigLinks
                    wmgId={props.wmgId}
                    wmgPublicInfoLinks={props.wmgPublicInfoLinks}
                    editable={false}
                />
            </PublicElementSection>
        </div>
    )
}
