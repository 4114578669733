import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { Button } from "semantic-ui-react";
import LookupFactory from "../../lookups/LookupFactory";
import BmpGeneral from "../../models/BmpGeneral";
import { Dashboard } from "./Dashboard";
import { Downloads } from "./Downloads";
import "./ProgressReporting.scss";
import ReceivingWaters from "../../models/ReceivingWaters";

interface IPublicReportingProps {
    lookups: LookupFactory;
    wmgId: number;
    sidebarVisible: boolean;
    setSidebarVisible: Dispatch<SetStateAction<boolean>>;
    sidebarProjectInfoObj: BmpGeneral;
    setSidebarProjectInfoObj: Dispatch<SetStateAction<BmpGeneral>>;
    publicReports: boolean;
    publicDashboard: boolean;
}

export const ProgressReporting = (props: IPublicReportingProps) => {
    const [activeView, setActiveView] = useState<"downloads" | "dashboard" | "none">(props.publicReports ? "downloads" : (props.publicDashboard ? "dashboard" : "none"));

    useEffect(() => {
        // when changing wmgs, if the active view is not available, switch to the other view
        if (activeView === "dashboard" && !props.publicDashboard) {
            setActiveView("downloads");
        }
        else if (activeView === "downloads" && !props.publicReports) {
            setActiveView("dashboard");
        }
    }, [activeView, props.publicDashboard, props.publicReports, props.wmgId]);

    const receivingWaters: ReceivingWaters[] = useMemo(() => {
        return props.lookups.lutReceivingWaters
            .filter(rw => rw.wsGroupRWaterJurisdictionMapping
                .some(mapping => mapping.watershedManagementGroupId === props.wmgId));
    }, [props.lookups.lutReceivingWaters, props.wmgId])

    const jurisdictions = useMemo(() => props.lookups.jurisdictionGroups.filter(x => x.watershedManagementGroupId === props.wmgId)
        , [props.lookups.jurisdictionGroups, props.wmgId]);

    return (
        <>
            <div id="progress-section"></div>
            <div className="progress-reporting">
                <div className="top-section">
                    <div className="top-section-title">
                        {props.lookups ?
                            props.lookups.getWatershedManagementGroupById(props.wmgId)
                            : ""}
                    </div>
                    <div className="top-section-line"></div>
                    <div className="top-section-subtitle">
                        This page is dedicated to the {props.lookups ?
                            props.lookups.getWatershedManagementGroupById(props.wmgId)
                            : " "}
                        &nbsp;Watershed Management Group and provides information on water quality improvement progress and resulting co-benefits.
                    </div>
                    <div className="city-watershed-wrapper">
                        <div className="cities">
                            <div className="cities-title">
                                Permittees participating in this group are:
                            </div>
                            <div className="cities-text">
                                {jurisdictions.map((item, index) => {
                                    return (
                                        <div className="with-comma" key={item.id}>{props.lookups.getJurisdictionById(item.jurisdictionId)}{index < (jurisdictions.length - 1) ? ", " : ""}&nbsp;</div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="managed">
                            <div className="managed-title">
                                Subwatersheds managed by this group are:
                            </div>
                            <div className="managed-text">
                                {receivingWaters.map((rw, index) => {
                                    return (
                                        <div className="with-comma" key={index}>{rw.displayName}{index < (receivingWaters.length - 1) ? ", " : ""}&nbsp;</div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="button-group-section">
                    <Button.Group>
                        {props.publicReports &&
                            <Button
                                className={activeView === "downloads" ? "active no-wrap" : "no-wrap"}
                                onClick={() => setActiveView("downloads")}
                            >
                                Downloads
                            </Button>
                        }
                        {props.publicDashboard &&
                            <Button
                                className={activeView === "dashboard" ? "active no-wrap" : "no-wrap"}
                                onClick={() => setActiveView("dashboard")}
                            >
                                Dashboard
                            </Button>
                        }
                    </Button.Group>
                </div>
                <div className="downloads-dashboard-wrapper">
                    {activeView === "downloads" && props.publicReports &&
                        <Downloads
                            lookups={props.lookups}
                            wsmgId={props.wmgId}
                        />
                    }
                    {activeView === "dashboard" && props.publicDashboard &&
                        <Dashboard
                            lookups={props.lookups}
                            wsmgId={props.wmgId}
                            sidebarProjectInfoObj={props.sidebarProjectInfoObj}
                            setSidebarProjectInfoObj={props.setSidebarProjectInfoObj}
                            setSidebarVisible={props.setSidebarVisible}
                        />
                    }
                    {activeView === "none" &&
                        // Shouldnt be possible but just in case
                        <div className="label">No reports are available for this group</div>
                    }
                </div>
            </div>
        </>
    )
}
