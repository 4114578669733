import { BaseModel } from "./BaseModel";


export default class WatershedReportJurisdictionSignOff extends BaseModel {

    id: number;
    jurisdictionGroupId: number;
    watershedReportStatusId: number;
    signatureText: string | null;
    active: number;
    editor: string | null;
    created: Date | string | null;
    updated: Date | string | null;
    watershedReportingPeriodId: number | null;


    constructor() {
        super("WatershedReportJurisdictionSignOff", "local")
    }
}