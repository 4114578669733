import { BaseModel } from "./BaseModel";

export default class PublicDocumentModel extends BaseModel {
    id: number;
    attachmentFileName: string;
    attachmentFileType: string;
    attachmentDate: Date | string;
    fileBlob: string;
    active: number;
    editor: string | null;
    created: Date | string | null;
    updated: Date | string | null;

    constructor() {
        super("publicDocument/allfiles", "local")
        this.attachmentDate = new Date().toJSON();
    }
}
