import React, { useEffect, useState, useMemo } from "react";
import { useAuth } from "react-oidc-context";
import { Menu, Icon, Message } from "semantic-ui-react";
import { Translation } from "react-i18next";

import { customFormat, dateDMY } from "../../../core/NumberFormat";
import { WaterGauge } from "../../waterGauge/WaterGauge";
import LookupFactory from "../../../lookups/LookupFactory";
import { BarChart } from "../../nivoCharts/barChart/BarChart";
import BmpGeneralModelResult from "../../../models/BmpGeneralModelResult";
import BmpGeneral from "../../../models/BmpGeneral";
import { BmpClassId } from "../../../models/lutModels/LutBmpClass";
import { CaptureBenefit } from "../../../models/CaptureBenefit";
import { SidebarManagerAction } from "../../mainWrapper/MainWrapper";
import { BmpService } from '../../../services/BmpService';
import ItsRainingBanner from "./ItsRainingBanner";

import Wave from "../../../images/wave3.svg";
import Wave2 from "../../../images/upsideDownWave5.svg";
import "./PrettyProjectPage.scss";

export type PrettyProjectPageSection = "overview" | "capture" | "additional";
export interface IPrettyProjectPageProps {
    dispatchSidebar?: (action: SidebarManagerAction) => void;
    sidebarProjectInfoObj: BmpGeneral;
    lookups: LookupFactory;
    showEditButton?: boolean;
    show24HourCapacity?: boolean;
    setSidebarVisible?: Function;
}

const bmpService = new BmpService();

export const PrettyProjectPage: React.FC<IPrettyProjectPageProps> = (props: IPrettyProjectPageProps) => {
    const project = props.sidebarProjectInfoObj;
    const auth = useAuth();

    const [activeItem, setActiveItem] = useState<PrettyProjectPageSection>("overview");

    const [totalCapture, setTotalCapture] = useState<number>();
    const [recentCapture, setRecentCapture] = useState<number>();
    const [modelReportingPeriod, setModelReportingPeriod] = useState<number>();

    const [yearToDateCapture, setYearToDateCapture] = useState<number | null>();
    const [recentStormEventCapture, setRecentStormEventCapture] = useState<CaptureBenefit | null>();

    useEffect(() => {
        // Get data for the rain event captures only for Completed Structural BMPs and New & Redevelopment BMPs
        if (props.sidebarProjectInfoObj.bmpStatusId === 1024 && (props.sidebarProjectInfoObj.bmpClassId === BmpClassId.WatershedControlMeasure || props.sidebarProjectInfoObj.bmpClassId === BmpClassId.NewAndRedevelopment)) {
            bmpService.getWaterYearToDateBenefitById(props.sidebarProjectInfoObj.id)
                .then((result) => {
                    setYearToDateCapture(result);
                });

            bmpService.getRecentBenefitById(props.sidebarProjectInfoObj.id)
                .then((result) => {
                    setRecentStormEventCapture(result);
                });
        }
        else {
            setYearToDateCapture(null);
            setRecentStormEventCapture(null);
        }
    }, [props.sidebarProjectInfoObj.bmpClassId, props.sidebarProjectInfoObj.bmpStatusId, props.sidebarProjectInfoObj.id]);

    const chartData = useMemo(() => {
        const total = Math.round(props.sidebarProjectInfoObj.bmpGeneralAttributes?.drainageAreaAc * 100) / 100;
        const imperviousPercent = (props.sidebarProjectInfoObj.bmpGeneralAttributes?.drainagePercentImperviousPercent / 100);
        const impervious = Math.round(total * imperviousPercent * 100) / 100; //calculates using total acres times impervious percentage = impervious in acres
        const pervious = parseFloat((total - impervious).toFixed(2));

        return [
            { acres: "Impervious", impervious: impervious },
            { acres: "Pervious", pervious: pervious },
            { acres: "Total", total: total }
        ];

    }, [props.sidebarProjectInfoObj]);

    const truncateText = () => {
        if (props.sidebarProjectInfoObj.name !== undefined && props.sidebarProjectInfoObj.name.length > 49) {
            return props.sidebarProjectInfoObj.name.slice(0, 49) + "...";
        } else {
            return props.sidebarProjectInfoObj.name
        }
    }

    function groupBy<T, V>(list: Array<T>, keyGetter: (t: T) => V): Map<V, T[]> {
        const map = new Map();
        list.forEach((item: T) => {
            const key = keyGetter(item);
            const collection = map.get(key);
            if (!collection) {
                map.set(key, [item]);
            } else {
                collection.push(item);
            }
        });
        return map;
    }

    useEffect(() => {
        if (!props.sidebarProjectInfoObj.bmpGeneralModelRequest) {
            setTotalCapture(0);
            return;
        }
        let modelResults: Array<BmpGeneralModelResult> = new Array<BmpGeneralModelResult>();
        props.sidebarProjectInfoObj.bmpGeneralModelRequest.forEach((request) => {
            if (request.active === 1) {
                request.bmpGeneralModelResult.forEach((result) => {
                    if (result.active === 1) {
                        modelResults.push(result);
                    }
                })
            }
        })

        let reportingPeriods = groupBy(modelResults, (r) => r.reportingPeriodId);

        var sumTotalCapture = 0;
        var lastReport = -1;
        var lastReportCapture = 0;
        reportingPeriods.forEach((value, _) => {
            var latestResult = value
                .sort((a, b) => { return a.created > b.created ? -1 : 1 })[0];

            sumTotalCapture += latestResult?.detainedFlowAcreFeet ?? 0;
            sumTotalCapture += latestResult?.retainedFlowAcreFeet ?? 0;
            if (latestResult.reportingPeriodId > lastReport) {
                lastReport = latestResult.reportingPeriodId;

                lastReportCapture = latestResult?.detainedFlowAcreFeet ?? 0;
                lastReportCapture += latestResult?.retainedFlowAcreFeet ?? 0;
            }
        });

        setModelReportingPeriod(lastReport);
        setRecentCapture(lastReportCapture);
        setTotalCapture(sumTotalCapture);
        if (props.sidebarProjectInfoObj.bmpClassId === BmpClassId.NonStructural && activeItem === "capture") {
            setActiveItem("overview");
        }
    }, [activeItem, props.sidebarProjectInfoObj]);

    return (
        <div className="pretty-project-sidebar-wrapper">
            <Translation>
                {(t) => (
                    <>
                        {props.setSidebarVisible &&
                            <div
                                className="welcome-sidebar-close"
                                onClick={() => props.setSidebarVisible(false)}
                            >
                                <Icon
                                    className="control-icon"
                                    name="close"
                                />
                            </div>
                        }
                        <div className="header-div">
                            <div className="side-title" title={props.sidebarProjectInfoObj.name}>
                                {truncateText()}
                            </div>
                        </div>

                        <Menu pointing secondary className="menu-items-ppp">
                            <Menu.Item
                                size="large"
                                name="overview"
                                active={activeItem === "overview"}
                                onClick={() => setActiveItem("overview")}
                                content="Overview"
                            />
                            {/* Only shows Capture tab for Structural and New & Redev projects - hides Non-Structural */}
                            {(props.sidebarProjectInfoObj.bmpClassId === BmpClassId.WatershedControlMeasure || props.sidebarProjectInfoObj.bmpClassId === BmpClassId.NewAndRedevelopment) &&
                                <Menu.Item
                                    size="large"
                                    name="capture"
                                    active={activeItem === "capture"}
                                    onClick={() => setActiveItem("capture")}
                                    content="Capture"
                                />
                            }
                            <Menu.Item
                                size="large"
                                name="additional"
                                active={activeItem === "additional"}
                                onClick={() => setActiveItem("additional")}
                                content="Additional Info"
                            />
                        </Menu>

                        <div className="body-div">

                            {/*  OVERVIEW TAB  */}
                            {activeItem === "overview" && (
                                <div className="overview-tab-section">
                                    {/* Edit button  - will only show if user is authenticated */}
                                    {
                                        auth.user && props.showEditButton &&
                                        <>
                                            <div className="edit-btn-div-container" >
                                                <div className="edit-btn-div">

                                                    <div className="header" onClick={() => {
                                                        props.dispatchSidebar({
                                                            type: "open",
                                                            payload: { name: "EditProjectPage", content: null },
                                                        });
                                                    }}> Edit Project Details &nbsp;<Icon name="pencil alternate" /></div>
                                                </div>
                                            </div>
                                        </>
                                    }

                                    <div className="wave-div">
                                        <img src={Wave} alt="wave" className="wave" />
                                    </div>

                                    {/* KEY INFORMATION TILE */}
                                    <div className="cards-container">
                                        <div className="custom-cards">
                                            <div className="cards-header-div">
                                                <div className="header">Key Information</div>
                                            </div>
                                            <div className="cards-content">
                                                {/* Use Non Structural Groups if it's a Non-Structural BMP */}
                                                {(props.sidebarProjectInfoObj.bmpClassId === BmpClassId.WatershedControlMeasure || props.sidebarProjectInfoObj.bmpClassId === BmpClassId.NewAndRedevelopment) &&
                                                    <div className="cards-content-item">
                                                        <span className="bold">Subwatershed:</span>
                                                        <div className="key-info-text">
                                                            {project.bmpGeneralGisData?.receivingWatersId
                                                                ? props.lookups.getReceivingWatersById(project.bmpGeneralGisData?.receivingWatersId)
                                                                : "N/A"}
                                                        </div>
                                                    </div>
                                                }
                                                {/* WMG for Structural */}
                                                {props.sidebarProjectInfoObj.bmpClassId === BmpClassId.WatershedControlMeasure &&
                                                    <div className="cards-content-item">
                                                        <span className="bold">WMG(s):</span>
                                                        <div className="key-info-text">
                                                            {props.lookups &&
                                                                project.bmpGeneralGisData?.watershedManagementGroupId
                                                                ? props.lookups.getWatershedManagementGroupById(project.bmpGeneralGisData?.watershedManagementGroupId)
                                                                : props.lookups.jurisdictionGroups
                                                                    .filter(jg => jg.jurisdictionId === props.sidebarProjectInfoObj.uploaderJurisdictionId)
                                                                    .map(jg => props.lookups.lutWatershedManagementGroup.find(wmg => wmg.id === jg.watershedManagementGroupId).displayName)
                                                                    .reduce((acc, name) => acc += ", " + name)
                                                            }

                                                        </div>
                                                    </div>
                                                }
                                                {/* WMG for Non-Structural */}
                                                {props.sidebarProjectInfoObj.bmpClassId === BmpClassId.NonStructural &&
                                                    <div className="cards-content-item">
                                                        <span className="bold">WMG(s):</span>
                                                        <div className="key-info-text">
                                                            {props.lookups &&
                                                                project.bmpGeneralNonStructuralWatershedGroups.length > 0
                                                                ? project.bmpGeneralNonStructuralWatershedGroups.map((grp, index) => {
                                                                    if (index > 0) { return ", " + props.lookups.getWatershedManagementGroupById(grp.watershedManagementGroupId) }
                                                                    else { return props.lookups.getWatershedManagementGroupById(grp.watershedManagementGroupId) }
                                                                })
                                                                : props.lookups.jurisdictionGroups
                                                                    .filter(jg => jg.jurisdictionId === props.sidebarProjectInfoObj.uploaderJurisdictionId)
                                                                    .map(jg => props.lookups.lutWatershedManagementGroup.find(wmg => wmg.id === jg.watershedManagementGroupId).displayName)
                                                                    .reduce((acc, name) => acc += ", " + name)
                                                            }
                                                        </div>
                                                    </div>
                                                }

                                                {(props.sidebarProjectInfoObj.bmpClassId === BmpClassId.WatershedControlMeasure || props.sidebarProjectInfoObj.bmpClassId === BmpClassId.NewAndRedevelopment) &&
                                                    <div className="cards-content-item">
                                                        <span className="bold">Jurisdiction:</span>
                                                        <div className="key-info-text">
                                                            {props.lookups &&
                                                                project.bmpGeneralGisData?.jurisdictionId
                                                                ? props.lookups.getJurisdictionById(
                                                                    project.bmpGeneralGisData?.jurisdictionId
                                                                )
                                                                : "N/A"}
                                                        </div>
                                                    </div>
                                                }
                                                {/* Use Uploader Jurisdiction if it's a Non-Structural BMP */}
                                                {props.sidebarProjectInfoObj.bmpClassId === BmpClassId.NonStructural &&
                                                    <div className="cards-content-item">
                                                        <span className="bold">Jurisdiction:</span>
                                                        <div className="key-info-text">
                                                            {props.lookups &&
                                                                project.uploaderJurisdictionId
                                                                ? props.lookups.getJurisdictionById(
                                                                    project.uploaderJurisdictionId
                                                                )
                                                                : "N/A"}
                                                        </div>
                                                    </div>
                                                }
                                                <div className="cards-content-item">
                                                    <span className="bold">Project Type:</span>
                                                    <div className="key-info-text">
                                                        {props.lookups
                                                            ? props.lookups.getBmpTypeById(props.sidebarProjectInfoObj.bmpTypeId)
                                                            : "N/A"}
                                                    </div>
                                                </div>

                                                <div className="cards-content-item">
                                                    <span className="bold">Status:</span>
                                                    <div className="key-info-text">
                                                        {props.lookups && props.sidebarProjectInfoObj.bmpStatusId
                                                            ? props.lookups.getBmpStatusById(props.sidebarProjectInfoObj.bmpStatusId)
                                                            : "N/A"}
                                                    </div>
                                                </div>
                                                {props.sidebarProjectInfoObj.bmpStatusId === 1024
                                                    //if project is complete, show completion date
                                                    ?
                                                    <div className="cards-content-item">
                                                        <span className="bold">Date Completed:</span>
                                                        <div className="key-info-text">
                                                            {props.sidebarProjectInfoObj.bmpCompletionDate
                                                                ?
                                                                <span>
                                                                    {dateDMY(props.sidebarProjectInfoObj.bmpCompletionDate)}
                                                                </span>
                                                                : "N/A"}
                                                        </div>
                                                    </div>
                                                    :
                                                    //otherwise, show estimated completion date
                                                    <div className="cards-content-item">
                                                        <span className="bold">Est. Completion:</span>
                                                        <div className="key-info-text">
                                                            {props.sidebarProjectInfoObj.bmpCompletionDate
                                                                ?
                                                                <span>
                                                                    {dateDMY(props.sidebarProjectInfoObj.bmpCompletionDate)}
                                                                </span>
                                                                : "N/A"}
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>

                                        {/* PROJECT ATTRIBUTES TILE - only shows for structural projects*/}
                                        {(props.sidebarProjectInfoObj.bmpClassId === BmpClassId.WatershedControlMeasure || props.sidebarProjectInfoObj.bmpClassId === BmpClassId.NewAndRedevelopment) && (
                                            <>
                                                <div className="spacer3">
                                                </div>

                                                <div className="custom-cards">
                                                    <div className="cards-header-div">
                                                        <div className="header">Project Attributes
                                                        </div>
                                                    </div>
                                                    <div className="cards-content">
                                                        <div className="cards-content-item">
                                                            <span className="bold project">Area Managed:</span>
                                                            <div className="project-info-text">
                                                                {project.bmpGeneralAttributes.drainageAreaAc
                                                                    ? customFormat(
                                                                        project.bmpGeneralAttributes.drainageAreaAc
                                                                    ) + " acres"
                                                                    : "N/A"}
                                                            </div>
                                                        </div>

                                                        <div className="cards-content-item">
                                                            <span className="bold project">Project Capacity:</span>
                                                            <div className="project-info-text">
                                                                {project.bmpGeneralAttributes.actualStorageCapacityAcft
                                                                    ? customFormat(project.bmpGeneralAttributes.actualStorageCapacityAcft) + " ac-ft"
                                                                    : "N/A"}
                                                            </div>
                                                        </div>

                                                        <div className="cards-content-item">
                                                            <span className="bold project">Project Footprint:</span>
                                                            <div className="project-info-text">
                                                                {project.bmpGeneralAttributes.footprintAc
                                                                    ? customFormat(project.bmpGeneralAttributes.footprintAc) + " acres"
                                                                    : "N/A"}
                                                            </div>
                                                        </div>

                                                        {props.show24HourCapacity &&
                                                            <div className="cards-content-item">
                                                                <span className="bold project">24 Hour Capacity:</span>
                                                                <div className="project-info-text">
                                                                    {project.bmpGeneralAttributes.dailyStorageCapacityAcft
                                                                        ? customFormat(project.bmpGeneralAttributes.dailyStorageCapacityAcft) + " ac-ft"
                                                                        : "N/A"}
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </>
                                        )}

                                    </div>
                                    <div className="wave-div">
                                        <img src={Wave2} alt="wave" className="wave" />
                                    </div>
                                </div>
                            )}

                            {/*  CAPTURE TAB  */}
                            {activeItem === "capture" && (props.sidebarProjectInfoObj.bmpClassId === BmpClassId.WatershedControlMeasure || props.sidebarProjectInfoObj.bmpClassId === BmpClassId.NewAndRedevelopment) && (
                                <div className="capture-tab-section">
                                    <ItsRainingBanner />
                                    <div className="wave-div">
                                        <img src={Wave} alt="wave" className="wave" />
                                    </div>
                                    <div className="bubble-div">
                                        <div className="bubble-inner-div">
                                            <div className="water-container">
                                                {/* TOTAL CAPTURE BUBBLE */}
                                                <WaterGauge
                                                    value={75}
                                                    size={50}
                                                    color="rgb(23, 139, 202)"
                                                    displayValue={props.sidebarProjectInfoObj.bmpStatusId === 1024 ? totalCapture : 0} //Show 0 if in-progress
                                                    offsetX={0}
                                                    offsetY={4}
                                                    numberSize={1} //size of number inside bubble
                                                    spacing="bubble-space"
                                                    metricColor="#fff" //color of metric label text
                                                    metricSize="metric-small" //size of metric label text
                                                    metric={t("genUnitAcFt")} //metric units
                                                />
                                                {/* <div className="coming-soon-overlay">COMING SOON</div> */}
                                            </div>

                                            <div className="text-wrapper">
                                                <div className="div-1">Total Capture</div>
                                                <div className="div-2">
                                                    Total stormwater captured by this project since construction
                                                </div>
                                            </div>
                                        </div>

                                        <div className="bubble-inner-div">
                                            <div className="text-wrapper">
                                                <div className="div-1">Recent Capture</div>
                                                <div className="div-2">
                                                    {modelReportingPeriod > 0 && <span>Stormwater captured by this project during the {props.lookups.getReportingPeriodById(modelReportingPeriod)} reporting period</span>}
                                                    {modelReportingPeriod <= 0 && <span>No stormwater captured by this project during recent reporting period</span>}
                                                </div>
                                            </div>
                                            <div className="water-container">
                                                {/* RECENT CAPTURE BUBBLE */}
                                                <WaterGauge
                                                    value={75}
                                                    size={50}
                                                    color="rgb(23, 139, 202)"
                                                    displayValue={recentCapture}
                                                    offsetX={0}
                                                    offsetY={4}
                                                    numberSize={1}
                                                    spacing="bubble-space"
                                                    metricColor="#fff" //color of metric label text
                                                    metricSize="metric-small" //size of metric label text
                                                    metric={t("genUnitAcFt")}
                                                />
                                            </div>
                                        </div>
                                        <div className="bubble-inner-div">
                                            <div className="water-container">
                                                {/* Year to Date CAPTURE BUBBLE */}
                                                <WaterGauge
                                                    value={75}
                                                    size={50}
                                                    color="rgb(23, 139, 202)"
                                                    displayValue={yearToDateCapture ?? 0}
                                                    offsetX={0}
                                                    offsetY={4}
                                                    numberSize={1}
                                                    spacing="bubble-space"
                                                    metricColor="#fff" //color of metric label text
                                                    metricSize="metric-small" //size of metric label text
                                                    metric={t("genUnitAcFt")}
                                                />
                                            </div>
                                            <div className="text-wrapper">
                                                <div className="div-1">Year to Date </div>
                                                <div className="div-2">
                                                    {`10/1/${new Date().getMonth() >= 10 ? new Date().getFullYear() : new Date().getFullYear() - 1} `}to {new Date(Date.now()).toLocaleString().split(',')[0]}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="bubble-inner-div">
                                            <div className="text-wrapper">
                                                <div className="div-1">Recent Storm Event</div>
                                                {!recentStormEventCapture && <div className="div-2">No recent storm event</div>}
                                                {recentStormEventCapture &&
                                                    <div className="div-2">{new Date(recentStormEventCapture?.eventStart).toLocaleString().split(',')[0]} to {new Date(recentStormEventCapture?.eventEnd).toLocaleString().split(',')[0]}</div>
                                                }
                                            </div>
                                            <div className="water-container">
                                                {/* RECENT Event CAPTURE BUBBLE */}
                                                <WaterGauge
                                                    value={75}
                                                    size={50}
                                                    color="rgb(23, 139, 202)"
                                                    displayValue={recentStormEventCapture?.amount ?? 0}
                                                    offsetX={0}
                                                    offsetY={4}
                                                    numberSize={1}
                                                    spacing="bubble-space"
                                                    metricColor="#fff" //color of metric label text
                                                    metricSize="metric-small" //size of metric label text
                                                    metric={t("genUnitAcFt")}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="wave-div">
                                        <img src={Wave2} alt="wave" className="wave" />
                                    </div>
                                </div>
                            )}

                            {/*  ADDITIONAL INFO TAB  */}
                            {activeItem === "additional" &&
                                <>
                                    {/* Doesn't show Text or Bar Chart if BMP is Non-structural */}
                                    {(props.sidebarProjectInfoObj.bmpClassId === BmpClassId.WatershedControlMeasure || props.sidebarProjectInfoObj.bmpClassId === BmpClassId.NewAndRedevelopment) &&
                                        <>
                                            <div className="additional-text">
                                                <div className="additional-chart">
                                                    <div className="chart-title">Area Managed By Project
                                                        <div className="tooltip">&nbsp;
                                                            <Icon
                                                                name="question circle"
                                                                className="info-icon"
                                                            />
                                                            <div className="tooltiptext">The amount of area, especially impervious area, that is managed by the project is an important metric for how much benefit is provided for stormwater capture and downstream water quality protection.
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <BarChart chartData={chartData} />
                                                </div>
                                            </div>
                                        </>
                                    }

                                    {project.bmpGeneralCobenefits?.coBenefitCleanStreets === 1 ||
                                        project.bmpGeneralCobenefits?.coBenefitDisadvantaged === 1 ||
                                        project.bmpGeneralCobenefits?.coBenefitFlooding === 1 ||
                                        project.bmpGeneralCobenefits?.coBenefitHeatIsland === 1 ||
                                        project.bmpGeneralCobenefits?.coBenefitJobCreation === 1 ||
                                        project.bmpGeneralCobenefits?.coBenefitNeighborhood === 1 ||
                                        project.bmpGeneralCobenefits?.coBenefitParks === 1 ||
                                        project.bmpGeneralCobenefits?.coBenefitWaterSupplyAugmentation === 1 ?
                                        <div className="additional-text mb-3">
                                            <div className="additional-title">
                                                Community co-benefits that are provided by this project:
                                            </div>
                                            <div className="small-wave-div overflow">
                                                <img src={Wave} alt="wave" className="wave" />
                                            </div>
                                            <div className="cobenefits">
                                                <ul>
                                                    {project.bmpGeneralCobenefits.coBenefitDisadvantaged === 1 &&
                                                        <li><Icon name="tint" /> <span>Benefits Accruing to DACs</span></li>
                                                    }
                                                    {project.bmpGeneralCobenefits.coBenefitHeatIsland === 1 &&
                                                        <li><Icon name="tint" /> <span>Reduces Heat Island Effect</span></li>
                                                    }
                                                    {project.bmpGeneralCobenefits.coBenefitNeighborhood === 1 &&
                                                        <li><Icon name="tint" /> <span>Neighborhood Beautification</span></li>
                                                    }
                                                    {project.bmpGeneralCobenefits.coBenefitParks === 1 &&
                                                        <li><Icon name="tint" /> <span>Creates Parks or Greenspaces</span></li>
                                                    }
                                                    {project.bmpGeneralCobenefits.coBenefitWaterSupplyAugmentation === 1 &&
                                                        <li><Icon name="tint" /> <span>Water Supply Augmentation</span></li>
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                        : null
                                    }
                                </>
                            }
                        </div>
                    </>
                )}
            </Translation>
        </div>
    );
};
