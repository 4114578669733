import { BaseModel } from "../BaseModel";


export default class WGReportingViewModel extends BaseModel {

    id: number;
    jurisdictionId: number;
    signed: boolean;
    signDate: Date | null;

    constructor() {
        super("", "local")
    }
}