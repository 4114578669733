import Extent from "@arcgis/core/geometry/Extent";
import axios, { AxiosRequestConfig } from "axios";
import Config from "../config/Config";

export class GeoJsonService {
    private authToken?: string;

    constructor(authToken?: string) {
        this.authToken = authToken;
    }

    async getExtent(wmgId: number): Promise<Extent | null> {

        return await axios
            .get<Extent | null>(Config.api_endpoint + `GeoJson/GetInitialMapExtent/${wmgId}`)
            .then((result) => {
                return result.data;
            })
            .catch((e) => {
                throw "Failed to get extent" + e;
            });
    }

    async getExtentForJurisdiction(jurisdictionId: number): Promise<Extent | null> {
        if (!this.authToken) {
            return null;
        }

        let requestConfig: AxiosRequestConfig = {
            headers: {
                "Authorization": "Bearer " + this.authToken
            },
        };

        return await axios
            .get<Extent | null>(Config.api_endpoint + `GeoJson/GetInitialMapExtentForJurisdiction/${jurisdictionId}`, requestConfig)
            .then((result) => {
                return result.data;
            })
            .catch((e) => {
                throw "Failed to get extent" + e;
            });
    }
}