import { Form, Input } from "semantic-ui-react";
import './InputComponent.scss';

export interface InputComponentProps<T extends string | number> {
    value: T;
    setValue: (value: T) => void;
    inputType: string;
    label: string;
    placeHolder: string;
    requiredField?: boolean; // Optional Property that will add red asterisk to requried fields
    toolTipInfo?: string; // Optional Property to show tooltip and point to corresponding tooltip object in json
    fluid?: boolean;
    inputSlim?: boolean;
    disabled?: boolean;
    maxCharacters?: number;
}

export function InputComponent<T extends string | number>(props: InputComponentProps<T>) {
    return (
        <div className="input-component">
            <Form.Field>
                <div className={`basic-input-label-wrapper ${props.requiredField ? "required field" : ""}`}>
                    <label className="basic-input-label">{props.label}</label>
                    {
                        props.toolTipInfo &&
                        // this will become the entrance to the tooltip modal
                        <div className="tool-tip-entry-icon">
                            {/* <ToolTipModal toolTipName={props.toolTipInfo} theme={"primary"} size={"small"} /> */}

                        </div>
                    }
                </div>
                <Input className={`${props.inputSlim ? "input-slim" : null}`}
                    placeholder={props.placeHolder}
                    type={props.inputType}
                    value={props.value ?? ""}
                    disabled={props.disabled}
                    onChange={(e) => props.setValue(e.currentTarget.value as T)}
                    maxLength={props.maxCharacters ? props.maxCharacters : null}
                />
            </Form.Field>
        </div>
    )
}
