import React, { useCallback } from "react";
import { Translation } from "react-i18next";
import { IProjectEditPageProps } from "../../sidebars/editProjectPage/EditProjectPage";
import BmpGeneralAttributes from "../../../models/BmpGeneralAttributes";
import BmpGeneralFinancial from "../../../models/BmpGeneralFinancial";
import BmpGeneralLocation from "../../../models/BmpGeneralLocation";
import { AddonInputComponent } from "../../../paradigmLib/domElements/addonInputComponent/AddonInputComponent";
import { InputComponent } from "../../../paradigmLib/domElements/inputComponent/InputComponent";
import { TextAreaComponent } from "../../../paradigmLib/domElements/textAreaComponent/TextAreaComponent";
import { ToggleComponent } from "../../../paradigmLib/domElements/toggleComponent/ToggleComponent";
import DropdownLutH2O from "../../../paradigmLib/dropdownLut/DropdownLut";
import './ProjectEditPages.scss';
import { BmpClassId } from "../../../models/lutModels/LutBmpClass";

export const ProjAdditional: React.FC<IProjectEditPageProps> = (props: IProjectEditPageProps) => {
    const onLanduseDropdownSelect = (event: any, data: any) => {
        props.setProject({
            ...props.project,
            bmpGeneralAttributes: {
                ...props.project.bmpGeneralAttributes,
                drainageLanduseId: data.value
            }
        });
    }

    const setProjectLocation = useCallback((newLocation: BmpGeneralLocation) => {
        props.setProject({
            ...props.project,
            bmpGeneralLocation: newLocation
        });
    }, [props]);

    const setProjectFinancial = useCallback((newFinancial: BmpGeneralFinancial) => {
        props.setProject({
            ...props.project,
            bmpGeneralFinancial: newFinancial
        })
    }, [props])

    const setProjectAttributes = useCallback((newAttributes: BmpGeneralAttributes) => {
        props.setProject({
            ...props.project,
            bmpGeneralAttributes: newAttributes
        })
    }, [props])

    return (

        <div className="edit-proj-additional">
            <Translation>
                {
                    t =>
                        <>
                            {props.project && props.project.bmpClassId &&
                                <>

                                    {(props.project.bmpClassId === BmpClassId.WatershedControlMeasure
                                        || props.project.bmpClassId === BmpClassId.NewAndRedevelopment) &&
                                        <>
                                            <InputComponent
                                                value={props.project.bmpGeneralLocation.street}
                                                setValue={(value) => {
                                                    const updated = props.project.bmpGeneralLocation;
                                                    updated.street = value;
                                                    setProjectLocation(updated);
                                                }}
                                                label="Street Address"
                                                placeHolder="123 Watershed Lane"
                                                inputType="string"
                                            />
                                            <InputComponent
                                                label="City"
                                                placeHolder="Los Angeles"
                                                value={props.project.bmpGeneralLocation.city}
                                                setValue={(value) => {
                                                    const updated = props.project.bmpGeneralLocation;
                                                    updated.city = value;
                                                    setProjectLocation(updated);
                                                }}
                                                inputType="string"
                                            />
                                            <InputComponent
                                                label="Zip Code"
                                                placeHolder="12345"
                                                value={props.project.bmpGeneralLocation.zip}
                                                setValue={(value) => {
                                                    const updated = props.project.bmpGeneralLocation;
                                                    updated.zip = value;
                                                    setProjectLocation(updated);
                                                }}
                                                inputType="number"
                                            />
                                            <InputComponent
                                                label="Parcel APN"
                                                placeHolder="7336-010-14"
                                                value={props.project.bmpGeneralLocation.parcelApn}
                                                setValue={(value) => {
                                                    const updated = props.project.bmpGeneralLocation;
                                                    updated.parcelApn = value;
                                                    setProjectLocation(updated);
                                                }}
                                                inputType="string"
                                            />
                                            <div className="storage-capacity-toggle">
                                                <ToggleComponent
                                                    sideLabel="Is Project Storage Capacity Equal to Runoff from the 85th Percentile, 24-hour Storm?"
                                                    value={props.project.bmpGeneralAttributes.storageEq85th24hr == 1}
                                                    setValue={(value) => setProjectAttributes({ ...props.project.bmpGeneralAttributes, storageEq85th24hr: value ? 1 : 0 })}
                                                    theme={"primary"}
                                                />
                                            </div>
                                        </>
                                    }

                                    { //NOTE: New and redev does have this field but it is under Additional section in the
                                        //bulk upload form so it is there in the edit page
                                        props.project.bmpClassId === BmpClassId.NewAndRedevelopment &&
                                        <AddonInputComponent
                                            label="Capital Costs"
                                            placeHolder="300000"
                                            value={props.project.bmpGeneralFinancial.projectCapitalCost}
                                            setValue={(value) => {
                                                const updated = props.project.bmpGeneralFinancial;
                                                updated.projectCapitalCost = value;
                                                setProjectFinancial(updated);
                                            }}
                                            inputType="number"
                                            theme="primary"
                                            addonSide="left"
                                            addonContent="$"
                                        />
                                    }

                                    {(props.project.bmpClassId === BmpClassId.WatershedControlMeasure) &&
                                        <DropdownLutH2O
                                            dropdownItems={props.lookups != null ? props.lookups.lutDrainageLanduse : []}
                                            dropdownTitle={"Predominant Land Use in Drainage Area"}
                                            isMultiselect={false}
                                            nullLabel="Please select a Land Use"
                                            onSelect={onLanduseDropdownSelect}
                                            parentId={props.project.bmpGeneralAttributes.drainageLanduseId
                                                ? props.project.bmpGeneralAttributes.drainageLanduseId
                                                : -1}
                                        />
                                    }

                                    {(props.project.bmpClassId === BmpClassId.WatershedControlMeasure || props.project.bmpClassId === BmpClassId.NewAndRedevelopment) &&
                                        <TextAreaComponent
                                            label="Comments / Notes"
                                            placeHolder="Comments"
                                            value={props.project.comment}
                                            setValue={(value) => {
                                                props.setProject({ ...props.project, comment: value });
                                            }}
                                            rows={5}
                                        />
                                    }
                                </>
                            }
                        </>
                }
            </Translation>
        </div>
    )
}
