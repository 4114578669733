import { BaseModel } from "./BaseModel";

export default class WatershedManagementGroupIsPublic extends BaseModel {
    id: number;
    watershedGroupId: number;
    isPublicReports: number;
    isPublicDashboard: number;
    active: number;
    editor: string | null;
    created: Date | string | null;
    updated: Date | string | null;

    constructor() {
        super("WatershedManagementGroupIsPublic", "local")
    }
}
