//import axios, { AxiosResponse, AxiosPromise, AxiosRequestConfig, AxiosInstance } from 'paradigm-react';
import Config from '../config/Config';
import axios, { AxiosInstance, AxiosPromise } from 'axios';

export type apiOption = 'local' | 'wramps2id_auth';

export class BaseModel {
    //  thisType: new () => any; //this is populated by the constructor and is the constructor of the child class.  This is used to create new instances from the base

    /**
     * This
     * @param strEndPoint
     * @param type - just pass the actual class in, more example if the endpoint is BMPType and the model is BMPType,
     * pass in BMPType as in the class, not in a string.  This is used to create new instances of the object
     */
    endpoint: string;
    id: number;
    editor: string;
    created?: Date | string | null;
    updated: Date | string | null;
    active: number;
    baseUrl: string;

    constructor(strEndPoint: string, api: apiOption) {//, type: new () => any ) {
        this.endpoint = strEndPoint;
        //// this.thisType = type;
        this.axiosBaseInstance = this.makeBaseAxiosInstance();
        this.axiosBaseFileInstance = this.makeBaseAxiosFileInstance();

        this.baseUrl = Config.api_endpoint;

        if (api === 'wramps2id_auth') {
            this.baseUrl = Config.authInfo.server;
        };
    }

    axiosBaseInstance: AxiosInstance;
    axiosBaseFileInstance: AxiosInstance;
    axiosBaseZipInstance: AxiosInstance;

    // // ----- Axios config Functions ----- //

    public initProps = function () {
        for (let key in this) {
            if (key !== "endpoint" && key !== "id") {
                this[key] = null;
            };
        };
    }

    public getNew = function () {
        return new this();
    }

    private makeBaseAxiosInstance = function (): AxiosInstance {
        let context: BaseModel = this;

        // const userContext = useContext(UserData);

        return axios.create({
            baseURL: Config.baseUrl,
            headers: {
                "Access-Control-Allow-Origin": '*',
                "Content-Type": 'application/json;charset=UTF-8',
                // "Authorization": `Bearer ${userContext.user.access_token}`
            }
        });

    };

    private makeBaseAxiosFileInstance = function (): AxiosInstance {
        let context: BaseModel = this;

        // const userContext = useContext(UserData);

        return axios.create({
            baseURL: Config.baseUrl,
            headers: {
                "Access-Control-Allow-Origin": '*',
                "Content-Type": 'multipart/form-data',
                // "Authorization": `Bearer ${userContext.user.access_token}`
            }
        });

    };

    static axiosGet = function (id: number): AxiosPromise {
        let t: BaseModel = new this();  //new (<any>this)();
        let d = new Date();
        let queryString = d.valueOf().toString();
        let endpoint = String(t.endpoint + "/" + id + "?" + queryString);

        return t.axiosBaseFileInstance({
            method: 'GET',
            url: endpoint
        });
    };

    // static FetchAll = function (data?): AxiosPromise {
    //     let t: BaseModel = new (<any>this)();
    //     return t.axiosBaseInstance({
    //          method: "GET",
    //          url: t.endpoint,
    //          params: data
    //      })
    // };

    // static MultiRequest = function (requests: AxiosPromise[]) {
    //     return axios.all(requests);
    // }; 
}
