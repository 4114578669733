import { useEffect, useState } from "react";
import { SortableTableComponent } from "../../../paradigmLib/domElements/sortableTable/SortableTableComponent";
import WatershedFormTable1_1 from "../../../models/viewModels/WatershedFormTable1_1";
import { Get } from "../../../core/DownloadUtil";
import { formatWithCommas } from "../../../core/NumberFormat";
import { SelectedParams } from "./BuildWatershedForm";
import useDebounce from "../../../core/UseDebounce";
import { DashboardBubbles } from "./wmgPublicDashboard/dashboardBubbles/DashboardBubbles";
import WelcomeViewModel from "../../../models/viewModels/WelcomeViewModel";
import { Button, Dimmer, Loader, Segment } from "semantic-ui-react";
import './BuildWatershedForm.scss';
import { useAuth } from "react-oidc-context";

export interface IBuildWatershedForm_1_1TableProps {
    selectedParams: SelectedParams
}

export const BuildWatershedForm_1_1Table: any = (props: IBuildWatershedForm_1_1TableProps) => {
    const auth = useAuth();
    const [table1_1, setTable1_1] = useState<WatershedFormTable1_1>(new WatershedFormTable1_1());
    const [fetchTable1_1, setFetchTable1_1] = useState<boolean>(true);
    const debouncedSelectedParameters = useDebounce(props.selectedParams, 500);
    const [bubbleInfo, setBubbleInfo] = useState(new WelcomeViewModel());
    const [activeBubbles, setActiveBubbles] = useState<"complete" | "plannedOrInProgress">("complete");

    useEffect(() => {
        // if (fetchTable1_1){
        Get<WatershedFormTable1_1>(WatershedFormTable1_1.endpoint,
            auth.user?.access_token,
            { jurisdictionId: debouncedSelectedParameters.jurisdictionId, watershedGroupId: debouncedSelectedParameters.watershedGroupId, reportingPeriodId: debouncedSelectedParameters.reportingPeriodId })
            .then((response) => {
                setTable1_1(response);
                setFetchTable1_1(false);
            });
        // }
    }, [debouncedSelectedParameters]);

    useEffect(() => {
        let newBubbleData: WelcomeViewModel = new WelcomeViewModel();

        if (table1_1.tableDataCompleted && table1_1.tableDataInProgress) {
            newBubbleData.numberHeatIslandBenefit = activeBubbles === "complete" ? table1_1.tableDataCompleted.coBenefitHeatIslandCount : table1_1.tableDataInProgress.coBenefitHeatIslandCount;

            newBubbleData.numberBeautificationBenefit = activeBubbles === "complete" ? table1_1.tableDataCompleted.coBenefitNeighborhoodCount : table1_1.tableDataInProgress.coBenefitNeighborhoodCount;

            newBubbleData.numberWaterSupplyBenefit = activeBubbles === "complete" ? table1_1.tableDataCompleted.coBenefitWaterSupplyAugmentationCount : table1_1.tableDataInProgress.coBenefitWaterSupplyAugmentationCount;

            newBubbleData.numberDacBenefit = activeBubbles === "complete" ? table1_1.tableDataCompleted.coBenefitDisadvantagedCount : table1_1.tableDataInProgress.coBenefitDisadvantagedCount;

            newBubbleData.numberParkBenefit = activeBubbles === "complete" ? table1_1.tableDataCompleted.coBenefitParksCount : table1_1.tableDataInProgress.coBenefitParksCount;

            newBubbleData.numberCleanStreetBenefit = activeBubbles === "complete" ? table1_1.tableDataCompleted.coBenefitCleanStreetsCount : table1_1.tableDataInProgress.coBenefitCleanStreetsCount;

            newBubbleData.numberReduceFloodingBenefit = activeBubbles === "complete" ? table1_1.tableDataCompleted.coBenefitFloodingCount : table1_1.tableDataInProgress.coBenefitFloodingCount;

            newBubbleData.numberJobCreationBenefit = activeBubbles === "complete" ? table1_1.tableDataCompleted.coBenefitJobCreationCount : table1_1.tableDataInProgress.coBenefitJobCreationCount;

            setBubbleInfo(newBubbleData);
        }
    }, [activeBubbles, table1_1]);

    return (
        <div className="section1_1-table-bubbles">
            {table1_1.tableDataCompleted &&
                <div className="sortable-table-wrapper">
                    <SortableTableComponent
                        tableData={[
                            { "metric": "Number of Watershed Control Measures Implemented", "completed": table1_1.tableDataCompleted.projectCount, "in-progress": table1_1.tableDataInProgress.projectCount },
                            { "metric": "Permittees Collaborated with for Achievement of Milestones", "completed": table1_1.tableDataCompleted.permittees, "in-progress": table1_1.tableDataInProgress.permittees },
                            { "metric": "Non-Permittees Collaborated with for Achievement of Milestones", "completed": table1_1.tableDataCompleted.nonPermittees, "in-progress": table1_1.tableDataInProgress.nonPermittees },
                            { "metric": "Funding Sought", "completed": "$" + formatWithCommas(table1_1.tableDataCompleted.fundingSoughtSum), "in-progress": "$" + formatWithCommas(table1_1.tableDataInProgress.fundingSoughtSum) },
                            { "metric": "Funding Obtained", "completed": "$" + formatWithCommas(table1_1.tableDataCompleted.fundingObtainedSum), "in-progress": "$" + formatWithCommas(table1_1.tableDataInProgress.fundingObtainedSum) },
                            { "metric": "Technical Assistance Received", "completed": table1_1.tableDataCompleted.technicalAssistanceReceived, "in-progress": table1_1.tableDataInProgress.technicalAssistanceReceived },
                        ]}
                        columnArr={["metric", "completed", "in-progress"]}
                        headerNames={["Watershed Control Measure Metric", "Completed", "Planned and In Progress"]}
                        headerBackgroundColor={"blue"}
                        headerTextColor={"white"}
                    />
                </div>
            }
            <div className="cobenefit-bubbles">
                {table1_1.tableDataCompleted && table1_1.tableDataInProgress && bubbleInfo !== undefined
                    ?
                    <>
                        <div className="dashboard-button-group">
                            <Button.Group
                            >
                                <Button
                                    className={activeBubbles === "complete" ? "active" : ""}
                                    onClick={() => setActiveBubbles("complete")}
                                >Complete
                                </Button>
                                <Button
                                    className={activeBubbles === "plannedOrInProgress" ? "active" : ""}
                                    onClick={() => setActiveBubbles("plannedOrInProgress")}
                                >Planned/In Progress
                                </Button>
                            </Button.Group>
                        </div>
                        <DashboardBubbles
                            bubbleInfo={bubbleInfo}
                            showReducesHeatIslandEffect
                            showNeighborhoodBeautification
                            showWaterSupplyAugmentation
                            showBenefitsToDacs
                            showProvidesParksAndGreenSpaces
                            showCleanStreets
                            showReducesFlooding
                            showJobCreation
                        />
                    </>
                    : <Segment style={{ height: "16rem" }}>
                        <Dimmer active inverted>
                            <Loader size='big'>Loading</Loader>
                        </Dimmer>
                    </Segment>
                }
            </div>
        </div>
    )
}
