import React, { useEffect, useState } from 'react';
import './UserManagementModal.scss'
import { Button, Icon, Modal, SemanticICONS, Divider, Menu, Message } from 'semantic-ui-react';
import { InputComponent } from '../../../paradigmLib/domElements/inputComponent/InputComponent';
import { ButtonComponent } from '../../../paradigmLib/domElements/buttonComponent/ButtonComponent';
import Wave from "../../../images/modalWave.svg"
import Wave2 from "../../../images/modalWave2.svg";
import { InviteUserComponent } from '../../admin/InviteUserComponent';
import UserListViewModel from '../../../models/viewModels/UserListViewModel';
import { toast } from 'react-toastify';

export interface IUserManagementModalProps {
    itemId: number;
    displayName: string;
    allUsers: Array<UserListViewModel>;
    userJoiner: Array<any>;
    updateUsers: Function;
    theme: string; // primary secondary tertiary
    size?: string;
    refreshUsers: () => void;
    role: "jurisdiction" | "watershedgroup";
}

export default class ModalFilterObj {
    searchText: string = "";
}

export const UserManagementModal: React.FC<IUserManagementModalProps> = (props: IUserManagementModalProps) => {
    const [open, setOpen] = useState(false);
    const [addFilter, setAddFilter] = useState<ModalFilterObj>(new ModalFilterObj());
    const [remList, setRemList] = useState<Array<string>>(new Array<string>());
    const [addList, setAddList] = useState<Array<string>>(new Array<string>());
    const [searchResult, setSearchResult] = useState<Array<any>>(new Array<any>());
    const [activeTab, setActiveTab] = useState<string>("search");

    const onUserInvited = (addedUserId: string) => {
        addUser(addedUserId);
        setActiveTab("search");
        props.refreshUsers();
    }

    const onInvitationFailed = () => {
        console.error("Invitation failed");
        toast.error("Invitation failed", { containerId: "main-wrapper-toast-container" })
    }

    useEffect(() => {
        if (!open) {
            cancel();
        }
    }, [open]);

    useEffect(() => {
        if (addFilter.searchText.length > 0) {
            // get list of users that match
            let matchInfo = props.allUsers.filter((item: UserListViewModel) => {
                return item.email.toLowerCase().includes(addFilter.searchText.toLowerCase()) ||
                    item.firstName.toLowerCase().includes(addFilter.searchText.toLowerCase()) ||
                    item.lastName.toLowerCase().includes(addFilter.searchText.toLowerCase());
            });
            matchInfo = matchInfo.filter((item: any) => { return props.userJoiner.find((user: any) => { return user.userID === item.id }) === undefined; });
            setSearchResult(matchInfo);
        }
        else {
            setSearchResult(new Array<any>());
        }
    }, [addFilter.searchText]);

    const cancel = () => {
        setAddFilter(new ModalFilterObj());
        setRemList(new Array<string>());
        setAddList(new Array<string>());
        setOpen(false);
    }

    const save = () => {
        //alert('save');
        props.updateUsers(props.itemId, addList, remList);
        setAddFilter(new ModalFilterObj());
        setRemList(new Array<string>());
        setAddList(new Array<string>());
        setOpen(false);
    }

    const getUsername = (id: string): string => {
        if (id !== undefined && props !== undefined && props.allUsers !== undefined) {
            let usr = props.allUsers.find((x: any) => { return x.id === id });
            if (usr !== undefined) {
                // return usr.firstName + " (" + usr.email + ")";
                return `${usr.firstName} ${usr.lastName} (${usr.email})`;
            }
        }
        return "unknown id";
    }

    const addUser = (id: string) => {
        if (!addList.includes(id) && props.userJoiner.find((user: any) => { return user.userID === id }) === undefined) {
            let newAddList: Array<string> = Array.from(addList);
            newAddList.push(id);
            setAddList(newAddList);
        }
    }

    const manageClick = (id: string) => {
        if (remList.includes(id)) {
            let newRemList: Array<string> = Array.from(remList);
            const index = newRemList.indexOf(id);
            if (index > -1) {
                newRemList.splice(index, 1);
            }
            setRemList(newRemList);
        } else if (addList.includes(id)) {
            let newAddList: Array<string> = Array.from(addList);
            const index = newAddList.indexOf(id);
            if (index > -1) {
                newAddList.splice(index, 1);
            }
            setAddList(newAddList);
        } else {
            let newRemList: Array<string> = Array.from(remList);
            newRemList.push(id);
            setRemList(newRemList);
        }
    }

    const getIcon = (id: string): SemanticICONS => {
        if (remList.includes(id) || addList.includes(id)) { return 'undo'; }
        return 'delete';
    }

    const handleItemClick = (e, { name }) => setActiveTab(name);

    return (
        <Modal className="user-management-modal-component"
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={<Icon className={`${props.size} ${props.theme} file-upload-entry-icon`} name="pencil" />}
        >
            <Modal.Header>
                <div className="user-modal-title">Add/Remove Users for {props.displayName}</div>
                <div className="wave-div">
                    <img src={Wave} alt="wave" className="wave" />
                </div>
            </Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    <Menu pointing secondary>
                        <Menu.Item
                            name="search"
                            active={activeTab === "search"}
                            onClick={handleItemClick}
                            content="Search" />

                        <Menu.Item
                            name="invite"
                            active={activeTab === "invite"}
                            onClick={handleItemClick}
                            content="Invite" />
                    </Menu>
                    {activeTab === "search" &&
                        <>
                            <div>
                                <div className='project-user-label'>
                                    Project Users:
                                </div>
                                <div className="project-user-list">
                                    {
                                        props !== undefined && props.userJoiner !== undefined &&
                                        props.userJoiner.filter((user: any) => addFilter.searchText === "" ? true : getUsername(user.userID).includes(addFilter.searchText)).map((user: any) => {
                                            return <div className={'' + (remList.includes(user.userID) ? ' removed-user' : addList.includes(user.userID) ? ' added-user' : '')}>{getUsername(user.userID)}<span><Icon name={getIcon(user.userID)} onClick={() => manageClick(user.userID)} /></span></div>

                                        })
                                    }
                                    {
                                        addList.map((user: string) => {
                                            return <div className={'' + (remList.includes(user) ? ' removed-user' : addList.includes(user) ? ' added-user' : '')}>{getUsername(user)}<span><Icon name={getIcon(user)} onClick={() => manageClick(user)} /></span></div>
                                        })
                                    }
                                </div>
                            </div>
                            <Divider className='divider-modal' />
                            <div>
                                <InputComponent
                                    value={addFilter.searchText}
                                    setValue={(value) => setAddFilter({ ...addFilter, searchText: value })}
                                    label="Search Users"
                                    placeHolder="Search by user name or email"
                                    inputType="text"
                                />
                            </div>
                            <div className='search-user'>
                                {
                                    addFilter !== undefined && addFilter.searchText !== undefined && addFilter.searchText.length > 0 &&
                                    <div>
                                        {
                                            searchResult.length > 0 &&
                                            searchResult.map((user: any) => { return <div className={'user-list'}>{getUsername(user.id)}<span><Icon name='plus' onClick={() => addUser(user.id)} /></span></div> })
                                        }
                                        {
                                            searchResult.length === 0 &&
                                            <Message warning>
                                                If you do not see your target user in the search results, please use the invite functionality.
                                            </Message>
                                        }
                                    </div>
                                }
                                {
                                    (addFilter === undefined || addFilter.searchText === undefined || addFilter.searchText.length === 0) && <div className='no-results'>Search for users to add</div>
                                }
                            </div>
                        </>
                    }
                    {activeTab === "invite" &&
                        <InviteUserComponent
                            role={props.role}
                            onUserInvited={onUserInvited}
                            onInvitationFailed={onInvitationFailed} />
                    }
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <div className='actions-div'>
                    <div className="wave-div">
                        <img src={Wave2} alt="wave" className="wave" />
                    </div>
                    <div className="btn-div">
                        <ButtonComponent
                            onBtnClick={save}
                            label="Save"
                            theme={"primaryBtn"} // primaryBtn secondaryBtn tertiaryBtn
                            disabled={activeTab !== "search"}
                        // disabled={(remList.length === 0 && addList.length === 0)}
                        />
                        <Button className="cancel-btn" onClick={cancel}>Cancel</Button>
                    </div>
                </div>
            </Modal.Actions>
        </Modal >
    )
}
