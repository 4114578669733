import { useEffect, useState } from 'react';
import { Modal, Button, Icon, Table } from "semantic-ui-react";
import Wave from "../../../images/modalWave.svg"
import Wave2 from "../../../images/modalWave2.svg";
import './TooltipModal.scss'

export const TooltipModal: any = (props: any) => {

    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (!open) {
            cancel();

        }

    }, [open]);


    const cancel = () => {
        setOpen(false);
    }

    return (
        <Modal className="tooltip-modal-component"
            size="large"
            centered={true}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={
                <div className="upload-tooltip-wrapper" style={{ marginTop: "0.4rem" }}>
                    <Icon name="question circle" className="info-icon upload-projects-tooltip" style={{ width: "auto" }} />
                    <span style={{ fontSize: "1.2rem", fontWeight: "bold" }}>Upload Instructions</span>
                </div>}
        >
            <Modal.Header>
                <div className="tooltip-modal-title">Instructions For Adding Bulk Upload Forms</div>
                <div className="wave-div">
                    <img src={Wave} alt="wave" className="wave" />
                </div>
            </Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    <div className="inner-wrapper">
                        <div className="instructions">
                            <div className="instructions-header">
                                Uploading Project Data
                            </div>
                            <ol>
                                <li>Please download and use the provided templates for bulk upload. There are two template files: &quot;Watershed Control Measures&quot; and &quot;New & Redevelopment&quot;.</li>
                                <li>Click the 'Upload Project' button to open the pop-up when you are ready to upload.</li>
                                <li>Select the appropriate upload type from the dropdown selector inside the pop-up &#40;i.e., &quot;Watershed Control Measure&quot; or &quot;New and Redevelopment&quot;&#41;</li>
                                <li>After uploading the form, the upload attempt will appear in the &quot;Recent Bulk Uploads&quot; table, which indicates the date/time of upload and upload status &#40;i.e., Complete or Failed&#41;.</li>
                                <li>Check upload status.
                                    <ol type="a">
                                        <li>If your upload is Complete, please click the &quot;i&quot; icon in the &quot;Error&#40;s&#41;/Warnings&quot; to review any warnings and then you may proceed to the &quot;Uploaded Projects&quot; tab to review your data.</li>
                                        <li>If your upload Failed, please click the &quot;i&quot; icon &#40;blue circle with question mark&#41; in the &quot;Error&#40;s&#41;/Warnings&quot; column to view the error&#40;s&#41; preventing successful upload.</li>
                                    </ol>
                                </li>
                            </ol>
                            <div className="instructions-header">
                                Revising Existing Data
                            </div>
                            <ol>
                                <li>To delete a project, select the project in the &quot;Uploaded Project List&quot; table on the “Uploaded Projects” tab and click &quot;Delete Selected Items&quot;.</li>
                                <li>To revise one or a few projects, consider using the &quot;Edit&quot; tool found by clicking the &quot;Edit&quot; button in the &quot;Info&quot; column project in the &quot;Uploaded Project List&quot; table on the &quot;Uploaded Projects&quot; tab. This tool allows users to edit individual projects that are already uploaded without needing to use the bulk upload process again to correct the information.</li>
                                <li>To revise several projects, consider using the bulk download button found on the &quot;Uploaded Project&quot; tab to download existing project data formatted in the bulk upload template, which can then be revised and uploaded using the bulk upload process.<br /><strong>Note:</strong> <em>Please review the Duplicate Checker rules below prior to this approach.</em></li>
                            </ol>
                            <div className="instructions-header mt-1">
                                Jurisdiction and Watershed Management Group Assignments
                            </div>
                            <p>During project upload, a project&apos;s location is used to identify which WMG and jurisdiction the project is spatially located in. Projects are assigned a WMG based on location and Jurisdiction is assigned based on the uploading jurisdiction, regardless of whether the project is physically located in that jurisdiction.</p>

                            <div className="instructions-header mt-1">
                                Duplicate Checking
                            </div>
                            <p>If a duplicate project/program is detected during upload, its existing information will be overwritten with the information in the upload.</p>
                            <p>A Project will be flagged as a duplicate if the following attributes match those of an existing project:</p>
                            <ul>
                                <li className="mb-1">Structural Watershed Control Measures and New & Redevelopment Projects:
                                    <ul>
                                        <li>Latitude and Longitude; AND</li>
                                        <li>Project Type; AND</li>
                                        <li>At least one of Name, Drainage Area, or Actual Storage Capacity.</li>
                                    </ul>
                                </li>
                                <li>Non Structural Watershed Control Measures:
                                    <ul>
                                        <li>Name; AND</li>
                                        <li>Non-Structural Project Type.</li>
                                    </ul>
                                </li>
                            </ul>
                            <p><strong>Note:</strong> <em>Revising one of the above project attributes for an existing project via bulk upload will result in a new project. To revise one of the above project attributes for an existing project, please use the &quot;Edit&quot; tool, or if using the bulk upload process, be sure to delete the outdated project from the &quot;Uploaded Projects List&quot; table.</em></p>
                            <div className="instructions-header mt-1">
                                Error and Warning Descriptions
                            </div>
                            <p>Provided below is a table of common upload errors and warnings.</p>
                            <Table structured>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Type</Table.HeaderCell>
                                        <Table.HeaderCell>Description</Table.HeaderCell>
                                        <Table.HeaderCell>Troubleshooting Suggestion</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {/* PARSE ERROR */}
                                    <Table.Row>
                                        <Table.Cell width="3" verticalAlign="top" rowSpan='6' className="bold-text"><strong>Parse Error</strong></Table.Cell>
                                        <Table.Cell>Headers Error: Headers do not match standard mixed template. Please ensure the most recent template is being used, which is available for download under 'Upload Projects' tab.</Table.Cell>
                                        <Table.Cell>Please download the bulk upload template to ensure you are using the most recent version.</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>Worksheet named 'Structural Control Measures' not found! Did you choose the right excel type?</Table.Cell>
                                        <Table.Cell>Check that the correct workbook type was selected during upload.</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>Lookup: Check that the cell is not and empty and matches the format in the template</Table.Cell>
                                        <Table.Cell>Check that entries are in an acceptable format.</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>Date: Check that a date exists and matches the format in the template</Table.Cell>
                                        <Table.Cell>Check that entries are in an acceptable format (MM/DD/YYYY). This field may be left blank for Planned &amp; In-Progress Projects.</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>Number: Invalid Number</Table.Cell>
                                        <Table.Cell>Check that entries are in an acceptable format.</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>Required: Project Name. Please check there are no rows with stranded entries such as an entry in a single cell or a blank space in a cell.</Table.Cell>
                                        <Table.Cell>There may be a stray cell in the uploaded workbook containing a space or other character that is preventing upload. Please clear all cells not containing Project data or copy and paste the data into a clean upload template.</Table.Cell>
                                    </Table.Row>
                                    {/* VALIDATION */}
                                    <Table.Row>
                                        <Table.Cell width="3" verticalAlign="top" rowSpan='3' className="bold-text"><strong>Validation</strong></Table.Cell>
                                        <Table.Cell>Required: Completion Date (for completed projects)</Table.Cell>
                                        <Table.Cell rowspan='3'>There are several fields in the workbook that require an entry, and some may require that the entry matches a value from its list of case-sensitive, acceptable entries which are listed in the readme tab. Please refer to the readme and field key table to resolve this error.</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>Required: Is this a Priority Development Project?</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>Required: Drainage Area (0 is accepted)</Table.Cell>
                                    </Table.Row>
                                    {/* GIS QUERY */}
                                    <Table.Row>
                                        <Table.Cell width="3" verticalAlign="top" rowSpan='2' className="bold-text"><strong>GIS Query</strong></Table.Cell>
                                        <Table.Cell>Project has been uploaded, but note the GIS coordinates have placed this project in a neighbouring jurisdiction (but still within a Group that your jurisdiction participates in).</Table.Cell>
                                        <Table.Cell>No action required. Users may wish review Project coordinates as needed to verify they
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>Project not uploaded. The GIS coordinates for this location would have placed the project in a different jurisdiction and a Group in which your jurisdiction does not participate.</Table.Cell>
                                        <Table.Cell>Please review Project coordinates to ensure they are both valid and within a WMG that your jurisdiction participates in.</Table.Cell>
                                    </Table.Row>
                                    {/* DUPLICATE CHECK */}
                                    <Table.Row>
                                        <Table.Cell width="3" verticalAlign="top" className="bold-text"><strong>Duplicate Check</strong></Table.Cell>
                                        <Table.Cell>Replaced existing BMP, &#34;...&#34;, with duplicate BMP from this upload.</Table.Cell>
                                        <Table.Cell>No action required. Users may wish to review the Project to confirm.</Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                        </div>
                    </div>
                </Modal.Description>
            </Modal.Content>
            <div className="wave-div">
                <img src={Wave2} alt="wave" className="wave" />
            </div>
            <Modal.Actions>
                <Button className="ok-btn" onClick={cancel}>OK</Button>
            </Modal.Actions>
        </Modal >
    )
}