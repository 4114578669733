import { Icon } from "semantic-ui-react";
import { WatershedReportPublicFacingConfig } from "../../../../models/viewModels/WatershedReportPublicFacingConfigViewModel";
import { ButtonComponent } from "../../../../paradigmLib/domElements/buttonComponent/ButtonComponent";
import { TextAreaComponent } from "../../../../paradigmLib/domElements/textAreaComponent/TextAreaComponent";
import { ToggleComponent } from "../../../../paradigmLib/domElements/toggleComponent/ToggleComponent";
import "./PublicConfigSection.scss"

export interface IPublicConfigSection {
    wmgPublicInfo: WatershedReportPublicFacingConfig;
    setWmgPublicInfo: Function;
    description: string;
    setDescription: (value: string) => void
    descriptionLabel: string;
    instructions: string | React.ReactNode;
    placeholder?: string;
    enabled: boolean;
    setEnabled: (value: boolean) => void;
    configMessage: string
}

export const PublicConfigSection: React.FC<IPublicConfigSection> = (props: IPublicConfigSection) => {
    const handleResetDefault = () => {
        props.setDescription(props.configMessage);
    }

    return (
        <div className="public-config-section">
            <div className="toggle-description-wrapper">
                <div className="include-toggle">
                    <ToggleComponent
                        value={props.enabled}
                        setValue={(value) => props.setEnabled(value)}
                        sideLabel="Include On Public Dashboard"
                        theme="primary"
                        useBoolean
                    />
                </div>
                <div className="instructions">
                    {props.instructions}
                </div>
                <div className="section-description">

                    <TextAreaComponent
                        value={props.description}
                        setValue={props.setDescription}
                        label={props.descriptionLabel}
                        placeHolder={props.placeholder ? props.placeholder : ""}
                        rows={2}
                    />
                    <div className="reset-default-wrapper">
                        <ButtonComponent
                            theme="primaryBtn"
                            onBtnClick={handleResetDefault}
                            label={<Icon name="refresh" />}
                            tooltip="Restore Default Text"
                            disabled={props.configMessage === props.description}
                        />
                    </div>
                </div>
            </div>
        </div >
    )
}
