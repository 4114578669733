import { Menu, Sidebar } from "semantic-ui-react";
import { SidebarManagerState } from "../components/mainWrapper/MainWrapper";

export interface ISidebarComponent {
  children: any;
  sidebarStore: SidebarManagerState;
  sidebarName: string;
  screenSize: number;
  screenDisplayType: string;
  isSpreadingGrounds?: boolean;
}

export const SidebarComponent = (props: ISidebarComponent) => {

  const getWidth = () => {
    let width: string;

    // if name is EditProjectPage
    if (props.sidebarStore.current.name === "EditProjectPage") {
      width = props.screenDisplayType === "desktop" ? "75%" : "100%";

      // if any other page on desktop
    } else if (props.screenDisplayType === "desktop") {
      width = props.isSpreadingGrounds ? "400px" : "330px"

      // if mobile
    } else {
      width = "100%";
    }
    return width;
  };

  return (
    <div>
      <div className="generic-sidebar-wrapper">
        <Sidebar
          as={Menu}
          animation="overlay"
          icon="labeled"
          inverted
          vertical
          visible={
            props.sidebarStore?.current.name === props.sidebarName
              ? true
              : false
          }
          style={{ width: getWidth() }}
          className="generic-sidebar"
        >
          {props.children}
        </Sidebar>
      </div>
    </div>
  );
};
