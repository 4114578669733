import React, { useState } from 'react';
import { MainWrapper } from './components/mainWrapper/MainWrapper';
import { Outlet, Routes, Route } from 'react-router-dom';
import RouteChangeTracker from './core/RouteChangeTracker';
import './App.scss';
import { AuthProvider } from 'react-oidc-context';
import Config from './config/Config';


export const LoadingContext = React.createContext({
    loading: false,
    setLoading: (loading: boolean) => { }
});

function App() {
    const [loading, setLoading] = useState(true);

    return (
        <div className="App">
            <LoadingContext.Provider value={{ loading: loading, setLoading: setLoading }}>
                <AuthProvider
                    authority={Config.authInfo.server}
                    client_id={Config.authInfo.clientId}
                    redirect_uri={Config.authInfo.redirectUri}
                    post_logout_redirect_uri={Config.authInfo.redirectUri}
                    scope={Config.authInfo.scopes}
                    onSigninCallback={() => {
                        window.history.replaceState(
                            {},
                            document.title,
                            window.location.pathname
                        )
                    }}>
                    <MainWrapper />
                </AuthProvider>
                <Outlet />

            </LoadingContext.Provider>
            <RouteChangeTracker />
        </div>
    );
}

export default App;
