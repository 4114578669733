import { Translation } from "react-i18next";
import { Divider, Icon, Message } from "semantic-ui-react";
import LookupFactory from "../../lookups/LookupFactory";
import WatershedManagementGroup from "../../models/WatershedManagementGroup";
import WatershedManagementGroupIsPublic from "../../models/WatershedManagementGroupIsPublic";
import "./Participants.scss";

interface IParticipantsProps {
    lookups: LookupFactory;
    setWatershedId: Function;
    watershedManagementGroupIsPublic: WatershedManagementGroupIsPublic[];
    publicReports: boolean;
    setPublicReports: Function;
    publicDashboard: boolean;
    setPublicDashboard: Function;
    setTabState: Function;
}

export const Participants = (props: IParticipantsProps) => {

    const handleIconClick = (watershedManagementGroupItem: WatershedManagementGroupIsPublic, id: number) => {
        props.setWatershedId(id);
        if (watershedManagementGroupItem.isPublicReports === 1) {
            props.setPublicReports(true);
        } else {
            props.setPublicReports(false);
        }
        if (watershedManagementGroupItem.isPublicDashboard === 1) {
            props.setPublicDashboard(true);
        } else {
            props.setPublicDashboard(false);
        }
        props.setTabState("forms");
        setTimeout(() => {
            document.getElementById('progress-section')?.scrollIntoView({ behavior: 'smooth' });
        }, 100);
    };

    return (
        <Translation>
            {
                t =>
                    <>
                        <div className="participants">
                            <div className="participants__header-background-img">
                                <div className="participants__header-title-container">
                                    <h1 className="participants__header-title">Watershed Progress Reporting</h1>
                                </div>
                                <div className="white-wave-wrapper">
                                    <div className="white-wave"></div>
                                </div>
                            </div>
                            <div className="participants-header">
                            </div>
                            <div style={{ margin: "2em" }} >
                                <Message info>
                                    <p className="participants__sub-header-text">
                                        The Watershed Groups below are using WRAMPS to make their WMP Progress Reports readily accessible to and easily understandable for the public. <br></br>Please explore each Watershed Group's progress using the links below to learn more about their progress toward water quality milestones and co-benefits provided.
                                    </p>
                                </Message>
                            </div>
                            <div className="wsgroup-table-container">
                                <table style={{ width: "100%" }}>
                                    <tbody>
                                        <tr className="header-row">
                                            <td style={{ fontSize: "2.4rem" }} width='2' className="header first-column">Watershed Groups</td>
                                            <td style={{ fontSize: "2.4rem" }} width='2' className="header center">Progress Reporting</td>
                                        </tr>
                                        <tr className="row-spacer"></tr>
                                        <tr className="row-spacer"></tr>
                                        {props.lookups && props.lookups &&
                                            props.lookups.lutWatershedManagementGroup.map((wsObj: WatershedManagementGroup, index: number) => {
                                                let watershedManagementGroupItem = props.watershedManagementGroupIsPublic.find(x => x.watershedGroupId === wsObj.id);
                                                if (watershedManagementGroupItem?.isPublicReports === 1 ||
                                                    watershedManagementGroupItem?.isPublicDashboard === 1) {
                                                    return (
                                                        <tr key={index} onClick={() => handleIconClick(watershedManagementGroupItem, wsObj.id)} >
                                                            <td className="first-column">{wsObj.displayName}</td>
                                                            <td className="center">
                                                                <Icon
                                                                    id={wsObj.id}
                                                                    className="icon"
                                                                    name="pie graph"
                                                                    onClick={() => handleIconClick(watershedManagementGroupItem, wsObj.id)}
                                                                />
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            })
                                        }
                                    </tbody>
                                </table>

                            </div>

                            {
                                props.watershedManagementGroupIsPublic &&
                                    props.watershedManagementGroupIsPublic.filter(x => x.isPublicReports === 1).length === 0
                                    ?
                                    <div className="no-watersheds">None of the participating Watershed Groups have published the progress report forms
                                        <br /><br />
                                        Please check back after the reporting deadline (June 15 or December 15)  </div>
                                    :
                                    ""
                            }
                        </div>
                    </>
            }
        </Translation >
    )
}