import React from 'react';

import { Table, Dimmer, Loader } from 'semantic-ui-react';

import { customFormat } from '../../core/NumberFormat';
import { CaptureBenefitService } from "../../services/CaptureBenefitService";
import { CaptureBenefitAggregateTableViewModel, } from "../../services/CaptureBenefitService";

interface SWCaptureDetailsPanelProps {
  tableData: CaptureBenefitAggregateTableViewModel;
  isLoading: boolean;
}

const CaptureSystemWideDetailsPanel: React.FC<SWCaptureDetailsPanelProps> = (props) => {

  const { totalAmount, totalCount } = getTotalAmountAndCount(props.tableData);

  return (
    <div className="capture-dashboard__widget-container">
      <h2 className="capture-dashboard__project-overview-title" >Stormwater Capture Details</h2>
      <h4 style={{ margin: "0 0 2em", fontStyle: "italic" }}>Explore our stormwater capture estimates! Below you can view capture by different project types and time periods. Check back after rain events to see our real-time progress!</h4>
      <div className="capture-dashboard__project-overview-attribute-container capture-dashboard__system-wide-project-overview">
        <Dimmer inverted active={props.isLoading}>
          <Loader active={props.isLoading} />
        </Dimmer>
        <Table celled fixed >
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width="1">Project Type</Table.HeaderCell>
              <Table.HeaderCell width="2">Stormwater Capture</Table.HeaderCell>
              <Table.HeaderCell width="1">Number of Projects</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>LID Projects</Table.Cell>
              <Table.Cell>{formatNumber(props.tableData?.lids.amount)} acre-feet</Table.Cell>
              <Table.Cell>{props.tableData?.lids.count} </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Green Streets</Table.Cell>
              <Table.Cell>{formatNumber(props.tableData?.greenStreets.amount)} acre-feet</Table.Cell>
              <Table.Cell>{props.tableData?.greenStreets.count} </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Regional Projects</Table.Cell>
              <Table.Cell>{formatNumber(props.tableData?.regionalProjects.amount)} acre-feet</Table.Cell>
              <Table.Cell>{props.tableData?.regionalProjects.count} </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Other Structural Projects</Table.Cell>
              <Table.Cell>{formatNumber(props.tableData?.otherProjects.amount)} acre-feet</Table.Cell>
              <Table.Cell>{props.tableData?.otherProjects.count} </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Spreading Grounds</Table.Cell>
              <Table.Cell>{formatNumber(props.tableData?.spreadingGrounds.amount)} acre-feet</Table.Cell>
              <Table.Cell>{props.tableData?.spreadingGrounds.count} </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Low Flow Diversions</Table.Cell>
              <Table.Cell>{formatNumber(props.tableData?.lowFlowDiversions.amount)} acre-feet</Table.Cell>
              <Table.Cell>{props.tableData?.lowFlowDiversions.count} </Table.Cell>
            </Table.Row>

            {/* <Table.Row>
              <Table.Cell>Total</Table.Cell>
              <Table.Cell>{customFormat(totalAmount)}</Table.Cell>
              <Table.Cell>{customFormat(totalCount)}</Table.Cell>
            </Table.Row>

            Waiting for confirmation on this from Dustin*/}

          </Table.Body>
        </Table>
        <p>
          * Spreading Grounds capture data for most the recent storm will be updated the month following the storm
        </p>
      </div>
    </div>
  );
};

export default CaptureSystemWideDetailsPanel;

function getTotalAmountAndCount(data: CaptureBenefitAggregateTableViewModel) {
  let totalAmount = 0;
  let totalCount = 0;

  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      totalAmount += data[key as keyof CaptureBenefitAggregateTableViewModel].amount;
      totalCount += data[key as keyof CaptureBenefitAggregateTableViewModel].count;
    }
  }

  return { totalAmount, totalCount };
}

function formatNumber(num: number): string {
  return num.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
}