import { BaseModel } from "./BaseModel";


export default class WatershedReportForm extends BaseModel {

    id: number;
    watershedManagementGroupId: number;
    watershedReportStatusId: number;
    jurisdictionId: number;
    watershedReportingPeriodId: number;
    active: number;
    editor: string;
    created: Date;
    updated: Date;

    constructor() {
        super("WatershedReportForm", "local")
    }
}