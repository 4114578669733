import React from "react";
import { Form, TextArea } from "semantic-ui-react";
import './TextAreaComponent.scss';

export interface ITextAreaComponentProps {
    value: string;
    setValue: (value: string) => void;
    label: string;
    placeHolder: string;
    rows: number;
    disabled?: boolean;
    objArray?: [];
    index?: number;
    requiredField?: boolean;
    toolTipInfo?: string;
}

export const TextAreaComponent: React.FC<ITextAreaComponentProps> = (props) => {
    return (
        <div className="text-area-component">
            <Form.Field>
                <div className={`basic-text-area-wrapper ${props.requiredField ? "required field" : ""}`}>
                    <label className="basic-text-area">{props.label}</label>
                    {
                        props.toolTipInfo &&
                        <div className="tool-tip-entry-icon" />
                    }
                </div>
                <TextArea placeholder={props.placeHolder}
                    className="textbox"
                    disabled={props.disabled}
                    value={props.value ?? ""}
                    onChange={(e) => props.setValue(e.target.value)}
                    rows={props.rows}
                />
            </Form.Field>
        </div>
    )
}
