import React from "react";
import { Translation } from "react-i18next";
import LiquidFillGauge from 'react-liquid-gauge';
import { color } from 'd3-color';
import { interpolateRgb } from 'd3-interpolate';
import { customFormat } from '../../core/NumberFormat';
import './WaterGauge.scss';

export interface IWaterGauge {
    value: number;
    size: number;
    color: string;
    displayValue?: number; // this is different from the value so you have the option to display inner text or not
    metric?: number | string;
    offsetX?: number;
    offsetY?: number;
    spacing?: string;
    numberSize?: number;
    metricSize?: string;
    metricColor?: string;
}

export const WaterGauge: React.FC<IWaterGauge> = (props: IWaterGauge) => {
    let startColor = props.color;
    let endColor = props.color;

    const radius = props.size;
    const interpolate = interpolateRgb(startColor, endColor);
    const fillColor = interpolate(props.value / 100);
    const gradientStops = [
        {
            key: '0%',
            stopColor: color(fillColor).darker(0.5).toString(),
            stopOpacity: 1,
            offset: '0%'
        },
        {
            key: '50%',
            stopColor: fillColor,
            stopOpacity: 0.75,
            offset: '50%'
        },
        {
            key: '100%',
            stopColor: color(fillColor).brighter(0.5).toString(),
            stopOpacity: 0.5,
            offset: '100%'
        }
    ];

    return (
        <div className="water-gauge-wrapper">
            <Translation>
                {
                    t =>

                        <div className={`outside-circle ${props.spacing}`}>
                            <LiquidFillGauge
                                style={{ margin: 'auto' }}
                                width={radius * 2}
                                height={radius * 2}
                                value={props.value}
                                textSize={props.numberSize}
                                textOffsetX={props.offsetX}
                                textOffsetY={props.offsetY}
                                textRenderer={(textProps) => {
                                    const radius = Math.min(textProps.height / 2, textProps.width / 2);
                                    const textPixels = (textProps.textSize * radius / 2);
                                    const valueStyle = {
                                        fontSize: textPixels
                                    };

                                    return (
                                        <>
                                            <tspan>
                                                <tspan className="value" style={valueStyle}>{customFormat(props.displayValue)}</tspan>
                                            </tspan>
                                        </>
                                    );
                                }}

                                riseAnimation
                                waveAnimation={true}
                                waveFrequency={1}
                                waveAmplitude={3}
                                gradient
                                gradientStops={gradientStops}
                                circleStyle={{
                                    fill: fillColor

                                }}
                                waveStyle={{
                                    fill: fillColor
                                }}
                                textStyle={{
                                    fill: color(props.metricColor),
                                    fontFamily: 'Arial'
                                }}
                                waveTextStyle={{
                                    fill: color('#fff').toString(),
                                    fontFamily: 'Arial'
                                }}
                            />

                            <div className={`${props.metricSize}`}
                                style={{ color: props.metricColor }}>
                                {props.metric}
                            </div>
                        </div>
                }
            </Translation>
        </div>
    )
}
