import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Button, Image, } from "semantic-ui-react";
import { Link } from 'react-router-dom'
import { Translation } from "react-i18next";

import WelcomeViewModel from "../../models/viewModels/WelcomeViewModel";
import WatershedManagementGroupIsPublic from "../../models/WatershedManagementGroupIsPublic";
import { Get } from "../../core/DownloadUtil";
import { WelcomePageSidebar } from "./welcomePageSidebar/WelcomePageSidebar";
import { ProgressReporting } from "./ProgressReporting";
import LookupFactory from "../../lookups/LookupFactory";
import BmpGeneral from "../../models/BmpGeneral";
import { Participants } from "./Participants";
import { CoBenefits } from "./CoBenefits"

import image01 from "../../images/004-ima.jpg";
import image02 from "../../images/002-ima.jpg";
import image03 from "../../images/003-ima.jpg";

import './WelcomePage.scss';

interface IWelcomePageProps {
    visible: boolean;
    setVisible: Function;
    lookups: LookupFactory;
    sidebarProjectInfoObj: BmpGeneral;
    setSidebarProjectInfoObj: Dispatch<SetStateAction<BmpGeneral>>;
    tabState: string;
    setTabState: Function;
}

export const WelcomePage = (props: IWelcomePageProps) => {
    const [watershedId, setWatershedId] = useState<number>();
    const [welcomeInfo, setWelcomeInfo] = useState<WelcomeViewModel>(new WelcomeViewModel());
    const [publicReports, setPublicReports] = useState(false);
    const [publicDashboard, setPublicDashboard] = useState(false);
    const [sidebarVisible, setSidebarVisible] = useState<boolean>(false);

    const [watershedManagementGroupIsPublic, setWatershedManagementGroupIsPublic] = useState<WatershedManagementGroupIsPublic[]>(new Array<WatershedManagementGroupIsPublic>());

    useEffect(() => {
        Get<WelcomeViewModel>(WelcomeViewModel.endpoint, null)
            .then((response) => {
                setWelcomeInfo(response);
            });

        Get<WatershedManagementGroupIsPublic[]>("WatershedManagementGroups/IsPublic", null)
            .then((response) => {
                setWatershedManagementGroupIsPublic(response);
            });
    }, []);

    return (
        <div className={"welcome-page"}>
            <div className="welcome-page-inner-wrapper">
                <Translation>
                    {
                        t =>
                            <>
                                {
                                    props.tabState === "welcomePage" && <div className="welcome-page__header-background-img">
                                        <div className="welcome-page__header-title-container">
                                            <h1 className="welcome-page__header-title">Los Angeles County Watershed Reporting System</h1>
                                            <p className="welcome-page__header-subtitle">WRAMPS is the home for reporting progress toward watershed-based regulatory  requirements and proactive water capture goals in LA County.</p>
                                        </div>
                                        <div className="white-wave-wrapper">
                                            <div className="white-wave"></div>
                                        </div>
                                    </div>
                                }
                                {props.tabState === "welcomePage" &&
                                    <div className="welcome-page__cards-container">
                                        <div>
                                            <section className="welcome-page-cards">
                                                <article className="welcome-page-card">
                                                    <Image src={image01} size='medium' />
                                                    <div className="welcome-page__card__info">
                                                        <h3 className="welcome-page__card__title">Progress Reporting</h3>
                                                        <p className="welcome-page__card__category">
                                                            Transparent, readily-understandable summaries of water quality improvement progress by municipalities as required by the MS4 Permit. <br /><br />Updates are provided every 6 months!
                                                        </p>

                                                        <Button
                                                            primary
                                                            size="massive"
                                                            className="welcome-page__card-btn"
                                                            onClick={() => props.setTabState("wsGroup")}
                                                        >
                                                            <Button.Content visible>View Progress</Button.Content>
                                                        </Button>
                                                    </div>

                                                </article>
                                            </section>
                                        </div>

                                        <div>
                                            <section className="welcome-page-cards">
                                                <article className="welcome-page-card">
                                                    <Image src={image02} size='medium' />
                                                    <div className="welcome-page__card__info">
                                                        <h3 className="welcome-page__card__title">Capture</h3>
                                                        <p className="welcome-page__card__category">
                                                            Real-time summaries of stormwater capture across the County by green infrastructure and water conservation facilities.<br /><br />Check the dashboard after each storm!
                                                        </p>
                                                        <Button
                                                            primary
                                                            size="massive" as={Link} to='/capture'
                                                            className="welcome-page__card-btn"
                                                        >
                                                            <Button.Content visible>Explore Capture</Button.Content>
                                                        </Button>
                                                    </div>
                                                </article>
                                            </section>
                                        </div>

                                        <div>
                                            <section className="welcome-page-cards">
                                                <article className="welcome-page-card">
                                                    <Image src={image03} size='medium' />
                                                    <div className="welcome-page__card__info">
                                                        <h3 className="welcome-page__card__title">Co-benefits </h3>
                                                        <p className="welcome-page__card__category">
                                                            Summaries of multiple co-benefits provided to the community by stormwater programs.
                                                        </p>
                                                        <Button
                                                            primary
                                                            size="massive"
                                                            className="welcome-page__card-btn"
                                                            onClick={() => props.setTabState("cobenefits")}
                                                        >
                                                            <Button.Content visible>See Co-benefits</Button.Content>
                                                        </Button>
                                                    </div>
                                                </article>
                                            </section>
                                        </div>
                                    </div>
                                }
                                <div className="small-device-message">Please visit this page from a larger device</div>
                                <div className="tab-div">

                                    {/* Participants tab */}
                                    {(props.tabState === "wsGroup" || props.tabState === "forms") &&
                                        <div className="first-tab">
                                            <Participants
                                                lookups={props.lookups}
                                                setWatershedId={setWatershedId}
                                                publicReports={publicReports}
                                                setPublicReports={setPublicReports}
                                                publicDashboard={publicDashboard}
                                                watershedManagementGroupIsPublic={watershedManagementGroupIsPublic}
                                                setPublicDashboard={setPublicDashboard}
                                                setTabState={props.setTabState}
                                            />
                                        </div>
                                    }

                                    {/* Co-Benefits tab */}
                                    {props.tabState === "cobenefits" &&
                                        <div className="second-tab">
                                            <CoBenefits
                                                welcomeInfo={welcomeInfo}
                                            />
                                        </div>
                                    }

                                    {/* Progress Reporting tab */}
                                    {props.tabState === "forms" &&
                                        <div className="fourth-tab">
                                            <ProgressReporting
                                                lookups={props.lookups}
                                                wmgId={watershedId}
                                                sidebarVisible={sidebarVisible}
                                                setSidebarVisible={setSidebarVisible}
                                                sidebarProjectInfoObj={props.sidebarProjectInfoObj}
                                                setSidebarProjectInfoObj={props.setSidebarProjectInfoObj}
                                                publicReports={publicReports}
                                                publicDashboard={publicDashboard}
                                            />
                                        </div>
                                    }
                                </div>
                            </>
                    }
                </Translation>
                <WelcomePageSidebar
                    sidebarVisible={sidebarVisible}
                    setSidebarVisible={setSidebarVisible}
                    lookups={props.lookups}
                    sidebarProjectInfoObj={props.sidebarProjectInfoObj}
                />
                <div className="footer-wave-wrapper">
                    <div className="blue-wave">
                        <div className="wave-background"></div>
                        <div className="wave-filler"></div>
                        <p className="welcome-page__footer-text">© {new Date().getFullYear()} Los Angeles County Watershed Reporting System</p>
                    </div>
                </div>
            </div >
        </div >
    )
}