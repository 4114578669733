import { Segment, Loader, Dimmer } from "semantic-ui-react";
import { useNavigate } from "react-router";
import Config from "../../config/Config";
import { AuthContextProps, useAuth } from "react-oidc-context";
import { useEffect } from "react";

export const LogOffLoader: any = (props: any) => {
    const auth = useAuth();
    let navigate = useNavigate();

    useEffect(() => {
        if (auth.user) {
            auth.signoutRedirect();
        } else {
            navigate(Config.routeUrl + "/welcome");
        }
    }, []);

    return (
        <div className="logoff-wrapper">
            <div className="loader-wrapper">
                <Segment className="dimmer-wrapper">
                    <Dimmer className="dimmer-full" active>
                        <Loader size="large" />
                    </Dimmer>
                </Segment>
            </div>
        </div>
    );
};
