import { useContext } from "react";
import './BuildWatershedForm.scss';
import { Translation } from "react-i18next";
import { SortableTableComponent } from "../../../paradigmLib/domElements/sortableTable/SortableTableComponent";
import { IBuildWatershedFormProps } from "./BuildWatershedForm";
import { ButtonComponent } from "../../../paradigmLib/domElements/buttonComponent/ButtonComponent";
import { UploadSection } from "../../uploadSection/uploadSection";
import { useAuth } from "react-oidc-context";

export const BuildWatershedForm_1_4: any = (props: IBuildWatershedFormProps) => {
    const auth = useAuth();

    const next = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        props.setActiveItem("1_5");
    }

    return (
        <div className="build-watershed-form-subpage">
            <Translation>
                {
                    t =>
                        <>
                            <div className="subpage-container">
                                <div className="subpage-header-container">
                                    <div className="subpage-header-1">1.4&nbsp; - &nbsp;Water Body Pollutant Combination (WBPC) Compliance</div>
                                    <div className="subpage-header-2">
                                        Complete Table 1c on an Excel spreadsheet for all WBPCs identified in the Watershed Management Program.
                                        If information is not available for a particular field, the field should indicate <q>Not Applicable</q> (N/A) [Order – X].
                                        The Excel output <q>Output for Tables 1a, 1b, 1c and Sections 1.2a, 1.3a (.xlsx)</q> generated in the Reporting Outputs tab
                                        includes a tab for Table 1c tab which contains the table template if needed.
                                    </div>
                                </div>

                                {
                                    props.reportingPeriod &&
                                    <SortableTableComponent
                                        tableData={[
                                            ['1', 'Zinc', 'Cumberland River', 'rainy', 'Interim', '1/1/1990', 'yes', 'via online']
                                        ]}
                                        headerNames={[
                                            'WBPC Category'
                                            , 'Pollutant'
                                            , 'Receiving Water'
                                            , 'Weather Condition'
                                            , 'Interim or Final'
                                            , 'Deadline'
                                            , 'Deadline Met?'
                                            , 'Method of Compliance'
                                        ]}
                                        headerBackgroundColor=''
                                        headerTextColor=''
                                        sortable celled striped
                                    />
                                }
                                <div className="subpage-header-container">
                                    {/* <div className="subpage-header-1 pt-point5">1.3a&nbsp; - &nbsp;Additional Information</div> */}
                                    <div className="subpage-header-2 pdf-upload-div mt-3 upload-div-flex">

                                        <UploadSection
                                            disabled={true}/*temporarily disabled: disabled={props.finalLock} */
                                            formName={"1_4"}
                                            watershedReportForm={props.watershedReportForm}
                                            lookups={props.lookups}
                                            fileType={".pdf"}
                                        />
                                        <i className="pl-1">Coming soon!</i>
                                    </div>
                                </div>

                                <div className="save-btn-div">
                                    <ButtonComponent
                                        onBtnClick={() => next()}
                                        label="Next"
                                        theme="primaryBtn"
                                    />
                                </div>

                            </div>
                        </>
                }
            </Translation>
        </div>
    )
}
