export type SpreadingGround = {
    id: number;
    name: string;
    basinTypeId: SpreadingGroundBasinTypeId | null;
    basinCount: number | null;
    firstSeason: string | null;
    grossAreaAc: number | null;
    wettedAreaAc: number | null;
    channelCapacityCfs: number | null;
    intakeCapacityCfs: number | null;
    storageCapacityAcft: number | null;
    percolationCapacityCfs: number | null;
    locationDescription: string | null;
    waterSourceDescription: string | null;
    comment: string | null;
    internalId: string | null;
    latitude: number | null;
    longitude: number | null;
    footprintGeoJson: string | null;
    watershedId: number | null;

    active: boolean;
    editor: string | null;
    created: Date | string | null;
    updated: Date | string | null;

    spreadingGroundCapture: SpreadingGroundCapture[];
}

export type SpreadingGroundCapture = {
    id: number;
    spreadingGroundId: number;
    spreadingGroundCaptureTimeFrameId: number;
    waterConservedAcft: number;

    active: boolean;
    editor: string | null;
    created: Date | string | null;
    updated: Date | string | null;

    spreadingGroundCaptureTimeFrame: SpreadingGroundTimeFrame | null; // optional, could be json ignored
}

export type SpreadingGroundTimeFrame = {
    id: number;
    startDate: Date;
    endDate: Date;
    isCustom: boolean;

    active: boolean;
    editor: string | null;
    created: Date | string | null;
    updated: Date | string | null;
}

export enum SpreadingGroundBasinTypeId {
    Shallow = 1,
    Deep = 2,
    DeepAndShallow = 3
}

export type SpreadingGroundBasinType = {
    id: number;
    name: string;
    description: string;
}
