import { ChangeEvent, useEffect, useState } from 'react';
import { Button, Form, Modal, TextArea } from 'semantic-ui-react';
import { BaseModel } from '../../models/BaseModel';
import { ButtonComponent } from '../domElements/buttonComponent/ButtonComponent';
import { enumUploadStatus, uploadOptions } from '../../customTypes/stringLiterals';
import { IUploadModal } from './IUploadModal';
import { useDeleteModel, usePDFPost } from '../../core/UseAPILocal'
import Wave from "../../images/modalWave.svg";
import Wave2 from "../../images/modalWave2.svg";
import './UploadModal.scss'
import { useAuth } from 'react-oidc-context';

export const UploadModal: any = (props: IUploadModal) => {
    const auth = useAuth();
    const [fileSelected, setFileSelected] = useState(false);
    const [chooseFile, setChooseFile] = useState('No file chosen...');
    const [docName, setDocName] = useState('');
    const [docDescription, setDocDescription] = useState('');
    const [formData, setFormData] = useState<FormData>();
    const [error, setError] = useState<enumUploadStatus>(enumUploadStatus.Ready);
    const [inputKey, setInputKey] = useState<Date>(new Date());
    const [deleteThenUpload, setDeleteThenUpload] = useState<boolean>(false);
    const [deleteModel, setDeleteModel] = useState<BaseModel | null>(null);

    const cancel = () => {
        props.setOpen(false);

        let fileUploaded = props.fileUploaded;
        fileUploaded = uploadOptions.Waiting;
        props.setFileUploaded(fileUploaded);

        setDeleteModel(null);
        setError(enumUploadStatus.Ready);
        setDocName("");
        setDocDescription("");
        setChooseFile('No file chosen...');
    };

    useEffect(() => {
        if (!props.open) {
            cancel();
        }
    }, [props]);

    useDeleteModel(
        auth.user?.access_token,
        deleteModel,
        setDeleteModel,
        () => {
            setDeleteThenUpload(true)
            setUpdateAttachmentModel(true);
            setInputKey(new Date())
            if (props.uploadAndClose === true) {
                cancel();
            }
        });

    let attachment = props.getAttachmentModel();

    const [attachmentModel, setAttachmentModel] = useState<BaseModel>(attachment);
    const [updateAttachmentModel, setUpdateAttachmentModel] = useState<boolean>(false);

    usePDFPost(
        auth.user?.access_token,
        attachmentModel,
        setAttachmentModel,
        formData,
        updateAttachmentModel,
        setUpdateAttachmentModel,
        (result: BaseModel) => {
            if (deleteThenUpload) {
                let newArray = [];
                newArray.push(result);
                props.setDocumentList(newArray);
            } else {
                let copy = Array.from(props.documentList);
                copy.push(result);
                props.setDocumentList(copy);
            }
        });

    useEffect(() => {
        attachment.attachmentFileName = docName;
        attachment.attachmentDescription = docDescription;
        setAttachmentModel(attachment);
    }, [docName, docDescription])

    function clearErrors() {
        setError(enumUploadStatus.Ready);

        let fileUploaded = props.fileUploaded;
        fileUploaded = uploadOptions.Waiting;
        props.setFileUploaded(fileUploaded);
    }

    function handleFieldChange(evt: any, setStateFunc: Function) {
        setStateFunc(evt.target.value);
        setError(enumUploadStatus.Ready);
    }

    function handleFileSelect(evt: ChangeEvent<HTMLInputElement>) {
        let formData: FormData = new FormData();
        let file: File = evt.target.files[0];

        if (!file) {
            setError(enumUploadStatus.SelectFile);
            return;
        }

        if (file.size > 1024 * 1024 * 100) {
            setError(enumUploadStatus.FileTooLarge);
            setDocName("");
            setDocDescription("");
            return;
        }

        formData.append("data", file);
        setError(enumUploadStatus.Ready);
        setDocName(file.name);
        setDocDescription(file.name + " - " + docDescription);
        setFormData(formData);
        setFileSelected(true);
        setChooseFile(file.name);
    };

    function uploadSelectedFile() {
        if (!fileSelected) {
            setError(enumUploadStatus.SelectFile);
            return;
        }

        if (docName === '') {
            setError(enumUploadStatus.FieldsRequired);
            return;
        }

        clearErrors();

        if (props.singleUpload && props.objToDelete.length > 0) {
            setDeleteModel(props.objToDelete[0]);
        } else {
            setUpdateAttachmentModel(true);
            setInputKey(new Date())
            if (props.uploadAndClose === true) {
                cancel();
            }
        }
    };

    return (
        <Modal className="upload-modal-component"
            onClose={() => props.setOpen(false)}
            onOpen={() => props.setOpen(true)}
            open={props.open}>
            <Modal.Header>
                <div className="upload-modal-title">{props.headerText}</div>
                <div className="wave-div">
                    <img src={Wave} alt="wave" />
                </div>
            </Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    <div className="uploader mb-1">
                        {
                            error === enumUploadStatus.SelectFile &&
                            <span className="text-danger ml-2 mb-1">***Please select a file to upload</span>
                        }
                        {
                            error === enumUploadStatus.FileTooLarge &&
                            <span className="text-danger ml-2 mb-1">***File is too large. Please use files under 20 MB.</span>
                        }

                        {
                            props.fileUploaded === uploadOptions.PDFExpected &&
                            <span className="text-danger ml-2 mb-1">***Please a attach PDF file only</span>
                        }
                        {
                            props.fileUploaded === uploadOptions.ShapeExpected &&
                            <span className="text-danger ml-2 mb-1">***Please a attach Shape file only</span>
                        }
                        <div className="file-upload">
                            <div className="file-select">
                                <div className="file-select-button" id="fileName">Choose File</div>
                                <div className="file-select-name" id="chooseFile">{chooseFile}</div>
                                <input type="file" accept={`${props.fileType}`} name="chooseFile" id="chooseFile" onChange={handleFileSelect} />
                            </div>
                        </div>
                    </div>
                    <div>
                        <Form>
                            <Form.Field>
                                <label>Document Name</label>{error === enumUploadStatus.FieldsRequired ? <span className="text-danger ml-1">***Please fill out field</span> : ''}
                                <input type="text" key={`${inputKey} 1`} value={docName} onChange={(e) => handleFieldChange(e, setDocName)} name="docName" id="docName" placeholder="Name" />
                            </Form.Field>
                            <Form.Field>
                                <label>Document Description</label>
                                <TextArea type="textarea" key={`${inputKey} 2`} rows={3} value={docDescription} onChange={(e) => handleFieldChange(e, setDocDescription)} name="docDescription" id="docDescription" placeholder="Description" />
                            </Form.Field>

                        </Form>
                    </div>
                </Modal.Description>
            </Modal.Content>
            <div className="action-div">
                <div className="wave-div">
                    <img src={Wave2} alt="wave" className="wave" />
                </div>
                {
                    props.fileUploaded === uploadOptions.Success &&
                    <Modal.Actions>
                        <div>
                            Upload Successful
                        </div>
                        <div>
                            <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                            </svg>
                        </div>
                        <Button color="grey" onClick={cancel}>Close</Button>
                    </Modal.Actions>
                }
                {
                    props.fileUploaded === uploadOptions.Failed &&
                    <Modal.Actions className="pdf-modal-actions">
                        <div className="error-div">
                            There was a issue with your upload
                        </div>
                        <ButtonComponent
                            onBtnClick={uploadSelectedFile}
                            label="Try Agian"
                            theme={"primaryBtn"}
                        />
                        <Button color="grey" onClick={cancel}>Cancel</Button>
                    </Modal.Actions>
                }
                {
                    ((props.fileUploaded === uploadOptions.Waiting || props.fileUploaded === uploadOptions.PDFExpected || props.fileUploaded === uploadOptions.ShapeExpected)) &&
                    <Modal.Actions className="pdf-modal-actions">
                        <ButtonComponent
                            onBtnClick={uploadSelectedFile}
                            label="Upload"
                            disabled={error !== enumUploadStatus.Ready}
                            theme={"primaryBtn"}
                        />
                        <Button color="grey" onClick={cancel}>Cancel</Button>
                    </Modal.Actions>
                }
            </div>
        </Modal>
    )
}
