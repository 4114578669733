import axios, { AxiosRequestConfig } from "axios";
import Config from "../config/Config";
import { WatershedReportPublicFacingConfig, WatershedReportPublicFacingConfigLinkInfo, WatershedReportPublicFacingConfigViewModel } from "../models/viewModels/WatershedReportPublicFacingConfigViewModel";

export class WatershedReportPublicFacingConfigService {
    private authToken?: string;

    constructor(authToken?: string) {
        this.authToken = authToken;
    }

    async saveDraft(config: WatershedReportPublicFacingConfig, links: Array<WatershedReportPublicFacingConfigLinkInfo>): Promise<boolean> {
        if (!this.authToken){
            return false;
        }

        let requestConfig: AxiosRequestConfig = {
            headers: {
                "Authorization": "Bearer " + this.authToken
            },
        };
        config.updated = new Date();
        const result = await axios
        .post(Config.api_endpoint + "WatershedReportPublicFacingConfig/Draft", { watershedReportPublicFacingConfig: config, watershedReportPublicFacingConfigLinkInfo: links }, requestConfig)
        .then(() => {
            return true;
        })
        .catch(() => {
            throw"Failed to save watershed report public facing draft";
        });

        return result;
    }

    async saveFinal(config: WatershedReportPublicFacingConfig, links: Array<WatershedReportPublicFacingConfigLinkInfo>): Promise<boolean> {
        if (!this.authToken){
            return false;
        }

        let requestConfig: AxiosRequestConfig = {
            headers: {
                "Authorization": "Bearer " + this.authToken
            },
        };

        config.updated = new Date();
        const result = await axios
        .post(Config.api_endpoint + "WatershedReportPublicFacingConfig/Final", { watershedReportPublicFacingConfig: config, watershedReportPublicFacingConfigLinkInfo: links }, requestConfig)
        .then(() => {
            return true;
        })
        .catch((e) => {
            console.error("Failed to save watershed report public facing final" + e);
            throw "Failed to save watershed report public facing final";
        });

        return result;
    }

    async getDraftConfig(wmgId: number): Promise<WatershedReportPublicFacingConfigViewModel> {
        if (!this.authToken){
            return;
        }

        let requestConfig: AxiosRequestConfig = {
            headers: {
                "Authorization": "Bearer " + this.authToken
            },
        };

        const result = await axios
        .get(Config.api_endpoint + `WatershedReportPublicFacingConfig/GetDraftConfig/${wmgId}`, requestConfig)
        .then((response) => {
            return response.data;
        })
        .catch(() => {
            console.error("Failed to get watershed report public facing draft");
            return;
        });

        return result;
    }

    async getFinalizedConfig(wmgId: number): Promise<WatershedReportPublicFacingConfigViewModel> {        
        const res = await axios
        .get(Config.api_endpoint + `WatershedReportPublicFacingConfig/GetConfig/${wmgId}`)
        .then((response) => {
            return response.data;
        })
        .catch((e) => {
            console.error(e);
            console.error("Failed to get watershed report public facing draft");
            return;
        });

        return res;
    }
}
