import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    en: {
        translation: {
            // navbar
            "navbarWelcome": "Home",
            "navbarMap": "Map",
            "navbarDashboard": "Dashboard",
            "navbarReporting": "Reporting",
            "navbarAdmin": "User Permissions",
            "navbarChngPw": "Change Password",
            "navbarAbout": "About",
            "navbarHelp": "Help",
            "navbarUser": "User Mgmt",
            "navbarProjects": "Projects",
            "navbarLogin": "Login",
            "navbarLogout": "Logout",
            "navbarEng": "English",
            "navbarEsp": "Spanish",
            "welcomeTitle": "Welcome Page",

            //leftNav
            "leftNavClose": "Close",
            "leftNaveHome": "Home",
            "leftNavFilter": "Filter",


            //mapWrapper

            // generic
            "genUnitAcFt": "ac-ft",
            "genUnitPercentSign": "%",
            "genUnitAcres": "acres",

            //Dashboard BMP Table
            "dashboardTableHeader1": "BMP Type",
            "dashboardTableHeader2": "Built",
            "dashboardTableHeader3": "Drainage Area",
            "dashboardTableHeader4": "Jurisdiction",
            "dashboardTableHeader5": "Coordinates",
            "dashboardTableHeader6": "Name",
            "dashboardTableHeader7": "Retained Volume",
            "dashboardTableHeader8": "Treated Volume",
            "dashboardTableHeader9": "Type",
            "dashboardTableHeader10": "Managed Volume",
            "dashboardTableHeader11": "Watershed ID",
            "dashboardWelcomeSoon": "Coming soon:",
            "dashboardWelcome": "This Dashboard will illustrate Watershed Management Program implementation, organized by Group. Through this Dashboard, visualizations and tabular outputs will be presented showing BMP implementation, stormwater capture metrics and more. In addition, the Watershed Progress Forms submitted by Groups bi-annually will be available for download.",
            "dashboardCountTextStart": "There are",
            "dashboardCountTextEnd": "BMPs",

            //login text
            "loginTermsText": "I acknowledge and agree to be bound by the",
            "loginTerms": "Terms of Use",
            "loginBtn": "Login",
            "loginUsername": "Username",
            "loginPassword": "Password",

            // landing Page
            "managePojData1": "Manage Project Data",
            "managePojData2": "(Upload/View Data)",
            "buildWatershedForm": "Build Watershed Form",
            "buildAnnReport": "Build Annual Report Form",
            "configureMileStone": "Configure Milestones",
            "configureProject": "Configure Project Assumptions",
            "configureDash": "Configure Public Dashboard",
            "userManagement": "User Management",

            //Loading Dialog
            "loading": "Loading",

            // Welcome Page
            "welcomeHeader": "Los Angeles County Watershed Reporting System",
            "welcomeText1": "Welcome to WRAMPS, the Watershed Reporting Adaptive Management & Planning System for Los Angeles County. WRAMPS is the home for reporting progress toward watershed goals defined by LA County’s Municipal Separate Storm Sewer System (MS4) permit. Municipalities across LA County have been implementing Watershed Management Programs (WMPs) since 2012, including green infrastructure and stormwater capture projects to improve water quality, augment water supply, and provide multiple community benefits.",
            "welcomeText2": "WRAMPS is the source of progress reporting information for many Watershed Groups in LA County. Please explore the links below for participating Watershed Group progress reporting pages.",
            "welcomeProjCountBubble": "Total # of Projects",
            "welcomeVolConservedBubble": "Total Water Captured",
            "welcomeCoBenefits": "Number Of Projects That Provide Co-Benefits",
            "welcomeCoBenefit1": "Provides Parks and Green Spaces",
            "welcomeCoBenefit2": "Reduces Heat Island Effect",
            "welcomeCoBenefit3": "Neighborhood Beautification",
            "welcomeCoBenefit4": "Job Creation",
            "welcomeCoBenefit5": "Water Supply Augmentation",
            "welcomeCoBenefit6": "Benefits to DACs",
            "welcomeCoBenefit7": "Reduces Flooding",
            "welcomeCoBenefit8": "Clean Streets",
            "tableHeader": "Watershed Groups Progress Reports",
            "tableHeaderGroup": "Watershed Groups",
            "tableHeaderGroupInfo": "Progress",
        }

    },
    es: {
        translation: {
            // navbar
            "navbarWelcome": "esp Home",
            "navbarMap": "esp Map",
            "navbarDashboard": "es Salpicadero",
            "navbarReporting": " esp Reporting",
            "navbarAdmin": "esp User Permissions",
            "navbarChngPw": "esp Change Password",
            // "navbarAbout": "esp About",
            "navbarHelp": "esp Help",
            "navbarProjects": "esp Projects",
            "navbarLogin": "esp Login",
            "navbarLogout": "esp Logout",
            "navbarEng": "esp English",
            "navbarEsp": "esp Spanish",
            "welcomeTitle": "esp Welcome Page",

            //leftNav
            "leftNavClose": "esp Close",
            "leftNaveHome": "esp Home",
            "leftNavFilter": "esp Filter",

            //mapWrapper

            // generic
            "genUnitAcFt": "es acre-pies",
            "genUnitPercentSign": "esp %",
            "genUnitAcres": "esp acres",

            //Dashboard BMP Table
            "dashboardTableHeader1": "BMP Typeo",
            "dashboardTableHeader2": "Built",
            "dashboardTableHeader3": "Drainage Área",
            "dashboardTableHeader4": "Jurisdicion",
            "dashboardTableHeader5": "Lat/Long",
            "dashboardTableHeader6": "Nombre",
            "dashboardTableHeader7": "Retained Volumen",
            "dashboardTableHeader8": "Treated Volumen",
            "dashboardTableHeader9": "Type",
            "dashboardTableHeader10": "Managed Volumen",
            "dashboardTableHeader11": "Watershed ID",
            "dashboardWelcomeSoon": "esp esp esp Coming soon:",
            "dashboardWelcome": "esp esp esp This Dashboard will illustrate Watershed Management Program implementation, organized by Group. Through this Dashboard, visualizations and tabular outputs will be presented showing BMP implementation, stormwater capture metrics and more. In addition, the Watershed Progress Forms submitted by Groups bi-annually will be available for download.",
            "dashboardCountTextStart": "esp There are",
            "dashboardCountTextEnd": "esp BMPs",

            //login text
            "loginTermsText": "esp I acknowledge and agree to be bound by the",
            "loginTerms": "esp Terms of Use",
            "loginBtn": "esp Login",
            "loginUsername": "esp Username",
            "loginPassword": "esp password",

            // landing Page
            "managePojData1": "esp Manage Project Data",
            "managePojData2": "(esp Upload/View Data)",
            "buildWatershedForm": "esp Build Watershed Form",
            "buildAnnReport": "esp Build Annual Report Form",
            "configureMileStone": "esp Configure Milestones",
            "configureProject": "esp Configure Project Assumptions",
            "configureDash": "esp Configure Public Dashboard",

            //Loading Dialog
            "loading": "esp Loading",

            // welcome Page
            "welcomeHeader": "esp Los Angeles County Watershed Reporting System",
            "welcomeText1": "esp Welcome to the WRAMPS, the Watershed Reporting Adaptive Management & Planning System for Los Angeles County. WRAMPS is the home for reporting progress toward watershed goals defined by LA County’s municipal stormwater permit. Municipalities across LA County have been implementing Watershed Management Programs since 2016, including green infrastructure and stormwater capture projects to improve water quality, augment water supply, and provide multiple community benefits.",
            "welcomeText2": "esp Please explore the Map and Dashboard to learn more about Watershed Management Program implementation in LA County.",
            "welcomeProjCountBubble": "esp Number of Projects",
            "welcomeVolConservedBubble": "esp Volume of Water Conserved",
            "welcomeCoBenefits": "esp Number of projects that provide Co-benefits",
            "welcomeCoBenefit1": "esp Street sweeping, litter abatement, etc",
            "welcomeCoBenefit2": "esp More Parks & Green Spaces",
            "welcomeCoBenefit3": "esp Reduces Heat Island Effect",
            "welcomeCoBenefit4": "esp Job Creation",
            "welcomeCoBenefit5": "esp Neighborhood Beautification",
            "tableHeader": "esp Watershed Groups",
            "tableHeaderGroup": "esp Group",
            "tableHeaderGroupInfo": "esp Group Info",
        }
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "en", // en english es spanish ----- language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // if you're using a language detector, do not define the lng option

        interpolation: {
        escapeValue: false // react already safes from xss
        }
    });

export default i18n;