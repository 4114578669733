import { Sidebar, Segment } from 'semantic-ui-react';
import LookupFactory from '../../../lookups/LookupFactory';
import BmpGeneral from '../../../models/BmpGeneral';
import { PrettyProjectPage } from '../../sidebars/prettyProjectPage/PrettyProjectPage';
import './WelcomePageSidebar.scss';

export interface IWelcomePageSidebar {
    sidebarVisible: boolean;
    setSidebarVisible: Function;
    lookups: LookupFactory;
    sidebarProjectInfoObj: BmpGeneral;
}

export const WelcomePageSidebar = (props: IWelcomePageSidebar) => {

    return (

        <div className="welcome-page-sidebar">
            <Sidebar
                as={Segment}
                animation='overlay'
                onHide={() => props.setSidebarVisible(false)}
                vertical
                visible={props.sidebarVisible}
                width='wide'
            >
                {props.sidebarProjectInfoObj &&
                    <PrettyProjectPage
                        sidebarProjectInfoObj={props.sidebarProjectInfoObj}
                        lookups={props.lookups}
                        showEditButton={false}
                        setSidebarVisible={props.setSidebarVisible}
                    />
                }
            </Sidebar>
        </div>
    )
}