import { BaseModel } from "./BaseModel";

export default class SummaryValue extends BaseModel {
    name: string | null;
    value: any | null;
    unit: string | null;

    constructor(endpoint: string) {
        super(endpoint, "local");
    }
}

