import { SearchParamTypes } from "../components/paradigmFilter/filterTypes/filterParams";
import proposed from '../components/checkboxFilterComponents/CheckboxFilterImages/water-proposal.svg';
import proposedGrey from '../components/checkboxFilterComponents/CheckboxFilterImages/water-proposal-grey.svg';
import saveWater from '../components/checkboxFilterComponents/CheckboxFilterImages/save-water.svg';
import saveWaterGrey from '../components/checkboxFilterComponents/CheckboxFilterImages/save-water-grey.svg';
import road from '../components/checkboxFilterComponents/CheckboxFilterImages/road-route.svg';
import roadGrey from '../components/checkboxFilterComponents/CheckboxFilterImages/road-route-grey.svg';
import area from '../components/checkboxFilterComponents/CheckboxFilterImages/home-address.svg';
import areaGrey from '../components/checkboxFilterComponents/CheckboxFilterImages/home-address-grey.svg';
import rocks from '../components/checkboxFilterComponents/CheckboxFilterImages/charcoal.svg';
import rocksGrey from '../components/checkboxFilterComponents/CheckboxFilterImages/charcoal-grey.svg';


export function filterConfig()  {
    return {
    "KeywordSearch": {
        paramType: "search-input" as SearchParamTypes,
        isLookup: false,
        displayTitle: "Keyword Search",
        value: [""],
    },

    "built": {
        paramType: "checkbox-section" as SearchParamTypes,
        isLookup: false,
        displayTitle: "BMP Status",
        isBoolean: true,
        hideTitle: true,
        value: [],
        checkBoxConfig: {
            dataArray: [
                {
                    filterName: "true",
                    displayName: "Built",
                    image: saveWaterGrey,
                    selectedImage: saveWater,
                },
                {
                    filterName: "false",
                    displayName: "Proposed",
                    image: proposedGrey,
                    selectedImage: proposed
                }
            ],
        }
    },

    "bmp_class": {
        paramType: "checkbox-section" as SearchParamTypes,
        isLookup: false,
        displayTitle: "BMP Type",
        hideTitle: true,
        value: [],
        cardCheckedBackgroundColor:"white",

        checkBoxConfig: {
            dataArray: [
                {
                    filterName: "bmp_green_street", //param to filter on
                    displayName: "Green Streets",
                     image: roadGrey,
                     selectedImage: road,
                     cardCheckedBackgroundColor:"white"
                },
                {
                    filterName: "bmp_lid", //param to filter on
                    displayName: "LIDs",
                    image: rocksGrey,
                    selectedImage: rocks,
                },
                {
                    filterName: "bmp_regional", //param to filter on
                    displayName: "Regionals",
                    image: areaGrey,
                    selectedImage: area,
                },
            ],
        }
    },
    
    
    "drainage_area_ac": {
        paramType: 'range-slider' as SearchParamTypes,
        isLookup: false,
        displayTitle: "Drainage Area",
        value: [0, 75], //initual values
        rangeSliderConfig: {
            dollarSign: false,
            minInputLabel: "min acres",
            maxInputLabel: "max acres",
            max: 75, //unused? doesnt change the maximum
            ceiling:75,  //actual maximum!
            step: .1,
            pushable: .3,
            dataArray: [],
            rangeCiel: 75, //unused? doesnt change the maximum
            rangeFloor: 0,
            trackPositiveColor: "#1a4682",
            // handleBorderColor: "rgba(66, 179, 189, 0.84)",
            handleBorderColor: "#1a4682"

        }
    },

};
}