import { PrettyProjectPageTypes, SidebarManagerAction, SidebarManagerState } from "../components/mainWrapper/MainWrapper";
import { EditProjectPage } from "../components/sidebars/editProjectPage/EditProjectPage";
import { PrettyProjectPage } from "../components/sidebars/prettyProjectPage/PrettyProjectPage";
import { ReportsSidebar } from "../components/sidebars/reportsSidebar/ReportsSidebar";
import Config from "../config/Config";
import LookupFactory from "../lookups/LookupFactory";
import BmpGeneral from "../models/BmpGeneral";
import { SidebarComponent } from "./SidebarComponent";

import { PrettyProjectPageLfd } from "../components/sidebars/prettyProjectPage/PrettyProjectPageLfd";
import PrettyProjectPageSpreadingGrounds from "../components/sidebars/prettyProjectPage/PrettyProjectPageSpreadingGrounds";

export interface ISidebarConfig {
    dispatchSidebar: (action: SidebarManagerAction) => void;
    sidebarStore: SidebarManagerState,
    screenSize: number,
    screenDisplayType: string,
    globalSearchParams: any,
    setGlobalSearchParams: Function;
    reportPageToShow: string
    setReportPageToShow: Function;
    sidebarProjectInfoObj: BmpGeneral;
    setSidebarProjectInfoObj: (project: BmpGeneral) => void;
    lookups: LookupFactory;
    setRefreshTable: Function;
}

export const SidebarConfig: React.FC<ISidebarConfig> = (props: ISidebarConfig) => {
    return (
        <div className="home-sidebar-wrapper">
            {
                window.location.href === (Config.appUrl + "reports") &&
                <>
                    <SidebarComponent
                        sidebarName="Reports"
                        sidebarStore={props.sidebarStore}
                        screenSize={props.screenSize}
                        screenDisplayType={props.screenDisplayType}>
                        <ReportsSidebar
                            dispatchSidebar={props.dispatchSidebar}
                            globalSearchParams={props.globalSearchParams}
                            setGlobalSearchParams={props.setGlobalSearchParams}
                            reportPageToShow={props.reportPageToShow}
                            setReportPageToShow={props.setReportPageToShow}
                            lookups={props.lookups} />
                    </SidebarComponent>
                    <SidebarComponent
                        sidebarName="PrettyProjectPage"
                        sidebarStore={props.sidebarStore}
                        screenSize={props.screenSize}
                        screenDisplayType={props.screenDisplayType}
                        isSpreadingGrounds={props.sidebarStore.current.content?.["prettyProjectPageType"] === PrettyProjectPageTypes.SpreadingGround}
                    >
                        {props.sidebarStore.current.content?.["prettyProjectPageType"] === PrettyProjectPageTypes.BmpGeneral &&
                            <PrettyProjectPage
                                sidebarProjectInfoObj={props.sidebarProjectInfoObj}
                                lookups={props.lookups}
                                showEditButton={props.sidebarStore.current.content?.["showEditButton"] ?? false}
                                show24HourCapacity={props.sidebarStore.current.content?.["show24HourCapacity"]}
                                dispatchSidebar={props.dispatchSidebar}
                            />
                        }
                        {props.sidebarStore.current.content?.["prettyProjectPageType"] === PrettyProjectPageTypes.LowFlowDiversion &&
                            <PrettyProjectPageLfd
                                lookups={props.lookups}
                                lowFlowDiversion={props.sidebarStore.current.content?.["lowFlowDiversion"]}
                            />
                        }
                        {props.sidebarStore.current.content?.["prettyProjectPageType"] === PrettyProjectPageTypes.SpreadingGround &&
                            <PrettyProjectPageSpreadingGrounds
                                lookups={props.lookups}
                                spreadingGroundsData={props.sidebarStore.current.content?.["spreadingGroundsData"]}
                                spreadingGroundsCaptureData={props.sidebarStore.current.content?.["spreadingGroundsCaptureData"]}
                            />
                        }
                    </SidebarComponent>

                    {props.sidebarProjectInfoObj &&
                        <SidebarComponent
                            sidebarName="EditProjectPage"
                            sidebarStore={props.sidebarStore}
                            screenSize={props.screenSize}
                            screenDisplayType={props.screenDisplayType}
                        >
                            <EditProjectPage
                                project={props.sidebarProjectInfoObj}
                                setProject={props.setSidebarProjectInfoObj}
                                lookups={props.lookups}
                                setRefreshTable={props.setRefreshTable} />
                        </SidebarComponent>
                    }
                </>
            }

        </div>
    )
}