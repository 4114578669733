import axios, { AxiosRequestConfig } from "axios";
import Config from "../config/Config";
import { LowFlowDiversion } from "../models/LowFlowDiversion";
import { CaptureBenefit } from "../models/CaptureBenefit";

export class LowFlowDiversionService {
    protected config: AxiosRequestConfig;

    constructor(authToken?: string) {
        this.config = {
            headers: {
                "Authorization": "Bearer " + authToken
            },
        }
    }

    async getAll(): Promise<LowFlowDiversion[]> {
        return await axios
            .get<LowFlowDiversion[]>(Config.api_endpoint + `LowFlowDiversion`, this.config)
            .then((result) => {
                return result.data;
            })
            .catch((e) => {
                throw new Error("Error getting low flow diversions", e);
            });
    }

    async getById(id: number): Promise<LowFlowDiversion> {
        return await axios
            .get<LowFlowDiversion>(Config.api_endpoint + `LowFlowDiversion/${id}`, this.config)
            .then((result) => {
                return result.data;
            })
            .catch((e) => {
                throw new Error("Error getting low flow diversion with ID " + id, e);
            });
    }

    async upsertLowFlowDiversion(lfd: LowFlowDiversion): Promise<LowFlowDiversion> {
        return await axios
            .post<LowFlowDiversion>(Config.api_endpoint + `LowFlowDiversion`, lfd, this.config)
            .then((result) => {
                return result.data;
            })
            .catch((e) => {
                throw new Error("Error updating low flow diversion", e);
            });
    }

    async getBenefitById(id: number): Promise<CaptureBenefit[]> {
        return await axios
            .get<CaptureBenefit[]>(Config.api_endpoint + `LowFlowDiversion/Benefits/${id}`, this.config)
            .then((result) => {
                return result.data;
            })
            .catch((e) => {
                throw new Error("Error getting low flow diversion benefit with ID " + id, e);
            });
    }
}
