import { Translation } from "react-i18next";
import LookupFactory from "../../../lookups/LookupFactory";
import './ConfigureProjAssumptions.scss';

export interface IConfigureProjAssumptionsProps {
    dispatchSidebar: Function;
    visible: boolean; 
    setVisible: Function;
    lookups: LookupFactory;
}


export const ConfigureProjAssumptions: any = (props: any) => {

    return (
        <div className="configure-proj-assumptions-page">
            <Translation>
                {
                    t =>
                    <>
                        <div className="page-header">
                            Configure Project Assumptions
                        </div>

                        <div className="coming-soon">COMING SOON!</div>
                    </>
                }
            </Translation>
        </div>
    )
}