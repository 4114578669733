import { MapProps, PopupLocation, PopupTrigger } from "@paradigmh2o/react-map";
import MapPopup from "../mapPopup/MapPopup";
import Point from "@arcgis/core/geometry/Point";

import LookupFactory from '../../lookups/LookupFactory';

function createCapMapConfig(lookups: LookupFactory): Partial<MapProps> {
    return {
      basemapUrl: "https://basemap.nationalmap.gov/arcgis/rest/services/USGSTopo/MapServer/tile/{z}/{y}/{x}",
      copyright: "Tiles courtesy of the <a href='https://usgs.gov/'>U.S. Geological Survey</a>",
      popupProps: {
          trigger: PopupTrigger.Hover,
          buildContent: (feature: any) => <MapPopup lookups={lookups} feature={feature} />,
          location: PopupLocation.Auto,
      },
      zoom: 10,
      center: new Point({ longitude: -118.30, latitude: 34.130 }),
      zoomWidgetPosition: "top-right"
    };
  }

export default createCapMapConfig;
