import axios from "axios";
import { useContext, useMemo, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { Button, Icon, Modal, Select } from "semantic-ui-react";
import Config from "../../../config/Config";
import Wave from "../../../images/modalWave.svg"
import Wave2 from "../../../images/modalWave2.svg";
import { ButtonComponent } from "../../../paradigmLib/domElements/buttonComponent/ButtonComponent";
import 'react-toastify/dist/ReactToastify.css';
import "../userMgmtModal/UserManagementModal.scss"
import { useAuth } from "react-oidc-context";

export interface IOwnerManagementModalProps {
    itemId: number;
    displayName: string;
    allUsers: Array<any>;
    itemType: "jurisdiction" | "group";
    refreshUsers: () => void
}

export const OwnerManagementModal: React.FC<IOwnerManagementModalProps> = (props: IOwnerManagementModalProps) => {
    var auth = useAuth();
    const [open, setOpen] = useState<boolean>(false);
    const [owner, setOwner] = useState<any>();

    const close = () => {
        setOwner(null);
        setOpen(false);
    }

    const ownerChanged = (_: any, selection: any) => {
        setOwner(props.allUsers[selection.value]);
    }

    const save = () => {
        if (!owner) {
            setOpen(false);
            return;
        }

        const updateOwnerEndpoint = Config.api_endpoint + `owners/${props.itemType}`;
        var ownerModel = { objectId: props.itemId, userId: owner.id };
        var options = {
            headers: {
                "Authorization": `Bearer ${auth.user?.access_token}`
            }
        };
        axios
            .post(updateOwnerEndpoint, ownerModel, options)
            .then(() => {
                props.refreshUsers();
                close();
            })
            .catch(() => {
                toast.error("Failed to set owner");
            });
    }

    return (
        <Modal className="owner-management-modal-component"
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={<Icon className="file-upload-entry-icon" name="pencil" />}>
            <Modal.Header>
                <div className="user-modal-title">Select Owner for {props.displayName}</div>
                <div className="wave-div">
                    <img src={Wave} alt="wave" className="wave" />
                </div>
            </Modal.Header>
            <Modal.Content>
                <ToastContainer />
                <Modal.Description>
                    <div>
                        <Select
                            placeholder='Select Owner'
                            fluid
                            search
                            selection
                            options={props.allUsers.map((user, index) => ({ value: index, text: `${user.firstName} ${user.lastName}` }))}
                            onChange={ownerChanged}
                            name="owner"
                        />
                    </div>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <div className='actions-div'>
                    <div className="wave-div">
                        <img src={Wave2} alt="wave" className="wave" />
                    </div>
                    <div className="btn-div">
                        <ButtonComponent
                            onBtnClick={save}
                            label="Save"
                            theme={"primaryBtn"} // primaryBtn secondaryBtn tertiaryBtn
                        />
                        <Button className="cancel-btn" onClick={close}>Cancel</Button>
                    </div>
                </div>
            </Modal.Actions>
        </Modal>
    );
}
