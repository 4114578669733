import BmpGeneralAdditionalPermittees from "./BmpGeneralAdditionalPermittees";
import BmpGeneralAttributes from "./BmpGeneralAttributes";
import BmpGeneralCobenefits from "./BmpGeneralCobenefits";
import BmpGeneralFinancial from "./BmpGeneralFinancial";
import BmpGeneralFundingSources from "./BmpGeneralFundingSources";
import BmpGeneralGisData from "./BmpGeneralGisData";
import BmpGeneralLocation from "./BmpGeneralLocation";
import BmpGeneralLowFlowDiversion from "./BmpGeneralLowFlowDiversion";
import BmpGeneralModelRequest from "./BmpGeneralModelRequest";
import BmpGeneralNewAndRedevelopment from "./BmpGeneralNewAndRedevelopment";
import BmpGeneralNonPermittees from "./BmpGeneralNonPermittees";
import BmpGeneralNonStructuralWatershedGroups from "./BmpGeneralNonStructuralWatershedGroups";
import BmpGeneralOAndM from "./BmpGeneralOAndM";

export default class BmpGeneral {
    id: number;
    bmpClassId: number;
    bmpTypeId: number;
    bmpStatusId: number;
    name: string;
    previousName: string;
    description: string;
    comment: string;
    bmpCompletionDate: Date;
    requiredCompletionDate: Date;
    bmpTypeOtherAndDescruptionOfTreatment: string;
    bmpTechnicalAssistanceId: number;
    wramps1Id: string;
    uploaderJurisdictionId: number;
    active: number;
    editor: string;
    created: Date;
    updated: Date;


    static endpoint:string = "bmp";

    bmpGeneralAdditionalPermittees: Array<BmpGeneralAdditionalPermittees>;
    bmpGeneralAttributes: BmpGeneralAttributes;
    bmpGeneralCobenefits: BmpGeneralCobenefits;
    bmpGeneralFinancial: BmpGeneralFinancial;
    bmpGeneralFundingSources: Array<BmpGeneralFundingSources>;
    bmpGeneralGisData: BmpGeneralGisData;
    bmpGeneralLocation: BmpGeneralLocation;
    bmpGeneralNewAndRedevelopment: BmpGeneralNewAndRedevelopment;
    bmpGeneralNonPermittees: Array<BmpGeneralNonPermittees>
    bmpGeneralOAndM: BmpGeneralOAndM
    bmpGeneralModelRequest: Array<BmpGeneralModelRequest>;
    bmpGeneralNonStructuralWatershedGroups: Array<BmpGeneralNonStructuralWatershedGroups>
    bmpGeneralLowFlowDiversion: BmpGeneralLowFlowDiversion;

}
