import { BaseModel } from "../BaseModel";


export default class UserListViewModel extends BaseModel {
    id: any;
    email: string;
    firstName: string;
    lastName: string;
    isAdmin: boolean;
    isUser: boolean;
    active: number;

    constructor() {
        super("info", "wramps2id_auth")
    }
}
