import { Button, Modal } from "semantic-ui-react";
import Wave2 from "../../../images/modalWave2.svg";
import './MessageModal.scss';
import { Dispatch, SetStateAction } from "react";

export interface IMessageModalProps {
    messageText: string;
    modalOpen: boolean;
    setModalOpen: Dispatch<SetStateAction<boolean>>;
    unsavedOrNoConfig: string;
    activeItem?: string;
    setActiveItem: Dispatch<SetStateAction<string>>;
    tab: string;
}

export const MessageModal = (props: IMessageModalProps) => {
    return (
        <Modal className="message-modal"
            size="tiny"
            centered={true}
            open={props.modalOpen}
            onClose={() => props.setModalOpen(false)}
        >
            <Modal.Header>{props.messageText}</Modal.Header>

            <Modal.Actions>
                <div className="wave-div">
                    <img src={Wave2} alt="wave" className="wave" />
                </div>
                <div className="modal-btns">
                    {props.unsavedOrNoConfig === "unSaved" &&
                        <>
                            <Button color="red" onClick={() => { props.setModalOpen(false); props.setActiveItem(props.activeItem.replace("_open_modal", "")) }}>Yes</Button>
                            <Button color="grey" onClick={() => { props.setModalOpen(false); props.setActiveItem(props.tab) }}>No</Button>
                        </>
                    }
                    {props.unsavedOrNoConfig === "noConfig" &&
                        <Button positive onClick={() => { props.setModalOpen(false); props.setActiveItem(props.tab) }
                        }>
                            OK
                        </Button>
                    }
                </div>
            </Modal.Actions>
        </Modal>
    )
}