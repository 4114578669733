export enum BmpClassId {
    WatershedControlMeasure = 1003,
    NewAndRedevelopment = 1005,
    NonStructural = 1006,
    LowFlowDiversion = 1007
}

export default class LutBmpClass {
    id: BmpClassId;
    internalName: string;
    displayName: string;
    displayWeight: number;
    active: number;
    editor: string;
    created: Date;
    updated: Date;
}