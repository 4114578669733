import { BaseModel } from "./BaseModel";


export default class WatershedReportForm1_1 extends BaseModel {

    id: number;
    watershedReportFormId: number;
    text: string;
    active: number;
    editor: string;
    created: Date;
    updated: Date;

    constructor() {
        super("WatershedReportForm1_1", "local")
    }
}