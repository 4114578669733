import React from "react";
import { IConfirmArchiveProps } from "./IConfirmArchiveProps";
import { Button, Icon, Modal } from "semantic-ui-react";
import Wave2 from "../../../images/modalWave2.svg";
import './ConfirmArchiveModal.scss';


export const ConfirmArchiveModal: any = (props: IConfirmArchiveProps) => {

    const [open, setOpen] = React.useState(false);

    const archiveAndClose = () => {

        props.confirmArchiveFunc(props.itemToArchive);
        setOpen(false);
    }

    return (
            <Modal className="confirm-archive-modal"
                size="tiny"
                centered={true}
                open={open}
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                trigger={<Icon className={`${props.icon} ${props.theme} trash-icon`} name="trash" />}
            >
                <Modal.Header>Are you sure you want to archive this file?</Modal.Header>
                <Modal.Content>{props.displayName}</Modal.Content>

                <Modal.Actions>
                <div className="wave-div">
                    <img src={Wave2} alt="wave" className="wave" />
                </div>
                <div className="modal-btns">
                    <Button color="red" onClick={() => archiveAndClose()}>Archive</Button>
                    <Button color="grey" onClick={() => setOpen(false)}>Cancel</Button>
                </div>
                </Modal.Actions>
            </Modal>
    )
}