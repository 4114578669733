import axios, { AxiosRequestConfig } from "axios";
import Config from "../config/Config";
import { SpreadingGround, SpreadingGroundCapture } from "../models/SpreadingGround";
import { CaptureBenefit } from "../models/CaptureBenefit";

export type SpreadingGroundUploadResult = { results: { spreadingGroundName: string, waterConservedAcft: number | null, month: number | null, year: number | null, oldWaterConservedAcft: number | null }[], warningMessage: string | null };

export class SpreadingGroundService {
    protected config: AxiosRequestConfig;

    constructor(authToken?: string) {
        this.config = {
            headers: {
                "Authorization": "Bearer " + authToken
            },
        }
    }

    async getAll(): Promise<SpreadingGround[]> {
        return await axios
            .get<SpreadingGround[]>(Config.api_endpoint + `SpreadingGrounds`, this.config)
            .then((result) => {
                return result.data;
            })
            .catch((e) => {
                throw new Error("Error getting all spreading grounds" + e);
            });
    }

    async getById(id: number): Promise<SpreadingGround> {
        return await axios
            .get<SpreadingGround>(Config.api_endpoint + `SpreadingGrounds/${id}`, this.config)
            .then((result) => {
                return result.data;
            })
            .catch((e) => {
                throw new Error("Error getting spreading ground with ID " + id + e);
            });
    }

    async getBenefitById(id: number): Promise<CaptureBenefit[]> {
        return await axios
            .get<CaptureBenefit[]>(Config.api_endpoint + `SpreadingGrounds/Benefit/${id}`, this.config)
            .then((result) => {
                return result.data;
            })
            .catch((e) => {
                throw new Error("Error getting spreading ground benefit with ID " + id + e);
            });
    }

    async getRainFallById(id: number): Promise<CaptureBenefit[]> {
        return await axios
            .get<CaptureBenefit[]>(Config.api_endpoint + `SpreadingGrounds/RainFall/${id}`, this.config)
            .then((result) => {
                return result.data;
            })
            .catch((e) => {
                throw new Error("Error getting spreading ground rainfall with ID " + id + e);
            });
    }

    async update(spreadingGround: SpreadingGround): Promise<SpreadingGround> {
        return await axios
            .post<SpreadingGround>(Config.api_endpoint + `SpreadingGrounds`, spreadingGround, this.config)
            .then((result) => {
                return result.data;
            })
            .catch((e) => {
                throw new Error("Error updating spreading ground" + e);
            });
    }

    async addNew(spreadingGround: SpreadingGround): Promise<SpreadingGround> {
        return await axios
            .put<SpreadingGround>(Config.api_endpoint + `SpreadingGrounds`, spreadingGround, this.config)
            .then((result) => {
                return result.data;
            })
            .catch((e) => {
                throw new Error("Error adding spreading ground" + e);
            });
    }

    async delete(id: number): Promise<SpreadingGround> {
        return await axios
            .delete<SpreadingGround>(Config.api_endpoint + `SpreadingGrounds/${id}`, this.config)
            .then((result) => {
                return result.data;
            })
            .catch((e) => {
                throw new Error("Error adding spreading ground" + e);
            });
    }

    async uploadExcel(excelFile: File, waterYear: number): Promise<SpreadingGroundUploadResult> {
        const formData = new FormData();
        formData.append("excelFile", excelFile);
        formData.append("waterYear", waterYear.toString());

        const configForFiles = { ...this.config };
        configForFiles.headers!["Content-Type"] = "multipart/form-data";

        const result = await axios.post<SpreadingGroundUploadResult>(Config.api_endpoint + "SpreadingGrounds/Upload/Excel", formData, configForFiles)

        return result.data;
    }

    async uploadCustomDateRange(startDate: Date, endDate: Date, captures: Partial<SpreadingGroundCapture>[]): Promise<string | null> {
        // returns an error message if it fails
        const data = {
            startDate: startDate,
            endDate: endDate,
            captures: captures
        }
        const result = await axios.post(Config.api_endpoint + "SpreadingGrounds/Upload/Custom", data, this.config);
        return result.data;
    }
}
