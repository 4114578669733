import React from 'react';

import { convertToAmPm } from '../../core/NumberFormat';

interface LFDDetailsPanelProps {
    lfdName: string;
    lfdDescription?: string;
    jurisdiction: string;
    areaManaged?: string | number;
    dailyFlow: string | number;
    projectCapacity?: string | number;
    maxFlowGpm?: string | number;
    is24Hour?: boolean;
    operatingHours: { startHour: number | null | undefined, endHour: number | null | undefined };
    completionDate?: string;
}

const LFDDetailsPanel: React.FC<LFDDetailsPanelProps> = (props) => {

    return (
        <div className="capture-dashboard__widget-container">
            <h2 className="capture-dashboard__project-overview-title" >Project Overview</h2>
            <h4 style={{ margin: "0 0 2em", fontStyle: "italic" }}> Low flow diversions protect rivers and beaches by capturing urban runoff from over irrigation, broken sprinklers, car washing and other dry weather sources and diverting them to the sewer for treatment.</h4>
            <div className="capture-dashboard__project-overview-attribute-container">
                <div className="capture-dashboard__project-overview-attribute-row">
                    {props.lfdDescription && <div style={{ marginBottom: "2em", width: "100%" }}>
                        <p style={{ margin: "0 auto 1em" }} className="capture-dashboard__project-overview-attribute-title">Project Description</p>
                        <p style={{ textAlign: "center" }} className="capture-dashboard__project-overview-attribute-value">{props.lfdDescription}</p>
                    </div>}
                    <div className="capture-dashboard__project-overview-attribute-container-side-column">
                        <p className="capture-dashboard__project-overview-attribute-title">Low Flow Diversion Project</p>
                        <p className="capture-dashboard__project-overview-attribute-value">{props.lfdName}</p>
                        <p className="capture-dashboard__project-overview-attribute-title">Jurisdiction</p>
                        <p className="capture-dashboard__project-overview-attribute-value">{props.jurisdiction}</p>
                    </div>
                    <div className="capture-dashboard__project-overview-attribute-container-center-column">
                        <p className="capture-dashboard__project-overview-attribute-title">Area Managed</p>
                        <p className="capture-dashboard__project-overview-attribute-value">{props.areaManaged} ac</p>
                        <p className="capture-dashboard__project-overview-attribute-title">Daily Flow</p>
                        <p className="capture-dashboard__project-overview-attribute-value">{props.dailyFlow} gallons</p>
                        <p className="capture-dashboard__project-overview-attribute-title">Max Flow</p>
                        <p className="capture-dashboard__project-overview-attribute-value">{props.maxFlowGpm} gallons per minute</p>
                    </div>
                    <div>
                        <p className="capture-dashboard__project-overview-attribute-title">Operating Hours</p>
                        <p className="capture-dashboard__project-overview-attribute-value">
                            {props.is24Hour ? "24 hours" : `${props.operatingHours.startHour ? convertToAmPm(props.operatingHours.startHour) : "N/A"} - ${props.operatingHours.endHour ? convertToAmPm(props.operatingHours.endHour) : "N/A"}`}
                        </p>
                        <p className="capture-dashboard__project-overview-attribute-title">Project Completion Date</p>
                        <p className="capture-dashboard__project-overview-attribute-value">{props.completionDate}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LFDDetailsPanel;
