import { useState } from "react";
import { Layer } from "@paradigmh2o/react-map";
import { Accordion, Icon } from "semantic-ui-react";
import "./Legend.scss"

export interface ILayers {
    layers: Array<Layer>;
    toggleVisibility: (layer: Layer) => void;
    topUnhiddenLayers: number;
}

export const Legend = (props: ILayers) => {
    const [legendOpen, setLegendOpen] = useState<boolean>(true);
    const [accordionOpen, setAccordionOpen] = useState<boolean>(false);

    const legendLayers = [...props?.layers]

    return (
        <>
            <div className="legend-wrapper">
                {legendOpen &&
                    <div className="legend">
                        <div className="legend-top">
                            <div className="legend-top-title">
                                <div className="header">Legend</div>
                            </div>
                            <div className='legend-top-close'>
                                <Icon className="close-icon" name="close" onClick={() => setLegendOpen(!legendOpen)} />
                            </div>
                        </div>
                        <div className="legend-layers">
                            {(legendLayers.reverse()).map((layer: any, index: number) => {
                                return (
                                    <>
                                        {index < props.topUnhiddenLayers &&
                                            <div key={layer.id}
                                                className={`legend-layers-item ${index === props.topUnhiddenLayers - 1 ? "last-geojson-layer" : ""}`}>
                                                <div className='legend-toggle'>
                                                    {/* .replace() removes unwanted characters and spaces and adds dashes to make them 'classname friendly' so we can target them in css so different toggles have different colors - input doesn't allow inline styling for round switch piece */}
                                                    <label className={`legend-toggle-switch ${layer.title.replace(/[(&)]/g, '').replace(/\s+/g, '-').toLowerCase()}`}>
                                                        <input
                                                            type="checkbox"
                                                            checked={layer.visible}
                                                            onChange={() => props.toggleVisibility(layer)}
                                                        />
                                                        <span className="switch" />
                                                    </label>
                                                </div>
                                                <div className="legend-toggle-label">
                                                    {layer.title}
                                                </div>
                                            </div>
                                        }
                                    </>
                                )
                            })
                            }
                            <Accordion>
                                <Accordion.Title
                                    className="legend-basemap-layers"
                                    active={accordionOpen === true}
                                    onClick={() => setAccordionOpen(!accordionOpen)}
                                >
                                    <div className="legend-basemap-layers">
                                        Basemap Layers
                                        <Icon name='dropdown' />
                                    </div>
                                </Accordion.Title>
                                <Accordion.Content
                                    active={accordionOpen === true}
                                >
                                    {(legendLayers).map((layer: any, index: number) => {
                                        return (
                                            <>
                                                {index >= props.topUnhiddenLayers &&
                                                    <div key={layer.id} className="legend-layers-item">
                                                        <div className='legend-toggle'>
                                                            {/* .replace() removes unwanted characters and spaces and adds dashes to make them 'classname friendly' so we can target them in css so different toggles have different colors - input doesn't allow inline styling for round switch piece */}
                                                            <label className={`legend-toggle-switch ${layer.title.replace(/[(&)]/g, '').replace(/\s+/g, '-').toLowerCase()}`}>
                                                                <input
                                                                    type="checkbox"
                                                                    checked={layer.visible}
                                                                    onChange={() => props.toggleVisibility(layer)}
                                                                />
                                                                <span className="switch" />
                                                            </label>
                                                        </div>
                                                        <div className="legend-toggle-label">
                                                            {layer.title}
                                                        </div>
                                                    </div>
                                                }
                                            </>
                                        )
                                    })
                                    }
                                </Accordion.Content>
                            </Accordion>
                        </div>
                    </div>
                }
                {
                    !legendOpen &&
                    <div className="legend-icon" >
                        <Icon
                            name="map"
                            onClick={() => setLegendOpen(!legendOpen)}
                        />
                        <div className="legend-icon-label">LEGEND</div>
                    </div>
                }
            </div>
        </>
    )
}