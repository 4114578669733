import axios, { AxiosRequestConfig } from "axios";
import Config from "../config/Config";
import JurisdictionDashboardExclusion from "../models/JurisdictionDashboardExclusion";

export default class JurisdictionService {
    protected config: AxiosRequestConfig;

    constructor(authToken?: string) {
        this.config = {
            headers: {
                "Authorization": "Bearer " + authToken,
                "Content-Type": "application/json",
            },
        }
    }


    async getDashboardExclusionByJurisdictionId(jurisdictionId: number): Promise<boolean> {
        return await axios
            .get<boolean>(Config.api_endpoint + `Jurisdiction/DashboardExclusion/${jurisdictionId}`, this.config)
            .then((result) => {
                return result.data;
            })
            .catch((e) => {
                throw new Error("Failed to get Jurisdiction Capture Dashboard Exclusion for jurisdiction " + jurisdictionId + ";" + e);
            });
    }

    async setDashboardExclusionByJurisdictionId(jurisdictionId: number, excludeOnDashboard: boolean): Promise<JurisdictionDashboardExclusion> {
        return await axios
            .post<JurisdictionDashboardExclusion>(Config.api_endpoint + `Jurisdiction/DashboardExclusion/${jurisdictionId}`, excludeOnDashboard, this.config)
            .then((result) => {
                return result.data;
            })
            .catch((e) => {
                throw new Error("Failed to save Jurisdiction Capture Dashboard Exclusion for jurisdiction " + jurisdictionId + ";"  + e);
            });
    }
}