import { useContext, useEffect, useState } from "react";
import './BuildWatershedForm.scss';
import { Translation } from "react-i18next";
import { SortableTableComponent } from "../../../paradigmLib/domElements/sortableTable/SortableTableComponent";
import { useAPIFetchAllLocal } from "../../../core/UseAPILocal";
import { IBuildWatershedFormProps } from "./BuildWatershedForm";
import { ButtonComponent } from "../../../paradigmLib/domElements/buttonComponent/ButtonComponent";
import SummaryValue from "../../../models/SummaryValue";
import { useAuth } from "react-oidc-context";

export const BuildWatershedForm_1_3: any = (props: IBuildWatershedFormProps) => {
    const auth = useAuth();
    const [fetchingBmps, setFetchingBmps] = useState<boolean>(false);
    const [tableData, setTableData] = useState<SummaryValue[]>([]);
    const [fetchingTableData, setFetchingTableData] = useState<boolean>(true);

    const next = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        props.setActiveItem("1_3a");
    }

    useEffect(() => {
        if (props.watershed) {
            setFetchingBmps(true);
        }
    }, [props]);

    useAPIFetchAllLocal(
        auth.user?.access_token,
        new SummaryValue("report/summary/jurisdiction/1b"),
        fetchingTableData,
        setFetchingTableData,
        setTableData,
        props.selectedParams,
        (data) => {
        });

    useEffect(() => {
        if (!fetchingTableData) {
            setFetchingTableData(true);
        }
    }, [props.selectedParams]);

    return (
        <div className="build-watershed-form-subpage">
            <Translation>
                {
                    t =>
                        <>
                            <div className="subpage-container">
                                <div className="subpage-header-container">
                                    <div className="subpage-header-1">1.3&nbsp; - &nbsp;Watershed Control Measures Planned and In Progress</div>
                                    <div className="subpage-header-2">
                                        Complete Table 1b, on an Excel spreadsheet. Include all watershed control measures (aside from minimum control measures specified in Part VIII of the Order) in the Watershed Management Program that are planned and in progress. Structural control measures as well as non-structural control measures (e.g., enhanced MCMs such as incentive programs, outreach and conservation programs, etc.) should be included in this table. If information is not available for a particular field, the field should indicate “Not Applicable” (N/A) [Order – IX].
                                    </div>
                                </div>
                                <div className="header-wrapper">
                                    <div className="subpage-header-1">Table 1b - Watershed Control Measures Planned and In Progress for {props.lookups.getWatershedManagementGroupById(props.selectedParams.watershedGroupId)}</div>
                                </div>
                                {(!props.watershed || props.watershed === "") && <div>Please select a Watershed Group from the dropdown above</div>}
                                {
                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                        <SortableTableComponent
                                            tableData={tableData}
                                            columnArr={["name", "value", "unit"]}
                                            headerNames={["Watershed Control Measure Metric", "Value", "Unit"]}
                                            headerBackgroundColor={"blue"}
                                            headerTextColor={"white"} />
                                    </div>
                                }

                                <div className="save-btn-div">
                                    <ButtonComponent
                                        onBtnClick={() => next()}
                                        label="Next"
                                        theme="primaryBtn"
                                    />
                                </div>

                            </div>
                        </>
                }
            </Translation>
        </div>
    )
}
