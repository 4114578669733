import { BaseModel } from "../BaseModel";


export default class ProgressReportViewModal extends BaseModel {

    id: number;
    reportingPeriodId: number;
    lutWatershedGroupOutputTypeId: number;
    lutLanguageId: number;
    publicDocumentId: number;
    publicDocumentName: string;
    published: number;
    active: number;

    constructor() {
        super("ProgressReportViewModal", "local")
    }
}