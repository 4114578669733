import { BaseModel } from "./BaseModel";
export default class QueueRequest extends BaseModel {

    returnURL: string;
    processRequested: enumProcessRequest;
    queueData: QueueData;

    constructor() {
        super("QueueManager", "local")
    }
}

export enum enumProcessRequest {
    newAndRedevelopment = 1,
    watershedControlMeasure = 2
}


export class QueueData {

    jurisdictionId: number;
    //data?

}