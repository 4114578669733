export default class InterestedParties {
    id: number;
    jurisdictionId: number;
    emailAddress: string | null;
    fullName: string | null;
    active: number;
    editor: string | null;
    created: Date | string | null;
    updated: Date | string | null;

    static endpoint: string = "InterestedParties"
}
