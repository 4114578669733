import './DatePickerComponent.scss';
import SemanticDatepicker from "react-semantic-ui-datepickers";
import { SemanticDatepickerProps } from "react-semantic-ui-datepickers/dist/types";

export interface IDatePickerComponentProps {
    value: Date;
    setValue: (value: Date) => void;
    label: string;
}

export function DatePickerComponent(props: IDatePickerComponentProps) {
    return (
        <div className={`date-picker-component`}>
            <SemanticDatepicker
                locale="en-US"
                allowOnlyNumbers={true}
                type="basic"
                format='MM-DD-YYYY'
                onChange={(_, data) => props.setValue(data.value as Date)}
                label={props.label}
                value={props.value ? new Date(props.value) : null}
            />
        </div>
    )
}
