import { Input } from 'semantic-ui-react';
import "./URLInputComponent.scss";

export interface IURLInputComponentProps {
    pageObj: any;
    setPageObj: Function;
    objKey: string;
    label: string;
    placeHolder: string;
    urlError: boolean;
}

export const URLInputComponent = (props: IURLInputComponentProps) => {

    const handleInputChange = (e) => {
        let newObj = { ...props.pageObj };
        newObj[props.objKey] = e.target.value;
        props.setPageObj(newObj);
    };

    return (
        <div className="url-input-component">
            <div className="url-input-inner-wrapper">
                <label className="basic-input-label">{props.label}</label>
                <Input
                    placeholder={props.placeHolder}
                    type="string"
                    value={props.pageObj[props.objKey] ?? ""}
                    onChange={handleInputChange}
                    error={props.urlError}
                />
                {props.urlError &&
                    <div className="invalid-url">
                        Please enter a valid URL starting with http:// or https://
                    </div>
                }
            </div>
        </div>
    );
};
