import { useEffect } from "react";
import { Translation } from "react-i18next";
import Wave from "../../../images/wave3.svg";
import Wave2 from "../../../images/upsideDownWave5.svg";
import { GlobalAuthInfoContext, IGlobalAuthInfo, ReportingPageType } from "../../mainWrapper/MainWrapper";
import LookupFactory from "../../../lookups/LookupFactory";
import "./ReportsSidebar.scss";
import { PermissionsStatusComponent } from "../../permissionsStatusComponent/PermissionsStatusComponent";

export interface IReportsSidebarProps {
    reportPageToShow: string
    setReportPageToShow: Function;
    dispatchSidebar: Function;
    visible: boolean;
    setVisible: Function;
    lookups: LookupFactory;
}

export const ReportsSidebar: any = (props: any) => {
    useEffect(() => {
        props.dispatchSidebar({
            type: "open",
            payload: { name: "Close", content: null },
        });
    }, [props.reportPageToShow]);

    const onButtonClick = (section: ReportingPageType) => {

        props.setReportPageToShow(section);
    };

    return (
        <div className="reports-sidebar">
            <Translation>
                {(t) => (
                    <GlobalAuthInfoContext.Consumer>
                        {
                            (authInfo: IGlobalAuthInfo) => {
                                return (


                                    <div className="content-container">
                                        <div className="header">Reporting</div>
                                        <div className={`card-container `}>

                                            <div className="wave-div2">
                                                <img src={Wave} alt="wave" className="wavy" />
                                            </div>
                                            <div className="inner-width-container">
                                                <PermissionsStatusComponent hasPermissions={props.reportPageToShow !== "alertMessagePage"} />
                                                {
                                                    authInfo.visibleComponents !== undefined && authInfo.visibleComponents.indexOf("manageProjectData") > -1 &&
                                                    <div
                                                        className={`card-div ${props.reportPageToShow === "manageProjectData"
                                                            ? "active-custom"
                                                            : ""
                                                            }`}
                                                        onClick={() => {
                                                            onButtonClick("manageProjectData");
                                                        }}
                                                    >
                                                        <div className="">{t("managePojData1")}</div>
                                                    </div>
                                                }
                                                {
                                                    authInfo.visibleComponents !== undefined && (authInfo.visibleComponents.indexOf("buildWatershedFormGroupLead") > -1 || authInfo.visibleComponents.indexOf("buildWatershedFormJuris") > -1) &&
                                                    <div
                                                        className={`card-div ${props.reportPageToShow === "buildWatershedForm"
                                                            ? "active-custom"
                                                            : ""
                                                            }`}
                                                        onClick={() => {
                                                            onButtonClick("buildWatershedForm");
                                                        }}
                                                    >
                                                        {t("buildWatershedForm")}
                                                    </div>
                                                }
                                                {
                                                    authInfo.visibleComponents !== undefined && authInfo.visibleComponents.indexOf("buildAnnReportForm") > -1 &&
                                                    <div
                                                        className={`card-div ${props.reportPageToShow === "buildAnnReportForm"
                                                            ? "active-custom"
                                                            : ""
                                                            }`}
                                                        onClick={() => {
                                                            onButtonClick("buildAnnReportForm");
                                                        }}
                                                    >
                                                        {t("buildAnnReport")}
                                                    </div>
                                                }
                                                {
                                                    authInfo.visibleComponents !== undefined && authInfo.visibleComponents.indexOf("configureMilestones") > -1 &&
                                                    <div
                                                        className={`disabled card-div ${props.reportPageToShow === "configureMilestones"
                                                            ? "active-custom"
                                                            : ""
                                                            }`}
                                                    // onClick={() => {
                                                    //     onButtonClick("configureMilestones");
                                                    // }}
                                                    >
                                                        <span className="not-overlay">{t("configureMileStone")}</span>
                                                        <span className="overlay">COMING SOON</span>
                                                    </div>
                                                }
                                                {
                                                    authInfo.visibleComponents !== undefined && authInfo.visibleComponents.indexOf("configureProjAssumptions") > -1 &&
                                                    <div
                                                        className={`disabled card-div ${props.reportPageToShow ===
                                                            "configureProjAssumptions"
                                                            ? "active-custom"
                                                            : ""
                                                            }`}
                                                    // onClick={() => {
                                                    //     onButtonClick("configureProjAssumptions");
                                                    // }}
                                                    >
                                                        <span className="not-overlay">{t("configureProject")}</span>
                                                        <span className="overlay">COMING SOON</span>
                                                    </div>
                                                }
                                                {
                                                    authInfo.visibleComponents !== undefined && (authInfo.visibleComponents.indexOf("userManagement") > -1 || authInfo.visibleComponents.indexOf("userManagementInterestedParties") > -1) &&
                                                    <div
                                                        className={`card-div ${props.reportPageToShow === "userManagement"
                                                            ? "active-custom"
                                                            : ""
                                                            }`}
                                                        onClick={() => {
                                                            onButtonClick("userManagement");
                                                        }}
                                                    >
                                                        {t("userManagement")}
                                                    </div>
                                                }

                                            </div>
                                            <div className="wave-div2">
                                                <img src={Wave2} alt="wave" className="wavy" />
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        }
                    </GlobalAuthInfoContext.Consumer>
                )}
            </Translation>
        </div>
    );
};
