import { useMemo } from 'react';
import {
    MaterialReactTable,
    MRT_ActionMenuItem,
    type MRT_ColumnDef,
} from 'material-react-table';
import { Icon } from 'semantic-ui-react'
import LookupFactory from '../../../lookups/LookupFactory';
import { LowFlowDiversion } from '../../../models/LowFlowDiversion';

interface ILfdsUITableProps {
    lookups: LookupFactory;
    lfds: LowFlowDiversion[];
    editLfd: Function;
    deleteLfd: Function;
}

const LfdsUITable: React.FC<ILfdsUITableProps> = (props) => {

    const columns = useMemo<MRT_ColumnDef<LowFlowDiversion>[]>(
        () => [
            {
                accessorKey: 'name',
                header: 'LFD Name',
                enableColumnFilter: false,
                sortDescFirst: true,
                size: 90,
            },
            {
                accessorKey: 'lowFlowDiversionTypeId',
                header: 'LDF Type',
                enableColumnFilter: false,
                sortDescFirst: true,
                size: 90,
                accessorFn: (row) => props.lookups.lutLowFlowDiversionType.find(x => x.id === row.lowFlowDiversionTypeId).name,
            },
            {
                accessorKey: 'completionDate',
                header: 'Completion Date',
                enableColumnFilter: false,
                sortDescFirst: true,
                size: 90,
                accessorFn: (row) => new Date(row.completionDate).toLocaleString([], { year: 'numeric', month: '2-digit', day: '2-digit' }),
            },
            {
                accessorKey: 'street',
                header: 'Address',
                enableColumnFilter: false,
                sortDescFirst: true,
                size: 90,
            },
            {
                accessorKey: 'dailyFlowGal',
                header: 'Daily Flow by Gallon',
                enableColumnFilter: false,
                sortDescFirst: true,
                size: 90,
            },
            {
                accessorKey: 'jurisdictionBreakdown',
                header: 'Jurisdiction',
                enableColumnFilter: false,
                sortDescFirst: true,
                size: 90,
            },
        ], []);

    const data = props.lfds;

    return (
        <div>
            <MaterialReactTable
                columns={columns}
                data={data}
                enableRowActions
                enableFullScreenToggle={false}
                renderRowActionMenuItems={({ row, table, closeMenu }) => [
                    <MRT_ActionMenuItem
                        icon={<Icon disabled name='pencil' />}
                        key="edit"
                        label="Edit"
                        onClick={() => {
                            props.editLfd(row.original);
                            closeMenu();
                        }
                        }
                        table={table}
                    />,
                    <MRT_ActionMenuItem
                        icon={<Icon disabled name='trash' />}
                        key="delete"
                        label="Delete"
                        onClick={() => {
                            props.deleteLfd(row.original);
                            closeMenu();
                        }
                        }
                        table={table}
                    />,
                ]}
                initialState={{
                    showColumnFilters: true,
                    pagination: { pageSize: 15, pageIndex: 0 },
                    showGlobalFilter: true,
                }}
                positionGlobalFilter="left"
                muiSearchTextFieldProps={{
                    placeholder: `Search LFDs`,
                    sx: { minWidth: '250px' },
                    variant: 'outlined',
                }}
            />

        </div>
    );
};

export default LfdsUITable;