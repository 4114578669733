import { BaseModel } from "./BaseModel";


export default class BmpGeneralGisData {
    id: number;
    bmpGeneralId: number;
    active: number;
    editor: string | null;
    created: Date | string | null;
    updated: Date | string | null;
    watershedManagementGroupId: number | null;
    receivingWatersId: number | null;
    subwatershedId: number | null;
    jurisdictionId: number | null;
    municipalityModelId: number | null;
    jurishedId: number | null;
    watershedId: number | null;
    weatherStationId: number | null;
    wstNum: string | null;
    scWshedId: number | null;
    rainPercentile85th: number | null;
    soilsLACType: string | null;
    soilsFinalKSAT: number | null;
}
