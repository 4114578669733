import ReactGA from 'react-ga4';
import { useLocation } from 'react-router';

const RouteChangeTracker = () => {
    let { pathname } = useLocation();
    ReactGA.set({ page: pathname });

    return <div></div>;
};

export default RouteChangeTracker;