import { Translation } from "react-i18next";
import { ButtonComponent } from "../../../../paradigmLib/domElements/buttonComponent/ButtonComponent";
import { Accordion, AccordionTitleProps, Button, Divider, Icon } from "semantic-ui-react";
import { PublicConfigSection } from "./PublicConfigSection";
import { ClassDisplayOptions, StatusDisplayOptions, WatershedReportPublicFacingConfig, WatershedReportPublicFacingConfigLinkInfo } from "../../../../models/viewModels/WatershedReportPublicFacingConfigViewModel";
import { PublicConfigLinks } from "../PublicConfigLinks";
import "./PublicConfigElements.scss"
import { additionalInfoDescription, programMessageDescription, programProgressDescription, programSummaryMultiBenefitsDescription, programSummaryTableDescription, programSummaryVisualizationDescription, projectDetailsMapDescription, projectDetailsTableDescription } from "../ConfigMessages";

export interface IPublicConfigElementsProps {
    setActiveItem: React.Dispatch<React.SetStateAction<string>>;
    wmgPublicInfo: WatershedReportPublicFacingConfig;
    setWmgPublicInfo: React.Dispatch<React.SetStateAction<WatershedReportPublicFacingConfig>>;
    wmgPublicInfoLinks: Array<WatershedReportPublicFacingConfigLinkInfo>;
    setWmgPublicInfoLinks: React.Dispatch<React.SetStateAction<WatershedReportPublicFacingConfigLinkInfo[]>>;
    savePublicInfoConfigDraft: Function;
    activeIndexes: number[];
    setActiveIndexes: React.Dispatch<React.SetStateAction<number[]>>;
    setOpenConfirmModal: React.Dispatch<React.SetStateAction<boolean>>;
    scrollFunction: Function;
}

export const PublicConfigElements: React.FC<IPublicConfigElementsProps> = (props: IPublicConfigElementsProps) => {
    const handleOpenSection = (e: React.MouseEvent<HTMLDivElement>, titleProps: AccordionTitleProps) => {
        const index: number = titleProps.index! as number
        const newIndexes = [...props.activeIndexes];
        props.setActiveIndexes(newIndexes)

        const currentIndexPosition = props.activeIndexes.indexOf(index);
        if (currentIndexPosition > -1) {
            newIndexes.splice(currentIndexPosition, 1);
        } else {
            newIndexes.push(index);
        }
        props.setActiveIndexes(newIndexes)
    }

    const Continue = () => {
        props.savePublicInfoConfigDraft();
        props.setActiveItem('1_8');
        props.scrollFunction();
    }

    return (
        <div className="build-watershed-form-subpage  public-config-elements">
            <Translation>
                {
                    t => <>
                        <div className="subpage-container">
                            <div className="config-controls">
                                <div className="tab-header">
                                    Use the toggles below to select which elements to share publicly. You will have the opportunity to review your choices on the Preview tab before publishing.
                                </div>
                                <ButtonComponent
                                    onBtnClick={() => Continue()}
                                    label={"Save Configuration & Continue to Preview"}
                                    theme="primaryBtn"
                                />
                            </div>
                            <Accordion fluid styled>

                                {/* PROGRAM MESSAGE SECTION */}
                                <Accordion.Title
                                    active={props.activeIndexes.includes(0)}
                                    index={0}
                                    onClick={handleOpenSection}
                                >
                                    <Icon name='dropdown' />
                                    Program Message
                                </Accordion.Title>
                                <Accordion.Content
                                    active={props.activeIndexes.includes(0)}
                                >
                                    <PublicConfigSection
                                        wmgPublicInfo={props.wmgPublicInfo}
                                        setWmgPublicInfo={props.setWmgPublicInfo}
                                        enabled={props.wmgPublicInfo.programMessageEnabled}
                                        setEnabled={(value) => props.setWmgPublicInfo({ ...props.wmgPublicInfo, programMessageEnabled: value })}
                                        descriptionLabel="Description"
                                        description={props.wmgPublicInfo.programMessageDescription}
                                        setDescription={(value) => props.setWmgPublicInfo({ ...props.wmgPublicInfo, programMessageDescription: value })}
                                        configMessage={programMessageDescription}
                                        instructions={
                                            <div>
                                                <p>Please use the text field below to customize a message for the public about your Watershed Management Group.</p>
                                                <p>The following message will be used unless revised or removed. The reset button may be used to restore the default text. If this element is toggled ON to include, a preview can be reviewed on the Preview Public Elements tab.</p>
                                            </div>
                                        }
                                    />
                                </Accordion.Content>

                                {/* PROGRAM SUMMARY VISUAL SECTION */}
                                <Accordion.Title
                                    active={props.activeIndexes.includes(1)}
                                    index={1}
                                    onClick={handleOpenSection}
                                >
                                    <Icon name='dropdown' />
                                    Program Summary Visual
                                </Accordion.Title>
                                <Accordion.Content
                                    active={props.activeIndexes.includes(1)}
                                >
                                    <PublicConfigSection
                                        wmgPublicInfo={props.wmgPublicInfo}
                                        setWmgPublicInfo={props.setWmgPublicInfo}
                                        enabled={props.wmgPublicInfo.programSummaryVisualizationEnabled}
                                        setEnabled={(value) => props.setWmgPublicInfo({ ...props.wmgPublicInfo, programSummaryVisualizationEnabled: value })}
                                        descriptionLabel="Description"
                                        description={props.wmgPublicInfo.programSummaryVisualizationDescription}
                                        setDescription={(value) => props.setWmgPublicInfo({ ...props.wmgPublicInfo, programSummaryVisualizationDescription: value })}
                                        configMessage={programSummaryVisualizationDescription}
                                        instructions={
                                            <div>
                                                <p>The Program Summary Visual visually summarizes projects using metrics like count, area managed, and footprint. For Completed projects, only those completed after December 28th, 2012 are counted. If this element is toggled ON to include, a preview can be reviewed on the Preview Public Elements tab.</p>
                                                <p>Please use the text field below to customize a message describing the Program Summary Visual. The following default description will be displayed with the visual unless revised or removed. The reset button may be used to restore the default text.</p>
                                            </div>
                                        }
                                    />
                                </Accordion.Content>

                                {/* PROGRAM COMMUNITY COBENEFITS SUMMARY SECTION */}
                                <Accordion.Title
                                    active={props.activeIndexes.includes(2)}
                                    index={2}
                                    onClick={handleOpenSection}
                                >
                                    <Icon name='dropdown' />
                                    Program Community Co-Benefits Summary
                                </Accordion.Title>
                                <Accordion.Content
                                    active={props.activeIndexes.includes(2)}
                                >
                                    <PublicConfigSection
                                        wmgPublicInfo={props.wmgPublicInfo}
                                        setWmgPublicInfo={props.setWmgPublicInfo}
                                        enabled={props.wmgPublicInfo.programSummaryMultiBenefitsEnabled}
                                        setEnabled={(value) => props.setWmgPublicInfo({ ...props.wmgPublicInfo, programSummaryMultiBenefitsEnabled: value })}
                                        descriptionLabel="Description"
                                        description={props.wmgPublicInfo.programSummaryMultiBenefitsDescription}
                                        setDescription={(value) => props.setWmgPublicInfo({ ...props.wmgPublicInfo, programSummaryMultiBenefitsDescription: value })}
                                        configMessage={programSummaryMultiBenefitsDescription}
                                        instructions={
                                            <div>
                                                <p>The Program Community Co-Benefits Summary summarizes the local Community Co-Benefits provided by WCMs. For Completed WCMs, only those completed after December 28th, 2012 are counted. The Co-Benefits displayed align with those listed in the 2021 MS4 Permit Watershed Management Program Progress Report Form. If toggled ON to include, a preview of this summary can be reviewed on the Preview Public Elements tab.</p>
                                                <p>Please use the text field below to customize a message describing the Program Community Co-Benefits Summary. The following default description will be displayed with the visual unless revised or removed. The reset button may be used to restore the default text.</p>
                                            </div>
                                        }
                                    />
                                    <div className="config-btn-group">
                                        <div className="btn-group-header">Choose Which Benefits You Would Like To Include</div>
                                    </div>
                                    <div className="benefits-group">
                                        <Button
                                            onClick={() => { props.setWmgPublicInfo({ ...props.wmgPublicInfo, showCoBenefitHeatIsland: !props.wmgPublicInfo.showCoBenefitHeatIsland }) }}
                                            className={props.wmgPublicInfo.showCoBenefitHeatIsland ? "active" : ""}
                                        >Reduces Heat Island Effect</Button>
                                        <Button
                                            onClick={() => { props.setWmgPublicInfo({ ...props.wmgPublicInfo, showCoBenefitNeighborhood: !props.wmgPublicInfo.showCoBenefitNeighborhood }) }}
                                            className={props.wmgPublicInfo.showCoBenefitNeighborhood ? "active" : ""}
                                        >Neighborhood Beautification</Button>
                                        <Button
                                            onClick={() => { props.setWmgPublicInfo({ ...props.wmgPublicInfo, showCoBenefitWaterSupplyAugmentation: !props.wmgPublicInfo.showCoBenefitWaterSupplyAugmentation }) }}
                                            className={props.wmgPublicInfo.showCoBenefitWaterSupplyAugmentation ? "active" : ""}
                                        >Water Supply Augmentation</Button>
                                        <Button
                                            onClick={() => { props.setWmgPublicInfo({ ...props.wmgPublicInfo, showCoBenefitDisadvantaged: !props.wmgPublicInfo.showCoBenefitDisadvantaged }) }}
                                            className={props.wmgPublicInfo.showCoBenefitDisadvantaged ? "active" : ""}
                                        >Benefits to DACs</Button>
                                    </div>
                                    <div className="benefits-group">
                                        <Button
                                            onClick={() => { props.setWmgPublicInfo({ ...props.wmgPublicInfo, showCoBenefitParks: !props.wmgPublicInfo.showCoBenefitParks }) }}
                                            className={props.wmgPublicInfo.showCoBenefitParks ? "active" : ""}
                                        >Provides Parks &amp; Green Spaces</Button>
                                        <Button
                                            onClick={() => { props.setWmgPublicInfo({ ...props.wmgPublicInfo, showCoBenefitCleanStreets: !props.wmgPublicInfo.showCoBenefitCleanStreets }) }}
                                            className={props.wmgPublicInfo.showCoBenefitCleanStreets ? "active" : ""}
                                        >Clean Streets</Button>
                                        <Button
                                            onClick={() => { props.setWmgPublicInfo({ ...props.wmgPublicInfo, showCoBenefitFlooding: !props.wmgPublicInfo.showCoBenefitFlooding }) }}
                                            className={props.wmgPublicInfo.showCoBenefitFlooding ? "active" : ""}
                                        >Reduces Flooding</Button>
                                        <Button
                                            onClick={() => { props.setWmgPublicInfo({ ...props.wmgPublicInfo, showCoBenefitJobCreation: !props.wmgPublicInfo.showCoBenefitJobCreation }) }}
                                            className={props.wmgPublicInfo.showCoBenefitJobCreation ? "active" : ""}
                                        >Job Creation</Button>
                                    </div>
                                </Accordion.Content>

                                {/* PROGRAM PROGRESS SECTION */}
                                <Accordion.Title
                                    active={props.activeIndexes.includes(3)}
                                    index={3}
                                    onClick={handleOpenSection}
                                >
                                    <Icon name='dropdown' />
                                    Program Progress
                                </Accordion.Title>
                                <Accordion.Content
                                    active={props.activeIndexes.includes(3)}
                                >
                                    <PublicConfigSection
                                        wmgPublicInfo={props.wmgPublicInfo}
                                        setWmgPublicInfo={props.setWmgPublicInfo}
                                        enabled={props.wmgPublicInfo.programProgressEnabled}
                                        setEnabled={(value) => props.setWmgPublicInfo({ ...props.wmgPublicInfo, programProgressEnabled: value })}
                                        descriptionLabel="Description"
                                        description={props.wmgPublicInfo.programProgressDescription}
                                        setDescription={(value) => props.setWmgPublicInfo({ ...props.wmgPublicInfo, programProgressDescription: value })}
                                        configMessage={programProgressDescription}
                                        instructions={
                                            <div>
                                                <p>The Program Progress chart visually summarizes WMP progress over time using project metrics like count, area managed, and footprint. Project completion dates are used to inform the time component of the chart and only projects completed after December 28th, 2012 are counted. By default, both Structural WCMs and N&R projects are included in the visual, the toggle below may be used to limit content to only include Structural WCMs. If toggled ON to include, a preview of the Program Progress chart can be reviewed on the Preview Public Elements tab.</p>
                                                <p>Please use the text field below to customize a message describing the Program Progress visual. The following default description will be displayed with the visual unless revised or removed. The reset button may be used to restore the default text.</p>
                                            </div>
                                        }

                                    />

                                    <div className="config-btn-group">
                                        <div className="btn-group-header">Choose Which Project Types You Would Like To Include On The Chart</div>
                                        <Button.Group>
                                            <Button
                                                onClick={() => { props.setWmgPublicInfo({ ...props.wmgPublicInfo, programProgressClass: ClassDisplayOptions.All }) }}
                                                className={props.wmgPublicInfo.programProgressClass === ClassDisplayOptions.All ? "active" : ""}
                                            >
                                                All Projects
                                            </Button>
                                            <Button
                                                onClick={() => { props.setWmgPublicInfo({ ...props.wmgPublicInfo, programProgressClass: ClassDisplayOptions.WCMOnly }) }}
                                                className={props.wmgPublicInfo.programProgressClass === ClassDisplayOptions.WCMOnly ? "active" : ""}
                                            >WCMs only
                                            </Button>
                                        </Button.Group>
                                    </div>
                                </Accordion.Content>

                                {/* PROGRAM SUMMARY TABLE SECTION */}
                                <Accordion.Title
                                    active={props.activeIndexes.includes(4)}
                                    index={4}
                                    onClick={handleOpenSection}
                                >
                                    <Icon name='dropdown' />
                                    Program Summary Table
                                </Accordion.Title>
                                <Accordion.Content
                                    active={props.activeIndexes.includes(4)}
                                >
                                    <PublicConfigSection
                                        wmgPublicInfo={props.wmgPublicInfo}
                                        setWmgPublicInfo={props.setWmgPublicInfo}
                                        enabled={props.wmgPublicInfo.programSummaryTableEnabled}
                                        setEnabled={(value) => props.setWmgPublicInfo({ ...props.wmgPublicInfo, programSummaryTableEnabled: value })}
                                        descriptionLabel="Description"
                                        description={props.wmgPublicInfo.programSummaryTableDescription}
                                        setDescription={(value) => props.setWmgPublicInfo({ ...props.wmgPublicInfo, programSummaryTableDescription: value })}
                                        configMessage={programSummaryTableDescription}
                                        instructions={
                                            <div>
                                                <p>This Program Summary Table summarizes projects metrics in a tabular format using metrics like count, footprint, drainage area, capital cost, and cumulative volume addressed. For Completed projects, only those completed after December 28th, 2012 are counted. By default, both Structural WCMs and N&R projects and all statuses are included in the table, the toggle below may be used to limit table content to only include Structural WCMs and/or Completed projects. If toggled to include, a preview of the Program Summary Table can be reviewed on the Preview Public Elements tab.</p>
                                                <p>Please use the text field below to customize a message describing the Program Summary table. The following default description will be displayed with the table unless revised or removed. The reset button may be used to restore the default text.</p>
                                            </div>
                                        }
                                    />
                                    <div className="config-btn-group">
                                        <div className="btn-group-header">Choose Which Project Types You Would Like To Include On The Table</div>
                                        <Button.Group>
                                            <Button
                                                onClick={() => { props.setWmgPublicInfo({ ...props.wmgPublicInfo, programSummaryTableClass: ClassDisplayOptions.All }) }}
                                                className={props.wmgPublicInfo.programSummaryTableClass === ClassDisplayOptions.All ? "active" : ""}
                                            >
                                                All Projects
                                            </Button>
                                            <Button
                                                onClick={() => { props.setWmgPublicInfo({ ...props.wmgPublicInfo, programSummaryTableClass: ClassDisplayOptions.WCMOnly }) }}
                                                className={props.wmgPublicInfo.programSummaryTableClass === ClassDisplayOptions.WCMOnly ? "active" : ""}
                                            >WCMs only
                                            </Button>
                                        </Button.Group>
                                        <Button.Group>
                                            <Button
                                                onClick={() => { props.setWmgPublicInfo({ ...props.wmgPublicInfo, programSummaryTableStatus: StatusDisplayOptions.All }) }}
                                                className={props.wmgPublicInfo.programSummaryTableStatus === StatusDisplayOptions.All ? "active" : ""}
                                                data-tooltip="Completed and Planned & In-Progress projects" data-position="top left" data-inverted=""
                                            >All Statuses
                                            </Button>
                                            <Button
                                                onClick={() => { props.setWmgPublicInfo({ ...props.wmgPublicInfo, programSummaryTableStatus: StatusDisplayOptions.CompleteOnly }) }}
                                                className={props.wmgPublicInfo.programSummaryTableStatus === StatusDisplayOptions.CompleteOnly ? "active" : ""}
                                            >Completed Only
                                            </Button>
                                        </Button.Group>
                                    </div>
                                </Accordion.Content>

                                {/* PROJECT DETAILS MAP SECTION */}
                                <Accordion.Title
                                    active={props.activeIndexes.includes(5)}
                                    index={5}
                                    onClick={handleOpenSection}
                                >
                                    <Icon name='dropdown' />
                                    Project Details Map
                                </Accordion.Title>
                                <Accordion.Content
                                    active={props.activeIndexes.includes(5)}
                                >
                                    <PublicConfigSection
                                        wmgPublicInfo={props.wmgPublicInfo}
                                        setWmgPublicInfo={props.setWmgPublicInfo}
                                        enabled={props.wmgPublicInfo.projectDetailsMapEnabled}
                                        setEnabled={(value) => props.setWmgPublicInfo({ ...props.wmgPublicInfo, projectDetailsMapEnabled: value })}
                                        descriptionLabel="Description"
                                        description={props.wmgPublicInfo.projectDetailsMapDescription}
                                        setDescription={(value) => props.setWmgPublicInfo({ ...props.wmgPublicInfo, projectDetailsMapDescription: value })}
                                        configMessage={projectDetailsMapDescription}
                                        instructions={
                                            <div>
                                                <p>The Project Details Map spatially displays individual project locations. By default, both Structural WCMs and N&R Projects are included in the map, however the toggle below may be used to limit map content to only Structural WCMs. Clicking on a Structural WCM on the map will display additional information for that project. If toggled ON to include, a preview of the Project Details Map can be reviewed on the Preview Public Elements tab</p>
                                                <p>Please use the text field below to customize a message describing the Project Details Map. The following default description will be displayed with the map unless revised or removed. The reset button may be used to restore the default text.</p>
                                            </div>
                                        }
                                    />
                                    <div className="config-btn-group">
                                        <div className="btn-group-header">Choose Which Project Types You Would Like To Include On The Map</div>
                                        <Button.Group>
                                            <Button
                                                onClick={() => { props.setWmgPublicInfo({ ...props.wmgPublicInfo, projectDetailsMapClass: ClassDisplayOptions.All }) }}
                                                className={props.wmgPublicInfo.projectDetailsMapClass === ClassDisplayOptions.All ? "active" : ""}
                                            >
                                                All Projects
                                            </Button>
                                            <Button
                                                onClick={() => { props.setWmgPublicInfo({ ...props.wmgPublicInfo, projectDetailsMapClass: ClassDisplayOptions.WCMOnly }) }}
                                                className={props.wmgPublicInfo.projectDetailsMapClass === ClassDisplayOptions.WCMOnly ? "active" : ""}
                                            >WCMs only
                                            </Button>
                                        </Button.Group>
                                    </div>
                                </Accordion.Content>
                                {/* PROJECT DETAILS TABLE SECTION */}
                                <Accordion.Title
                                    active={props.activeIndexes.includes(6)}
                                    index={6}
                                    onClick={handleOpenSection}
                                >
                                    <Icon name='dropdown' />
                                    Project Details Table
                                </Accordion.Title>
                                <Accordion.Content
                                    active={props.activeIndexes.includes(6)}
                                >
                                    <PublicConfigSection
                                        wmgPublicInfo={props.wmgPublicInfo}
                                        setWmgPublicInfo={props.setWmgPublicInfo}
                                        enabled={props.wmgPublicInfo.projectDetailsTableEnabled}
                                        setEnabled={(value) => props.setWmgPublicInfo({ ...props.wmgPublicInfo, projectDetailsTableEnabled: value })}
                                        descriptionLabel="Description"
                                        description={props.wmgPublicInfo.projectDetailsTableDescription}
                                        setDescription={(value) => props.setWmgPublicInfo({ ...props.wmgPublicInfo, projectDetailsTableDescription: value })}
                                        configMessage={projectDetailsTableDescription}
                                        instructions={
                                            <div>
                                                <p>The Project Details Table provides details like Name, Type, Status, and more for individual WCMs in a tabular format. By default, both Structural and Non-Structural WCMs are included in the table, however the toggles below may be used to limit table contents to only Structural WCMs. If toggled ON to include, a preview of the Project Details Table can be reviewed on the Preview Public Elements tab.</p>
                                                <p>Please use the text field below to customize a message describing the Project Details Table. The following default description will be displayed with the table unless revised or removed. The reset button may be used to restore the default text.</p>
                                            </div>
                                        }
                                    />
                                    <div className="config-btn-group">
                                        <div className="btn-group-header">Choose Which Project Types You Would Like To Include On The Table</div>
                                        <Button.Group>
                                            <Button
                                                onClick={() => { props.setWmgPublicInfo({ ...props.wmgPublicInfo, projectDetailsTableClass: ClassDisplayOptions.All }) }}
                                                className={props.wmgPublicInfo.projectDetailsTableClass === ClassDisplayOptions.All ? "active" : ""}
                                            >
                                                All WCM Projects
                                            </Button>
                                            <Button
                                                onClick={() => { props.setWmgPublicInfo({ ...props.wmgPublicInfo, projectDetailsTableClass: ClassDisplayOptions.WCMOnly }) }}
                                                className={props.wmgPublicInfo.projectDetailsTableClass === ClassDisplayOptions.WCMOnly ? "active" : ""}
                                            >Structural WCMs only
                                            </Button>
                                        </Button.Group>
                                    </div>
                                </Accordion.Content>

                                {/* ADDITIONAL INFORMATION SECTION */}
                                <Accordion.Title
                                    active={props.activeIndexes.includes(7)}
                                    index={7}
                                    onClick={handleOpenSection}
                                >
                                    <Icon name='dropdown' />
                                    Additional Information
                                </Accordion.Title>
                                <Accordion.Content
                                    active={props.activeIndexes.includes(7)}
                                >
                                    <PublicConfigSection
                                        wmgPublicInfo={props.wmgPublicInfo}
                                        setWmgPublicInfo={props.setWmgPublicInfo}
                                        enabled={props.wmgPublicInfo.additionalInfoEnabled}
                                        setEnabled={(value) => props.setWmgPublicInfo({ ...props.wmgPublicInfo, additionalInfoEnabled: value })}
                                        descriptionLabel="General Info"
                                        description={props.wmgPublicInfo.additionalInfoDescription}
                                        setDescription={(value) => props.setWmgPublicInfo({ ...props.wmgPublicInfo, additionalInfoDescription: value })}
                                        configMessage={additionalInfoDescription}
                                        instructions={
                                            <div>
                                                <p>The Additional Information section can be used to provide supporting web links as needed. Please use the fields below to add a link and provide a display name and description. </p>
                                                <p>Please use the text field below to customize a message describing the Additional Information provided. The following default description will be displayed unless revised or removed. The reset button may be used to restore the default text.</p>
                                            </div>
                                        }
                                    />
                                    <Divider />
                                    <PublicConfigLinks
                                        wmgId={props.wmgPublicInfo.watershedManagementGroupId}
                                        wmgPublicInfoLinks={props.wmgPublicInfoLinks}
                                        setWmgPublicInfoLinks={props.setWmgPublicInfoLinks}
                                        editable={true}
                                    />
                                </Accordion.Content>
                            </Accordion>
                            <div className="save-btn-div">
                                <ButtonComponent
                                    onBtnClick={() => Continue()}
                                    label={"Save Configuration & Continue to Preview"}
                                    theme="primaryBtn"
                                />
                            </div>
                        </div>
                    </>
                }
            </Translation>
        </div>
    )
}
