import { BaseModel } from "./BaseModel";
export default class BmpGeneralIntermediate extends BaseModel {

    id: number;
    bmpClassId: number | null;
    bmpTypeId: number | null;
    bmpTypeOtherAndDescruptionOfTreatment: string | null;
    soilInfiltrationRateInHr: number | null;
    storageEq85th24hr: number | null;
    storageCapacityAcft: number | null;
    name: string | null;
    previousName: string | null;
    description: string | null;
    projectCapitalCost: number | null;
    latitude: number | null;
    longitude: number | null;
    street: string | null;
    city: string | null;
    zip: string | null;
    parcelApn: string | null;
    comment: string | null;
    bmpCompletionDate: Date | string | null;
    bmpStatusId: number | null;
    drainageLanduseId: number | null;
    drainageAreaAc: number | null;
    drainagePercentImperviousPercent: number | null;
    requiredCompletionDate: Date | string | null;
    footprintAc: number | null;
    projectedStorageCapacityAcft: number | null;
    actualStorageCapacityAcft: number | null;
    fundingSought: number | null;
    fundingObtained: number | null;
    //Regional Only
    regionalDiversionDesignRateCfs: number | null;
    regionalDrawdownRateCfs: number | null;
    //Green Street Only
    greenStreetInfiltrationWellDrawdownRateCfs: number | null;
    greenStreetLengthFt: number | null;
    //Local Community Co-benefits
    coBenefitCleanStreets: number | null;
    coBenefitParks: number | null;
    coBenefitHeatIsland: number | null;
    coBenefitFlooding: number | null;
    coBenefitWaterSupplyAugmentation: number | null;
    coBenefitNeighborhood: number | null;
    coBenefitJobCreation: number | null;
    coBenefitDisadvantaged: number | null;
    //Operation and Maintenance Costs
    oMEstimatedAnnual: number | null;
    oMEdtimatedCumulativeBeforePeriod1: number | null;
    oMReportingPeriod1: number | null;
    oMReportingPeriod2: number | null;
    oMReportingPeriod3: number | null;
    oMReportingPeriod4: number | null;
    oMReportingPeriod5: number | null;
    oMReportingPeriod6: number | null;
    oMReportingPeriod7: number | null;
    oMReportingPeriod8: number | null;
    oMReportingPeriod9: number | null;
    oMReportingPeriod10: number | null;
    //Storing these semicolon separated lists as strings in the intermediate table
    additionalPermittees: string | null;
    nonPermittees: string | null;
    fundingSources: string | null;
    nonStructuralWatershedGroups: string | null;
    //new and redevelopment
    exemptPriorityDevelopmentPerformanceRequirements: number | null;
    requiresHydromodificationControlsReportingPeriod: number | null;
    exemptImplementationHydromodificationControls: number | null;
    municipalProjectId: string | null;
    dateOfAcceptance: Date | string | null;
    dateOfMaintenanceAgreement: Date | string | null;
    maitenanceRecords: string | null;
    firstInspectionDateAndSummary: string | null;
    firstCorrectiveAction: string | null;
    dateCertificateOfOccupancyIssued: Date | string | null;
    firstReplacementOrRepairDate: Date | string | null;
    bmpNonStructuralTypeId: number | null;
    lidDevelopmentType: number | null;
    bmpLidTypeId: number | null;

    bmpTechnicalAssistanceId: number | null;
    wramps1Id: string | null;
    queueItemId: number;
    queueErrorTypeId: number | null;
    queueErrorDescription: string | null;
    duplicateBmpId: number | null;
    uploaderJurisdictionId: number | null;

    rowId: number | null;


    //gis data values
    subwatershedId: number | null;
    jurisdictionId: number | null;
    municipalityModelId: number | null;
    jurishedId: number | null;
    watershedId: number | null;
    weatherStationId: number | null;
    wstNum: string | null;
    sCWshedId: number | null;
    rainPercentile85th: number | null;
    soilsLACType: string | null;
    soilsFinalKSAT: number | null;
    watershedManagementGroupId: number | null;
    receivingWatersId: number | null;

    active: number;
    editor: string | null;
    created: Date | string | null;
    updated: Date | string | null;

    
    constructor() {
        super("BmpGeneralIntermediate", "local")
    }
}