import QueryBase from "./QueryBase";

export default class DashboardQueryBase extends QueryBase {

    //all old
    "WeatherType": string;
    "ProjectDeveloper": string;
    "City": string;
    "IsInDAC": string;
    "ProjectCapacity": number;
    "CaptureArea": number;
    "ImperviousArea": number;
    "IrrigationUse": boolean;
    "WaterRecycling": boolean;
    "WaterSupplyAquifer": boolean;
    "NaturalProcess": boolean;
    "NaturalMaterial": boolean;
    "ProjectName": string;
    "ProjectDescription": string;
    "FundingRequested": number;
    "FundingYear": string;
    "Latitude": number;
    "Longitude": number;
    "ProjectType": string;
    "Status": string;
    "BMPType":string;
    "FootprintArea": number;
    "StormCapacity": number;
    "PrimaryPollutant": string;
    "PrimaryPollutantReduction": number;
    "SecondaryPollutant": string;
    "SecondaryPollutantReduction": number;
    "AvgAnnCapture": number;
    "AvgDryFlow": number;
    "RemovedImperviousArea": number;
    "CapitalCost": number;
    "HasFundingSources": boolean;
    "FundingSources": number;
    "Score": number;
    "WatershedConc" : string;
    
    //new
    "bmp_class": string;
    "built": string;
    "drainage_area_ac": number;
    "funding_source_id": number;
    "id": number;
    "jurisdiction": string;
    "location_latitude": number;
    "location_longitude": number;
    "location_street": string;
    "name": string;
    "retained_acft": number;
    "rw_id": number;
    "treated_acft": number;
    "type": string;
    "volume_managed_acft": number;
    "watersheds_id": number;


    constructor() {
        //list of fields that the search should work on
        super(["jurisdiction", "location_street", "name"], true);
    }
    
}