import React, { useEffect, useState } from 'react';
import { Icon, Message } from "semantic-ui-react";

import { CaptureBenefitService } from "../../../services/CaptureBenefitService";


const ItsRainingBanner: React.FC = () => {
    const captureBenefitService = new CaptureBenefitService();
    const [itsRaining, setItsRaining] = useState<boolean>(false);

    useEffect(() => {
        getItsRainingData();
    }, []);

    const getItsRainingData = async () => {
        try {
            const itsRainingData = await captureBenefitService.getRainfallItsRaining();
            setItsRaining(itsRainingData);
        } catch (error) {
            setItsRaining(false);
            console.error('Error fetching Countywide data:', error);
        }
    };

    return (itsRaining ?
        <Message info style={{ width: "90%", margin: "0 auto" }}>
            <p>Good news, it's raining now! Check back later for updated capture estimates! <Icon name="rain" ></Icon></p>
        </Message>
        :
        null
    );
};

export default ItsRainingBanner;
