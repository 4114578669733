
export default class BmpGeneralModelResult {
    id: number;
    bmpGeneralModelRequestId: number;
    active: number;
    totalFlowAcreFeet: number;
    bypassFlowAcreFeet: number;
    retainedFlowAcreFeet: number;
    detainedFlowAcreFeet: number;
    created: Date;
    updated: Date;
    reportingPeriodId: number;
}
