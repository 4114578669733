import React, { useState, useCallback, useMemo } from 'react';
import { Modal, Button, Form, FormField, Checkbox, FormInput, Dropdown } from 'semantic-ui-react';
import dayjs from 'dayjs';
import type { DatePickerProps } from 'antd';
import { DatePicker } from 'antd';

import Wave2 from "../../../images/modalWave2.svg";
import { LowFlowDiversion } from '../../../models/LowFlowDiversion';
import LookupFactory from '../../../lookups/LookupFactory';

interface EditLFDModalProps {
    lfdName?: string;
    isOpen: boolean;
    onClose: () => void;
    lfdValues: LowFlowDiversion | null;
    onSubmitCreate?: (values: LowFlowDiversion) => Promise<void>;
    onSubmitEdit?: (values: LowFlowDiversion) => Promise<void>;
    lookups: LookupFactory;
    resetValues: () => void;
}

export const EditLFDModal: React.FC<EditLFDModalProps> = (props) => {

    const isCreateModal = useMemo(() => !props.lfdValues, [props.lfdValues]);

    const [inputValues, setValues] = useState<Partial<LowFlowDiversion>>({
        ...props.lfdValues,
        id: props.lfdValues?.id ?? 0,
        active: true,
        is24Hour: true,
        completionDate: dayjs().toDate()
    });

    const isFormValid = useMemo(() => {
        return inputValues.name &&
            inputValues.lowFlowDiversionTypeId &&
            inputValues.latitude?.toString().length &&
            inputValues.longitude?.toString().length &&
            inputValues.street &&
            inputValues.zip &&
            inputValues.city &&
            inputValues.drainageAreaAc?.toString().length &&
            inputValues.sumpGal?.toString().length &&
            inputValues.maxFlowGpm?.toString().length &&
            inputValues.dailyFlowGal?.toString().length.toString().length &&
            inputValues.jurisdictionBreakdown
    }, [inputValues]);

    const onChangeDatePicker: DatePickerProps['onChange'] = (date) => {
        setValues({ ...inputValues, completionDate: date.toDate() });
    };

    const handleTypeChange = (e, { value }) => {
        setValues({ ...inputValues, lowFlowDiversionTypeId: value })
    };

    const handleSubmit = useCallback(() => {
        const payload: LowFlowDiversion = {
            ...inputValues as LowFlowDiversion,
            startHour: inputValues?.is24Hour ? null : inputValues?.startHour,
            endHour: inputValues?.is24Hour ? null : inputValues?.endHour,
        };

        if (isCreateModal) {
            props.onSubmitCreate(payload).then();
        } else {
            props.onSubmitEdit(payload).then();
        }
        props.onClose();
        props.resetValues();
    }, [inputValues, isCreateModal, props]);

    return (
        <Modal
            open={props.isOpen}
            className="confirm-delete-modal"
            size="tiny"
            centered={true}
        >
            <Modal.Header style={{ marginBottom: "-20px" }}>
                {isCreateModal &&
                    <>Create New LFD</>
                }
                {!isCreateModal &&
                    <>Edit<br></br><b> {props.lfdName}</b></>
                }
            </Modal.Header>
            <Modal.Content>
                <div>
                    <Form>
                        <div>
                            {!isCreateModal && <FormField>
                                <FormInput
                                    fluid
                                    label='ID'
                                    placeholder='ID'
                                    value={inputValues.id}
                                    readOnly
                                />
                            </FormField>}

                            <FormField required>
                                <label>LFD Name</label>
                                <FormInput
                                    required
                                    placeholder="LFD Name"
                                    value={inputValues.name}
                                    onChange={(e) =>
                                        setValues({ ...inputValues, name: e.target.value })
                                    }
                                />
                            </FormField>

                            <FormField required>
                                <label>Low Flow Diversion Type</label>
                                <Dropdown
                                    placeholder="Low Flow Diversion Type"
                                    options={props.lookups.lutLowFlowDiversionType
                                        .map((x) => ({key: x.id, text: x.name, value: x.id}))}
                                    dropdownTitle="Low Flow Diversion Type"
                                    fluid
                                    selection
                                    onChange={handleTypeChange}
                                    value={inputValues.lowFlowDiversionTypeId}
                                />
                            </FormField>

                            <FormField required>
                                <label>Completion Date</label>
                                <DatePicker
                                    onChange={onChangeDatePicker}
                                    format="MM-DD-YYYY"
                                    defaultValue={dayjs(inputValues.completionDate)}
                                    allowClear={false}
                                />
                            </FormField>

                            <FormField required>
                                <label>Latitude</label>
                                <FormInput
                                    required
                                    placeholder="Latitude"
                                    value={inputValues.latitude}
                                    onChange={(e) =>
                                        setValues({ ...inputValues, latitude: Number(e.target.value) })
                                    }
                                    type='number'
                                />
                            </FormField>

                            <FormField required>
                                <label>Longitude</label>
                                <FormInput
                                    required
                                    placeholder="Longitude"
                                    value={inputValues.longitude}
                                    onChange={(e) =>
                                        setValues({ ...inputValues, longitude: Number(e.target.value) })
                                    }
                                    type='number'
                                />
                            </FormField>

                            <FormField required>
                                <label>Watershed</label>
                                <Dropdown
                                    placeholder="Watershed"
                                    options={props.lookups.lutWatershed
                                        .map((x) => ({key: x.id, text: x.displayName, value: x.id}))}
                                    dropdownTitle="Watershed"
                                    fluid
                                    selection
                                    onChange={(e, { value }) => setValues({ ...inputValues, watershedId: value as number })}
                                    value={inputValues.watershedId}
                                />
                            </FormField>

                            <FormField required>
                                <label>Street</label>
                                <FormInput
                                    required
                                    placeholder="Street"
                                    value={inputValues.street}
                                    onChange={(e) =>
                                        setValues({ ...inputValues, street: e.target.value })
                                    }
                                />
                            </FormField>

                            <FormField required>
                                <label>Zip</label>
                                <FormInput
                                    required
                                    placeholder="Zip"
                                    value={inputValues.zip}
                                    onChange={(e) =>
                                        setValues({ ...inputValues, zip: e.target.value })
                                    }
                                />
                            </FormField>
                            <FormField required>
                                <label>City</label>
                                <FormInput
                                    required
                                    placeholder="City"
                                    value={inputValues.city}
                                    onChange={(e) =>
                                        setValues({ ...inputValues, city: e.target.value })
                                    }
                                />
                            </FormField>

                            <FormField required>
                                <label>Drainage Area (acres)</label>
                                <FormInput
                                    required
                                    placeholder="Drainage Area (acres)"
                                    value={inputValues.drainageAreaAc}
                                    onChange={(e) =>
                                        setValues({ ...inputValues, drainageAreaAc: Number(e.target.value) })
                                    }
                                    type='number'
                                />
                            </FormField>

                        </div>
                        <div>
                            <FormField>
                                <label>24 Hour</label>
                                <Checkbox
                                    toggle
                                    onChange={(e, data) => setValues({ ...inputValues, is24Hour: data.checked })}
                                    defaultChecked={inputValues.is24Hour}
                                />
                            </FormField>

                            {!inputValues?.is24Hour && <FormField required>
                                <label>Start Hour</label>
                                <FormInput
                                    placeholder="Start Hour"
                                    value={inputValues.startHour}
                                    onChange={(e) =>
                                        setValues({ ...inputValues, startHour: Number(e.target.value) })
                                    }
                                    type='number'
                                    max={5}
                                //This is how to add error message to the input for future reference jjir 2024/02/22
                                // error={{
                                //     content: 'Please enter a valid email address',
                                //     pointing: 'below',
                                // }}
                                />
                            </FormField>}

                            {!inputValues?.is24Hour && <FormField required>
                                <label>End Hour</label>
                                <FormInput
                                    placeholder="End Hour"
                                    value={inputValues.endHour}
                                    onChange={(e) =>
                                        setValues({ ...inputValues, endHour: Number(e.target.value) })
                                    }
                                    type='number'
                                />
                            </FormField>}

                            <FormField required>
                                <label>Sump (gal)</label>
                                <FormInput
                                    required
                                    placeholder="Sump (gal)"
                                    value={inputValues.sumpGal}
                                    onChange={(e) =>
                                        setValues({ ...inputValues, sumpGal: Number(e.target.value) })
                                    }
                                    type='number'

                                />
                            </FormField>

                            <FormField required>
                                <label>Max Flow (gpm)</label>
                                <FormInput
                                    required
                                    placeholder="Max Flow (gpm)"
                                    value={inputValues.maxFlowGpm}
                                    onChange={(e) =>
                                        setValues({ ...inputValues, maxFlowGpm: Number(e.target.value) })
                                    }
                                    type='number'
                                />
                            </FormField>

                            <FormField required>
                                <label>Daily Flow (gal) </label>
                                <FormInput
                                    required
                                    placeholder="Daily Flow (gal))"
                                    value={inputValues.dailyFlowGal}
                                    onChange={(e) =>
                                        setValues({ ...inputValues, dailyFlowGal: Number(e.target.value) })
                                    }
                                    type='number'
                                />
                            </FormField>

                            <FormField required>
                                <label>Jurisdiction</label>
                                <FormInput
                                    required
                                    placeholder="Jurisdiction"
                                    value={inputValues.jurisdictionBreakdown}
                                    onChange={(e) =>
                                        setValues({ ...inputValues, jurisdictionBreakdown: e.target.value })
                                    }
                                />
                            </FormField>
                        </div>
                    </Form>
                </div>
            </Modal.Content>
            <Modal.Actions>
                <div className="wave-div">
                    <img src={Wave2} alt="wave" className="wave" />
                </div>
                <div style={{ padding: "1em" }}>
                    <Button color="blue" onClick={handleSubmit} disabled={!isFormValid}>
                        {isCreateModal ? "Create" : "Edit"}
                    </Button>
                    <Button onClick={() => {props.onClose(); props.resetValues();}}>
                        Cancel
                    </Button>
                </div>
            </Modal.Actions>
        </Modal>
    );
};
