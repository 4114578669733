import { BaseModel } from "../BaseModel";


export default class QueueItemModel extends BaseModel {

    id: number;
    fileName: string | null;
    totalProjects: number | null;
    jurisdictionId: number;
    finishDate: Date | string | null;
    queueTypeId: number;
    queueStepProcessId: number;
    queueStatusId: number;
    queueError: string | null;
    duplicateCount: number;
    warningCount: number;
    active: number;
    editor: string | null;
    created: Date;
    updated: Date | string | null;

    constructor() {
        super("QueueItem/QueueItemModel", "local")
    }
}