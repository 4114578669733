import { useEffect, useState, useMemo } from 'react';
import { Button } from 'semantic-ui-react';
import LookupFactory from '../../../../lookups/LookupFactory';
import { WatershedReportPublicFacingConfig } from '../../../../models/viewModels/WatershedReportPublicFacingConfigViewModel';
import { WatershedReportSnapshotBmp } from '../../../../models/WatershedReportSnapshot';
import { GraphMetrics, MetricOption } from './ProgramProgressGraph';
import { GraphDatum, ProgramSummarySunburstGraph } from './ProgramSummarySunburstGraph';
import { BmpClassId } from '../../../../models/lutModels/LutBmpClass';

export interface IProgramSummaryVisualProps {
    wmgId: number;
    bmpData: Array<WatershedReportSnapshotBmp>;
    publicConfig: WatershedReportPublicFacingConfig;
    graphHeight: number | string;
    lookups: LookupFactory;
}

export const ProgramSummaryVisual = (props: IProgramSummaryVisualProps) => {

    const [selectedMetric, setSelectedMetric] = useState<MetricOption>(GraphMetrics.Count); // default count
    const [legend, setLegend] = useState<GraphDatum[] | undefined>(undefined);
    const [showLegend, setShowLegend] = useState<boolean>(true);
    const [drilldownText, setDrilldownText] = useState<string | undefined>(undefined);

    useEffect(() => {
        setShowLegend(true); // resets showing legend when changing metrics
    }, [selectedMetric]);

    const bmpDataForGraph: Array<WatershedReportSnapshotBmp> = useMemo(() => {
        if (props.bmpData.length === 0) {
            return [];
        }

        return props.bmpData
            .filter(x => x.lutBmpClassId === BmpClassId.WatershedControlMeasure || x.lutBmpClassId === BmpClassId.NewAndRedevelopment); // Dont include non structurals
    }, [props.bmpData]);

    return (
        <div className="program-summary-visual">
            <div className="dashboard-button-group program-summary-visual-button-group">
                <div className="dashboard-button-group-title">Progress Metric</div>
                <Button.Group>
                    <Button
                        onClick={() => { setSelectedMetric(GraphMetrics.Count) }}
                        className={selectedMetric === GraphMetrics.Count ? "active" : ""}
                    >Count</Button>
                    <Button
                        onClick={() => { setSelectedMetric(GraphMetrics.AreaManaged) }}
                        className={selectedMetric === GraphMetrics.AreaManaged ? "active" : ""}
                    >Area Managed</Button>
                    <Button
                        onClick={() => { setSelectedMetric(GraphMetrics.ProjectFootprint) }}
                        className={selectedMetric === GraphMetrics.ProjectFootprint ? "active" : ""}
                    >Project Footprint</Button>
                    <Button
                        onClick={() => { setSelectedMetric(GraphMetrics.TwentyFourHrCapacity) }}
                        className={selectedMetric === GraphMetrics.TwentyFourHrCapacity ? "active" : ""}
                    >24 Hour Capacity</Button>
                </Button.Group>
            </div>
            <div className="program-summary-visual-charts">
                {
                    bmpDataForGraph.length > 0 &&
                    <>
                        <div className="program-summary-visual-charts-pie"
                            style={{ height: props?.graphHeight }}>
                            <div className="pie-legend-wrapper">
                                <ProgramSummarySunburstGraph
                                    lookups={props.lookups}
                                    wmgId={props.wmgId}
                                    bmpData={bmpDataForGraph}
                                    publicConfig={props.publicConfig}
                                    graphHeight={props.graphHeight}
                                    selectedMetric={selectedMetric}
                                    setLegend={setLegend}
                                    setShowLegend={setShowLegend} // needs separate state because on some views legend data exists but chart data doesn't (e.g. 24hr capacity / footprint)
                                    drilldownText={drilldownText}
                                    setDrilldownText={setDrilldownText}
                                />
                                {legend && showLegend &&
                                    <div className="sunburst-legend-wrapper">
                                        <div className="sunburst-legend">
                                            {legend.map(x => {
                                                return (
                                                    <div
                                                        key={x.id}
                                                        className="legend-item-wrapper"
                                                    >
                                                        <div
                                                            className="legend-square"
                                                            style={{ backgroundColor: x.color }}></div>
                                                        <span>{x.displayName}</span>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </>
                }
                {
                    bmpDataForGraph.length === 0 &&
                    <div className="graph-no-data-wrapper">
                        <div className='graph-no-data'>
                            No Graph Data
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}
