import { useEffect, useState } from "react";
import Config from "../../config/Config";
import { useAPIFetchAllLocal, useDeleteModelLocal } from "../../core/UseAPILocal";
import LookupFactory from "../../lookups/LookupFactory";
import DocumentModel from "../../models/DocumentModel";
import WatershedReportForm from "../../models/WatershedReportForm";
import { ButtonComponent } from "../../paradigmLib/domElements/buttonComponent/ButtonComponent";
import { UploadModal } from "../../paradigmLib/uploadModal/UploadModal";
import { ConfirmDeletePDFModal } from "../modals/ConfirmDeletePDFModal/ConfirmDeletePDFModal";
import './uploadSection.scss';
import { useAuth } from "react-oidc-context";

class UploadModel extends DocumentModel {
    formName: string;
    reportingFormId: number;

    constructor(endpoint: string, formName: string, reportingFormId: number) {
        super(endpoint)

        this.active = 1;
        this.formName = formName;
        this.reportingFormId = reportingFormId;
        this.attachmentFileType = "application/pdf";
    }
}
export interface IUploadSectionProps {
    formName: string
    watershedReportForm: WatershedReportForm
    lookups: LookupFactory
    disabled: boolean
    fileType: String
}

export const UploadSection = (props: IUploadSectionProps) => {
    const auth = useAuth();
    const [docList, setDocList] = useState<UploadModel[]>(new Array<UploadModel>());
    const [fileUploaded, setFileUploaded] = useState<boolean>(false);
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [fetchingDocs, setFetchingDocs] = useState<boolean>(true);
    const [deleteModel, setDeleteModel] = useState<UploadModel>(null);

    const onDeleteModel = (objToDelete: UploadModel) => {
        setDeleteModel(objToDelete);
    };

    useDeleteModelLocal(auth.user?.access_token, deleteModel, setDeleteModel, () => {
        setDeleteModel(null);
    });

    useAPIFetchAllLocal(
        auth.user?.access_token,
        new DocumentModel("document/sectionFiles"),
        fetchingDocs,
        setFetchingDocs,
        setDocList,
        { sectionName: props.formName, watershedReportingFormId: props.watershedReportForm.id },
        () => {});

    useEffect(() => {
        if (!deleteModel) {
            setFetchingDocs(true);
        }
    }, [deleteModel, props.watershedReportForm]);

    function buildUrl(file: UploadModel) {
        if (file && file.id) {
            return Config.api_endpoint + "document/download/" + file.id;
        }
        return "";
    }

    return (
        <div>
            <div className="pdf-div">
                <div className="pdf-div-text">
                    Attach any additional information or reports pertinent to the WMP to this report. Provide a brief summary of these attachments in the box above.
                </div>
                {
                    props.watershedReportForm.watershedReportStatusId !== props.lookups.getWatershedReportStatusByName("Final") &&
                    <div className="upload ml-3">
                        <ButtonComponent label="Upload" onBtnClick={() => setModalVisible(true)} disabled={props.disabled} theme="primaryBtn" btnHeight="btnHeightSlim" />
                        <UploadModal
                            theme={"primary"}
                            fileUploaded={fileUploaded}
                            setFileUploaded={setFileUploaded}
                            singleUpload={false}
                            uploadAndClose={true}
                            headerText="Upload Your File"
                            documentList={docList}
                            setDocumentList={() => setFetchingDocs(true)}
                            open={modalVisible}
                            setOpen={setModalVisible}
                            getAttachmentModel={() => new UploadModel("document", props.formName, props.watershedReportForm.id)}
                            fileType={props.fileType}
                        />
                    </div>
                }

            </div>
            <div className="pdf-div pdf-list-div">
                {
                    docList.map((file) => {
                        return (
                            <div key={file.id} className="pdf-item">
                                <a href={buildUrl(file)} target="_blank" rel="noreferrer">{file.attachmentFileName}</a>
                                <div className="pl-2 pr-2">{new Date(file.attachmentDate).toDateString()}</div>
                                {
                                    props.watershedReportForm.watershedReportStatusId !== props.lookups.getWatershedReportStatusByName("Final") &&
                                    <div>
                                        <ConfirmDeletePDFModal icon="trash alternate outline" confirmDeleteFunc={onDeleteModel} itemToDelete={file} />
                                    </div>
                                }
                            </div>
                        )
                    })
                }
            </div>
        </div>);
}
