import { Form } from "semantic-ui-react";
import { IToggleComponentWithOnClickProps } from "./IToggleComponentWithOnClickProps";
import './ToggleComponentWithOnClick.scss';

// This was made so that a function can be called that only happens whem the toggle is switched.  The callback() gets hit when the pageObj loads
// it does not use the data handler!
export const ToggleComponentWithOnClick: any = (props: IToggleComponentWithOnClickProps) => {
    return (
        <div className="toggle-component-with-on-click">

            <Form.Field>
                <div className="ui form toggle-container">
                    <div className="top-label-div">
                        <label className={`top-label' ${props.topLabel ? "" : "hide"}`}>{props.topLabel ? props.topLabel : ''}</label>

                        {
                            (props.topLabel && props.toolTipInfo) &&
                            <div className="tool-tip-entry-icon pl-point5 padding-top-label-tooltip">
                                {/* <ToolTipModal toolTipName={props.toolTipInfo} theme={"primary"} size={"small"} /> */}
                            </div>
                        }

                    </div>
                    <div className="toggle-div">
                        <div className={`field ${props.requiredField ? "required field" : ""} ${props.theme}`}
                            id={props.objKey}
                            onClick={(e) => props.onClick(props.pageObj)}>

                            <div className="ui checkbox toggle">
                                <input
                                    type="checkbox"
                                    className="hidden"
                                    id={props.objKey}
                                    checked={props.pageObj && props.pageObj[props.objKey] === 1 ? true : false}
                                />
                                <label style={{ color: "$primaryColor !important" }}> {props.sideLabel} </label>
                            </div>
                        </div>
                        {
                            (!props.topLabel && props.toolTipInfo) &&
                            <div className="tool-tip-entry-icon pl-point5 padding-side-label-tooltip">
                            </div>
                        }
                    </div>
                </div>
            </Form.Field>
        </div>
    )
}
