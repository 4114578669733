import { Translation } from "react-i18next";
import { Icon } from "semantic-ui-react";
import Wave2 from "../../images/modalWave2.svg";
import './HelpPage.scss';

export const HelpPage: any = (props: any) => {
    return (
        <div className="help-wrapper">
            <div className="help-page">
                <Translation>
                    {
                        t =>
                            <>
                                <div className="page-header">
                                    Help Page <br />
                                    <div className="hr-line"></div>
                                </div>

                                <div className="help-section1">
                                    <div className="section-title">Resources for common questions:</div>
                                    <div className="section-text">
                                        <div className="blue">
                                            <Icon name="tint" />
                                        </div>
                                        <div className="indent">Bulk Upload tips can be found on the Manage Project Data page under the Bulk Uploads tab. &nbsp;Click on the info icon <Icon name="question circle" className="info-icon yellow" /> located next to the 'Upload Projects' button.
                                        </div>
                                    </div>
                                    <div className="section-text">
                                        <span className="blue">
                                            <Icon name="tint" />
                                        </span>
                                        Additional information for bulk uploads can be found in the 'Read Me' tab at the bottom of the Bulk Upload Templates.
                                    </div>
                                    <div className="section-text">
                                        <span className="blue">
                                            <Icon name="tint" />
                                        </span>
                                        <a href="https://wramps2.org/vid/WRAMPS2_InformationalSessions_04282022.mp4" target="_blank" rel="noreferrer">
                                            Video Recording of the Informational Session and Demo held on April 28th, 2022 </a>
                                    </div>
                                    <div className="section-text">
                                        <span className="blue">
                                            <Icon name="tint" />
                                        </span>
                                        <a href="https://wramps2.org/vid/WRAMPS2_InfoSession_QA_04282022.pdf" target="_blank" rel="noreferrer">
                                            Questions and Answers PDF from the Informational Session and Demo held on April 28th, 2022</a>
                                    </div>

                                    <div className="section-text">
                                        <span className="blue">
                                            <Icon name="tint" />
                                        </span>
                                        <a href="https://wramps2.org/vid/WRAMPS2_InformationalSessions_06062023.mp4" target="_blank" rel="noreferrer">
                                            Video Recording of the Informational Session and Demo held on June 6th, 2023 </a>
                                    </div>
                                    <div className="section-text">
                                        <span className="blue">
                                            <Icon name="tint" />
                                        </span>
                                        <a href="https://wramps2.org/vid/WRAMPS2_InfoSession_QA_06062023.pdf" target="_blank" rel="noreferrer">
                                            Questions and Answers PDF from the Informational Session and Demo held on June 6th, 2023</a>
                                    </div>
                                    <div className="section-text">
                                        <span className="blue">
                                            <Icon name="tint" />
                                        </span>
                                        <div>If these resources did not answer your question or you need additional assistance, please contact Wramps Support at: <a href="mailto: support@wramps.org">support@wramps.org</a>
                                        </div>
                                    </div>
                                </div>
                            </>
                    }
                </Translation>
            </div>
            <div className="wave-div">
                <img src={Wave2} alt="wave" className="wave" />
            </div>
        </div>
    )
}
