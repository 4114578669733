import { BaseModel } from "./BaseModel";


export default class BmpGeneralCobenefits extends BaseModel {

    id: number;
    bmpGeneralId: number;
    coBenefitCleanStreets: number | null;
    coBenefitParks: number | null;
    coBenefitHeatIsland: number | null;
    coBenefitFlooding: number | null;
    coBenefitWaterSupplyAugmentation: number | null;
    coBenefitNeighborhood: number | null;
    coBenefitJobCreation: number | null;
    coBenefitDisadvantaged: number | null;
    active: number;
    editor: string | null;
    created: Date | string | null;
    updated: Date | string | null;



    constructor() {
        super("BmpGeneralCobenefits", "local")
    }
}