import { BaseModel } from "./BaseModel";

export default class BmpGeneralLocation {
    id: number;
    bmpGeneralId: number;
    latitude: number | null;
    longitude: number | null;
    street: string | null;
    city: string | null;
    zip: string | null;
    parcelApn: string | null;
    active: number;
    editor: string | null;
    created: Date | string | null;
    updated: Date | string | null;

    static endpoint = 'bmpgenerallocation';
}
