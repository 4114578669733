import { useCallback, useMemo, useState } from 'react';
import { Modal, Button, Message, Segment, Form } from 'semantic-ui-react';
import Wave2 from "../../../images/modalWave2.svg";
import { useAuth } from 'react-oidc-context';
import { SpreadingGroundService } from '../../../services/SpreadingGroundService';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { SpreadingGround, SpreadingGroundCapture } from '../../../models/SpreadingGround';
import { AddonInputComponent } from '../../../paradigmLib/domElements/addonInputComponent/AddonInputComponent';
import { toast } from 'react-toastify';
import moment from 'moment';

import './CustomSpreadingGroundModal.scss';

type CustomSpreadingGroundModalProps = {
    isOpen: boolean;
    onClose: () => void;
    spreadingGrounds: SpreadingGround[];
}

export const CustomSpreadingGroundModal = (props: CustomSpreadingGroundModalProps) => {
    const auth = useAuth();
    const spreadingGroundsService = useMemo(() => new SpreadingGroundService(auth.user?.access_token), [auth]);
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [captures, setCaptures] = useState<Partial<SpreadingGroundCapture>[]>([]);
    const [dateError, setDateError] = useState<string | null>(null);

    const handleClose = useCallback(() => {
        setStartDate(null);
        setEndDate(null);
        setCaptures([]);
        props.onClose();
    }, [props]);

    const handleSave = async () => {
        if (startDate === null || endDate === null) {
            return;
        }

        spreadingGroundsService.uploadCustomDateRange(startDate, endDate, captures).then((response) => {
            if (response) {
                toast.error(response, { containerId: "main-wrapper-toast-container" })
            } else {
                toast.success("Custom Event Uploaded", { containerId: "main-wrapper-toast-container" });
                handleClose()
            }
        })
        .catch((err) => {
            console.error("Failed to upload custom spreading ground data ", err);
            toast.error('Failed to upload custom event!', { containerId: "main-wrapper-toast-container" })
        });
    }

    const sortedSpreadingGrounds = useMemo(() => props.spreadingGrounds.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1), [props.spreadingGrounds]);

    return (
        <Modal
            open={props.isOpen}
            className="confirm-delete-modal custom-update-sg-modal"
            size="small"
            centered={true}
            onClose={handleClose}
        >
            <Modal.Header>Upload Custom Spreading Grounds Data</Modal.Header>
            <Modal.Content style={{ overflowY: "auto", height: "70vh"}}>
                <Message info>
                    <p>
                        <b>Directions:</b> Use the functionality below to upload spreading ground capture values for a given rain event. Note that data for an event that occurred during a month for which monthly data already exists or with dates that overlap with an existing event cannot be uploaded.
                        <br></br>
                        <br></br>
                        <b>Please review entered values carefully.</b>
                        <br></br>
                        Values cannot be revised after upload.
                    </p>
                </Message>
                <Segment>
                    <Form>
                        <div>
                            <Form.Field>
                                <label>Spreading Grounds Capture Date Range</label>
                            </Form.Field>
                            <div className="custom-update-sg-modal__date-range-container">
                                <div className="custom-update-sg-modal__date-input-left">
                                    <SemanticDatepicker
                                        clearable={false}
                                        value={startDate}
                                        onChange={(_, data) => {
                                            const newDate = moment(data.value as Date);
                                            if (endDate && newDate.isAfter(moment(endDate))) {
                                                setDateError("Start date cannot be later than end date.");
                                            } else {
                                                setDateError(null);
                                                setStartDate(newDate.toDate());
                                            }
                                        }}
                                        placeholder="Start Date (Inclusive)"
                                    />
                                </div>
                                <div>
                                    <SemanticDatepicker
                                        value={endDate}
                                        clearable={false}
                                        onChange={(_, data) => {
                                            const newDate = moment(data.value as Date);
                                            if (startDate && newDate.isBefore(moment(startDate))) {
                                                setDateError("End date cannot be before start date.");
                                            } else {
                                                setDateError(null);
                                                setEndDate(newDate.toDate());
                                            }
                                        }}
                                        placeholder="End Date (Inclusive)"
                                    />
                                </div>
                            </div>
                            {dateError && <div><Message><p>{dateError}</p></Message></div>}

                            <Message error hidden={(endDate?.getTime() - startDate?.getTime()) <= (1000 * 60 * 60 * 24 * 15)}>
                                <p>Date range must be within 15 days</p>
                            </Message>
                        </div>
                        <div style={{ overflowY: "auto" }}>
                            {sortedSpreadingGrounds.map((sg) => {
                                return (
                                    <div key={sg.id}>
                                        <AddonInputComponent
                                            label={sg.name}
                                            value={captures.find(v => v.spreadingGroundId === sg.id)?.waterConservedAcft.toString() ?? ""}
                                            setValue={
                                                (value) => setCaptures(
                                                    [...captures.filter(v => v.spreadingGroundId !== sg.id),
                                                        { spreadingGroundId: sg.id, waterConservedAcft: parseFloat(value) }
                                                    ]
                                                )
                                            }
                                            inputType="number"
                                            theme="primary"
                                            addonSide="right"
                                            addonContent="ac-ft"
                                            placeHolder={''}
                                        />
                                    </div>
                                )
                            })}
                        </div>
                    </Form>
                </Segment>
            </Modal.Content>
            <Modal.Actions>
                <div className="wave-div">
                    <img src={Wave2} alt="wave" className="wave" />
                </div>
                <div style={{ padding: "1em" }}>
                    <Button
                        onClick={handleSave}
                        disabled={dateError !== null || !startDate || !endDate || (endDate.getTime() - startDate.getTime()) > (1000 * 60 * 60 * 24 * 15)} // dont allow dates more than 15 days apart
                        color='blue'
                    >
                        Upload
                    </Button>
                    <Button color="grey" onClick={handleClose}>Cancel</Button>
                </div>
            </Modal.Actions>
        </Modal>
    );
};
