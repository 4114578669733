const environment = process.env.REACT_APP_ENVIRONMENT ?? "localhost";

interface IAuthInfo {
    server: string,
    redirectUri: string,
    postLogoutRedirectUri?: string,
    scopes: string,
    clientId: string,
}

interface IEnvironment {
    authInfo: IAuthInfo,
    api_endpoint: string,
    env: string,
    routeUrl: string,
    baseUrl: string,
    appUrl: string,
}

interface IEnvironments {
    localhost: IEnvironment,
    dev: IEnvironment,
    qa: IEnvironment,
    prod: IEnvironment,
}

const environmentSpecific: IEnvironments = {
    localhost: {
        authInfo: {
            server: "https://auth-dev.wramps2.org",
            redirectUri: "http://localhost:3000/authentication/callback",
            scopes: "roles email profile openid wramps2_auth_dev",
            clientId: "wramps2_dev",
            postLogoutRedirectUri: "http://localhost:3000/welcome",
        },
        api_endpoint: "https://localhost:7001/api/",
        env: "dev",
        routeUrl: '/',
        baseUrl: '/wramps2/',
        appUrl: "http://localhost:3000/",
    },
    dev: {
        authInfo: {
            server: "https://auth-dev.wramps2.org",
            redirectUri: "https://dev.wramps2.org/",
            scopes: "roles email profile openid wramps2_auth_dev",
            clientId: "wramps2_dev",
            postLogoutRedirectUri: "https://dev.wramps2.org/welcome",
        },
        env: "dev",
        routeUrl: '/',
        baseUrl: '/',
        api_endpoint: "https://api-dev.wramps2.org/api/",
        appUrl: "https://dev.wramps2.org/",
    },
    qa: {
        authInfo: {
            server: "https://auth-qa.wramps2.org",
            redirectUri: "https://qa.wramps2.org/",
            scopes: "roles email profile openid wramps2_auth_qa",
            clientId: "wramps2_qa",
            postLogoutRedirectUri: "https://qa.wramps2.org/welcome",
        },
        env: "qa",
        routeUrl: '/',
        baseUrl: '/',
        api_endpoint: "https://api-qa.wramps2.org/api/",
        appUrl: "https://qa.wramps2.org/",
    },
    prod: {
        authInfo: {
            server: "https://auth.wramps2.org",
            redirectUri: "https://www.wramps2.org/",
            scopes: "roles email profile openid wramps2_auth",
            clientId: "wramps2",
            postLogoutRedirectUri: "https://www.wramps2.org/welcome",
        },
        env: "prod",
        routeUrl: '/',
        baseUrl: '/',
        api_endpoint: "https://api.wramps2.org/api/",
        appUrl: "https://www.wramps2.org/",
    },
}

export const Config = {
    ...environmentSpecific[environment as keyof IEnvironments],
    disablePdfCompile: false,
    disableSubmit: false,
    disableMunicipalAnnualPdfCompile: false,
    disableMunicipalAnnualSubmit: false,
    disableRegionalAnnualSubmit: true,
    disableRegionalAnnualPdfCompile: false,
    map_connection_url: "https://gis.wramps.org/cgi-bin/qgis_mapserv.fcgi?MAP=/home/ubuntu/wramps2/GIS/WRAMPS2_PublicFacingDashboardTK.qgs",
    autoCookieLogin: true
}


export default Config;
