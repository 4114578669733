export default class WelcomeViewModel {
    id: number;
    numberOfProjects: number;
    numberCleanStreetBenefit: number;
    numberParkBenefit: number;
    numberHeatIslandBenefit: number;
    numberReduceFloodingBenefit: number;
    numberWaterSupplyBenefit: number;
    numberBeautificationBenefit: number;
    numberJobCreationBenefit: number;
    numberDacBenefit: number;
    total24hCapacity: number;
    totalWaterConserved: number;

    static endpoint: string = "welcome";
}
