import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { Table, Icon } from "semantic-ui-react";
import Config from "../../../../config/Config";
import { useAPIFetchAllLocal, usePutPostLocalOnlyWhenTrue } from "../../../../core/UseAPILocal";
import LookupFactory from "../../../../lookups/LookupFactory";
import PublicDocumentModel from "../../../../models/PublicDocumentModel";
import { UploadGroupOutputModel } from "../../../../models/UploadGroupOutputModel";
import WatershedGroupOutput from "../../../../models/WatershedGroupOutput";
import WatershedManagementGroupIsPublic from "../../../../models/WatershedManagementGroupIsPublic";
import { ButtonComponent } from "../../../../paradigmLib/domElements/buttonComponent/ButtonComponent";
import { GlobalAuthInfoContext } from "../../../mainWrapper/MainWrapper";
import { ConfirmDeleteModal } from "../../../modals/ConfirmDeleteModal/ConfirmDeleteModal";
import { ConfirmModal } from "../../../modals/confirmModal/ConfirmModal";
import { UploadGroupOutputModal } from "../../../modals/uploadGroupOutputModal/uploadGroupOutputModal";
import { ToggleComponentWithOnClick } from "../../../../paradigmLib/domElements/ToggleComponentWithOnClick/ToggleComponentWithOnClick";
import { SelectedParams } from "../BuildWatershedForm";
import { useAuth } from "react-oidc-context";

export interface IWatershedGroupReportingPublicOutputs {
    lookups: LookupFactory;
    selectedParams: SelectedParams
}

export const WatershedGroupReportingPublicOutputs: React.FC<IWatershedGroupReportingPublicOutputs> = (props: IWatershedGroupReportingPublicOutputs) => {
    const [wmpProgressReportForm, setWmpProgressReportForm] = useState<WatershedGroupOutput>(new WatershedGroupOutput());
    const [extractedTables, setExtractedTables] = useState<WatershedGroupOutput>(new WatershedGroupOutput());
    const [languageOutputs, setLanguageOutputs] = useState<WatershedGroupOutput[]>(new Array<WatershedGroupOutput>());

    const [watershedGroupOutput, setWatershedGroupOutput] = useState<WatershedGroupOutput[]>(new Array<WatershedGroupOutput>());
    const [fetchWatershedGroupOutput, setFetchWatershedGroupOutput] = useState<boolean>(true);
    const [updateWatershedGroupOutput, setUpdateWatershedGroupOutput] = useState<boolean>(false);
    const [documentToUpdate, setDocumentToUpdate] = useState<WatershedGroupOutput>(null);

    const [watershedManagementGroupIsPublic, setWatershedManagementGroupIsPublic] = useState<WatershedManagementGroupIsPublic>(new WatershedManagementGroupIsPublic());
    const [fetchWatershedManagementGroupIsPublic, setFetchWatershedManagementGroupIsPublic] = useState<boolean>(true);
    const [updateWatershedManagementGroupIsPublic, setUpdateWatershedManagementGroupIsPublic] = useState<boolean>(false);

    const [publicDocument, setPublicDocument] = useState<PublicDocumentModel[]>(new Array<PublicDocumentModel>());
    const [fetchPublicDocument, setFetchPublicDocument] = useState<boolean>(true);

    const [selectedGroupOutputTypeId, setselectedGroupOutputTypeId] = useState<number>(0);
    const [hasDropdown, setHasDropdown] = useState<boolean>(false);

    const auth = useAuth();
    const globalAuthInfoContext = useContext(GlobalAuthInfoContext);
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [allowExcelsInUpload, setAllowExcelsInUpload] = useState<boolean>(false);
    const [modalVisibleConfirm, setModalVisibleConfirm] = useState<boolean>(false);

    useEffect(() => {
        setFetchWatershedGroupOutput(true);
        setFetchWatershedManagementGroupIsPublic(true)
        setFetchPublicDocument(true)
    }, [props.selectedParams])

    useAPIFetchAllLocal(
        auth.user?.access_token,
        new WatershedGroupOutput(),
        fetchWatershedGroupOutput,
        setFetchWatershedGroupOutput,
        setWatershedGroupOutput,
        { "WatershedManagementGroupId": props.selectedParams.watershedGroupId, 'ReportingPeriodId': props.selectedParams.reportingPeriodId, "active": 1 },
        (data: WatershedGroupOutput[]) => {
            setWmpProgressReportForm(data.filter(x => x.lutWatershedGroupOutputTypeId === getWatershedGroupOutputTypeId("wmpProgressReportForm"))[0] ?? null)
            setExtractedTables(data.filter(x => x.lutWatershedGroupOutputTypeId === getWatershedGroupOutputTypeId("extractedTables"))[0] ?? null)
            setLanguageOutputs(data.filter(x => x.lutWatershedGroupOutputTypeId === getWatershedGroupOutputTypeId("section1_1")))
        });

    useAPIFetchAllLocal(
        auth.user?.access_token,
        new WatershedManagementGroupIsPublic(),
        fetchWatershedManagementGroupIsPublic,
        setFetchWatershedManagementGroupIsPublic,
        setWatershedManagementGroupIsPublic,
        { "WatershedGroupId": props.selectedParams.watershedGroupId, "active": 1 },
        (data: WatershedManagementGroupIsPublic[]) => {
            if (data.length > 0) {
                setWatershedManagementGroupIsPublic(data[0]);
            }
            else {
                setWatershedManagementGroupIsPublic(new WatershedManagementGroupIsPublic());
            }
        });

    //for group public updates
    usePutPostLocalOnlyWhenTrue(auth.user?.access_token, watershedManagementGroupIsPublic, (data) => {
        setUpdateWatershedManagementGroupIsPublic(false);
        setWatershedManagementGroupIsPublic(data);
    }, [updateWatershedManagementGroupIsPublic]);

    //for document publish
    usePutPostLocalOnlyWhenTrue(auth.user?.access_token, documentToUpdate, (data) => {
        setUpdateWatershedGroupOutput(false);
        setDocumentToUpdate(null);
        setFetchWatershedGroupOutput(true);
    }, [updateWatershedGroupOutput]);

    useAPIFetchAllLocal(
        auth.user?.access_token,
        new PublicDocumentModel(),
        fetchPublicDocument,
        setFetchPublicDocument,
        setPublicDocument,
        { watershedManagementGroupId: props.selectedParams.watershedGroupId, reportingPeriodId: props.selectedParams.reportingPeriodId, active: 1 },
        () => {});

    function buildDownloadUrl(file: number) {
        return Config.api_endpoint + "publicDocument/download/" + file;
    }

    const getFileName = (itemId: number) => {
        return publicDocument.find(x => x.id === itemId)?.attachmentFileName
    }

    const refetchAllData = () => {
        setFetchWatershedGroupOutput(true);
        setFetchPublicDocument(true);
    }

    const toggleAndSavePublish = (output: WatershedGroupOutput) => {
        if (output.id > 0) {
            if (!output.published || output.published === 0) {
                output.published = 1;
            }
            else {
                output.published = 0;
            }

            setDocumentToUpdate(output);
            setUpdateWatershedGroupOutput(true);
        }
    }

    const toggleAndSaveGroupPublic = () => {
        let group = watershedManagementGroupIsPublic;

        if (!group.id || group.id <= 0) {
            //group doesnt exist yet on isActive table
            group.active = 1;
            group.watershedGroupId = props.selectedParams.watershedGroupId;
        }

        if (!group.isPublicReports) { //null or 0
            group.isPublicReports = 1;
        }
        else {
            group.isPublicReports = 0;
        }
        setWatershedManagementGroupIsPublic(group);
        setUpdateWatershedManagementGroupIsPublic(true);
    }

    const confirmGroupPublic = (group: WatershedManagementGroupIsPublic) => {
        setWatershedManagementGroupIsPublic(group);
        setModalVisibleConfirm(true);
    }

    const deleteWatershedGroupOutputItem = (itemToDelete: WatershedGroupOutput) => {
        let endpoint = Config.api_endpoint + "publicDocument/delete/" + itemToDelete.id;

        axios.delete(endpoint,
            {
                headers: {
                    "Authorization": `Bearer ${auth.user?.access_token}`,
                    method: 'DELETE'
                }
            })
            .then((response) => {
                refetchAllData();
            });
    }

    const getWatershedGroupOutputTypeId = (internalName: string) => {
        return props.lookups.lutWatershedGroupOutputType.find((x) => { return (x.internalName === internalName) }).id;
    }

    return (

        <div className="subpage-header-container">
            <div className="subpage-header-2">
                <div className="subpage-header-1">Step 3: Public Posting of WMP Progress Report</div>
                <div>WRAMPS2 can publish the WMP Progress Report Form and extracted Section 1.1 summaries to the public-facing Home page on <a href={Config.appUrl} target="_blank" rel="noreferrer">wramps2.org</a> to partially address public-posting requirements in Section XIV.A of the Monitoring and Reporting Program (Attachment E) of the Permit.
                </div>
                <div className="pt-2">Please use the toggle below to indicate if the Watershed Management Group elects to use WRAMPS2 to publicly post their WMP Progress Report Form outputs.
                </div>
                <div className="radio-div">
                    <>
                        <ToggleComponentWithOnClick
                            sideLabel={watershedManagementGroupIsPublic && watershedManagementGroupIsPublic.isPublicReports ? " Public" : " Hidden"}
                            objKey={"isPublicReports"}
                            pageObj={watershedManagementGroupIsPublic}
                            theme={"primary"}
                            onClick={confirmGroupPublic}
                        />
                        <div className="pl-1 pt-1">
                            Publish WMP Progress Report Form uploaded outputs to <a href={Config.appUrl} target="_blank" rel="noreferrer">wramps2.org</a> on behalf of the Watershed Management Group.
                            <br /><b>Note: This toggle publishes uploaded outputs for all reporting periods</b>
                        </div>
                    </>
                </div>

                <div className="pt-2">If elected, please use the table below to upload outputs for this Watershed Management Group and Reporting Period to be made available on the Group's public-facing page.
                </div>
                <div className="pt-1 pb-1">The Section 1.1 prompt was translated to Spanish and is available here. (<a href={Config.api_endpoint + "template/download/Att_H_b_WMP Progress Report Form_Section1.1_Spanish.docx"} className="" target="_blank" rel="noreferrer">Download Template (.docx)</a>)
                </div>
                <div className="table-header-div">
                    <div className="subpage-header-outputs">Outputs to Post Publicly for this Reporting Period</div>
                    <div className="hr-div">
                        <hr />
                    </div>
                </div>

                <div className="output-table-wrapper">
                    <Table fixed basic='very'>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Output</Table.HeaderCell>
                                <Table.HeaderCell textAlign="center">Upload / Delete</Table.HeaderCell>
                                <Table.HeaderCell>Publish</Table.HeaderCell>
                                <Table.HeaderCell>Current Document</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            <Table.Row key={"wmpProgressReportForm"}>
                                <Table.Cell className="pl-1">WMP Progress Report Form</Table.Cell>
                                <Table.Cell textAlign="center">
                                    {
                                        wmpProgressReportForm && <ConfirmDeleteModal
                                            icon='trash'
                                            confirmDeleteFunc={deleteWatershedGroupOutputItem}// delete function you will pass the itemToDelete to
                                            displayName='WMP Progress Report Form' // name to display in the modal
                                            itemToDelete={wmpProgressReportForm} // Send this to confirmDeleteFunc on delete
                                            theme='primary' // primary secondary tertiary white
                                        />
                                    }
                                    {
                                        !wmpProgressReportForm &&
                                        <ButtonComponent label="Add" onBtnClick={() => { setModalVisible(true); setselectedGroupOutputTypeId(props.lookups ? getWatershedGroupOutputTypeId("wmpProgressReportForm") : -1); setHasDropdown(false); setAllowExcelsInUpload(false); }} theme="primaryBtn" btnHeight="btnHeightSlim" />
                                    }
                                </Table.Cell>
                                <Table.Cell className="publish-toggle">
                                    {wmpProgressReportForm &&
                                        <ToggleComponentWithOnClick
                                            sideLabel={wmpProgressReportForm.published === 1 ? " Published" : " Hidden"}
                                            objKey={"published"}
                                            pageObj={wmpProgressReportForm}
                                            //setPageObj={setWmpProgressReportForm}
                                            theme={"primary"}
                                            onClick={toggleAndSavePublish}
                                        />
                                    }
                                </Table.Cell>
                                <Table.Cell>
                                    {
                                        wmpProgressReportForm &&
                                        <a href={buildDownloadUrl(wmpProgressReportForm.publicDocumentId)} target="_blank" rel="noreferrer"><Icon className="icon" name="cloud download" />{getFileName(wmpProgressReportForm.publicDocumentId)}</a>
                                    }
                                </Table.Cell>
                            </Table.Row>

                            <Table.Row key={"extractedTables"}>
                                <Table.Cell className="pl-1">Table 1a, 1b and 1c</Table.Cell>
                                <Table.Cell textAlign="center">
                                    {
                                        extractedTables && <ConfirmDeleteModal
                                            icon='trash'
                                            confirmDeleteFunc={deleteWatershedGroupOutputItem}// delete function you will pass the itemToDelete to
                                            displayName='Table 1a, 1b and 1c' // name to display in the modal
                                            itemToDelete={extractedTables} // Send this to confirmDeleteFunc on delete
                                            theme='primary' // primary secondary tertiary white
                                        />
                                    }
                                    {
                                        !extractedTables &&
                                        <ButtonComponent label="Add" onBtnClick={() => { setModalVisible(true); setselectedGroupOutputTypeId(props.lookups ? getWatershedGroupOutputTypeId("extractedTables") : -1); setHasDropdown(false); setAllowExcelsInUpload(true); }} theme="primaryBtn" btnHeight="btnHeightSlim" />
                                    }
                                </Table.Cell>
                                <Table.Cell className="publish-toggle">
                                    {extractedTables &&
                                        <ToggleComponentWithOnClick
                                            sideLabel={extractedTables.published === 1 ? " Published" : " Hidden"}
                                            objKey={"published"}
                                            pageObj={extractedTables}
                                            //setPageObj={setExtractedTables}
                                            theme={"primary"}
                                            onClick={toggleAndSavePublish}
                                        />
                                    }
                                </Table.Cell>
                                <Table.Cell>
                                    {
                                        extractedTables &&
                                        <a href={buildDownloadUrl(extractedTables.publicDocumentId)} target="_blank" rel="noreferrer"><Icon className="icon" name="cloud download" />{getFileName(extractedTables.publicDocumentId)}</a>
                                    }
                                </Table.Cell>
                            </Table.Row>

                            {
                                /*Only Section 1.1s below*/
                                languageOutputs && publicDocument && languageOutputs.map((item: WatershedGroupOutput, i) => {
                                    return (
                                        <Table.Row key={i + 200}>
                                            <Table.Cell className="pl-1">
                                                {"Section 1.1 in " + props.lookups.getLutLanguageTypeById(item.lutLanguageId)}
                                            </Table.Cell>
                                            <Table.Cell textAlign="center">
                                                {
                                                    <ConfirmDeleteModal
                                                        icon='trash'
                                                        confirmDeleteFunc={deleteWatershedGroupOutputItem}// delete function you will pass the itemToDelete to
                                                        displayName={"Section 1.1 in " + props.lookups.getLutLanguageTypeById(item.lutLanguageId)} // name to display in the modal
                                                        itemToDelete={item} // Send this to confirmDeleteFunc on delete
                                                        theme='primary' // primary secondary tertiary white
                                                    />
                                                }

                                            </Table.Cell>
                                            <Table.Cell className="publish-toggle">
                                                <ToggleComponentWithOnClick
                                                    sideLabel={item.published === 1 ? " Published" : " Hidden"}
                                                    objKey={"published"}
                                                    pageObj={item}
                                                    //setPageObj={setExtractedTables}
                                                    theme={"primary"}
                                                    onClick={toggleAndSavePublish}
                                                />
                                            </Table.Cell>
                                            <Table.Cell>
                                                <a href={buildDownloadUrl(item.publicDocumentId)} target="_blank" rel="noreferrer"><Icon className="icon" name="cloud download" />{getFileName(item.publicDocumentId)}</a>
                                            </Table.Cell>
                                        </Table.Row>
                                    )
                                })
                            }

                        </Table.Body>
                        <Table.Footer fullWidth>
                            <Table.Row>
                                <Table.HeaderCell colSpan='4'>
                                    <div className="button-spacer">
                                        <ButtonComponent label="+ Add Extracted Section 1.1 " onBtnClick={() => { setModalVisible(true); setselectedGroupOutputTypeId(props.lookups ? getWatershedGroupOutputTypeId("section1_1") : -1); setHasDropdown(true); setAllowExcelsInUpload(false); }} theme="primaryBtn" btnHeight="btnHeightSlim" />
                                        &nbsp;&nbsp; <b>(by language)</b>
                                    </div>
                                </Table.HeaderCell>
                            </Table.Row>

                        </Table.Footer>
                    </Table>

                    <UploadGroupOutputModal
                        theme={"primary"}
                        singleUpload={false}
                        uploadAndClose={true}
                        headerText="Upload Your File"
                        documentList={watershedGroupOutput}
                        refetchData={refetchAllData}
                        open={modalVisible}
                        setOpen={setModalVisible}
                        groupOutputTypeId={selectedGroupOutputTypeId}
                        getAttachmentModel={() => new UploadGroupOutputModel("publicDocument", null, props.selectedParams.watershedGroupId, props.selectedParams.reportingPeriodId, selectedGroupOutputTypeId)}
                        languagelookup={props.lookups.lutLanguage}
                        hasDropdown={hasDropdown}
                        allowExcelAndPdf={allowExcelsInUpload}
                    />
                    <ConfirmModal
                        confirmFunc={toggleAndSaveGroupPublic}
                        confirmText={"Are you sure you want to change your group's visibility on the Public Progress Reporting List?"}
                        openModal={modalVisibleConfirm}
                        setOpenModal={setModalVisibleConfirm}
                    />

                </div>
            </div>
        </div>

    )
}
