export default class BmpGeneralAttributes {
    id: number;
    bmpGeneralId: number;
    footprintAc: number | null;
    soilInfiltrationRateInHr: number | null;
    drainageAreaAc: number | null;
    actualStorageCapacityAcft: number | null;
    projectedStorageCapacityAcft: number | null;
    dailyStorageCapacityAcft: number | null;
    calculatedDailyStorageCapacityAcft: number | null;
    storageEq85th24hr: number | null;
    drainageLanduseId: number | null;
    drainagePercentImperviousPercent: number | null;
    greenStreetInfiltrationWellDrawdownRateCfs: number | null;
    greenStreetLengthFt: number | null;
    regionalDiversionDesignRateCfs: number | null;
    regionalDrawdownRateCfs: number | null;
    active: number;
    editor: string | null;
    created: Date | string | null;
    updated: Date | string | null;
}
