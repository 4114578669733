import { WatershedReportPublicFacingConfig } from "../../../../models/viewModels/WatershedReportPublicFacingConfigViewModel";

export interface IPublicElementSectionProps {
    sectionDescriptionProperty: string;
    wmgPublicInfo: WatershedReportPublicFacingConfig;
    sectionTitle: string;
    visible: boolean;
    children?: React.ReactElement;
}

export const PublicElementSection: React.FC<IPublicElementSectionProps> = (props: IPublicElementSectionProps) => {

    return (
        <>
            {props.visible &&
                <div className="public-element-section">
                    <div className="public-element-section-title">
                        {props.sectionTitle}
                    </div>
                    <div className="public-element-section-description">
                        {props.wmgPublicInfo[props.sectionDescriptionProperty]}
                    </div>
                    {props.children &&
                        <div className="public-element-section-content">
                            {props.children}
                        </div>
                    }
                </div>
            }
        </>
    )
}
