import React, { useState, useCallback, useMemo } from 'react';
import { Modal, Button, Form, FormField, FormInput, Dropdown } from 'semantic-ui-react';

import { SpreadingGround } from "../../../models/SpreadingGround";
import { LutSpreadingGroundBasinType } from "../../../models/lutModels/LutSpreadingGroundBasinType";
import Wave2 from "../../../images/modalWave2.svg";
import Watershed from '../../../models/Watershed';

interface SGFormModalProps {
    isOpen: boolean;
    onClose: () => void;
    sgValues: SpreadingGround;
    onSubmitCreate?: (values: SpreadingGround) => Promise<void>;
    onSubmitEdit?: (values: SpreadingGround) => Promise<void>;
    resetValues: () => void;
    typeOfBasinOpts: LutSpreadingGroundBasinType[];
    watershedOptions: Watershed[];
}

const SGFormModal: React.FC<SGFormModalProps> = (props) => {

    const isCreateModal = useMemo(() => !props.sgValues, [props.sgValues]);
    const [inputValues, setValues] = useState<Partial<SpreadingGround>>({
        ...props.sgValues,
        id: props.sgValues?.id ?? 0,
        active: true,
        spreadingGroundCapture: isCreateModal ? [] : props.sgValues?.spreadingGroundCapture,
    });

    const typeOfBasinOpts = props.typeOfBasinOpts.map((basin) => {
        return { key: basin.id, text: basin.name, value: basin.id };
    });

    const isFormValid = useMemo(() => {
        return inputValues.name?.length > 0
    }, [inputValues]);

    const handleTypeChange = (_, { value }) => {
        setValues({ ...inputValues, basinTypeId: value })
    };

    const handleSubmit = useCallback(() => {
        const payload: SpreadingGround = {
            ...inputValues as SpreadingGround,
        };

        if (isCreateModal) {
            props.onSubmitCreate(payload).then();
        } else {
            props.onSubmitEdit(payload).then();
        }
        props.onClose();
        props.resetValues();

    }, [inputValues, isCreateModal, props]);

    return (
        <Modal
            open={props.isOpen}
            className="confirm-delete-modal"
            size="tiny"
            centered={true}
        >
            <Modal.Header style={{ marginBottom: "-20px" }}>
                {isCreateModal &&
                    <>Create New Spreading Ground</>
                }
                {!isCreateModal &&
                    <>Edit<br></br><b> {props.sgValues.name}</b></>
                }
            </Modal.Header>
            <Modal.Content>
                <div>
                    <Form>
                        <div>
                            {!isCreateModal && <FormField>
                                <FormInput
                                    fluid
                                    label='ID'
                                    placeholder='ID'
                                    value={inputValues.id}
                                    readOnly
                                />
                            </FormField>}

                            <FormField required>
                                <label>Spreading Ground Name</label>
                                <FormInput
                                    required
                                    placeholder="Spreading Ground Name"
                                    value={inputValues.name}
                                    onChange={(e) =>
                                        setValues({ ...inputValues, name: e.target.value })
                                    }
                                />
                            </FormField>

                            <FormField>
                                <label>Basin Type</label>
                                <Dropdown
                                    placeholder={`Basin Type`}
                                    options={typeOfBasinOpts}
                                    dropdownTitle={"Basin Type"}
                                    dropdownItems={typeOfBasinOpts.map(x => x.text)}
                                    fluid
                                    selection
                                    onChange={handleTypeChange}
                                    value={inputValues.basinTypeId}
                                />
                            </FormField>

                            <FormField>
                                <label>Internal ID</label>
                                <FormInput
                                    placeholder="Internal ID"
                                    value={inputValues.internalId}
                                    onChange={(e) =>
                                        setValues({ ...inputValues, internalId: e.target.value })
                                    }
                                />
                            </FormField>

                            <FormField>
                                <label>Channel Capacity (cfs)</label>
                                <FormInput
                                    placeholder="Channel Capacity (cfs)"
                                    value={inputValues.channelCapacityCfs}
                                    onChange={(e) =>
                                        setValues({ ...inputValues, channelCapacityCfs: Number(e.target.value) })
                                    }
                                    type='number'
                                />
                            </FormField>

                            <FormField>
                                <label>Intake Capacity (cfs)</label>
                                <FormInput
                                    placeholder="Intake Capacity (cfs)"
                                    value={inputValues.intakeCapacityCfs}
                                    onChange={(e) =>
                                        setValues({ ...inputValues, intakeCapacityCfs: Number(e.target.value) })
                                    }
                                    type='number'
                                />
                            </FormField>

                            <FormField>
                                <label>Storage Capacity (acft)</label>
                                <FormInput
                                    placeholder="Storage CapacityAcft (acft)"
                                    value={inputValues.storageCapacityAcft}
                                    onChange={(e) =>
                                        setValues({ ...inputValues, storageCapacityAcft: Number(e.target.value) })
                                    }
                                    type='number'
                                />
                            </FormField>

                            <FormField>
                                <label>Percolation Capacity (cfs)</label>
                                <FormInput
                                    placeholder="Percolation Capacity (cfs)"
                                    value={inputValues.percolationCapacityCfs}
                                    onChange={(e) =>
                                        setValues({ ...inputValues, percolationCapacityCfs: Number(e.target.value) })
                                    }
                                    type='number'
                                />
                            </FormField>

                            <FormField>
                                <label>First Season</label>
                                <FormInput
                                    placeholder="First Season"
                                    value={inputValues.firstSeason}
                                    onChange={(e) =>
                                        setValues({ ...inputValues, firstSeason: e.target.value })
                                    }
                                    type='number'
                                />
                            </FormField>

                            <FormField>
                                <label>Gross Area (ac)</label>
                                <FormInput
                                    placeholder="Gross Area (ac)"
                                    value={inputValues.grossAreaAc}
                                    onChange={(e) =>
                                        setValues({ ...inputValues, grossAreaAc: Number(e.target.value) })
                                    }
                                    type='number'
                                />
                            </FormField>

                            <FormField>
                                <label>Wetted Area (ac)</label>
                                <FormInput
                                    placeholder="Wetted Area (ac)"
                                    value={inputValues.wettedAreaAc}
                                    onChange={(e) =>
                                        setValues({ ...inputValues, wettedAreaAc: Number(e.target.value) })
                                    }
                                    type='number'
                                />
                            </FormField>

                            <FormField>
                                <label>Basin Count</label>
                                <FormInput
                                    placeholder="Basin Count"
                                    value={inputValues.basinCount}
                                    onChange={(e) =>
                                        setValues({ ...inputValues, basinCount: Number(e.target.value) })
                                    }
                                    type='number'

                                />
                            </FormField>

                            <FormField>
                                <label>Latitude</label>
                                <FormInput
                                    placeholder="Latitude"
                                    value={inputValues.latitude}
                                    onChange={(e) =>
                                        setValues({ ...inputValues, latitude: Number(e.target.value) })
                                    }
                                    type='number'
                                />
                            </FormField>

                            <FormField>
                                <label>Longitude</label>
                                <FormInput
                                    placeholder="Longitude"
                                    value={inputValues.longitude}
                                    onChange={(e) =>
                                        setValues({ ...inputValues, longitude: Number(e.target.value) })
                                    }
                                    type='number'
                                />
                            </FormField>

                            <FormField>
                                <label>Watershed</label>
                                <Dropdown
                                    placeholder='Watershed'
                                    options={props.watershedOptions.map((x) => ({ key: x.id, text: x.displayName, value: x.id }))}
                                    dropdownTitle="Watershed"
                                    fluid
                                    selection
                                    onChange={(_, data) => setValues({ ...inputValues, watershedId: data.value as number })}
                                    value={inputValues.watershedId}
                                />
                            </FormField>

                            <FormField>
                                <label>Location Description</label>
                                <FormInput
                                    placeholder="Location Description)"
                                    value={inputValues.locationDescription}
                                    onChange={(e) =>
                                        setValues({ ...inputValues, locationDescription: e.target.value })
                                    }
                                />
                            </FormField>

                            <FormField>
                                <label>Water Source Description</label>
                                <FormInput
                                    placeholder="Water Source Description)"
                                    value={inputValues.waterSourceDescription}
                                    onChange={(e) =>
                                        setValues({ ...inputValues, waterSourceDescription: e.target.value })
                                    }
                                />
                            </FormField>

                            <FormField>
                                <label>Comment</label>
                                <FormInput
                                    placeholder="Comment"
                                    value={inputValues.comment}
                                    onChange={(e) =>
                                        setValues({ ...inputValues, comment: e.target.value })
                                    }
                                />
                            </FormField>
                        </div>
                    </Form>
                </div>
            </Modal.Content>
            <Modal.Actions>
                <div className="wave-div">
                    <img src={Wave2} alt="wave" className="wave" />
                </div>
                <div style={{ padding: "1em" }}>
                    <Button color="blue" onClick={handleSubmit} disabled={!isFormValid}>
                        {isCreateModal ? "Create" : "Edit"}
                    </Button>
                    <Button onClick={() => { props.onClose(); props.resetValues(); }}>
                        Cancel
                    </Button>
                </div>
            </Modal.Actions>
        </Modal>
    );
};

export default SGFormModal;