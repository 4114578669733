import { useCallback, useContext, useEffect, useState } from "react";
import { Translation } from "react-i18next";
import { IBuildWatershedFormProps } from "./BuildWatershedForm";
import { ButtonComponent } from "../../../paradigmLib/domElements/buttonComponent/ButtonComponent";
import Config from "../../../config/Config";
import WatershedReportJurisdictionSignOff from "../../../models/WatershedReportJurisdictionSignOff";
import { GlobalAuthInfoContext } from "../../mainWrapper/MainWrapper";
import { useAPIFetchAllLocal, usePutPostLocal } from "../../../core/UseAPILocal";
import JurisdictionGroup from "../../../models/JurisdictionGroup";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Download from "../../../core/DownloadUtil";
import './BuildWatershedForm.scss';
import { useAuth } from "react-oidc-context";

export interface IJurisReportingOutputs extends IBuildWatershedFormProps {
    jurisdictionGroup: JurisdictionGroup;
}

export const JurisReportingOutputs: any = (props: IJurisReportingOutputs) => {
    const [showOutputs, setShowOutputs] = useState<boolean>(false);
    const [timeStampOfGen, setTimeStampOfGen] = useState<string>('');

    const [excelOutputEndpoint, setExcelOutputEndpoint] = useState<string>();
    const [zipOutputEndpoint, setZipOutputEndpoint] = useState<string>();
    const [wordOutputEndoint, setWordOutputEndpoint] = useState<string>();

    const [submitObj, setSubmitObj] = useState<WatershedReportJurisdictionSignOff>(new WatershedReportJurisdictionSignOff());
    const [fetchSubmit, setFetchSubmit] = useState<boolean>(true);
    const [postSubmit, setPostSubmit] = useState<boolean>(false);

    const auth = useAuth();
    const globalAuthInfoContext = useContext(GlobalAuthInfoContext);

    useAPIFetchAllLocal(
        auth.user?.access_token,
        new WatershedReportJurisdictionSignOff(),
        fetchSubmit,
        setFetchSubmit,
        setSubmitObj,
        { 'watershedReportingPeriodId': props.selectedParams.reportingPeriodId, 'jurisdictionGroupId': props.jurisdictionGroup.id, "active": 1 },
        (data) => {
            if (data && data.length > 0) {
                setSubmitObj(data[0]);
            }
        });

    useEffect(() => {
        setFetchSubmit(true);
    }, [props.selectedParams])

    usePutPostLocal(auth.user?.access_token, submitObj, (data) => {
        let newObject: WatershedReportJurisdictionSignOff = Object.assign(new WatershedReportJurisdictionSignOff(), data);
        setSubmitObj(newObject);
    }, [postSubmit]);

    const callSubmit = () => {
        let newObject: WatershedReportJurisdictionSignOff = Object.assign(new WatershedReportJurisdictionSignOff(), submitObj);
        newObject.jurisdictionGroupId = props.jurisdictionGroup.id;
        newObject.active = 1;
        newObject.watershedReportStatusId = 1002; // final... not really used
        newObject.watershedReportingPeriodId = props.selectedParams.reportingPeriodId;
        setSubmitObj(newObject);

        setPostSubmit(!postSubmit)
    }

    useEffect(() => {
        setTimeStampOfGen('')
        setShowOutputs(false)

        setExcelOutputEndpoint(Config.api_endpoint
            + "report/excel/jurisdiction/"
            + props.selectedParams.jurisdictionId + "/"
            + props.selectedParams.watershedGroupId + "/"
            + props.selectedParams.reportingPeriodId);
        setZipOutputEndpoint(Config.api_endpoint + "zip/jurisdiction/" + props.selectedParams.jurisdictionId + "/" + props.selectedParams.watershedGroupId + "/" + props.selectedParams.reportingPeriodId);
        setWordOutputEndpoint(Config.api_endpoint
            + "report/word/jurisdiction/"
            + props.selectedParams.jurisdictionId + "/"
            + props.selectedParams.watershedGroupId + "/"
            + props.selectedParams.reportingPeriodId);
    }, [props.selectedParams]);

    const getFileName = useCallback((extension: string) => {
        var name = props.lookups.getJurisdictionById(props.selectedParams.jurisdictionId);
        name += " " + props.lookups.getWatershedManagementGroupById(props.selectedParams.watershedGroupId);
        name += " " + props.lookups.getReportingPeriodById(props.selectedParams.reportingPeriodId);
        name = name.replaceAll("-", "_").replaceAll("/", "-");
        name += extension;

        return name;
    }, [props.lookups, props.selectedParams]);

    const downloadFile = useCallback(async (endpoint: string, fileExtension: string, errorMessage?: string) => {
        var name = getFileName(fileExtension);

        const toastId = toast.info("Downloading...");
        try {
            await Download(endpoint, auth.user?.access_token, name);
        } catch (err) {
            toast.error(errorMessage ?? "Failed to download file");
        } finally {
            toast.dismiss(toastId);
        }
    }, [getFileName, auth]);

    const generateOutputClick = () => {
        setShowOutputs(true);
        setTimeStampOfGen(new Date().toLocaleString([], { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }))
    }

    return (
        <div className="build-watershed-form-subpage juris-reporting-outputs">
            <ToastContainer position="top-center" />
            <Translation>
                {
                    t => <>
                        <div className="subpage-container">
                            <div className="subpage-header-container">
                                <div className="subpage-header-1">Generate Outputs</div>
                                <div className="subpage-header-2">
                                    <div className="text">
                                        WRAMPS will compile key outputs for the WMP Progress Report Form. Below are the key outputs for this jurisdiction.  These outputs will be provided to the Group Lead in a compiled format to support the Group's submittal.  Please review these outputs and edit the data entries as needed to best reflect the information to be submitted to the Regional Board on the Group's behalf.
                                    </div>
                                    <div className="btn-div">
                                        <ButtonComponent
                                            onBtnClick={generateOutputClick}
                                            label="Generate Output"
                                            theme="primaryBtn"
                                        />
                                        {timeStampOfGen &&
                                            <div className="submitted">
                                                Generated On: &nbsp;&nbsp;{timeStampOfGen}
                                            </div>
                                        }
                                    </div>
                                    <div className="outputs">
                                        {
                                            showOutputs &&
                                            <>
                                                <div className="outputs-title">Outputs:</div>

                                                <ul>
                                                    <li>
                                                        <button onClick={() => downloadFile(wordOutputEndoint, ".docx")}>
                                                            Progress Report Form with text entries (.docx)
                                                        </button>
                                                    </li>
                                                    <li>
                                                        <button onClick={() => downloadFile(zipOutputEndpoint, ".zip", "Failed to download file, ensure at least one file is uploaded")}>
                                                            Compiled attachments by section (.zip)
                                                        </button>
                                                    </li>
                                                    <li>
                                                        <button onClick={() => downloadFile(excelOutputEndpoint, ".xlsx")}>
                                                            Output for Tables 1a, 1b, 1c and Sections 1.2a, 1.3a (.xlsx)
                                                        </button>
                                                    </li>
                                                </ul>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="subpage-header-container">
                                <div className="subpage-header-1">Submittal to Group Lead</div>
                                <div className="subpage-header-2">
                                    WRAMPS can submit the latest Reporting Outputs to the Group Lead. After reviewing the reporting outputs and verifying their accuracy, submit those outputs to the Group Lead based on the deadlines and instructions established by your Group.  Once the Group Lead has categorized the Progress Report status as Final then edit functionality will no longer be available.
                                    <div className="btn-div">
                                        <ButtonComponent
                                            onBtnClick={() => {
                                                callSubmit();
                                            }}
                                            label="Submit to Group Lead"
                                            theme="primaryBtn"
                                            disabled={props.finalLock}
                                        />
                                        {submitObj && submitObj.id > 0 &&
                                            <div className="submitted">
                                                Submitted On: &nbsp;&nbsp;{new Date(submitObj.updated).toLocaleString([], { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' })}
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </Translation>
        </div>
    )
}
