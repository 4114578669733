export type uploadStatesOptions = "waiting" | "failed" | "success" | "wrongFileType";

export enum uploadOptions {Waiting, Failed, Success, PDFExpected, ShapeExpected, JPGExpected};

export enum enumUploadStatus {
    Ready = 0,
    FieldsRequired = 1,
    SelectFile = 2,
    FileTooLarge = 3,
    WrongFileType = 4
}


export const placeholderDropdownItems = [
    {
        id: 1,
        displayName: "Placeholder 1",
        internalName: "placeholder1",
        displayWeight: 1, 
        active: 1
    },
    {
        id: 2,
        displayName: "Placeholder 2",
        internalName: "placeholder2",
        displayWeight: 2, 
        active: 1
    },
    {
        id: 3,
        displayName: "Placeholder 3",
        internalName: "placeholder3",
        displayWeight: 3, 
        active: 1
    }
];
