import { useState, useMemo, useEffect, useCallback } from "react";
import { useAuth } from "react-oidc-context";
import { toast } from "react-toastify";
import { Message, Segment, Header } from 'semantic-ui-react';

import LookupFactory from "../../../lookups/LookupFactory";

import JurisdictionService from "../../../services/JurisdictionService";
import { ToggleComponent } from "../../../paradigmLib/domElements/toggleComponent/ToggleComponent";

import "./CaptureDashboardConfigTab.scss";

export type CaptureDashboardConfigTabProps = {
    lookups: LookupFactory;
    selectedJurisdictionId: number;
}

export const CaptureDashboardConfigTab = (props: CaptureDashboardConfigTabProps) => {
    const auth = useAuth();
    const jurisdictionService = useMemo(() => new JurisdictionService(auth.user?.access_token), [auth]);

    const [excluded, setExcluded] = useState<boolean>(false);
    const [excludedLoading, setExcludedLoading] = useState<boolean>(true);

    useEffect(() => {
        jurisdictionService.getDashboardExclusionByJurisdictionId(props.selectedJurisdictionId)
            .then((result) => {
                setExcluded(result);
                setExcludedLoading(false);
            })
            .catch((e) => {
                toast.error('Error retrieving Jurisdiction Exclusion', { containerId: "manage-project-data-toast-container", autoClose: 5000, });
            });
    }, [jurisdictionService, props.selectedJurisdictionId]);

    const handleExclusionChange = useCallback((newValue: boolean) => {
        setExcludedLoading(true);
        jurisdictionService.setDashboardExclusionByJurisdictionId(props.selectedJurisdictionId, !newValue)
            .then((result) => {
                setExcluded(result.excludeOnDashboard);
                setExcludedLoading(false);
            })
            .catch((e) => {
                toast.error('Error saving Jurisdiction Exclusion', { containerId: "manage-project-data-toast-container", autoClose: 5000, });
            });
    }, [jurisdictionService, props.selectedJurisdictionId]);

    return (
        <div className="capture-config-tab">
            <div className="capture-config-tab__info-text-container">
                <Message info>
                    <p>
                        By default completed structural watershed control measures will be included in the public facing Stormwater Capture Dashboard.
                    </p>
                    <p>
                        WRAMPS strongly encourages users to include their projects in the Stormwater Capture Dashboard to help demonstrate the impact of their projects as well as progress made across the County.
                    </p>

                    <p>
                        If the Jurisdiction wishes to EXCLUDE their projects from the Stormwater Capture Dashboard, please use the toggle below.
                    </p>
                </Message>
            </div>
            <div className="capture-config-tab__toggle-container">
                <Segment className="capture-dashboard__widget-container " placeholder>
                    <Header icon>
                        This status may be updated at any time
                    </Header>
                    <ToggleComponent
                        sideLabel={excluded ?
                            <span className="capture-config-tab__toggle-text" style={{ color: "#9e9e9e" }}>Excluded</span>
                            :
                            <span className="capture-config-tab__toggle-text" style={{ color: "#006ba5" }}>Included</span>
                        }
                        value={!excluded}
                        disabled={excludedLoading}
                        setValue={handleExclusionChange}
                        theme={"primary"}
                    />
                </Segment>
            </div>
        </div>
    );
};