import React from 'react';

import './DataBlock.scss';
import { SemanticICONS } from 'semantic-ui-react';

type DataBlockProps = {
    title: string | number | JSX.Element;
    infoType: string;
    description?: string;
    icon?: SemanticICONS;
    className?: string;
}

export const DataBlock: React.FC<DataBlockProps> = (props: DataBlockProps) => (
    <>
        {props.description &&
            <div className={`card`}>
                <div className='card-content'>
                    <div className='card-description'>
                        <p className="description-text">
                            {props.description}
                        </p>
                    </div>
                    <div className='card-header'>
                        <div className="header-div">
                            <p className={`header-text ${props.className} `}>
                                {props.title} <span className="info-type">{props.infoType}</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        }
    </>
);
