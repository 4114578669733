import Table1_1Data from "./Table1_1Data";

export default class WatershedFormTable1_1 {

    tableDataCompleted: Table1_1Data;
    tableDataInProgress: Table1_1Data;

    static endpoint: string = "report/summary/jurisdiction/1_1";

}

