import { BaseModel } from "./BaseModel";

export default class DocumentModel extends BaseModel {
    id: number;
    attachmentFileName: string;
    attachmentDescription: string;
    attachmentFileType: string;
    attachmentDate: string;
    active: number;

    constructor(endpoint: string) {
        super(endpoint, "local")
        this.attachmentDate = new Date().toJSON();
    }
}
