import React from "react";
import { Button } from "semantic-ui-react";
import { IButtonComponentProps } from "./IButtonComponentProps";
import './ButtonComponent.scss';

export const ButtonComponent: React.FC<IButtonComponentProps> = (props: IButtonComponentProps) => {

    return (
        <div className="button-component">
            <Button
                className={`${props.btnHeight ? "btnHeightSlim" : ""} ${props.theme}`}
                disabled={props.disabled === true}
                loading={props.loading === true}
                onClick={(e) => props.onBtnClick(e)}
                data-tooltip={props.tooltip ? props.tooltip : null}
                data-position="top left"
                data-inverted=""
            >
                {props.label}
            </Button>
        </div>
    )
}
