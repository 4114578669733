import { ResponsiveLine } from '@nivo/line';
import { customFormat } from '../../core/NumberFormat';
import moment from 'moment';
import { Icon } from "semantic-ui-react";
import { CaptureDashboardConversionUnit, CaptureDashboardDateRangeTypes, CaptureDashboardGraphType } from './CaptureDashboardContainer';
import { useMemo } from 'react';
import { CaptureBenefit } from '../../models/CaptureBenefit';

type CaptureDashboardGraphProps = {
    graphData: CaptureBenefit[];
    dataType: CaptureDashboardGraphType;
    unitType: CaptureDashboardConversionUnit;
    dateRangeType: CaptureDashboardDateRangeTypes;
    startDate: Date;
    endDate: Date;
    recentRainfall: CaptureBenefit | null;
    isLfd: boolean;
}

const captureUnitOptions: Record<CaptureDashboardConversionUnit, string> = {
    acft: "ac-ft",
    gallons: "Gallons",
    swimmingPools: "Swimming Pools",
    roseBowls: "Rose Bowls"
};

const graphTheme = {
    axis: {
        legend: {
            text: {
                fontSize: 18,
                fontWeight: 800
            }
        }
    },
};

export const CaptureDashboardGraph = (props: CaptureDashboardGraphProps) => {

    const graphDataXY: { x: Date, y: number }[] = useMemo(() => {
        let rollingTotal = 0;

        if (props.graphData.length === 0) {
            // for no events, don't add anything
            return [];
        }

        if (props.dateRangeType === "recentEvent") {
            if (props.isLfd) {
                return [{ x: new Date(props.graphData.at(-1)?.eventStart), y: 0 }, { x: new Date(props.graphData.at(-1).eventEnd), y: props.graphData.at(-1).amount }];
            }

            const recentWetEvent = props.graphData.find(
                (record) => record.eventEnd === props.recentRainfall?.eventEnd && record.eventStart === props.recentRainfall?.eventStart
            );

            return [{ x: new Date(recentWetEvent?.eventStart), y: 0 }, { x: new Date(recentWetEvent?.eventEnd), y: Number(recentWetEvent?.amount.toFixed(2)) }];
        }

        // for latest event, there is only one data point, so adding one more data point to show the start of the event
        // for all events, adding one more data point to show the end of the last event and one at the beginning to show the start of the date range
        var data = props.graphData
            .map((record) => {
                rollingTotal += record.amount;
                return { ...record, amountStart: rollingTotal - record.amount, amountEnd: rollingTotal };
            })
            .map((record) => [{ x: new Date(record.eventStart), y: record.amountStart }, { x: new Date(record.eventEnd), y: record.amountEnd }])
            .flat();

        // remove any duplicates
        data = data.filter((record, index, self) => self.findIndex((t) => t.x.getTime() === record.x.getTime()) === index);

        return [{ x: props.startDate, y: 0 }, ...data, { x: props.endDate, y: rollingTotal }];
    }, [props.dateRangeType, props.endDate, props.graphData, props.startDate, props.recentRainfall, props.isLfd]);

    const graphMessage: string | null = useMemo(() => {
        // This message is shown when there is invalid data or no data to show in the graph
        if (props.graphData.length === 0) {
            return `No ${props.dataType === CaptureDashboardGraphType.Capture ? "Capture " : "Rainfall "} Data Available`;
        }

        if (props.dateRangeType === "recentEvent" && props.graphData.at(-1).amount === 0) {
            return `Recent Event has 0 ${props.dataType === CaptureDashboardGraphType.Capture ? "acft " : "inches "} of ${props.dataType === CaptureDashboardGraphType.Capture ? "Capture " : "Rainfall "}`;
        }

        return null;
    }, [props.dataType, props.dateRangeType, props.graphData]);

    return (
        <div className="program-progress-graph"
            style={{ width: "100%", height: "35em", background: "white", margin: "1em 0 0" }}>
            {graphMessage &&
                <div style={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <div
                        style={{
                            boxShadow: "12px 6px 20px rgba(0, 0, 0, 0.19), 2px 5px 9px 0px rgba(0, 0, 0, 0.23)",
                            color: "gray",
                            padding: "1em",
                            borderRadius: "1em",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            border: "1px solid #547daa",
                        }}>
                        <Icon
                            name="ban"
                            size='large'
                            style={{ color: "rgb(84, 125, 170)", fontSize: "3em", margin: "0.3em  auto 0em" }}
                        />
                        <h3 style={{ color: "gray", padding: "1em", borderRadius: "1em", margin: 0 }}>
                            {graphMessage}
                        </h3>
                    </div>
                </div>
            }
            {!graphMessage &&
                <ResponsiveLine
                    data={[{
                        id: `${props.unitType}`,
                        data: graphDataXY
                    }]}
                    theme={graphTheme}
                    margin={{ top: 50, right: 25, bottom: 50, left: 60 }}
                    yScale={{
                        type: 'linear',
                        min: 0,
                        max: 'auto',
                        stacked: true,
                        reverse: false
                    }}
                    enableSlices='x'
                    colors={props.dataType === CaptureDashboardGraphType.Capture ? "#006ba5" : "#00bcd4"}
                    enablePoints={true}
                    pointSize={4}
                    pointBorderWidth={1}
                    animate={true}
                    motionConfig={'gentle'}
                    enableArea={true}
                    areaOpacity={0.2}
                    axisBottom={{
                        tickSize: 3,
                        tickPadding: 5,
                        tickRotation: -45,
                        format: (value) => moment(value).format('MMM DD YY')
                    }}
                    enableGridX={false}
                    axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: props.dataType === CaptureDashboardGraphType.Capture ? captureUnitOptions[props.unitType] : "inches",
                        format: (value) => customFormat(value as number),
                        legendOffset: 15
                    }}
                    xScale={{
                        type: props.dateRangeType === "recentEvent" ? 'point' : 'time'
                    }}
                    sliceTooltip={({ slice }) => {
                        return (
                            <div
                                className="graph-tooltip"
                                style={{
                                    background: 'white',
                                    padding: '9px 12px 8px',
                                    border: '1px solid #ccc',
                                    borderRadius: '5px',
                                    boxShadow: '0.5px 0.5px 3px 0px #a5a5a5'
                                }}>
                                <div className="x-value">
                                    {`${(moment(slice.points[0].data.x).format('MMM DD YYYY'))}`}
                                </div>
                                <div className="y-value" style={{ color: slice.points[0].serieColor, padding: '3px 0' }}>
                                    <p>
                                        <b>{customFormat(slice.points[0].data.y as number)}</b> {props.dataType === CaptureDashboardGraphType.Capture ? captureUnitOptions[props.unitType] : "inches"}
                                    </p>
                                </div>
                            </div>
                        )
                    }}
                    pointColor={{ theme: 'background' }}
                    pointBorderColor={{ from: 'serieColor' }}
                    pointLabelYOffset={-12}
                    useMesh={true}
                    areaBaselineValue={0}
                />
            }
        </div>
    );
}
