import React, { useEffect } from "react";
import { IConfirmProps } from "./IConfirmProps";
import { Button, Modal } from "semantic-ui-react";
import Wave2 from "../../../images/modalWave2.svg";
import './ConfirmModal.scss';

export const ConfirmModal: any = (props: IConfirmProps) => {

    const [open, setOpen] = React.useState(false);

    //this will run the function passed into it (currently either onStatusChange (modal1) or confirmCreateReport (modal2) )
    const confirmAndClose = () => {
        if (props.tempStatus) {
            props.confirmFunc(props.tempStatus);
            props.setOpenModal(false);

        } else {
            props.confirmFunc();
            props.setOpenModal(false);
        }
    }

    useEffect(() => {
        setOpen(props.openModal)
    }, [props.openModal]);

    return (
        <Modal
            className="confirm-modal"
            size="tiny" // options are mini tiny small large or fullscreen
            centered={true}
            open={open}
            onClose={() => props.setOpenModal(false)}
            onOpen={() => setOpen(true)}>

            <Modal.Content>{props.confirmText}</Modal.Content>

            <Modal.Actions>
                <div className="wave-div">
                    <img src={Wave2} alt="wave" className="wave" />
                </div>
                <div className="modal-btns">
                    <Button className="confirm-btn" onClick={confirmAndClose}>OK</Button>
                    <Button className="cancel-btn" onClick={() => { props.setOpenModal(false) }}>Cancel</Button>
                </div>
            </Modal.Actions>
        </Modal>
    )
}