import { useContext, useEffect, useState } from "react";
import './BuildWatershedForm.scss';
import { Translation } from "react-i18next";
import { useAPIGetByIdLocal, usePutPostLocal } from "../../../core/UseAPILocal";
import { ButtonComponent } from "../../../paradigmLib/domElements/buttonComponent/ButtonComponent";
import { TextAreaComponent } from "../../../paradigmLib/domElements/textAreaComponent/TextAreaComponent";
import WatershedReportForm1_5 from "../../../models/WatershedReportForm1_5";
import { IBuildWatershedFormProps } from "./BuildWatershedForm";
import { UploadSection } from "../../uploadSection/uploadSection";
import { AllGroupsModal, WatershedReportType } from "./AllGroupsModal";
import { useAuth } from "react-oidc-context";

export const BuildWatershedForm_1_5: any = (props: IBuildWatershedFormProps) => {
    const auth = useAuth();
    const [allGroupsModalOpen, setAllGroupsModalOpen] = useState<boolean>(false);

    const [watershedReportForm, setWatershedReportForm] = useState<WatershedReportForm1_5>(new WatershedReportForm1_5());

    // this state handles when the useAPIFetchAllLocal hook should be called
    const [fetchingPageObj, setFetchingPageObj] = useState<boolean>(false);

    // this handles when to flip flag and put/post to the db
    const [updatePageObj, setUpdatePageObj] = useState<boolean>(false);

    useEffect(() => {
        if (props.watershedReportForm && props.watershedReportForm.id !== undefined) {
            setFetchingPageObj(true);
        }
    }, [props.watershedReportForm]);

    // This hook will fetch the obj from the db with the params needed
    useAPIGetByIdLocal(
        auth.user?.access_token,
        new WatershedReportForm1_5(),
        fetchingPageObj,
        setFetchingPageObj,
        setWatershedReportForm,
        { 'watershedReportFormId': props.watershedReportForm.id, "active": 1 },
        (data) => {
            if (!data) {
                let stateCopy: WatershedReportForm1_5 = new WatershedReportForm1_5();
                stateCopy.watershedReportFormId = props.watershedReportForm.id;
                stateCopy.active = 1;
                setWatershedReportForm(stateCopy);
            }
        });

    // This hook will put or post the object on save
    usePutPostLocal(auth.user?.access_token, watershedReportForm, (data) => {
        setWatershedReportForm(data);
        props.setActiveItem("1_6");
    }, [updatePageObj]);

    const Save = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        setUpdatePageObj(true);
    }

    return (
        <div className="build-watershed-form-subpage">
            <Translation>
                {
                    _ => <>
                        <div className="subpage-container">
                            <AllGroupsModal
                                open={allGroupsModalOpen}
                                setOpen={setAllGroupsModalOpen}
                                watershedReportType={WatershedReportType.Report1_5}
                                watershedReportFormId={props.watershedReportForm.id}
                                summaryText={watershedReportForm.text}
                                setActiveTab={props.setActiveItem}
                                nextTab="1_6"
                            />
                            <div className="subpage-header-container">
                                <div className="subpage-header-1">1.5&nbsp; - &nbsp;Additional Information</div>
                            </div>

                            <div className="input-div">
                                <TextAreaComponent
                                    label="Summary"
                                    placeHolder="Summary"
                                    value={watershedReportForm.text}
                                    setValue={(value) => setWatershedReportForm(Object.assign({}, watershedReportForm, { text: value }))}
                                    rows={10}
                                    disabled={props.finalLock}
                                />
                            </div>
                            <div className="subpage-header-container">
                                <div className="subpage-header-2 pdf-upload-div mt-3">
                                    <UploadSection
                                        formName={"1_5"}
                                        watershedReportForm={props.watershedReportForm}
                                        lookups={props.lookups}
                                        disabled={props.finalLock}
                                        fileType={".pdf"}
                                    />
                                </div>
                            </div>
                            {props.watershedReportForm.watershedReportStatusId !== props.lookups.getWatershedReportStatusByName("Final") &&
                                <div className="save-btn-div">
                                    {props.lookups.jurisdictionGroups.filter(x => x.jurisdictionId === props.watershedReportForm.jurisdictionId).length > 1 &&
                                        <span style={{ marginRight: "1rem" }}>
                                            <ButtonComponent
                                                onBtnClick={() => setAllGroupsModalOpen(true)}
                                                label="Save To All Groups &amp; Continue"
                                                theme="primaryBtn"
                                                disabled={props.finalLock}
                                            />
                                        </span>
                                    }
                                    <ButtonComponent
                                        onBtnClick={() => Save()}
                                        label="Save &amp; Continue"
                                        theme="primaryBtn"
                                        disabled={props.finalLock}
                                    />
                                </div>
                            }
                        </div>
                    </>
                }
            </Translation>
        </div>
    )
}
