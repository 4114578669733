import { Button, Modal } from 'semantic-ui-react';
import Wave from "../../../images/modalWave.svg";
import Wave2 from "../../../images/modalWave2.svg";
import { ButtonComponent } from '../../../paradigmLib/domElements/buttonComponent/ButtonComponent';
import { getAxiosApiBase } from '../../../core/UseAPILocal';
import { useCallback, useContext, useState } from 'react';
import { AxiosError } from 'axios';
import { useAuth } from 'react-oidc-context';

export enum WatershedReportType {
    Report1_1,
    Report1_2a,
    Report1_3a,
    Report1_5
}

export interface IAllGroupsModalProps {
    watershedReportType: WatershedReportType;
    watershedReportFormId: number;
    summaryText: string;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    nextTab: string;
    setActiveTab: (tab: string) => void;
}

export const AllGroupsModal = (props: IAllGroupsModalProps) => {
    const auth = useAuth();
    const [errorMessage, setErrorMessage] = useState<string | undefined>();

    const saveAllGroupsAndContinue = useCallback(() => {
        getAxiosApiBase(auth.user?.access_token ?? "")
            .post("report/postToAllGroups/", {
                watershedReportFormId: props.watershedReportFormId,
                watershedReportType: props.watershedReportType,
                summary: props.summaryText
            })
            .then(() => {
                //success
                props.setOpen(false);
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
                props.setActiveTab(props.nextTab);
            })
            .catch((error: AxiosError) => {
                if (error.response.data.message === "finalizedError") {
                    setErrorMessage("Error: You cannot add to each group because one or more of the reports is already finalized");
                }
                else {
                    setErrorMessage("Error Uploading!");
                }
            })
    }, [auth, props]);

    return (
        <Modal className="upload-modal-component"
            size='tiny'
            onClose={() => props.setOpen(false)}
            onOpen={() => props.setOpen(true)}
            open={props.open}>
            <Modal.Header>
                <div className="upload-modal-title">Are you sure?</div>
                <div className="wave-div">
                    <img src={Wave} alt="wave" />
                </div>
            </Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    <div className="uploader mb-1">
                        Are you sure you want to apply the summary and attachments input for this Section to all WMG's your
                        jurisdiction is part of for the selected reporting period? This action will overwrite existing entries
                        for this Section and reporting period for all WMGs and cannot be undone. Note that all files on other
                        reports will be replaced by the files uploaded in this report.
                    </div>
                    <div className="uploader mb-1">
                        <span className="text-danger ml-2 mb-1">{errorMessage}</span>
                    </div>
                </Modal.Description>
            </Modal.Content>
            <div className="action-div">
                <div className="wave-div">
                    <img src={Wave2} alt="wave" className="wave" />
                </div>
                <Modal.Actions className="pdf-modal-actions">
                    <ButtonComponent
                        onBtnClick={() => saveAllGroupsAndContinue()}
                        label="Yes"
                        theme={"primaryBtn"}
                    />
                    <Button color="grey" onClick={() => props.setOpen(false)}>Cancel</Button>
                </Modal.Actions>
            </div>
        </Modal>
    )
}
