import axios, { AxiosRequestConfig } from "axios";
import Config from "../config/Config";

export default async function Download(
    endpoint: string,
    authToken: string,
    downloadName: string) {

    const config: AxiosRequestConfig<any> = {
        headers: {
            "Authorization": "Bearer " + authToken
        },
        responseType: "blob"
    }

    let response = await axios.get<Blob>(endpoint, config);
    const url = window.URL.createObjectURL(new Blob([response.data]));

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", downloadName);

    document.body.appendChild(link);
    link.click();
}


// TODO: Move this to another spot or rename this file
export async function GetById<T>(
    url: string,
    authToken: string,
    id: number): Promise<T> {

    const config: AxiosRequestConfig<any> = {
        headers: {
            "Authorization": "Bearer " + authToken
        }
    };
    let response = await axios.get<T>(Config.api_endpoint + url + "/" + id, config);

    return response.data;
}

export async function Get<T>(
    url: string,
    authToken: string,
    params?: object): Promise<T> {
    const config: AxiosRequestConfig<any> = {
        headers: {
            "Authorization": "Bearer " + authToken
        },
        params: params

    }

    let response = await axios.get<T>(Config.api_endpoint + url, config);

    return response.data;
}



export async function GetAll<T>(url: string, authToken: string, params?: object): Promise<Array<T>> {
    const config: AxiosRequestConfig<any> = {
        headers: {
            "Authorization": "Bearer " + authToken
        },
        params: params
    }

    let response = await axios.get<Array<T>>(Config.api_endpoint + url, config);

    return response.data;
}

export async function Post<T>(
    url: string,
    authToken: string,
    obj: T): Promise<T> {

    const config: AxiosRequestConfig<any> = {
        headers: {
            "Authorization": "Bearer " + authToken
        }
    }

    let response = await axios.post<T>(Config.api_endpoint + url, obj, config);

    return response.data;
}

interface IPuttable { id: number }
export async function Put<T extends IPuttable>(
    url: string,
    authToken: string,
    obj: T): Promise<T> {

    const config: AxiosRequestConfig<any> = {
        headers: {
            "Authorization": "Bearer " + authToken
        }
    }

    let response = await axios.put<T>(Config.api_endpoint + url + "/" + obj.id, obj, config);

    return response.data;
}
