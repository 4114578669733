import React, { useCallback, useEffect, useReducer, useState } from "react";
import { useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Sidebar, Segment, Icon, Loader, Dimmer } from "semantic-ui-react";
import Config from "../../config/Config";
import { SidebarConfig } from "../../core/SidebarConfig";
import {
    getAxiosApiBase,
    useAPIFetchAllLocal,
    useLookupTablesLocal,
} from "../../core/UseAPILocal";
import MapData from "../../models/mapData";
import { Navbar } from "../navbar/Navbar";
import { Translation } from "react-i18next";
import { filterConfig } from "../../config/filterConfig";
import DashboardQueryBase from "../../GeoJsonQueryBase/DashboardQueryBase";
import alasql from "alasql";
import { LandingPage } from "../landingPage/LandingPage";
import { WelcomePage } from "../welcomePage/WelcomePage";
import LookupFactory from "../../lookups/LookupFactory";
import BmpGeneral from "../../models/BmpGeneral";
import { UserManagement } from "../admin/UserManagement";
import Jurisdiction from "../../models/Jurisdiction";
import WatershedManagementGroup from "../../models/WatershedManagementGroup";
import JurisdictionUser from "../../models/JurisdictionUser";
import WatershedGroupUser from "../../models/WatershedGroupUser";
import { HelpPage } from "../helpPage/HelpPage";
import { JurisdictionOwner, WatershedManagementGroupOwner } from "../../models/OwnerModels";
import { AxiosResponse } from "axios";
import "./MainWrapper.scss";
import { useAuth } from "react-oidc-context";
import { LogOffLoader } from "../logoffLoader/LogOffLoader";
import { CaptureDashboardContainer } from "../CaptureDashboardPage/CaptureDashboardContainer";
import { ToastContainer } from "react-toastify";

export type ReportingPageType = "alertMessagePage" | "none" | "manageProjectData" | "buildWatershedForm" | "buildAnnReportForm" | "configureMilestones" | "configureProjAssumptions" | "configurePublicDash" | "userManagement";

// buildWatershedForm needs broken down into smaller sections example "buildWatershedFormJuris", "buildWatershedFormGroupLead", "buildWatershedFormGroupUser"
// userManagement needs broken down into smaller sections example "userManagementInterestedParties", "userManagement"
export type VisibleComponentNames = "manageProjectData" | "buildWatershedFormJuris" | "buildWatershedFormGroupLead" | "buildAnnReportForm" | "configureMilestones" | "configureProjAssumptions" | "configurePublicDash" | "userManagement" | "userManagementInterestedParties";

export type SidebarTypes =
    | "Home"
    | "Project Details"
    | "Filter"
    | "Reporting"
    | "Welcome"
    | "Close"
    | "Reports"
    | "LogOff"
    | "PrettyProjectPage"
    | "EditProjectPage";

export enum PrettyProjectPageTypes {
    BmpGeneral = "BmpGeneral",
    LowFlowDiversion = "LowFlowDiversion",
    SpreadingGround = "SpreadingGround"
};

export type SidebarManagerAction = {
    type: "open" | "close" | "toggle" | "reset";
    payload?: { name: SidebarTypes; content: any };
};

export type SidebarManagerState = {
    current: { name: SidebarTypes; content: any };
    previous: { name: SidebarTypes; content: any };
};

export type DisplaySize = "desktop" | "mobile";

export interface ReportsInfo {
    jurisdictionId: number;
    reportingPage: ReportingPageType;
}

export interface IGlobalAuthInfo {
    roles: Array<string>;
    jurisdictionList: Array<Jurisdiction>;
    watershedGroupList: Array<WatershedManagementGroup>;
    jurisdictionOwnerList: Array<Jurisdiction>;
    watershedGroupOwnerList: Array<WatershedManagementGroup>;
    visibleComponents: Array<string>;
}
export class GlobalAuthInfo implements IGlobalAuthInfo {
    roles: Array<string>;
    jurisdictionList: Array<Jurisdiction>;
    watershedGroupList: Array<WatershedManagementGroup>;
    jurisdictionOwnerList: Array<Jurisdiction>;
    watershedGroupOwnerList: Array<WatershedManagementGroup>;
    visibleComponents: Array<string>;
}

export const SaveHelperContext = React.createContext({
    hasChanged: false,
    setHasChangedTrue: () => { },
    setHasChangedFalse: () => { },
});

function authReducer(state, action) {
    switch (action.type) {
        case 'addJurisdictionList':
            return { ...state, jurisdictionList: action.data };
        case 'addWatershedGroupList':
            return { ...state, watershedGroupList: action.data };
        case 'addVisibleComponents':
            return { ...state, visibleComponents: action.data };
        case 'addUserRoles':
            return { ...state, roles: action.data };
        case 'addJurisdictionOwnerList':
            return { ...state, jurisdictionOwnerList: action.data };
        case 'addWatershedGroupOwnerList':
            return { ...state, watershedGroupOwnerList: action.data };
        default:
            throw new Error();
    }
}

export const BmpListFilteredContext = React.createContext<Array<MapData>>([]);

export const GlobalAuthInfoContext = React.createContext(new GlobalAuthInfo());

export const MainWrapper: any = (props: any) => {
    const auth = useAuth();

    const [reportPageToShow, setReportPageToShow] = useState<ReportingPageType>("none");
    const [sidebarProjectInfoObj, setSidebarProjectInfoObj] = useState<BmpGeneral>(null);
    const [refreshTable, setRefreshTable] = useState<boolean>(false);
    const [screenSize, setScreenSize] = useState<number>();
    const [screenDisplayType, setScreenDisplayType] = useState<DisplaySize>("desktop");
    const [visible, setVisible] = React.useState(false);
    const [sidebarStore, setSidebarStore] = React.useState<SidebarManagerState>({
        current: { name: "Reports" as SidebarTypes, content: null }, previous: { name: "Welcome" as SidebarTypes, content: null },
    });
    const [bmpListFiltered, setBmpListFiltered] = useState<Array<MapData>>(new Array<MapData>());
    const [filterParameters, setFilterParameters] = useState(filterConfig());
    const [bmpListAll, setBmpListAll] = useState<Array<MapData>>(new Array<MapData>());
    const [fetchBmpListAll, setFetchBmpListAll] = useState<boolean>(false); // turned the fetching off so we can fetch it lower down based on dropdown selections
    const [lookups, setLookups] = useState<LookupFactory>(null);
    const [fetchingLookups, setFetchingLookups] = useState<boolean>(true);

    //Fetch JurisdictionUser
    const [jurisdictionUserList, setJurisdictionUserList] = useState<JurisdictionUser[]>(new Array<JurisdictionUser>());
    const [fetchingJurisdictionUser, setFetchingJurisdictionUser] = useState<boolean>(false);

    // fetch WatershedGroupUser
    const [watershedGroupUserList, setWatershedGroupUserList] = useState<WatershedGroupUser[]>(new Array<WatershedGroupUser>());
    const [fetchingWatershedGroupUser, setFetchingWatershedGroupUser] = useState<boolean>(false);

    const [jurisdictionOwners, setJurisdictionOwners] = useState<JurisdictionOwner[]>(new Array<JurisdictionOwner>());
    const [fetchingJurisdictionOwners, setFetchingJurisdictionOwners] = useState<boolean>(false);

    const [groupOwners, setGroupOwners] = useState<WatershedManagementGroupOwner[]>(new Array<WatershedManagementGroupOwner>());
    const [fetchingGroupOwners, setFetchingGroupOwners] = useState<boolean>(false);
    const [tabState, setTabState] = useState<string>("welcomePage");

    const [globalAuthInfo, dispatchGlobalAuthInfo] = useReducer(authReducer, new GlobalAuthInfo());

    useEffect(() => {
        if (fetchingJurisdictionOwners) {
            getAxiosApiBase(auth.user?.access_token)
                .get<JurisdictionOwner[]>("owners/jurisdiction/getByUserId/" + auth.user?.profile.sub)
                .then((response: AxiosResponse<JurisdictionOwner[]>) => {
                    setJurisdictionOwners(response.data);
                });
        }
    }, [fetchingJurisdictionOwners, auth]);

    useEffect(() => {
        if (fetchingGroupOwners) {
            getAxiosApiBase(auth.user?.access_token)
                .get<WatershedManagementGroupOwner[]>("owners/group/getByUserId/" + auth.user?.profile.sub)
                .then((response: AxiosResponse<WatershedManagementGroupOwner[]>) => {
                    setGroupOwners(response.data);
                });
        }
    }, [fetchingGroupOwners, auth]);

    useAPIFetchAllLocal(auth.user?.access_token, new WatershedGroupUser(), fetchingWatershedGroupUser, setFetchingWatershedGroupUser, setWatershedGroupUserList, { 'userID': auth.user ? auth.user.profile.sub : null, "active": 1 }, (data) => {
    });

    useAPIFetchAllLocal(auth.user?.access_token, new JurisdictionUser(), fetchingJurisdictionUser, setFetchingJurisdictionUser, setJurisdictionUserList, { 'userID': auth.user ? auth.user.profile.sub : null, "active": 1 }, (data) => {
    });

    useEffect(() => {
        if (lookups && jurisdictionUserList.length > 0 && auth && auth.user) {
            // if they are admin give them access to all lutJurisdiction
            if (auth.user && (auth.user?.profile.role as string[]).indexOf("admin") > -1) {
                dispatchGlobalAuthInfo({ type: "addJurisdictionList", data: lookups.lutJurisdiction });
            } else {
                let filteredJurisdictionList: Jurisdiction[] = new Array<Jurisdiction>();
                jurisdictionUserList.forEach((jurisUserObj: JurisdictionUser) => {
                    lookups.lutJurisdiction.forEach((jurisdictionObj: Jurisdiction) => {
                        if (jurisUserObj.jurisdictionId === jurisdictionObj.id) {
                            filteredJurisdictionList.push(jurisdictionObj);
                        }
                    })
                })
                dispatchGlobalAuthInfo({ type: "addJurisdictionList", data: filteredJurisdictionList });
            }
        }

    }, [jurisdictionUserList, lookups, auth]);

    useEffect(() => {
        if (!lookups || watershedGroupUserList.length === 0 || !auth || !auth.user) {
            return;
        }

        if ((auth.user?.profile.role as string[]).indexOf("admin") > -1) {
            dispatchGlobalAuthInfo({ type: "addWatershedGroupList", data: lookups.lutWatershedManagementGroup });
        } else {
            const filteredGroupList = watershedGroupUserList
                .map(gu => lookups.lutWatershedManagementGroup.find(wmg => wmg.id === gu.watershedGroupId))
                .filter(g => g != null);
            dispatchGlobalAuthInfo({ type: "addWatershedGroupList", data: filteredGroupList });
        }
    }, [watershedGroupUserList, lookups, auth]);

    useEffect(() => {
        if (lookups && watershedGroupUserList.length > 0 && auth && auth.user) {
            // if they are admin give them access to all lutWatershedManagementGroup
            if (auth.user && (auth.user?.profile.role as string[]).indexOf("admin") > -1) {
                dispatchGlobalAuthInfo({ type: "addWatershedGroupList", data: lookups.lutWatershedManagementGroup });
            } else {
                //defines a new variable to hold the filtered list
                let filteredWatershedGroupList: WatershedManagementGroup[] = new Array<WatershedManagementGroup>();
                //maps through the watershedGroupUserList (list of watersheds associated with user) in state
                watershedGroupUserList.forEach((wsUserObj: WatershedGroupUser) => {
                    //maps through the whole list of watershed groups
                    lookups.lutWatershedManagementGroup.forEach((watershedGroupObj: WatershedManagementGroup) => {
                        //checks each item in list of watershed groups to see which ones match the ids in the watershedGroupUserList (list of watersheds associated with user)
                        if (wsUserObj.watershedGroupId === watershedGroupObj.id) {
                            //the ones that match get saved to the new array defined above
                            filteredWatershedGroupList.push(watershedGroupObj);
                        }
                    })
                })
                //the list of all matching objs gets saved in state
                dispatchGlobalAuthInfo({ type: "addWatershedGroupList", data: filteredWatershedGroupList });
            }
        }

    }, [watershedGroupUserList, lookups, auth]);

    useEffect(() => {
        if (auth.user) {
            setFetchingWatershedGroupUser(true);
            setFetchingJurisdictionUser(true);
            setFetchingJurisdictionOwners(true);
            setFetchingGroupOwners(true);
        }
    }, [auth])

    useEffect(() => {
        if (auth.user && lookups) {
            if ((auth.user?.profile.role as string[]).indexOf("admin") > -1) {
                dispatchGlobalAuthInfo({ type: "addJurisdictionOwnerList", data: lookups.lutJurisdiction });
                dispatchGlobalAuthInfo({ type: "addWatershedGroupOwnerList", data: lookups.lutWatershedManagementGroup });
            } else {
                let filteredJuisList: Array<Jurisdiction> = [];
                if (jurisdictionOwners.length > 0) {
                    lookups.lutJurisdiction.forEach((juris: Jurisdiction) => {
                        jurisdictionOwners.forEach((owner: JurisdictionOwner) => {
                            if (juris.id === owner.jurisdictionId) {
                                filteredJuisList.push(juris);
                            }

                        })
                    })
                }

                let filteredWGList: Array<WatershedManagementGroup> = [];
                if (groupOwners.length > 0) {
                    lookups.lutWatershedManagementGroup.forEach((wg: WatershedManagementGroup) => {
                        groupOwners.forEach((owner: WatershedManagementGroupOwner) => {
                            if (wg.id === owner.watershedManagementGroupId) {
                                filteredWGList.push(wg);
                            }

                        })
                    })
                }
                dispatchGlobalAuthInfo({ type: "addJurisdictionOwnerList", data: filteredJuisList });
                dispatchGlobalAuthInfo({ type: "addWatershedGroupOwnerList", data: filteredWGList });
            }
        }
    }, [auth, jurisdictionOwners, groupOwners, lookups])

    useEffect(() => {
        if (auth.user) {

            dispatchGlobalAuthInfo({ type: "addUserRoles", data: auth.user?.profile.role });

            let visibleComponents: Set<string> = new Set<string>();
            if ((auth.user?.profile.role as string[]).indexOf("admin") > -1) {
                ["manageProjectData", "buildWatershedFormJuris", "buildWatershedFormGroupLead", "buildAnnReportForm", "configureMilestones", "configureProjAssumptions", "configurePublicDash", "userManagement", "userManagementInterestedParties"].forEach((item) => visibleComponents.add(item));
            } else {
                if (globalAuthInfo.watershedGroupList?.length > 0) {
                    ["buildWatershedFormGroupLead", "configureMilestones", "configurePublicDash", "userManagementInterestedParties"].forEach((item) => visibleComponents.add(item));
                }

                if (globalAuthInfo.jurisdictionList?.length > 0) {
                    ["manageProjectData", "buildWatershedFormJuris", "buildAnnReportForm", "configureProjAssumptions", "userManagementInterestedParties"].forEach((item) => visibleComponents.add(item));
                }
            }

            let finalList = Array.from(visibleComponents.values());

            if (finalList.indexOf("manageProjectData") > -1) {
                setReportPageToShow("manageProjectData");

            } else if (finalList.indexOf("buildWatershedFormJuris") > -1 || finalList.indexOf("buildWatershedFormGroupLead") > -1) {
                setReportPageToShow("buildWatershedForm");

            } else {
                setReportPageToShow("alertMessagePage");
            }

            dispatchGlobalAuthInfo({ type: "addVisibleComponents", data: finalList });

        } else {
            setReportPageToShow("alertMessagePage");

            dispatchGlobalAuthInfo({ type: "addVisibleComponents", data: [] });

            dispatchGlobalAuthInfo({ type: "addUserRoles", data: [] });
        }

    }, [auth, globalAuthInfo.watershedGroupList, globalAuthInfo.jurisdictionList]);

    useLookupTablesLocal(auth.user?.access_token, setFetchingLookups, fetchingLookups, setLookups);

    let dashboardQueryBase: DashboardQueryBase = new DashboardQueryBase();

    useEffect(() => {
        //on change of filters

        let sqlQuery: string =
            dashboardQueryBase.buildNewExpression(filterParameters);
        let filteredData = new Array<MapData>();

        // check to make sure the sql query has condistions before running. Maybe refactor so this function does not get called at all if no params are set.
        if (sqlQuery !== "SELECT * FROM ? WHERE ") {
            filteredData = alasql(sqlQuery, [bmpListAll]);
        } else {
            filteredData = alasql("SELECT * FROM ? WHERE 1 = 1", [bmpListAll]);
        }
        setBmpListFiltered(filteredData);
    }, [filterParameters]);

    useAPIFetchAllLocal(
        auth.user?.access_token,
        new MapData(),
        fetchBmpListAll,
        setFetchBmpListAll,
        setBmpListAll,
        {},
        (data) => {
            setBmpListFiltered(data);
        }
    );

    const [hasChanged, setHasChanged] = useState(false);
    const saveHelper = useContext(SaveHelperContext);

    const setHasChangedTrue = () => {
        setHasChanged(true);
    };

    const setHasChangedFalse = () => {
        setHasChanged(false);
    };

    useEffect(() => {
        if (!hasChanged) {
            saveHelper.setHasChangedFalse();
        }
    }, [hasChanged]);

    const handleResize = () => {
        setScreenSize(window.innerWidth);
        if (window.innerWidth <= 768) {
            setScreenDisplayType("mobile");
        } else {
            setScreenDisplayType("desktop");
        }
    };

    useEffect(() => {
        handleResize();
    }, []);

    window.addEventListener("resize", handleResize);

    const sidebarReducer = (
        state: SidebarManagerState,
        action: SidebarManagerAction
    ) => {
        switch (action.type) {
            case "open":
                return {
                    current: action.payload,
                    previous:
                        action.payload.name === state.current.name
                            ? state.previous
                            : state.current,
                };
            case "close":
                return {
                    current: state.previous,
                    previous: { name: "Home" as SidebarTypes, content: null },
                };
            case "toggle":
                return {
                    current:
                        state.current.name === action.payload.name
                            ? state.previous
                            : action.payload,
                    previous:
                        state.current.name === action.payload.name
                            ? { name: "Home" as SidebarTypes, content: null }
                            : state.current,
                };
            case "reset":
                return {
                    current: { name: "Home" as SidebarTypes, content: null },
                    previous: { name: "Home" as SidebarTypes, content: null },
                };
            default:
                throw new Error();
        }
    };

    const dispatchSidebar = useCallback((action: SidebarManagerAction) => {
        setSidebarStore(sidebarReducer(sidebarStore, action));
    }, [sidebarStore]);

    return (
        <div className="main-wrapper">
            {lookups === null && <Dimmer inverted active={lookups === null}>
                <Loader active={lookups === null} >WRAMPS2 Loading... </Loader>
            </Dimmer>}
            <ToastContainer containerId="main-wrapper-toast-container" position="top-center" />
            {lookups != null && <Translation>
                {(t) => (
                    <SaveHelperContext.Provider
                    value={{ hasChanged, setHasChangedTrue, setHasChangedFalse }}
                    >
                        <BmpListFilteredContext.Provider value={bmpListFiltered}>
                            <GlobalAuthInfoContext.Provider value={globalAuthInfo}>

                                <Navbar
                                    {...props}
                                    screenSize={screenSize}
                                    screenDisplayType={screenDisplayType}
                                    setTabState={setTabState}
                                    tabState={tabState}
                                    />
                                <div className="main-wrapper-container">
                                    {(auth && auth.user) && window.location.href !== Config.appUrl + "capture" &&  window.location.href !== Config.appUrl + "welcome" && window.location.href !== Config.appUrl + "help" && (
                                        <div className="controls-container">
                                            <div
                                                className="control-div"
                                                onClick={() =>
                                                    dispatchSidebar({
                                                        type: "open",
                                                        payload: { name: "Close", content: null },
                                                    })
                                                }
                                                title={t("leftNavClose")}
                                                >
                                                <Icon
                                                    className={`control-icon ${sidebarStore.current.name === "Close"
                                                    ? `active-controls`
                                                    : ""}`}
                                                    name="close"
                                                />
                                            </div>
                                            {window.location.href !== Config.appUrl + "reports" && (
                                                <>
                                                    <div
                                                        className="control-div"
                                                        onClick={() =>
                                                            dispatchSidebar({
                                                                type: "open",
                                                                payload: { name: "Welcome", content: null },
                                                            })
                                                        }
                                                        title={t("leftNaveHome")}
                                                        >
                                                        <Icon
                                                            className="control-icon"
                                                            name="home"
                                                            style={{
                                                                color:
                                                                sidebarStore.current.name === "Welcome"
                                                                ? "#64c2de"
                                                                : "",
                                                            }}
                                                            />
                                                    </div>
                                                    <div
                                                        className="control-div"
                                                        onClick={() =>
                                                            dispatchSidebar({
                                                                type: "open",
                                                                payload: { name: "Filter", content: null },
                                                            })
                                                        }
                                                        title={t("leftNavFilter")}
                                                        >
                                                        <Icon
                                                            className="control-icon"
                                                            name="filter"
                                                            style={{
                                                                color:
                                                                sidebarStore.current.name === "Filter"
                                                                ? "#64c2de"
                                                                : "",
                                                            }}
                                                            />
                                                    </div>
                                                </>
                                            )}
                                            {window.location.href === Config.appUrl + "reports" && (
                                                <div
                                                className="control-div"
                                                onClick={() => {
                                                    if (sidebarStore.current.name === "Reports") {
                                                        dispatchSidebar({
                                                            type: "open",
                                                            payload: { name: "Close", content: null },
                                                        })
                                                    } else {
                                                        dispatchSidebar({
                                                            type: "open",
                                                            payload: { name: "Reports", content: null },
                                                        })
                                                    }
                                                }
                                            }
                                                    title={t("leftNaveHome")}
                                                >
                                                    <Icon
                                                        // no matter what I put inside "" string below, it always evaluates to true
                                                        className={`control-icon ${sidebarStore.current.name === "Reports"
                                                        ? `active-controls`
                                                        : ""}`}
                                                        name="arrow right"
                                                        />
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    <Sidebar.Pushable
                                        as={Segment}
                                        className={"sidebar-pushable"}
                                        style={{
                                            minWidth:
                                            (window.location.href === Config.appUrl + "welcome") || (window.location.href === Config.appUrl + "help")
                                            ? "100vw"
                                            : "calc(100vw - 2.1em)"
                                        }}
                                        >
                                        <SidebarConfig
                                            sidebarStore={sidebarStore}
                                            screenSize={screenSize}
                                            screenDisplayType={screenDisplayType}
                                            dispatchSidebar={dispatchSidebar}
                                            globalSearchParams={filterParameters}
                                            setGlobalSearchParams={setFilterParameters}
                                            reportPageToShow={reportPageToShow}
                                            setReportPageToShow={setReportPageToShow}
                                            sidebarProjectInfoObj={sidebarProjectInfoObj}
                                            setSidebarProjectInfoObj={setSidebarProjectInfoObj}
                                            lookups={lookups}
                                            setRefreshTable={setRefreshTable}
                                            />

                                        <Sidebar.Pusher>
                                            <Routes>
                                                <Route
                                                    path={Config.routeUrl}
                                                    element={
                                                        <>
                                                            {(auth && auth.user) && <Navigate replace to="/reports" />}
                                                            {!(auth && auth.user) && <Navigate replace to="/welcome" />}
                                                        </>
                                                    }
                                                    key='1'
                                                    />
                                                <Route
                                                    path={Config.routeUrl + "reports"}
                                                    element={
                                                        <LandingPage
                                                        dispatchSidebar={dispatchSidebar}
                                                            visible={visible}
                                                            setVisible={setVisible}
                                                            reportPageToShow={reportPageToShow}
                                                            setReportPageToShow={setReportPageToShow}
                                                            sidebarProjectInfoObj={sidebarProjectInfoObj}
                                                            setSidebarProjectInfoObj={setSidebarProjectInfoObj}
                                                            lookups={lookups}
                                                            refreshTable={refreshTable}
                                                            setRefreshTable={setRefreshTable}
                                                        />
                                                    }
                                                    key='3'
                                                />
                                                <Route path={Config.routeUrl + "userManagement"} element={<UserManagement lookups={lookups} {...props} />} key='5' />
                                                <Route
                                                    index
                                                    path={Config.routeUrl + "welcome"}
                                                    element={
                                                        <WelcomePage
                                                        {...props}
                                                        dispatchSidebar={dispatchSidebar}
                                                        visible={visible}
                                                        setVisible={setVisible}
                                                        lookups={lookups}
                                                        sidebarProjectInfoObj={sidebarProjectInfoObj}
                                                        setSidebarProjectInfoObj={setSidebarProjectInfoObj}
                                                        tabState={tabState}
                                                        setTabState={setTabState}
                                                        />
                                                    }
                                                    key='4'
                                                    />
                                                <Route path={Config.routeUrl + "help"} element={<HelpPage lookups={lookups} {...props} />} key='6' />
                                                <Route path={Config.routeUrl + "logoff"} element={<LogOffLoader />} key='7' />
                                                <Route path={Config.routeUrl + "capture"} element={<CaptureDashboardContainer lookups={lookups} />} key='9' />x
                                                {/* // 404s will redirect */}
                                                <Route
                                                    path={Config.routeUrl + "*"}
                                                    element={<>{(auth && auth.user) && <Navigate replace to="/reports" />} {!(auth && auth.user) && <Navigate replace to="/welcome" />} </>}
                                                    key='8'
                                                    />
                                            </Routes>
                                        </Sidebar.Pusher>
                                    </Sidebar.Pushable>
                                </div>
                            </GlobalAuthInfoContext.Provider>
                        </BmpListFilteredContext.Provider>
                    </SaveHelperContext.Provider>
                )}
            </Translation>}
        </div>
    );
};
