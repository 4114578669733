import BmpGeneral from "./BmpGeneral";
import BmpGeneralAttributes from "./BmpGeneralAttributes";
import BmpGeneralCobenefits from "./BmpGeneralCobenefits";
import BmpGeneralFinancial from "./BmpGeneralFinancial";
import BmpGeneralGisData from "./BmpGeneralGisData";
import BmpGeneralLocation from "./BmpGeneralLocation";
import BmpGeneralModelRequest from "./BmpGeneralModelRequest";
import BmpGeneralModelResult from "./BmpGeneralModelResult";
import Jurisdiction from "./Jurisdiction";
import LutBmpClass from "./lutModels/LutBmpClass";
import LutBmpStatus from "./lutModels/LutBmpStatus";
import LutBmpType from "./lutModels/LutBmpType";
import ReceivingWaters from "./ReceivingWaters";
import WatershedManagementGroup from "./WatershedManagementGroup";

export class WatershedReportSnapshot {
    id: number;
    watershedManagementGroupId: number;
    snapshotDate: Date;
    active: boolean;
    watershedManagementGroup: WatershedManagementGroup;
    watershedReportBmpSnapshots: WatershedReportSnapshotBmp[];
}

export class WatershedReportSnapshotBmp {
    bmpGeneralId: number;
    watershedReportSnapshotId: number;
    name: string;
    lutBmpClassId: number;
    lutBmpTypeId: number;
    lutBmpStatusId: number;
    lutBmpStatusTypeId: number;
    uploaderJurisdictionId: number;
    receivingWatersId: number;
    latitude: number | null;
    longitude: number | null;
    bmpCompletionDate: Date | null;
    drainageAreaAc: number | null;
    actualStorageCapacityAcft: number | null;
    footprintAc: number | null;
    totalCapture: number | null;
    drainagePercentImperviousPercent: number | null;
    dailyStorageCapacityAcft: number | null;
    projectCapitalCost: number | null;
    recentCapture: number | null;
    recentCaptureReportingPeriodId: number | null;

    coBenefitCleanStreets: number | null;
    coBenefitParks: number | null;
    coBenefitHeatIsland: number | null;
    coBenefitFlooding: number | null;
    coBenefitWaterSupplyAugmentation: number | null;
    coBenefitNeighborhood: number | null;
    coBenefitJobCreation: number | null;
    coBenefitDisadvantaged: number | null;
    lutBmpClass: LutBmpClass;
    lutBmpType: LutBmpType;
    lutBmpStatus: LutBmpStatus;
    uploaderJurisdiction: Jurisdiction;
    receivingWaters: ReceivingWaters;
}

export function SnapshotToBmpGeneral(snap: WatershedReportSnapshotBmp, watershedGroupId: number): BmpGeneral {
    var bmp = new BmpGeneral();
    bmp.id = snap.bmpGeneralId;
    bmp.active = 1;
    bmp.name = snap.name;
    bmp.bmpClassId = snap.lutBmpClassId;
    bmp["bmpClass"] = snap.lutBmpClass;
    bmp.bmpTypeId = snap.lutBmpTypeId;
    bmp["bmpType"] = snap.lutBmpType;
    bmp.bmpStatusId = snap.lutBmpStatusId;
    bmp["bmpStatus"] = snap.lutBmpStatus;
    bmp.uploaderJurisdictionId = snap.uploaderJurisdictionId;
    bmp["uploaderJurisdiction"] = snap.uploaderJurisdiction;
    bmp.bmpCompletionDate = snap.bmpCompletionDate;

    var gis = new BmpGeneralGisData();
    gis.receivingWatersId = snap.receivingWatersId;
    gis["receivingWaters"] = snap.receivingWaters
    gis.watershedManagementGroupId = watershedGroupId;
    gis.jurisdictionId = snap.uploaderJurisdictionId;
    bmp.bmpGeneralGisData = gis;

    var loc = new BmpGeneralLocation();
    loc.latitude = snap.latitude;
    loc.longitude = snap.longitude;
    bmp.bmpGeneralLocation = loc;

    var fin = new BmpGeneralFinancial();
    fin.projectCapitalCost = snap.projectCapitalCost;
    bmp.bmpGeneralFinancial = fin;

    var attr = new BmpGeneralAttributes();
    attr.drainageAreaAc = snap.drainageAreaAc;
    attr.actualStorageCapacityAcft = snap.actualStorageCapacityAcft;
    attr.footprintAc = snap.footprintAc;
    attr.drainagePercentImperviousPercent = snap.drainagePercentImperviousPercent;
    attr.dailyStorageCapacityAcft = snap.dailyStorageCapacityAcft;
    bmp.bmpGeneralAttributes = attr;

    var cob = new BmpGeneralCobenefits();
    cob.coBenefitCleanStreets = snap.coBenefitCleanStreets;
    cob.coBenefitDisadvantaged = snap.coBenefitDisadvantaged;
    cob.coBenefitFlooding = snap.coBenefitFlooding;
    cob.coBenefitHeatIsland = snap.coBenefitHeatIsland;
    cob.coBenefitJobCreation = snap.coBenefitJobCreation;
    cob.coBenefitNeighborhood = snap.coBenefitNeighborhood;
    cob.coBenefitParks = snap.coBenefitParks;
    cob.coBenefitWaterSupplyAugmentation = snap.coBenefitWaterSupplyAugmentation
    bmp["bmpGeneralCobenefits"] = cob;

    var res = new BmpGeneralModelResult();
    res.active = 1;
    res.created = new Date();
    res.reportingPeriodId = 1000;
    res.detainedFlowAcreFeet = 0;
    res.retainedFlowAcreFeet = snap.totalCapture - snap.recentCapture ?? 0;
    var res2 = new BmpGeneralModelResult();
    res2.active = 1;
    res2.created = new Date();
    res2.reportingPeriodId = snap.recentCaptureReportingPeriodId;
    res2.detainedFlowAcreFeet = 0;
    res2.retainedFlowAcreFeet = snap.recentCapture ?? 0;
    var req = new BmpGeneralModelRequest();
    req.active = 1;
    req.bmpGeneralModelResult = [res, res2];
    bmp.bmpGeneralModelRequest = [req];

    return bmp;
}

export function BmpGeneralToSnapshot(bmp: BmpGeneral): WatershedReportSnapshotBmp {
    var snap = new WatershedReportSnapshotBmp();

    snap.bmpGeneralId = bmp.id;
    snap.name = bmp.name!;
    snap.lutBmpClassId = bmp.bmpClassId;
    snap.lutBmpClass = bmp["bmpClass"];
    snap.lutBmpTypeId = bmp.bmpTypeId;
    snap.lutBmpType = bmp["bmpType"];
    snap.lutBmpStatusId = bmp.bmpStatusId;
    snap.lutBmpStatus = bmp["bmpStatus"];
    snap.uploaderJurisdictionId = bmp.uploaderJurisdictionId;
    snap.uploaderJurisdiction = bmp["uploaderJurisdiction"];
    snap.receivingWatersId = bmp.bmpGeneralGisData.receivingWatersId;
    snap.receivingWaters = bmp.bmpGeneralGisData["receivingWaters"];
    snap.latitude = bmp.bmpGeneralLocation.latitude;
    snap.longitude = bmp.bmpGeneralLocation.longitude;
    snap.bmpCompletionDate = bmp.bmpCompletionDate;
    snap.drainageAreaAc = bmp.bmpGeneralAttributes.drainageAreaAc;
    snap.actualStorageCapacityAcft = bmp.bmpGeneralAttributes.actualStorageCapacityAcft;
    snap.footprintAc = bmp.bmpGeneralAttributes.footprintAc;
    snap.drainagePercentImperviousPercent = bmp.bmpGeneralAttributes.drainagePercentImperviousPercent;
    snap.dailyStorageCapacityAcft = bmp.bmpGeneralAttributes.dailyStorageCapacityAcft;
    snap.projectCapitalCost = bmp.bmpGeneralFinancial.projectCapitalCost;
    snap.coBenefitCleanStreets = bmp.bmpGeneralCobenefits.coBenefitCleanStreets;
    snap.coBenefitParks = bmp.bmpGeneralCobenefits.coBenefitParks;
    snap.coBenefitHeatIsland = bmp.bmpGeneralCobenefits.coBenefitHeatIsland;
    snap.coBenefitFlooding = bmp.bmpGeneralCobenefits.coBenefitFlooding;
    snap.coBenefitWaterSupplyAugmentation = bmp.bmpGeneralCobenefits.coBenefitWaterSupplyAugmentation;
    snap.coBenefitNeighborhood = bmp.bmpGeneralCobenefits.coBenefitNeighborhood;
    snap.coBenefitJobCreation = bmp.bmpGeneralCobenefits.coBenefitJobCreation;
    snap.coBenefitDisadvantaged = bmp.bmpGeneralCobenefits.coBenefitDisadvantaged;

    let modelResults: Array<BmpGeneralModelResult> = new Array<BmpGeneralModelResult>();
    bmp.bmpGeneralModelRequest.forEach((request) => {
        if (request.active === 1) {
            request.bmpGeneralModelResult.forEach((result) => {
                if (result.active === 1) {
                    modelResults.push(result);
                }
            })
        }
    })

    let reportingPeriods = groupBy(modelResults, (r) => r.reportingPeriodId);

    var sum = 0;
    reportingPeriods.forEach((value, _) => {
        var latestResult = value
            .sort((a, b) => { return a.created > b.created ? -1 : 1 })[0];

        sum += latestResult?.detainedFlowAcreFeet ?? 0;
        sum += latestResult?.retainedFlowAcreFeet ?? 0;
    });
    snap.totalCapture = sum

    var recentCapture = [...reportingPeriods.entries()].reduce((maxReportingPeriod, e ) => e[1] > maxReportingPeriod[1] ? e : maxReportingPeriod);
    snap.recentCaptureReportingPeriodId = recentCapture[0];
    snap.recentCapture = recentCapture[1]
    .sort((a, b) => { return a.created > b.created ? -1 : 1 })[0].detainedFlowAcreFeet + recentCapture[1].sort((a, b) => { return a.created > b.created ? -1 : 1 })[0].retainedFlowAcreFeet;
    
    return snap;
}

function groupBy<T, V>(list: Array<T>, keyGetter: (t: T) => V): Map<V, T[]> {
    const map = new Map();
    list.forEach((item: T) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}

