import './index.scss';
import 'semantic-ui-css/semantic.min.css'
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n';
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import "@arcgis/core/assets/esri/themes/dark/main.css";

import ReactGA from "react-ga4";

ReactGA.initialize("G-228NB7LZE0");

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
