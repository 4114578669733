import axios, { AxiosRequestConfig } from "axios";
import Config from "../config/Config";
import { CaptureBenefit } from "../models/CaptureBenefit";

export type BmpCaptureViewModel = {
    eventId?: number;
    startDate: string | Date;
    endDate: string | Date;
    captureVolumeAcreFeet: number;
}

export type RainEventViewModel = {
    eventId?: number;
    startDate: string | Date;
    endDate: string | Date;
    rainfallInches: number;
}

export type BmpGeneralViewModel = {
    id: number;
    name: string;
    description: string;
    bmpClassId: number;
    bmpTypeId: number;
    watershedId?: number;
    watershedManagementGroupId?: number;
    jurisdictionId?: number;
    drainageAreaAc?: number;
    actualStorageCapacityAcft?: number;
    footprintAc?: number;
    bmpCompletionDate?: string | Date;
}

export class BmpService {
    protected config: AxiosRequestConfig;

    constructor(authToken?: string) {
        this.config = {
            headers: {
                "Authorization": "Bearer " + authToken
            },
        }
    }


    async getRainfallByBmpId(bmpId: number): Promise<CaptureBenefit[]> {
        return await axios
            .get<RainEventViewModel[]>(Config.api_endpoint + `RainEvent?bmpId=${bmpId}`)
            .then((result) => {
                // map to CaptureBenefit for now until endpoint is changed
                return result.data.map((rainEvent) => ({
                        amount: rainEvent.rainfallInches,
                        eventStart: rainEvent.startDate,
                        eventEnd: rainEvent.endDate
                    } as CaptureBenefit));
            })
            .catch((e) => {
                throw new Error("Error getting bmp rainfall with ID " + bmpId, e);
            });
    }

    async getRainfallByBmpIdOLDDELETEME(bmpId: number): Promise<RainEventViewModel[]> {
        return await axios
            .get<RainEventViewModel[]>(Config.api_endpoint + `RainEvent?bmpId=${bmpId}`)
            .then((result) => {
                // map to CaptureBenefit for now until endpoint is changed
                return result.data;
            })
            .catch((e) => {
                throw new Error("Error getting bmp rainfall with ID " + bmpId, e);
            });
    }

    async getViewModelById(bmpId: number): Promise<BmpGeneralViewModel> {
        return await axios
            .get<BmpGeneralViewModel>(Config.api_endpoint + `Bmp/ViewModel/${bmpId}`)
            .then((result) => {
                return result.data;
            })
            .catch((e) => {
                throw new Error("Error getting bmp view model with ID " + bmpId, e);
            });
    }

    async getBenefitById(bmpId: number): Promise<CaptureBenefit[]> {
        return await axios
            .get<BmpCaptureViewModel[]>(Config.api_endpoint + `Bmp/Capture/${bmpId}`)
            .then((result) => {
                // map to CaptureBenefit for now until endpoint is changed
                return result.data.map((capture) => ({
                        amount: capture.captureVolumeAcreFeet,
                        eventStart: capture.startDate,
                        eventEnd: capture.endDate
                    } as CaptureBenefit));
            })
            .catch((e) => {
                throw new Error("Error getting bmp capture benefit with ID " + bmpId, e);
            });
    }

    async getBenefitByIdOLDDELETEME(bmpId: number): Promise<BmpCaptureViewModel[]> {
        return await axios
            .get<BmpCaptureViewModel[]>(Config.api_endpoint + `Bmp/Capture/${bmpId}`)
            .then((result) => {
                // map to CaptureBenefit for now until endpoint is changed
                return result.data;
            })
            .catch((e) => {
                throw new Error("Error getting bmp capture benefit with ID " + bmpId, e);
            });
    }

    async getWaterYearToDateBenefitById(bmpId: number): Promise<number> {
        // Gets a sum of the capture from October 1 to the current date
        return await axios
            .get<number>(Config.api_endpoint + `Bmp/Capture/YearToDate/${bmpId}`)
            .then((result) => {
                return result.data;
            })
            .catch((e) => {
                throw new Error("Error getting bmp capture benefit ytr with ID " + bmpId, e);
            });
    }
    async getRecentBenefitById(bmpId: number): Promise<CaptureBenefit | null> {
        // Gets a the capture for the most recent rain event
        return await axios
            .get<CaptureBenefit | null>(Config.api_endpoint + `Bmp/Capture/Recent/${bmpId}`)
            .then((result) => {
                return result.data;
            })
            .catch((e) => {
                throw new Error("Error getting bmp recent capture benefit with ID " + bmpId, e);
            });
    }
}
