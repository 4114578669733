import QueryBase from "../GeoJsonQueryBase/QueryBase";

export default class ExpressionBuilder {

    queryBase: QueryBase;
    expression: string = "";

    constructor(queryBase: QueryBase) {
        this.queryBase = queryBase;
    };


    // for strings the condition can be "name LIKE '%yo%'"
    addDrainageAreaRange = (min: number, max: number): void => {
        if (min && max) {
            if (this.expression.length > 0) {
                this.expression += " AND ";
            };

            this.expression += `drainageArea >= ${min} AND drainageArea <= ${max}`;
        };
    };

    addRange = (min: number, max: number, propertyValue: string) => {
        if (min && max)
        {
            if (this.expression.length > 0) {
                this.expression += " AND ";
            };

            this.expression += `${propertyValue} >= ${min} AND ${propertyValue} <= ${max}`;
        };
    };

    // create new function to handle watershed areas. follow the logic in add keywords.
    // function paramater will be the array of strings produced by the watersheds checkbox.

    //this.expression += `WatershedAreas LIKE '%${}%'`


    addWatersheds = (watersheds: string[]): void => {
        // if watersheds array length is 0 don't execute any logic
        // - check if there is more than one watershed in the area
        // - if there is only one, don't loop and add OR wrapper
        
        // safety check for null on watersheds array
            if (watersheds && watersheds.length) {

                if (this.expression.length > 0) {
                    this.expression += " AND ";
                };
                if (watersheds.length === 1) {
                    this.expression += `WatershedConc LIKE '%${watersheds[0]}%'`
                } else if (watersheds.length > 1) {
                    watersheds.forEach((w: string, i: number) => {
                        if (i === 0) {
                            this.expression += "(";
                        };
                        this.expression += `WatershedConc LIKE '%${w}%'`
                        if (i + 1 < watersheds.length) {
                            this.expression += " OR ";
                        } else if (i + 1 === watersheds.length) {
                            this.expression += ")";
                        };
                    });
                };

                // this.expression += `Lower(WatershedConc) IN(${watersheds.map((w, i, a) => `'${w.toLowerCase()}'`)})`
                // this.expression += `LOWER(WatershedConc) LIKE '%${watersheds[0].toLowerCase()}%'`

                // this.expression += `WatershedConc IN(${watersheds.map((w, i, a) => w.toLowerCase() + (i === a.length ? "" : ", ")  )})`
            };

    }

    addKeywords = (keywords: string): void => {
        if (keywords) {
            if (keywords !== "") {
                if (this.expression.length > 0) {
                    this.expression += " AND ";
                };
                let keywordProperties = this.queryBase.getKeywordProperties();
                if (keywordProperties.length === 1) {
                    this.expression += `Lower(${keywordProperties[0]}) LIKE '%${keywords.toLowerCase()}%'`
                } else if (keywordProperties.length > 1) {
                    keywordProperties.forEach((k: string, i: number) => {

                        if (i === 0) {
                            this.expression += "(";
                        };

                        this.expression += `Lower(${k}) LIKE '%${keywords.toLowerCase()}%'`;

                        if (i + 1 < keywordProperties.length) {
                            this.expression += " OR ";
                        } else if (i + 1 === keywordProperties.length) {
                            this.expression += ")";
                        };
                    });
                };
            };
        };
    };

    addPublicPrivateFilter = (isPublic: boolean) => {
        if (isPublic) {
            if (this.expression.length > 0) {
                this.expression += " AND ";
            };
            this.expression += `public = ${isPublic}`;
        };
    };

    addJurisdictionIDs = (ids: string[]) => {
        if (ids) {
            if (ids.length > 0) {
                if (this.expression.length > 0) {
                    this.expression += " AND ";
                };
                if (ids.length === 1) {
                    this.expression += `jurisdictionId = ${ids[0]}`;
                } else if (ids.length > 1) {
                    ids.forEach((id: string, i: number) => {
                        if (i === 0) {
                            this.expression += `jurisdictionId IN (${id},`
                        } else {
                            this.expression += `${id}`
                            if (i < ids.length - 1) {
                                this.expression += ',';
                            } else if (i === ids.length - 1) {
                                this.expression += ')';
                            };
                        };
                    });
                };
            };
        };
    };

    addIDs = (values: any[], property: string) => {
        if (values) {
            if (values.length > 0) {
                if (this.expression.length > 0) {
                    this.expression += " AND ";
                };
                if (values.length === 1) {


                    this.expression += property + " = "

                    if (typeof values[0] === 'string') {
                        this.expression += "'";
                    };

                    this.expression += values[0];

                    if (typeof values[0] === 'string') {
                        this.expression += "'";
                    };


                } else if (values.length > 1) {
                    values.forEach((id: string, i: number) => {


                        if (i === 0) {
                            this.expression += property + " IN ("

                            if (typeof id === 'string') {
                                this.expression += "'";
                            };

                            this.expression += id;

                            if (typeof id === 'string') {
                                this.expression += "'";
                            };

                            this.expression += ",";

                        } else {

                            if (typeof id === 'string') {
                                this.expression += "'";
                            };

                            this.expression += id;

                            if (typeof id === 'string') {
                                this.expression += "'";
                            };

                            if (i < values.length - 1) {
                                this.expression += ',';
                            } else if (i === values.length - 1) {
                                this.expression += ')';
                            };


                        };



                    });
                };
            };
        };
    }

    getQuery = (): string => {
        // this will return completed query
        return this.expression;
    };

    clearExpression = () => {
        this.expression = "";
    };

    addAlaSqlPrefix = () => {
        this.expression = "SELECT * FROM ? WHERE " + this.expression;
    }



};