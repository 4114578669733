import { useContext, useEffect, useState } from "react";
import { Menu, } from "semantic-ui-react";
import { Translation } from "react-i18next";
import { GlobalAuthInfoContext, IGlobalAuthInfo } from "../../mainWrapper/MainWrapper";
import LookupFactory from "../../../lookups/LookupFactory";
import { UserManagement } from "../../admin/UserManagement";
import { ManageInterestedParties } from "./interestedParties/ManageInterestedParties";
import "./UserAndInterestedPartyManager.scss";


export interface IUserAndInterestedPartyManagerProps {
    lookups: LookupFactory;
}

export const UserAndInterestedPartyManager: any = (props: any) => {

    const globalAuthInfoContext = useContext(GlobalAuthInfoContext);

    const [activeItem, setActiveItem] = useState<string>('');

    useEffect(() => {
        // if (authContext.oidcUser.profile.role.indexOf("watershedgroup") > -1 || authContext.oidcUser.profile.role.indexOf("admin") > -1) {
        if (globalAuthInfoContext.visibleComponents.indexOf("userManagement") > 0 || ((globalAuthInfoContext.jurisdictionList &&  globalAuthInfoContext.jurisdictionList.length > 0) || (globalAuthInfoContext.watershedGroupOwnerList &&  globalAuthInfoContext.watershedGroupOwnerList.length > 0))) {
            setActiveItem("userMgmt");
        } else {
            setActiveItem("intPart");

        }
    }, [globalAuthInfoContext])

    const handleItemClick = (e, { name }) => setActiveItem(name);

    return (
        <div className="user-interested-parties">
            <Translation>
                {(t) => (
                    <GlobalAuthInfoContext.Consumer>
                        {
                            (authInfo: IGlobalAuthInfo) => {
                                return (

                                    <>
                                        <div className="content-div">
                                            <div className="page-header">User &amp; Interested Parties Management</div>
                                            <Menu pointing secondary>
                                                {
                                                    // or you are a owner
                                                    (globalAuthInfoContext.visibleComponents.indexOf("userManagement") > 0 || ((globalAuthInfoContext.jurisdictionList &&  globalAuthInfoContext.jurisdictionList.length > 0) || (globalAuthInfoContext.watershedGroupOwnerList &&  globalAuthInfoContext.watershedGroupOwnerList.length > 0))) &&
                                                    <Menu.Item
                                                        name='userMgmt'
                                                        active={activeItem === 'userMgmt'}
                                                        onClick={handleItemClick}
                                                        content="User Management"
                                                    />
                                                }
                                                <Menu.Item
                                                    name='intPart'
                                                    active={activeItem === 'intPart'}
                                                    onClick={handleItemClick}
                                                    content="Interested Parties"
                                                />
                                            </Menu>
                                            {
                                                activeItem === "userMgmt" && (globalAuthInfoContext.visibleComponents.indexOf("userManagement") > 0 || ((globalAuthInfoContext.jurisdictionList &&  globalAuthInfoContext.jurisdictionList.length > 0) || (globalAuthInfoContext.watershedGroupOwnerList &&  globalAuthInfoContext.watershedGroupOwnerList.length > 0))) &&
                                                <UserManagement lookups={props.lookups} />
                                            }
                                            {
                                                activeItem === "intPart" && (globalAuthInfoContext.visibleComponents.indexOf("userManagement") > 0 || ((globalAuthInfoContext.jurisdictionList &&  globalAuthInfoContext.jurisdictionList.length > 0) || (globalAuthInfoContext.watershedGroupOwnerList &&  globalAuthInfoContext.watershedGroupOwnerList.length > 0))) && 
                                                <ManageInterestedParties lookups={props.lookups} />
                                            }
                                        </div>
                                    </>
                                )
                            }
                        }
                    </GlobalAuthInfoContext.Consumer>
                )}
            </Translation>
        </div>
    );
};