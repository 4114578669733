import { useContext, useEffect, useState } from "react";
import { Translation } from "react-i18next";
import { SortableTableComponent } from "../../../paradigmLib/domElements/sortableTable/SortableTableComponent";
import { useAPIFetchAllLocal } from "../../../core/UseAPILocal";
import { IBuildWatershedFormProps } from "./BuildWatershedForm";
import { ButtonComponent } from "../../../paradigmLib/domElements/buttonComponent/ButtonComponent";
import SummaryValue from "../../../models/SummaryValue";
import './BuildWatershedForm.scss';
import { useAuth } from "react-oidc-context";

export const BuildWatershedForm_1_2: any = (props: IBuildWatershedFormProps) => {
    const auth = useAuth();
    const [tableData, setTableData] = useState<SummaryValue[]>([]);
    const [fetchingTableData, setFetchingTableData] = useState<boolean>(true);

    useAPIFetchAllLocal(
        auth.user?.access_token,
        new SummaryValue("report/summary/jurisdiction/1a"),
        fetchingTableData,
        setFetchingTableData,
        setTableData,
        props.selectedParams,
        (data) => {
        });

    const next = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        props.setActiveItem("1_2a");
    }

    useEffect(() => {
        if (!fetchingTableData) {
            setFetchingTableData(true);
        }
    }, [props.selectedParams]);

    return (
        <div className="build-watershed-form-subpage">
            <Translation>
                {
                    t =>
                        <>
                            <div className="subpage-container">
                                <div className="subpage-header-container">
                                    <div className="subpage-header-1">1.2 &nbsp; - &nbsp;Watershed Control Measures Completed</div>
                                    <div className="subpage-header-2">
                                        Complete Table 1a, on an Excel spreadsheet. Include all watershed control measures (aside from minimum control measures specified in Part VIII of the Order) in the Watershed Management Program completed since the effective date of the Order for Ventura County Permittees, since March 28, 2014 for the City of Long Beach, and since December 28, 2012 for other Los Angeles County Permittees. This table is cumulative—i.e., the table should include all the control measures completed from the time of the aforementioned dates to the end of this reporting period. Structural control measures as well as non-structural control measures (e.g., enhanced MCMs such as incentive programs, outreach and conservation programs, etc.) should be included in this table. If information is not available for a particular field, the field should indicate “Not Applicable” (N/A) [Order – IX].
                                    </div>
                                </div>

                                {/* <Header as='h3'>Watershed Control Measures Completed</Header> */}
                                <div className="header-wrapper">
                                    <div className="subpage-header-1">Table 1a - Watershed Control Measures Completed for {props.lookups.getWatershedManagementGroupById(props.selectedParams.watershedGroupId)}

                                        {/* { props.selectedParams.watershedGroupId === null ? ""
                                    : ` for ${props.lookups.getWatershedManagementGroupById(props.selectedParams.watershedGroupId)}`
                                    } */}
                                    </div>
                                </div>

                                {/* { props.selectedParams.watershedGroupId === null ?  */}
                                {/* <div className="description">Please select a watershed group from the dropdown above</div> */}
                                <div className="description">Below is a summary of the Watershed Control measures in Table 1a for the Jurisdiction/Watershed Group/Reporting Period selected in the dropdowns above. A complete Table 1a excel spreadsheet output is available under the 'Reporting Outputs' tab above.</div>
                                {/* } */}

                                {/* <div className="bulk-download">
                                    <ButtonComponent
                                        onBtnClick={() => {}}
                                        label="Generate Table 1a"
                                        theme="primaryBtn"
                                    />
                                </div> */}

                                {
                                    <div style={{ display: "flex", justifyContent: 'center' }}>
                                        <SortableTableComponent
                                            tableData={tableData}
                                            columnArr={["name", "value", "unit"]}
                                            headerNames={["Watershed Control Measure Metric", "Value", "Unit"]}
                                            headerBackgroundColor={"blue"}
                                            headerTextColor={"white"} />
                                    </div>
                                }

                                <div className="save-btn-div">
                                    <ButtonComponent
                                        onBtnClick={() => next()}
                                        label="Next"
                                        theme="primaryBtn"
                                    />
                                </div>

                            </div>
                        </>
                }
            </Translation>
        </div>
    )
}
