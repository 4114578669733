import { ComputedDatum, ResponsiveSunburst, SunburstCustomLayerProps } from '@nivo/sunburst';
import { useCallback, useEffect, useMemo, useState } from 'react';
import LookupFactory from '../../../../lookups/LookupFactory';
import { WatershedReportPublicFacingConfig } from '../../../../models/viewModels/WatershedReportPublicFacingConfigViewModel';
import { WatershedReportSnapshotBmp } from '../../../../models/WatershedReportSnapshot';
import { useTheme } from '@nivo/core';
import { MetricOption } from './ProgramProgressGraph';
import { animated } from '@react-spring/web'
import { customFormat } from '../../../../core/NumberFormat';
import { BmpClassId } from '../../../../models/lutModels/LutBmpClass';

export interface IProgramSummarySunburstGraphProps {
    wmgId: number;
    bmpData: Array<WatershedReportSnapshotBmp>;
    publicConfig: WatershedReportPublicFacingConfig;
    graphHeight: number | string;
    lookups: LookupFactory;
    selectedMetric: MetricOption;
    setLegend: (data: GraphDatum[]) => void
    setShowLegend: Function;
    drilldownText: string;
    setDrilldownText: (text: string | undefined) => void
}

export interface GraphDatum {
    id: string; //concatenation of upper tiers so that they are unique
    value?: number;
    color?: string;
    displayName: string;
    concatenatedDisplayName: string;
    tier: number;
    children?: GraphDatum[];
    unit?: string;
}

interface GraphTier {
    name: string;
    displayName: string;
    propertyName: keyof WatershedReportSnapshotBmp;
    lookupName: string;
    lookupSecondProperty?: string;
}

const graphTiers: GraphTier[] = [
    { name: 'subwatershed', displayName: 'Subwatershed', propertyName: 'receivingWatersId', lookupName: 'lutReceivingWaters' },
    { name: 'status', displayName: 'Status', propertyName: 'lutBmpStatusTypeId', lookupName: 'lutBmpStatusType' },
    { name: 'class', displayName: 'Class', propertyName: 'lutBmpClassId', lookupName: 'lutBmpClass' }
];
const flattenRecursive = ({ id, children = [] }: GraphDatum): string[] => [id, ...children.flatMap(flattenRecursive)];

const customPalette = ['#88CCEE', '#44AA99', '#117733', '#332288', '#DDCC77', '#CC6677', '#AA4499', '#882255',
    '#CC79A7', '#D55E00', '#0072B2', '#F0E442', '#009E73', '#56B4E9', '#E69F00', '#648FFF', '#785EF0', '#DC267F', '#FE6100', '#FFB000']

const ARC_LABEL_ANGLE = 40;

export const ProgramSummarySunburstGraph = (props: IProgramSummarySunburstGraphProps) => {
    const [drilldownNode, setDrilldownNode] = useState<GraphDatum>({ id: "chartRoot", tier: 0, displayName: "", concatenatedDisplayName: "", children: [] });

    // Used by labels to see if they should fade out or not
    const drilldownIds: string[] = useMemo(() => {
        return drilldownNode.children!.flatMap(flattenRecursive)
    }, [drilldownNode])

    const generateDataRecursive = useCallback((tree: GraphDatum, bmp: WatershedReportSnapshotBmp, tierNumber: number) => {
        if (tierNumber === graphTiers.length) { return }
        let groupDisplayName: string = "";
        if (graphTiers[tierNumber].name === "class" && bmp.lutBmpClassId === BmpClassId.WatershedControlMeasure) {
            groupDisplayName = "WCM";
        }
        else if (graphTiers[tierNumber].name === "class" && bmp.lutBmpClassId === BmpClassId.NewAndRedevelopment) {
            groupDisplayName = "N & R";
        }
        else {
            groupDisplayName = (props.lookups[graphTiers[tierNumber].lookupName] as Array<any>).find(x => x.id === bmp[graphTiers[tierNumber].propertyName])!.displayName;
        }
        let concatenatedId: string = tree.id + bmp[graphTiers[tierNumber].propertyName] + props.selectedMetric.internalName;
        let group: GraphDatum | undefined = tree.children!.find(g => g.id === concatenatedId);

        if (!group) {
            group = { id: concatenatedId, children: [], value: 0, unit: props.selectedMetric.unit, tier: tree.tier + 1, displayName: groupDisplayName, concatenatedDisplayName: tree.displayName === "" ? groupDisplayName : `${tree.displayName} > ${groupDisplayName}` }
            tree.children!.push(group);
        }
        if (tierNumber === graphTiers.length - 1) {
            // Add up values for the last nodes in the tree
            group.value! += props.selectedMetric.internalName === 'count' ? 1 : bmp[props.selectedMetric.propertyName] as number;
            group.children = undefined;
        }

        // Set Colors
        if (tierNumber === 0) {
            group.color = customPalette[tree.children!.indexOf(group)]
        }
        else {
            group.color = increaseColorBrightness(tree.color!, 15)
        }

        generateDataRecursive(group, bmp, tierNumber + 1);
    }, [props.lookups, props.selectedMetric])

    const rootNode: GraphDatum = useMemo(() => {
        let groups = { id: "chartRoot", tier: 0, displayName: "", concatenatedDisplayName: "", value: 0, unit: props.selectedMetric.unit, children: [] };
        if (props.bmpData.length === 0) return groups;

        props.bmpData.filter(x => x.lutBmpClassId === BmpClassId.WatershedControlMeasure || x.lutBmpClassId === BmpClassId.NewAndRedevelopment).forEach(bmp => { // Dont include Non-structurals since they don't have receiving waters
            generateDataRecursive(groups, bmp, 0);
        });
        setDrilldownNode(groups)

        return groups;
    }, [props.bmpData, generateDataRecursive])

    useEffect(() => {
        props.setLegend(rootNode.children)
    }, [props, rootNode])

    const handleDrilldown = useCallback((clickedNode: ComputedDatum<GraphDatum>) => {
        if (drilldownNode.tier === graphTiers.length - 1) return;

        let foundObject = drilldownNode.children!.find(x => x.id === clickedNode.id);
        if (foundObject && foundObject.children?.length !== 0) {
            setDrilldownNode(foundObject)
        }
    }, [drilldownNode]);

    useEffect(() => {
        if (drilldownNode.tier === 0) {
            props.setDrilldownText(undefined);
        }
        else {
            props.setDrilldownText(drilldownNode.concatenatedDisplayName);
        }
    }, [drilldownNode, props.setDrilldownText])

    const hideLegend = () => {
        props.setShowLegend(false);
    }

    const MiddleTotal = useCallback((props: SunburstCustomLayerProps<GraphDatum>) => {
        if (sumChildren(drilldownNode) === 0) {
            hideLegend(); // had to create separate function because setShowLegend is not part of this function's props
            return (
                <text
                    x={props.centerX}
                    y={props.centerY}
                    textAnchor="middle"
                    fill="rgb(21, 136, 198)"
                    dominantBaseline="central"
                    style={{ fontSize: '20px', fontWeight: 600 }}
                >No Data</text>
            )
        } else {
            return (
                <text
                    x={props.centerX}
                    y={props.centerY}
                    textAnchor="middle"
                    fill="rgb(21, 136, 198)"
                    dominantBaseline="central"
                    style={{ fontSize: '20px', fontWeight: 600 }}
                >{`${customFormat(sumChildren(drilldownNode))} ${drilldownNode.unit}`}</text>
            )
        }
    }, [drilldownNode])

    const ResetButton = useCallback((props: SunburstCustomLayerProps<GraphDatum>) => {
        return (
            <>
                {drilldownNode.tier > 0 && // show nothing if not drilled down
                    <text
                        x={props.centerX - props.radius}
                        y={props.centerY - props.radius}
                        textAnchor="left"
                        dominantBaseline="central"
                        fill="rgb(21, 136, 198)"
                        style={{ fontSize: '20px', cursor: 'pointer' }}
                        onClick={() => { setDrilldownNode(rootNode); }}
                    >Reset</text>
                }
            </>
        )
    }, [drilldownNode, rootNode])

    const CustomTooltip = (props: ComputedDatum<GraphDatum>): JSX.Element => {
        const theme = useTheme()
        return (
            <strong style={{ ...theme.tooltip.container }}>
                {/* Values in inner rings have to be 0 so add up their children's values to display here */}
                {`${props.data.displayName}: ${props.data.children ? props.data.children && customFormat(sumChildren(props.data)) : customFormat(props.data.value!)
                    } ${props.data.unit}`}
            </strong>
        )
    }

    return (
        <div className="program-summary-sunburst-graph"
            style={{ height: `calc(${props?.graphHeight} - 10.5rem` }}
        >
            <div className="chart-name">{props?.drilldownText}</div>
            <ResponsiveSunburst<GraphDatum>
                layers={['arcs', 'arcLabels', ResetButton, MiddleTotal]}
                margin={{ top: 10, right: 20, bottom: 30, left: 30 }}
                data={drilldownNode}
                id='id'
                value='value'
                childColor={(_, child) => {
                    return child.data.color!
                }}
                colors={(d) => { return d.data.color! }}
                enableArcLabels
                arcLabel={(d) => { return d.data.displayName }}
                arcLabelsSkipAngle={ARC_LABEL_ANGLE} //slices with degree less than this will not have a label
                arcLabelsTextColor='#000'
                onClick={handleDrilldown}
                cornerRadius={8} //rounded corners
                tooltip={CustomTooltip}
                arcLabelsComponent={({ datum, label, style }): JSX.Element => {
                    // These are all with origin in the center of the graph, but to use transform={style.transform} and get them to 
                    // look nice, they must be with origin in the midpoint of the arc (midPointX, midPointY)
                    let middleRadius = datum.arc.innerRadius + ((datum.arc.outerRadius - datum.arc.innerRadius) / 2);
                    let midPointX = middleRadius * Math.cos(((datum.arc.endAngle - datum.arc.startAngle) / 2 + datum.arc.startAngle) - Math.PI / 2); //subtract these radians because 0 is positive Y axis on these angles but 0 is pos X on svg
                    let midPointY = middleRadius * Math.sin(((datum.arc.endAngle - datum.arc.startAngle) / 2 + datum.arc.startAngle) - Math.PI / 2);
                    let startX = middleRadius * Math.cos(datum.arc.startAngle - Math.PI / 2);
                    let startY = middleRadius * Math.sin(datum.arc.startAngle - Math.PI / 2);
                    let endX = middleRadius * Math.cos(datum.arc.endAngle - Math.PI / 2);
                    let endY = middleRadius * Math.sin(datum.arc.endAngle - Math.PI / 2);
                    let arcAngle: number = Math.abs(datum.arc.endAngle - datum.arc.startAngle)
                    // If graph is too small then use a smaller font size
                    let fontSize = (datum.arc.outerRadius - datum.arc.innerRadius) < 18 ? 10 : 12
                    // Need to specify which arc to take from the two points. svg defaults to the smaller so if > 180 deg then flip order
                    let circleLessThan180 = arcAngle < Math.PI
                    // If the arc is a right-side-up 'smile' then reverse the order like above and flip a flag to make sure it stays a small arc 
                    let flipText = circleLessThan180 && Math.abs((endY - startY) / (endX - startX)) < 1.5 && midPointY > (endY + startY) / 2;

                    return (
                        <animated.g key={10000 + datum.data.id + props.selectedMetric.internalName} transform={style.transform} style={{ pointerEvents: 'none' }}>
                            <defs>
                                <path fill="none" stroke={"blue"} id={`curve-${datum.data.id}`}
                                    d={circleLessThan180 && !flipText
                                        ? `M ${startX - midPointX} ${startY - midPointY} A ${middleRadius} ${middleRadius} 0 0 1 ${endX - midPointX} ${endY - midPointY}`
                                        : `M ${endX - midPointX} ${endY - midPointY} A ${middleRadius} ${middleRadius} 0 ${flipText ? 0 : 1} 0 ${startX - midPointX} ${startY - midPointY}`
                                    }
                                />
                            </defs>
                            <text className={!drilldownIds.includes(datum.data.id) || (arcAngle * 180) / Math.PI < ARC_LABEL_ANGLE ? "fadeOut" : "fadeIn"} textAnchor="middle" dominantBaseline="central" style={{ fontSize: fontSize }}>
                                <textPath startOffset="50%" xlinkHref={`#curve-${datum.data.id}`} >
                                    <tspan>{`${label} (${datum.data.children ? datum.data.children && customFormat(sumChildren(datum.data)) : customFormat(datum.data.value!)})`}</tspan>
                                </textPath>
                            </text>
                        </animated.g>
                    );
                }}
                theme={{
                    labels: {
                        text: { fontSize: '15px', fontWeight: 'bold', paintOrder: 'stroke', stroke: '#ffffff', strokeWidth: '2px', strokeLinecap: 'butt', strokeLinejoin: 'bevel' }
                    },
                    tooltip: {
                        container: { border: 'solid black 1px', background: '#EEE', borderRadius: "5px" }
                    }
                }}
            />
        </div>
    );
}

const sumChildren = (o: GraphDatum): number => (o.children || []).reduce((acc, o) => acc + sumChildren(o), o.value!);

function increaseColorBrightness(hex: string, percent: number) {
    hex = hex.replace(/^\s*#|\s*$/g, '');

    var r = parseInt(hex.slice(0, 2), 16),
        g = parseInt(hex.slice(2, 4), 16),
        b = parseInt(hex.slice(4, 6), 16);

    return '#' +
        ((0 | (1 << 8) + r + (256 - r) * percent / 100).toString(16)).slice(1) +
        ((0 | (1 << 8) + g + (256 - g) * percent / 100).toString(16)).slice(1) +
        ((0 | (1 << 8) + b + (256 - b) * percent / 100).toString(16)).slice(1);
}
