import React, { Fragment, useContext, useEffect, useState } from "react";
import { IQueueModalProps } from "./IQueueModalProps";
import { Button, Icon, Modal, Table } from "semantic-ui-react";
import { useAPIFetchAllLocal } from "../../../core/UseAPILocal";
import BmpGeneralIntermediate from "../../../models/BmpGeneralIntermediate";
import Wave2 from "../../../images/modalWave2.svg";
import { GlobalAuthInfoContext } from "../../mainWrapper/MainWrapper";
import './QueueModal.scss';
import { useAuth } from "react-oidc-context";

export const QueueModal: any = (props: IQueueModalProps) => {
    const auth = useAuth();
    const globalAuthInfoContext = useContext(GlobalAuthInfoContext);
    const [showTable, setShowTable] = useState<boolean>(true);
    const [showAllRecords, setShowAllRecords] = useState<boolean>(true);
    const [open, setOpen] = React.useState(false);
    const [queueIntermediateList, setQueueIntermediateList] = useState<Array<BmpGeneralIntermediate>>(new Array<BmpGeneralIntermediate>());
    const [fetchingQueueIntermediateList, setFetchingQueueIntermediateList] = useState<boolean>(false);

    //not showing completed and successful uploads because they are inactivated
    useAPIFetchAllLocal(auth.user?.access_token, new BmpGeneralIntermediate(), fetchingQueueIntermediateList, setFetchingQueueIntermediateList, setQueueIntermediateList, { queueItemId: props.queueItemId, active: 1 }, (data) => {
    });

    useEffect(() => {
        if (open && !fetchingQueueIntermediateList && queueIntermediateList.length > 0 && !queueIntermediateList.some((x) => x.queueErrorDescription)) {
            //if there are no errors tied to specific bmps then its a general error with the queue item so dont show the table
            setShowTable(false);
        }
    }, [fetchingQueueIntermediateList, open, queueIntermediateList]);

    useEffect(() => {
        if (open && props.queueItemId) {
            setFetchingQueueIntermediateList(true);
        }
    }, [props.queueItemId, open]);

    return (
        <Modal className="queue-modal-component mb-2"
            size="large"
            centered={true}
            open={open}
            onClose={() => { setOpen(false) }}
            onOpen={() => setOpen(true)}
            trigger={<Icon className={`${props.icon} ${props.theme}`} />}
        >
            <Modal.Header>
                <div className="queue-modal-title">Project Error List</div>
                <div className="queue-modal-title-subtext">Please see the Project Upload Instructions available on the Upload Project Data tab for additional support on resolving upload errors.</div>
            </Modal.Header>
            <Modal.Content>
                <div className="table-wrapper">
                    {showTable && queueIntermediateList.length > 0 &&
                        <>
                            <Button size="mini" onClick={() => setShowAllRecords(!showAllRecords)}>{showAllRecords ? "Show only messages" : "Show all"}</Button>
                            <div className="modal-scrollable">
                                <Table celled fixed>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell width={1}>Row</Table.HeaderCell>
                                            <Table.HeaderCell width={5}>BMP Name</Table.HeaderCell>
                                            <Table.HeaderCell width={2}>Error Type</Table.HeaderCell>
                                            <Table.HeaderCell width={8}>Error Description</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {queueIntermediateList
                                            .filter(x => showAllRecords || x.queueErrorDescription)
                                            .map((item: BmpGeneralIntermediate, index: number) => {
                                                return (<Table.Row key={index + item.id}>
                                                    <Table.Cell>{item.rowId}</Table.Cell>
                                                    <Table.Cell>{item.name}</Table.Cell>
                                                    <Table.Cell className={!item.queueErrorDescription ? "queue-error-none" : ""}>{props.lookups && item.queueErrorTypeId ? props.lookups.getQueueErrorTypeById(item.queueErrorTypeId) : "None"}</Table.Cell>
                                                    <Table.Cell className={!item.queueErrorDescription ? "queue-error-none" : ""}>{item.queueErrorDescription ?? "None"}</Table.Cell>
                                                </Table.Row>)
                                            })
                                        }
                                    </Table.Body>
                                </Table>
                            </div>
                        </>
                    }
                    {(!showTable || queueIntermediateList.length === 0) &&
                        <div className="modal-scrollable">
                            {props.queueError?.trim().split('\n').map((item, key) => {
                                return <Fragment key={key}>{item}<br /></Fragment>
                            })}
                        </div>
                    }
                    {props.queueError && queueIntermediateList.length > 0 && <Button size="mini" onClick={() => setShowTable(!showTable)}>{showTable ? "Other Errors" : "Record Errors"}</Button>}
                </div>
            </Modal.Content>
            <Modal.Actions>
                <div className="wave-div">
                    <img src={Wave2} alt="wave" className="wave" />
                </div>
                <div className="btn-wrapper">
                    <Button color="grey" onClick={() => setOpen(false)}>Close</Button>
                </div>
            </Modal.Actions>
        </Modal>
    )
}
