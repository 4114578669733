import React, { HTMLInputTypeAttribute } from "react";
import { Form } from "semantic-ui-react";
import './AddonInputComponent.scss';

export interface AddonInputComponentProps<T extends string | number> {
    value: T;
    setValue: (value: T) => void;
    label: string;
    placeHolder: string;
    addonSide: string; // left or right
    addonContent: string;
    addonIcon?: string;
    theme: string; // primary secondary tertiary
    inputType: HTMLInputTypeAttribute
    // isArrayOfObjs: boolean;
    requiredField?: boolean; // Optional Property that will add red asterisk to requried fields
    toolTipInfo?: string; // Optional Property to show tooltip and point to corresponding tooltip object in json with should be names with a preface of the component type, the component name and then index with a number as needed example (RRSchedule1)
}

export function AddonInputComponent<T extends string | number>(props: AddonInputComponentProps<T>) {
    return (
        <div className="addon-input-component">
            <Form.Field>
                <div>
                    <div className={`addon-input-label-wrapper ${props.requiredField ? "required field" : ""}`}>
                        <label className="addon-input-label">{props.label}</label>
                        {
                            props.toolTipInfo &&
                            // this will become the entrance to the tooltip modal
                            <div className="tool-tip-entry-icon">
                                {/* <ToolTipModal toolTipName={props.toolTipInfo} theme={"primary"} size={"small"} /> */}

                            </div>
                        }
                    </div>
                    <div className={`ui ${props.addonSide} labeled input addon-div`}>
                        <React.Fragment>
                            {
                                props.addonSide === "left" &&
                                <div className={`ui label ${props.theme}`}>
                                    {props.addonIcon
                                        ? <i aria-hidden="true" className="user icon"></i>
                                        : props.addonContent}
                                </div>
                            }
                            <input
                                placeholder={props.placeHolder}
                                type={props.inputType}
                                value={props.value}
                                onChange={(e) => props.setValue(e.target.value as T)}
                            />
                            {
                                props.addonSide === "right" &&
                                <div className={`ui label ${props.theme}`}>
                                    {props.addonIcon
                                        ? <i aria-hidden="true" className="user icon"></i>
                                        : props.addonContent}
                                </div>
                            }
                        </React.Fragment>
                    </div>
                </div>
            </Form.Field>
        </div>
    )
}
