import React from 'react';
import { BmpGeneralViewModel } from '../../services/BmpService';
import LookupFactory from '../../lookups/LookupFactory';
import moment from 'moment';

type BmpDetailsPanelProps = {
    bmpModel: BmpGeneralViewModel;
	lookups: LookupFactory;
}

export const BmpDetailsPanel: React.FC<BmpDetailsPanelProps> = (props: BmpDetailsPanelProps) => {

  	return (
		<div className="capture-dashboard__widget-container">
		    <h2 className="capture-dashboard__project-overview-title" >Project Overview</h2>
			<div className="capture-dashboard__project-overview-attribute-container">
				<div className="capture-dashboard__project-overview-attribute-row">
					{props.bmpModel.description && <div style={{ marginBottom: "2em", width: "100%" }}>
						<p style={{ margin: "0 auto 1em" }} className="capture-dashboard__project-overview-attribute-title">Project Description</p>
						<p style={{ textAlign: "center" }} className="capture-dashboard__project-overview-attribute-value">{props.bmpModel.description}</p>
					</div>}
					<div className="capture-dashboard__project-overview-attribute-container-side-column">
						<p className="capture-dashboard__project-overview-attribute-title">BMP</p>
						<p className="capture-dashboard__project-overview-attribute-value">{props.bmpModel.name}</p>
						<p className="capture-dashboard__project-overview-attribute-title">Watershed</p>
						<p className="capture-dashboard__project-overview-attribute-value">{props.lookups.getWatershedById(props.bmpModel.watershedId!)}</p>
						<p className="capture-dashboard__project-overview-attribute-title">WMG(s)</p>
						<p className="capture-dashboard__project-overview-attribute-value">{props.lookups.getWatershedManagementGroupById(props.bmpModel.watershedManagementGroupId!)}</p>
					</div>
					<div className="capture-dashboard__project-overview-attribute-container-center-column">
						<p className="capture-dashboard__project-overview-attribute-title">Jurisdiction</p>
						<p className="capture-dashboard__project-overview-attribute-value">{props.lookups.getJurisdictionById(props.bmpModel.jurisdictionId!)}</p>
						<p className="capture-dashboard__project-overview-attribute-title">Area Managed</p>
						<p className="capture-dashboard__project-overview-attribute-value">{props.bmpModel.drainageAreaAc!.toFixed(2)} ac</p>
						<p className="capture-dashboard__project-overview-attribute-title">Project Capacity</p>
						<p className="capture-dashboard__project-overview-attribute-value">{props.bmpModel.actualStorageCapacityAcft!.toFixed(2)} ac/ft</p>
					</div>
					<div>
						<p className="capture-dashboard__project-overview-attribute-title">Project Footprint</p>
						<p className="capture-dashboard__project-overview-attribute-value">{props.bmpModel.footprintAc!.toFixed(2)} ac</p>
						<p className="capture-dashboard__project-overview-attribute-title">Project Completion Date</p>
						<p className="capture-dashboard__project-overview-attribute-value">{moment(props.bmpModel.bmpCompletionDate).format("MM-DD-YYYY")}</p>
					</div>
				</div>
			</div>
		</div>
	);
}
