import React from 'react';
import { Modal, Button } from 'semantic-ui-react';
import Wave2 from "../../../images/modalWave2.svg";

interface DeleteLFDModalProps {
    lfdName?: string;
    isOpen: boolean;
    onDelete: (id: number) => Promise<void>;
    onClose: () => void;
    lfdId?: number;
    resetValues: () => void;
}

export const DeleteLFDModal: React.FC<DeleteLFDModalProps> = (props) => {

    const handleDelete = () => {
        props.onDelete(props.lfdId).then(() => {
            props.onClose();
            props.resetValues();
        });

    };

    return (
        <Modal
            open={props.isOpen}
            className="confirm-delete-modal"
            size="tiny"
            centered={true}
        >
            <Modal.Header>Are you sure want to delete?</Modal.Header>
            <Modal.Content>
                <p><i>LFD:  </i> <b>{props.lfdName}</b></p>
            </Modal.Content>
            <Modal.Actions>
                <div className="wave-div">
                    <img src={Wave2} alt="wave" className="wave" />
                </div>
                <div style={{ padding: "1em" }}>

                    <Button color="red" onClick={handleDelete}>
                        Delete
                    </Button>
                    <Button onClick={() => {props.onClose(); props.resetValues();}}>
                        Cancel
                    </Button>
                </div>
            </Modal.Actions>
        </Modal>
    );
};
