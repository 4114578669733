import { useEffect, useState } from "react";
import { Translation } from "react-i18next";
import DropdownLutH2O from "../../paradigmLib/dropdownLut/DropdownLut";
import ProgressReportViewModal from "../../models/viewModels/ProgressReportViewModal";
import { Icon } from "semantic-ui-react";
import Config from "../../config/Config";
import LookupFactory from "../../lookups/LookupFactory";
import LutWatershedReportingPeriod from "../../models/lutModels/LutWatershedReportingPeriod";
import moment from "moment";
import { useAPIFetchAllLocal } from "../../core/UseAPILocal";
import "./Downloads.scss";

export interface IDownloadsProps {
    lookups: LookupFactory;
    wsmgId: number;
}

export const Downloads = (props: IDownloadsProps) => {
    const [filteredProgressReportList, setFilteredProgressReportList] = useState<ProgressReportViewModal[]>(new Array<ProgressReportViewModal>());
    const [selectedReportingYearId, setSelectedReportingYearId] = useState<number>(-3);
    const [progressReportList, setProgressReportList] = useState<ProgressReportViewModal[]>(new Array<ProgressReportViewModal>());
    const [fetchingProgressReportList, setFetchProgressReportList] = useState<boolean>(false);

    const onSelect = (event, data) => {
        setSelectedReportingYearId(data)
    }

    function buildDownloadUrl(file: number) {
        return Config.api_endpoint + "publicDocument/download/" + file;
    }

    useAPIFetchAllLocal("", new ProgressReportViewModal(), fetchingProgressReportList, setFetchProgressReportList, setProgressReportList, { 'watershedGroupId': props.wsmgId }, (data) => {
    });

    useEffect(() => {
        if (props.wsmgId) {
            setFetchProgressReportList(true);
        }
    }, [props.wsmgId])

    useEffect(() => {
        if (props.lookups) {
            setSelectedReportingYearId(getDefaultReportingPeriod);
        }
    }, [props.lookups])

    useEffect(() => {
        if (progressReportList.length > 0) {

            filterProgressReportByReportingYear();
        }

        if (selectedReportingYearId !== -3) {
            filterProgressReportByReportingYear();
        }

    }, [progressReportList, selectedReportingYearId]);

    const filterProgressReportByReportingYear = () => {
        let filteredList = progressReportList.filter((x) => { return (x.reportingPeriodId === selectedReportingYearId) });
        setFilteredProgressReportList(filteredList);
    }

    const getDefaultReportingPeriod = () => {
        let periodId: number = -1;
        let closestDayDiff: number = 1000;
        const currentDate = moment(new Date());
        props.lookups.lutWatershedReportingPeriod.forEach((reportingPeriod: LutWatershedReportingPeriod) => {
            let fullDate = reportingPeriod.displayName.split("-");
            //plus 6months plus 15days
            let endDate = moment(fullDate[1]);
            endDate.add(6, "month");
            endDate.add(15, "days");
            //if endDate is greater than currentDate
            if (endDate.isAfter(currentDate)) {
                var duration = endDate.diff(currentDate, 'days');
                if (duration < closestDayDiff) {
                    closestDayDiff = duration;
                    periodId = reportingPeriod.id;
                }
            }
        })
        if (periodId === -1) {
            periodId = props.lookups.lutWatershedReportingPeriod[0].id;
        }
        return periodId;
    }

    return (
        <Translation>
            {
                t =>
                    <>
                        <div className="downloads">
                            <div className="reporting-dropdown-wrapper">
                                <div className="label">Reporting Period For Downloads</div>
                                <DropdownLutH2O
                                    dropdownItems={props.lookups ? props.lookups.lutWatershedReportingPeriod : []}
                                    dropdownTitle={""}
                                    isMultiselect={false}
                                    nullLabel={null}
                                    onSelect={(e, d) => { onSelect(e, d.value) }}
                                    ddSlim={true}
                                    parentId={selectedReportingYearId}
                                />
                            </div>
                            <div className="tile-wrapper">
                                <div className="tile1">
                                    <div className="title-description-wrapper">
                                        <div className="tile-title">WATERSHED MANAGEMENT PROGRAM PROGRESS REPORT
                                        </div>
                                        <div className="tile-description">The WMP Progress Report is based on a template created by the Los Angeles Regional Water Quality Control Board.  WMP Progress Reports provide fundamental updates on implementation progress, and are published every 6 months, by June 15 and December 15 of each year. The WMP Progress Report is made readily available to the public through the links below.
                                        </div>
                                    </div>
                                    <div className="tile-download-wrapper">
                                        <div className="tile-download-title">DOWNLOADS</div>
                                        <div className="tile-downloads"><Icon name="tint" />Progress Report&nbsp; - &nbsp;
                                            {
                                                filteredProgressReportList.map((report: ProgressReportViewModal) => {
                                                    //checks to see if report is WMP Progress Report (id=1000)
                                                    if (report.lutWatershedGroupOutputTypeId === 1000) {
                                                        return (
                                                            <a href={buildDownloadUrl(report.publicDocumentId)} target="_blank" rel="noreferrer">
                                                                &nbsp;<Icon className="icon" name="cloud download" />
                                                                &nbsp;Download
                                                            </a>
                                                        )
                                                    }
                                                })
                                            }

                                            {
                                                filteredProgressReportList.filter((report: ProgressReportViewModal) => {
                                                    return (report.lutWatershedGroupOutputTypeId === 1000)
                                                }).length <= 0 &&
                                                <span>
                                                    Coming soon
                                                </span>
                                            }
                                        </div>

                                        <div className="tile-downloads"><Icon name="tint" />Tables 1a, 1b, 1c&nbsp; - &nbsp;
                                            {
                                                filteredProgressReportList.map((report: ProgressReportViewModal) => {
                                                    //checks to see if report is Table 1a 1b 1c (id=1001)
                                                    if (report.lutWatershedGroupOutputTypeId === 1001) {
                                                        return (
                                                            <a href={buildDownloadUrl(report.publicDocumentId)} target="_blank" rel="noreferrer">
                                                                &nbsp;<Icon className="icon" name="cloud download" />
                                                                &nbsp;Download
                                                            </a>
                                                        )
                                                    }
                                                })
                                            }

                                            {
                                                filteredProgressReportList.filter((report: ProgressReportViewModal) => {
                                                    return (report.lutWatershedGroupOutputTypeId === 1001)
                                                }).length <= 0 &&
                                                <span>
                                                    Coming soon
                                                </span>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="tile2">
                                    <div className="title-description-wrapper">
                                        <div className="tile-title">EXTRACTED PROGRESS SUMMARIES (SECTION 1.1)
                                        </div>
                                        <div className="tile-description"> The progress summary in Section 1.1 of the WMP Progress Report is extracted separately and made readily available and understandable to the public through the links below. Separate links are provided for different languages to ensure access across community demographics.
                                        </div>
                                    </div>
                                    <div className="tile-download-wrapper">
                                        <div className="tile-download-title">DOWNLOADS</div>

                                        {
                                            filteredProgressReportList.length > 0 &&
                                            filteredProgressReportList.map((report: ProgressReportViewModal) => {
                                                //checks to see if report is Extracted Section 1.1 (id=1002)
                                                if (report.lutWatershedGroupOutputTypeId === 1002) {
                                                    return (
                                                        <div className="tile-downloads"><Icon name="tint" />Section 1.1 in&nbsp;{props.lookups ? props.lookups.getLutLanguageTypeById(report.lutLanguageId) : ""}
                                                            &nbsp; - &nbsp;
                                                            <a href={buildDownloadUrl(report.publicDocumentId)} target="_blank" rel="noreferrer">&nbsp;
                                                                <Icon className="icon" name="cloud download" />
                                                                &nbsp;Download
                                                            </a>
                                                        </div>
                                                    )
                                                }
                                            })
                                        }

                                        {
                                            filteredProgressReportList.filter((report: ProgressReportViewModal) => {
                                                // this checks to see if it is of type 1002 (Section 1.1)
                                                return (report.lutWatershedGroupOutputTypeId === 1002)
                                            }).length === 0 &&
                                            <div className="extracted-coming-soon">
                                                Section 1.1 summaries available for download coming soon
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
            }
        </Translation>
    )
}
