import { BaseModel } from "../BaseModel";
import BmpGeneralGisData from "../BmpGeneralGisData";


export default class GisQueryAdHocModel extends BaseModel {

    bmpGeneralId: number;
    latitude: number;
    longitude: number;
    gisQueryMsg: string;
    msgIsError: boolean;
    active: number;
    gisData?: BmpGeneralGisData;

    constructor() {
        super("GisQuery", "local")
    }
}