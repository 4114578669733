import BmpGeneralModelResult from "./BmpGeneralModelResult";

export default class BmpGeneralModelRequest {
    id: number;
    bmpGeneralId: number;
    cloudEngineId: number;
    requestBody: string;
    created: Date;
    updated: Date;
    active: number;

    bmpGeneralModelResult: Array<BmpGeneralModelResult>
}
