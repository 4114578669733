import React from 'react';

import { customFormat} from '../../core/NumberFormat';
import { SpreadingGround, SpreadingGroundBasinTypeId } from '../../models/SpreadingGround';

interface SGDetailsPanelProps {
  sgData: SpreadingGround | null;
}

const SGDetailsPanel: React.FC<SGDetailsPanelProps> = (props) => {

  return (
    <div className='capture-dashboard__widget-container'>
      <h2 className="capture-dashboard__project-overview-title" >Project Overview</h2>
      <h4 style={{ margin: "0 0 2em", fontStyle: "italic" }}>Spreading Grounds are large facilities that capture runoff and soak it into the ground, increasing the sustainability of our local groundwater supply.</h4>
      <div className="capture-dashboard__project-overview-attribute-container">
      <div className="capture-dashboard__project-overview-attribute-row">
          {props.sgData?.locationDescription && <div style={{ marginBottom: "2em", width: "100%" }}>
            <p style={{ margin: "0 auto 1em" }} className="capture-dashboard__project-overview-attribute-title">Spreading Grounds Description</p>
            <p style={{ textAlign: "center" }} className="capture-dashboard__project-overview-attribute-value">{props.sgData?.locationDescription ?? "N/A"}</p>
          </div>}
          <div className="capture-dashboard__project-overview-attribute-container-side-column">
            <p className="capture-dashboard__project-overview-attribute-title">Spreading Grounds Project</p>
            <p className="capture-dashboard__project-overview-attribute-value">{props.sgData?.name}</p>
            <p className="capture-dashboard__project-overview-attribute-title">Basin Type</p>
            <p className="capture-dashboard__project-overview-attribute-value">{props.sgData?.basinTypeId ? SpreadingGroundBasinTypeId[props.sgData?.basinTypeId] : "N/A"}</p>
          </div>
					<div className="capture-dashboard__project-overview-attribute-container-center-column">
            <p className="capture-dashboard__project-overview-attribute-title">Intake Capacity</p>
            <p className="capture-dashboard__project-overview-attribute-value">{props.sgData?.intakeCapacityCfs ? customFormat(props.sgData?.intakeCapacityCfs) : "N/A"} cfs</p>
            <p className="capture-dashboard__project-overview-attribute-title">Storage Capacity</p>
            <p className="capture-dashboard__project-overview-attribute-value">{props.sgData?.storageCapacityAcft ? customFormat(props.sgData?.storageCapacityAcft) : "N/A"} cfs</p>
            <p className="capture-dashboard__project-overview-attribute-title">Percolation Rate</p>
            <p className="capture-dashboard__project-overview-attribute-value">{props.sgData?.percolationCapacityCfs ? customFormat(props.sgData?.percolationCapacityCfs) : "N/A"} cfs</p>
          </div>
          <div>
            <p className="capture-dashboard__project-overview-attribute-title">Total Area</p>
            <p className="capture-dashboard__project-overview-attribute-value">{props.sgData?.grossAreaAc ? customFormat(props.sgData?.grossAreaAc) : "N/A"} (ac)</p>
            <p className="capture-dashboard__project-overview-attribute-title">Project Completion Date</p>
            <p className="capture-dashboard__project-overview-attribute-value">{props.sgData?.firstSeason ?? "N/A"}</p>
            {/* Waiting on Dusting confirmation 2024-03-22 */}
            {/* <p className="capture-dashboard__project-overview-attribute-value">{moment(props.sgData?.).format("MM-DD-YYYY")}</p> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SGDetailsPanel;
