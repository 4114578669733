import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { Button, Menu, MenuItemProps, Message, Segment } from "semantic-ui-react";
import { Translation } from "react-i18next";
import { BuildWatershedForm_1_1 } from "./BuildWatershedForm_1_1";
import { BuildWatershedForm_1_2 } from "./BuildWatershedForm_1_2";
import { BuildWatershedForm_1_2a } from "./BuildWatershedForm_1_2a";
import { BuildWatershedForm_1_3 } from "./BuildWatershedForm_1_3";
import { BuildWatershedForm_1_3a } from "./BuildWatershedForm_1_3a";
import { BuildWatershedForm_1_4 } from "./BuildWatershedForm_1_4";
import { BuildWatershedForm_1_5 } from "./BuildWatershedForm_1_5";
import { useAPIFetchAllLocal, usePutPostLocal } from "../../../core/UseAPILocal";
import { ConfirmModal } from "../../modals/confirmModal/ConfirmModal";
import DropdownLutH2O from "../../../paradigmLib/dropdownLut/DropdownLut";
import LutWatershedReportStatus from "../../../models/lutModels/LutWatershedReportStatus";
import LookupFactory from "../../../lookups/LookupFactory";
import WatershedReportForm from "../../../models/WatershedReportForm";
import WatershedManagementGroup from "../../../models/lutModels/WatershedManagementGroup";
import JurisdictionGroup from "../../../models/JurisdictionGroup";
import { WGReportingOutputs } from "./WGReportingOutputs";
import { JurisReportingOutputs } from "./JurisReportingOutputs";
import { GlobalAuthInfoContext, IGlobalAuthInfo } from "../../mainWrapper/MainWrapper";
import moment from "moment";
import LutWatershedReportingPeriod from "../../../models/lutModels/LutWatershedReportingPeriod";
import WatershedReportFormGroupStatus from "../../../models/WatershedReportingFormGroupStatus";
import { PermissionsStatusComponent } from "../../permissionsStatusComponent/PermissionsStatusComponent";
import { WatershedReportPublicFacingConfigComponent } from "./WatershedReportPublicFacingConfigComponent";
import BmpGeneral from "../../../models/BmpGeneral";
import './BuildWatershedForm.scss';
import { useAuth } from "react-oidc-context";

export interface IBuildWatershedFormProps {
    lookups: LookupFactory;
    reportingPeriod: string;
    watershed: string;
    setActiveItem: (tab: string) => void;
    watershedReportForm: WatershedReportForm;
    selectedParams: SelectedParams
    finalLock: boolean;
    setSidebarProjectInfoObj: Dispatch<SetStateAction<BmpGeneral>>;
    dispatchSidebar: Function;
}

export type SelectedParams = {
    reportingPeriodId: number;
    watershedGroupId: number;
    jurisdictionId: number;
}

enum SelectedView { Jurisdiction, GroupLead };

export const BuildWatershedForm: any = (props: IBuildWatershedFormProps) => {
    const auth = useAuth();
    const globalAuthInfoContext = useContext(GlobalAuthInfoContext);
    const [selectedView, setSelectedView] = useState<SelectedView>();

    useEffect(() => {
        setSelectedView(SelectedView.GroupLead);

        // If the user has jurisdictions they are probably more interested
        // in starting out in 'jurisdiction' mode so we will overwrite that here
        if (globalAuthInfoContext?.jurisdictionList?.length > 0) {
            setSelectedView(SelectedView.Jurisdiction);
        }
    }, []);

    const [activeItem, setActiveItem] = useState<string>('1_1'); // controls active tab
    const [tempStatus, setTempStatus] = useState<number>(); // used for modal

    const [selectedParams, setSelectedParams] = useState<SelectedParams>({
        reportingPeriodId: -1,
        watershedGroupId: -1,
        jurisdictionId: -1
    });

    const [watershedReportForm, setWatershedReportForm] = useState<WatershedReportForm | null>(new WatershedReportForm());
    const [fetchingWatershedReportForm, setFetchingWatershedReportForm] = useState<boolean>(false);

    const [activeJurisdictionGroup, setActiveJurisdictionGroup] = useState<JurisdictionGroup>(new JurisdictionGroup());
    const [jurisdictionWatershedGroupList, setJurisdictionWatershedGroupList] = useState<JurisdictionGroup[]>(new Array<JurisdictionGroup>());
    const [fetchingJurisdictionWatershedGroupList, setFetchingJurisdictionWatershedGroupList] = useState<boolean>(true);
    const [filteredJurisdictionWatershedGroupList, setFilteredJurisdictionWatershedGroupList] = useState<WatershedManagementGroup[]>(new Array<WatershedManagementGroup>());

    const [groupStatus, setGroupStatus] = useState<WatershedReportFormGroupStatus>(new WatershedReportFormGroupStatus());
    const [fetchGroupStatus, setFetchGroupStatus] = useState<boolean>(false);

    const [showAccessAlertMsg, setShowAccessAlertMsg] = useState<boolean>(false);

    const [showUpdateStatusModal, setShowUpdateStatusModal] = useState<boolean>(false);
    const [showCreateReportModal, setShowCreateReportModal] = useState<boolean>(false);

    const [filterWatersheds, setFilterWatersheds] = useState<boolean>(false);

    // state to trigger when to save (put/post) the data
    const [updateWatershedReportForm, setUpdateWatershedReportForm] = useState<boolean>(false);
    const [updateGroupStatus, setUpdateGroupStatus] = useState<boolean>(false);

    useEffect(() => {
        if (globalAuthInfoContext) {

            // Maybe add logic to hide toggle if they have watershedgroup lead role but no watersheds assigned to them

            if ((globalAuthInfoContext.visibleComponents.indexOf("buildWatershedFormGroupLead") > -1 && globalAuthInfoContext.visibleComponents.indexOf("buildWatershedFormJuris") < 0)) {
                setSelectedView(SelectedView.GroupLead)
                setActiveItem("1_6")
            } else if (globalAuthInfoContext.visibleComponents.indexOf("buildWatershedFormGroupLead") < 0 && globalAuthInfoContext.visibleComponents.indexOf("buildWatershedFormJuris") < 0 && globalAuthInfoContext.roles.indexOf("admin") < 0) {
                setShowAccessAlertMsg(true);
            }
        }
    }, [globalAuthInfoContext])

    useAPIFetchAllLocal(auth.user?.access_token, new WatershedReportFormGroupStatus(), fetchGroupStatus, setFetchGroupStatus, setGroupStatus, { 'watershedReportingPeriodId': selectedParams.reportingPeriodId, "watershedManagementGroupId": selectedParams.watershedGroupId, "active": 1 }, (data) => {
        if (!data || data.length === 0) {
            let newObject: WatershedReportFormGroupStatus = Object.assign(new WatershedReportFormGroupStatus());
            newObject.watershedReportStatusId = props.lookups.getWatershedReportStatusByName("Draft"); // default is in draft...
            setGroupStatus(newObject);
        }
        else {
            setGroupStatus(data[0]);
        }

    });

    useAPIFetchAllLocal(
        auth.user?.access_token,
        new WatershedReportForm(),
        fetchingWatershedReportForm,
        setFetchingWatershedReportForm,
        setWatershedReportForm,
        {
            'watershedReportingPeriodId': selectedParams.reportingPeriodId === -1 ? null : selectedParams.reportingPeriodId,
            "jurisdictionId": (selectedParams.jurisdictionId === -1 || selectedView === SelectedView.GroupLead) ? null : selectedParams.jurisdictionId,
            "watershedManagementGroupId": selectedParams.watershedGroupId === -1 ? null : selectedParams.watershedGroupId,
            "active": 1
        },
        (data) => {
            if (data.length !== 0) {
                setWatershedReportForm(data[0]);
            } else if (selectedParams.jurisdictionId !== -1 &&
                selectedParams.watershedGroupId !== -1 &&
                selectedParams.reportingPeriodId !== -1 &&
                selectedView === SelectedView.Jurisdiction) {
                let newReport: WatershedReportForm = new WatershedReportForm();
                newReport.jurisdictionId = selectedParams.jurisdictionId;
                newReport.watershedReportingPeriodId = selectedParams.reportingPeriodId;
                newReport.watershedManagementGroupId = selectedParams.watershedGroupId;
                newReport.watershedReportStatusId = props.lookups.lutWatershedReportStatus[1].id; // draft
                newReport.active = 1;
                setWatershedReportForm(newReport);
                setShowCreateReportModal(true);
            } else {
                setWatershedReportForm(null);
            }
        });

    useAPIFetchAllLocal(
        auth.user?.access_token,
        new JurisdictionGroup(),
        fetchingJurisdictionWatershedGroupList,
        setFetchingJurisdictionWatershedGroupList,
        setJurisdictionWatershedGroupList,
        { "active": 1 },
        (data) => {}
    );

    useEffect(() => {
        if (jurisdictionWatershedGroupList && jurisdictionWatershedGroupList.length > 0 && selectedParams && selectedParams.jurisdictionId > -1 && selectedParams.watershedGroupId > -1) {
            let selectedItem = jurisdictionWatershedGroupList.filter((item) => item.jurisdictionId === selectedParams.jurisdictionId && item.watershedManagementGroupId === selectedParams.watershedGroupId)[0];
            if (selectedItem !== undefined && selectedItem.id > 0) {
                setActiveJurisdictionGroup(selectedItem);
            }
        }
    }, [selectedParams, jurisdictionWatershedGroupList])

    useEffect(() => {
        if (props.lookups && jurisdictionWatershedGroupList.length > 0) {
            let filteredWatershedGroupByJuris: WatershedManagementGroup[] = new Array<WatershedManagementGroup>();

            jurisdictionWatershedGroupList.forEach((wsByJurisItem: JurisdictionGroup) => {
                props.lookups.lutWatershedManagementGroup.forEach((watershedGroupObj: WatershedManagementGroup) => {

                    if (wsByJurisItem.jurisdictionId === selectedParams.jurisdictionId && wsByJurisItem.watershedManagementGroupId === watershedGroupObj.id) {
                        filteredWatershedGroupByJuris.push(watershedGroupObj);
                    }
                })
            });

            if (filteredWatershedGroupByJuris.length > 0) {
                let stateCopy: SelectedParams = { ...selectedParams };
                stateCopy.watershedGroupId = filteredWatershedGroupByJuris[0].id;
                setSelectedParams(stateCopy);
            }

            setFilteredJurisdictionWatershedGroupList(filteredWatershedGroupByJuris);
            setFilterWatersheds(false);
        }

    }, [jurisdictionWatershedGroupList, filterWatersheds, props.lookups]);

    useEffect(() => {
        let stateCopy: SelectedParams = { ...selectedParams };
        if ((globalAuthInfoContext.jurisdictionList !== undefined && globalAuthInfoContext.jurisdictionList.length > 0) && props.lookups) {
            // On first load set watershedDropdown
            setFilterWatersheds(true);

            stateCopy.jurisdictionId = globalAuthInfoContext.jurisdictionList[0].id;

            stateCopy.reportingPeriodId = getDefaultReportingPeriod();

            setSelectedParams(stateCopy);
        }

    }, [globalAuthInfoContext, props.lookups]);

    useEffect(() => {
        if (globalAuthInfoContext.watershedGroupList !== undefined && globalAuthInfoContext.watershedGroupList.length > 0) {

            // if they are of select groupLead default them to the first group in their list
            if (selectedView === SelectedView.GroupLead) {
                let stateCopy: SelectedParams = Object.assign({}, selectedParams);
                stateCopy.watershedGroupId = globalAuthInfoContext.watershedGroupList[0].id;
                stateCopy.reportingPeriodId = getDefaultReportingPeriod();
                setSelectedParams(stateCopy);
            } else if (selectedView === SelectedView.Jurisdiction) {
                setFilterWatersheds(true);
            }
        }

    }, [globalAuthInfoContext, selectedView]);

    // looks at state and saves watershedReportForm
    usePutPostLocal(auth.user?.access_token, watershedReportForm!, (data) => {
        let newObject: WatershedReportForm = Object.assign(new WatershedReportForm(), data);
        setWatershedReportForm(newObject);
    }, [updateWatershedReportForm]);

    usePutPostLocal(auth.user?.access_token, groupStatus, (data) => {
        let newObject: WatershedReportFormGroupStatus = Object.assign(new WatershedReportFormGroupStatus(), data);
        setGroupStatus(newObject);
    }, [updateGroupStatus]);

    const getDefaultReportingPeriod = () => {
        let periodId: number = -1;
        let closestDayDiff: number = 1000;

        const currentDate = moment(new Date());

        props.lookups.lutWatershedReportingPeriod.forEach((reportingPeriod: LutWatershedReportingPeriod) => {
            let fullDate = reportingPeriod.displayName.split("-");

            //plus 6months plus 15days
            let endDate = moment(fullDate[1]);
            endDate.add(6, "month");
            endDate.add(15, "days");

            //if endDate is greater than currentDate
            if (endDate.isAfter(currentDate)) {
                var duration = endDate.diff(currentDate, 'days');

                if (duration < closestDayDiff) {
                    closestDayDiff = duration;
                    periodId = reportingPeriod.id;
                }
            }
        })

        if (periodId === -1) {
            periodId = props.lookups.lutWatershedReportingPeriod[0].id;
        }

        return periodId;
    }

    const handleItemClick = (_e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, { name }: MenuItemProps) => setActiveItem(name!)

    const jurisClick = () => {
        let stateCopy: SelectedParams = Object.assign({}, selectedParams);
        stateCopy.watershedGroupId = globalAuthInfoContext.watershedGroupList[0].id;
        stateCopy.reportingPeriodId = getDefaultReportingPeriod();
        setSelectedParams(stateCopy);

        setSelectedView(SelectedView.Jurisdiction)
        setActiveItem("1_6")
    }

    const groupLeadClick = () => {
        let stateCopy: SelectedParams = Object.assign({}, selectedParams);
        stateCopy.watershedGroupId = globalAuthInfoContext.watershedGroupList[0].id;
        stateCopy.reportingPeriodId = getDefaultReportingPeriod();
        setSelectedParams(stateCopy);

        setSelectedView(SelectedView.GroupLead)
        setActiveItem("1_6")
    }

    const onSelect = (sel: string, val: number) => {
        let paramCopy: SelectedParams = Object.assign({}, selectedParams);
        let saveEdit: boolean = false;
        if (sel === 'wat') {
            paramCopy.watershedGroupId = val;
            saveEdit = true;
        } else if (sel === 'jur') {
            paramCopy.jurisdictionId = val;
            setFilterWatersheds(true);
            saveEdit = true;
        } else if (sel === 'rep') {
            paramCopy.reportingPeriodId = val;
            saveEdit = true;
        }

        if (saveEdit) {
            setSelectedParams(paramCopy);
        }
    }

    useEffect(() => {
        if (selectedView === SelectedView.GroupLead) {

            if (selectedParams.reportingPeriodId !== -1 && selectedParams.watershedGroupId !== -1) {
                setFetchingWatershedReportForm(true);
                setFetchGroupStatus(true);
            }
        } else {
            if (selectedParams.jurisdictionId !== -1 && selectedParams.reportingPeriodId !== -1 && selectedParams.watershedGroupId !== -1) {
                setFetchingWatershedReportForm(true);
                setFetchGroupStatus(true);
            }
        }
    }, [selectedParams, selectedView])

    //this function changes whatever statusId is passed into it (signalling either draft or final based on the number) and changes the status when user clicks OK inside the modal
    const onStatusChange = (statusId: number) => {
        if (selectedParams.reportingPeriodId > -1 && selectedParams.watershedGroupId > -1) {
            let stateCopy: any = { ...groupStatus };
            stateCopy.watershedReportStatusId = statusId;
            stateCopy.watershedReportingPeriodId = selectedParams.reportingPeriodId;
            stateCopy.watershedManagementGroupId = selectedParams.watershedGroupId;
            setGroupStatus(stateCopy);
            setUpdateGroupStatus(!updateGroupStatus);
        }
    }

    // this function checks to see whether the button they clicked was already the currently active one. If it is, nothing happens, if not then it opens modal for user to confirm if they really want to change it.
    const checkStatusChange = (statusId: number) => {
        if (statusId !== groupStatus.watershedReportStatusId) {
            setTempStatus(statusId)
            setShowUpdateStatusModal(true);
        } else {
            return null;
        }
    }

    const confirmCreateReport = () => {
        if (watershedReportForm) {
            setUpdateWatershedReportForm(!updateWatershedReportForm); //flips the flag to save it to the db
        }
    }

    // to get top of page to scroll into view
    const element = document.getElementById("scrollTop");
    const scrollFunction = () => {
        element.scrollIntoView(true);
    }

    return (
        <div className="build-watershed-form-page">
            <Translation>
                {(t) => (
                    <GlobalAuthInfoContext.Consumer>
                        {(authInfo: IGlobalAuthInfo) => {
                            return (
                                <>
                                    <PermissionsStatusComponent hasPermissions={!showAccessAlertMsg} />
                                    {
                                        showAccessAlertMsg === false &&
                                        <>
                                            <ConfirmModal confirmFunc={onStatusChange} confirmText={"Are you sure you want to update the report status?"} openModal={showUpdateStatusModal} setOpenModal={setShowUpdateStatusModal} tempStatus={tempStatus} />
                                            <ConfirmModal confirmFunc={confirmCreateReport} confirmText={"Report doesn't exist for your current selection. Would you like to create a new report?"} openModal={showCreateReportModal} setOpenModal={setShowCreateReportModal} />

                                            <Segment
                                                id="scrollTop"
                                                className="top-segment">
                                                <div className="main-page-top-segment-container">
                                                    <div className="segment-wrapper">
                                                        <div className="button-segment">
                                                            {
                                                                authInfo.roles && (authInfo.roles.indexOf("admin") > -1 || (authInfo.visibleComponents.indexOf("buildWatershedFormGroupLead") > -1 && authInfo.visibleComponents.indexOf("buildWatershedFormJuris") > -1)) &&
                                                                <Button.Group>
                                                                    <Button className={`custom-btn-group-left ${selectedView === SelectedView.Jurisdiction ? "btnColor" : ""}`} onClick={jurisClick}>Jurisdiction</Button>
                                                                    <Button className={`custom-btn-group-right ${selectedView === SelectedView.GroupLead ? "btnColor" : ""}`} onClick={groupLeadClick}>Group Lead</Button>
                                                                </Button.Group>
                                                            }
                                                        </div>
                                                        <div className="dropdown-segment">
                                                            {
                                                                authInfo.roles && authInfo.visibleComponents.indexOf("buildWatershedFormJuris") > -1 &&
                                                                <div className={`reportWrap ${selectedView === SelectedView.GroupLead ? "hidden" : ""}`}>
                                                                    <DropdownLutH2O
                                                                        dropdownItems={globalAuthInfoContext.jurisdictionList ? globalAuthInfoContext.jurisdictionList : []}
                                                                        dropdownTitle={"Jurisdiction"}
                                                                        isMultiselect={false}
                                                                        nullLabel={""}
                                                                        onSelect={(e, d) => { onSelect('jur', d.value) }}
                                                                        ddSlim={false}
                                                                        search={true}
                                                                        parentId={selectedParams.jurisdictionId}
                                                                        width="21.5rem"
                                                                    />
                                                                </div>
                                                            }
                                                            {
                                                                selectedView === SelectedView.GroupLead &&
                                                                <div className='reportWrap'>
                                                                    <DropdownLutH2O
                                                                        dropdownItems={globalAuthInfoContext.watershedGroupList ? globalAuthInfoContext.watershedGroupList : []}
                                                                        dropdownTitle={"Watershed Group"}
                                                                        isMultiselect={false}
                                                                        nullLabel={""}
                                                                        onSelect={(e, d) => { onSelect('wat', d.value) }}
                                                                        ddSlim={false}
                                                                        search={true}
                                                                        parentId={selectedParams.watershedGroupId}
                                                                    />
                                                                </div>
                                                            }
                                                            {
                                                                selectedView === SelectedView.Jurisdiction &&
                                                                <div className='reportWrap'>
                                                                    <DropdownLutH2O
                                                                        dropdownItems={filteredJurisdictionWatershedGroupList}
                                                                        dropdownTitle={"Watershed Group"}
                                                                        isMultiselect={false}
                                                                        nullLabel={""}
                                                                        onSelect={(e, d) => { onSelect('wat', d.value) }}
                                                                        ddSlim={false}
                                                                        search={true}
                                                                        parentId={selectedParams.watershedGroupId}
                                                                    />
                                                                </div>
                                                            }
                                                            <div className='reportWrap'>
                                                                <DropdownLutH2O
                                                                    dropdownItems={props.lookups !== null ? props.lookups.lutWatershedReportingPeriod : []}
                                                                    dropdownTitle={"Reporting Period"}
                                                                    isMultiselect={false}
                                                                    nullLabel={""}
                                                                    onSelect={(e, d) => { onSelect('rep', d.value) }}
                                                                    ddSlim={false}
                                                                    parentId={selectedParams.reportingPeriodId}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="header-segment">
                                                        <div className="left-div">
                                                        </div>

                                                        <div className="center-div">
                                                            <div className="jurisdiction-title">
                                                                {/* THIS CONTROLS TITLE AT TOP OF PAGE UNDER FILTERS - EITHER JURISDICTION OR WATERSHED GROUP NAME */}
                                                                {
                                                                    props.lookups &&
                                                                    <span>
                                                                        {
                                                                            selectedView === SelectedView.Jurisdiction ?
                                                                                props.lookups.getJurisdictionById(selectedParams.jurisdictionId)
                                                                                : props.lookups.getWatershedManagementGroupById(selectedParams.watershedGroupId)
                                                                        }
                                                                    </span>
                                                                }
                                                                <hr />
                                                            </div>
                                                            {
                                                                watershedReportForm && watershedReportForm.id > 0 &&
                                                                <div className="subpage-header-1">
                                                                    <span>
                                                                        Report Status:
                                                                    </span>
                                                                    <div className="report-button-segment">
                                                                        <Button.Group>
                                                                            {
                                                                                props.lookups &&
                                                                                props.lookups.lutWatershedReportStatus.map((reportStatus: LutWatershedReportStatus, index: number) => {
                                                                                    if (reportStatus.internalName !== "notStarted") {
                                                                                        return (
                                                                                            <Button
                                                                                                key={index}
                                                                                                className={`small-btn-group-${reportStatus.internalName !== "final" ? "left" : "right"}
                                                                                                ${groupStatus.watershedReportStatusId === reportStatus.id ? "btnColor" : ""}
                                                                                                ${selectedView === SelectedView.GroupLead ? "show-cursor" : ""}`}
                                                                                                onClick={() => {
                                                                                                    if (selectedView === SelectedView.GroupLead) {
                                                                                                        checkStatusChange(reportStatus.id)
                                                                                                    }
                                                                                                }}
                                                                                            >{reportStatus.displayName}</Button>
                                                                                        )
                                                                                    }

                                                                                })
                                                                            }
                                                                        </Button.Group>
                                                                    </div>
                                                                </div>
                                                            }

                                                        </div>

                                                        <div className="right-div">
                                                        </div>
                                                    </div>
                                                </div>
                                            </Segment>
                                            {/* {
                                                (!watershedReportForm && selectedView === SelectedView.GroupLead) &&
                                                <Message warning>
                                                    None of the jurisdictions in the selected watershed group have started their report forms for the selected reporting period.
                                                    Please select a different watershed group and reporting period combination to check its reporting status.
                                                </Message>
                                            } */}
                                            {
                                                <>
                                                    <Menu
                                                        pointing
                                                        secondary>
                                                        {selectedView === SelectedView.Jurisdiction && watershedReportForm && watershedReportForm.id > 0 &&
                                                            <>
                                                                <Menu.Item
                                                                    name='1_1'
                                                                    active={activeItem === '1_1'}
                                                                    onClick={handleItemClick}
                                                                    content="Section 1.1"
                                                                />
                                                                <Menu.Item
                                                                    name='1_2'
                                                                    active={activeItem === '1_2'}
                                                                    onClick={handleItemClick}
                                                                    content="Section 1.2"
                                                                />
                                                                <Menu.Item
                                                                    name='1_2a'
                                                                    active={activeItem === '1_2a'}
                                                                    onClick={handleItemClick}
                                                                    content="Section 1.2a"
                                                                />
                                                                <Menu.Item
                                                                    name='1_3'
                                                                    active={activeItem === '1_3'}
                                                                    onClick={handleItemClick}
                                                                    content="Section 1.3"
                                                                />
                                                                <Menu.Item
                                                                    name='1_3a'
                                                                    active={activeItem === '1_3a'}
                                                                    onClick={handleItemClick}
                                                                    content="Section 1.3a"
                                                                />
                                                                <Menu.Item
                                                                    name='1_4'
                                                                    active={activeItem === '1_4'}
                                                                    onClick={handleItemClick}
                                                                    content="Section 1.4"
                                                                />
                                                                <Menu.Item
                                                                    name='1_5'
                                                                    active={activeItem === '1_5'}
                                                                    onClick={handleItemClick}
                                                                    content="Section 1.5"
                                                                />
                                                                <div className="tab-spacer"></div>
                                                            </>
                                                        }

                                                        <div className="report-tab">
                                                            <Menu.Item
                                                                name='1_6'
                                                                active={activeItem === '1_6'}
                                                                onClick={handleItemClick}
                                                                content="Reporting Outputs"
                                                            />
                                                        </div>

                                                        {selectedView === SelectedView.GroupLead &&
                                                            <>
                                                                <Menu.Item
                                                                    name='1_7'
                                                                    active={activeItem === '1_7'}
                                                                    onClick={handleItemClick}
                                                                    content="Configure Public Elements"
                                                                />
                                                                <Menu.Item
                                                                    name='1_8'
                                                                    active={activeItem === '1_8' || activeItem === '1_8_open_modal'}
                                                                    onClick={() => setActiveItem("1_8_open_modal")}
                                                                    content="Preview Public Elements"
                                                                />
                                                                <Menu.Item
                                                                    name='1_9'
                                                                    active={activeItem === '1_9' || activeItem === "1_9_open_modal"}
                                                                    onClick={() => setActiveItem("1_9_open_modal")}
                                                                    content="Publishing Controls"
                                                                />
                                                            </>
                                                        }
                                                    </Menu>

                                                    <div className="subpage-segment">
                                                        {watershedReportForm && watershedReportForm.id > 0 &&
                                                            <>
                                                                {activeItem === '1_1' && <BuildWatershedForm_1_1 setActiveItem={setActiveItem} watershedReportForm={watershedReportForm} selectedParams={selectedParams} lookups={props.lookups} finalLock={props.lookups.getWatershedReportStatusInternalNameById(groupStatus.watershedReportStatusId) === "final" ? true : false} />}
                                                                {activeItem === '1_2' && <BuildWatershedForm_1_2 setActiveItem={setActiveItem} watershedReportForm={watershedReportForm} selectedParams={selectedParams} lookups={props.lookups} finalLock={props.lookups.getWatershedReportStatusInternalNameById(groupStatus.watershedReportStatusId) === "final" ? true : false} />}
                                                                {activeItem === '1_2a' && <BuildWatershedForm_1_2a setActiveItem={setActiveItem} watershedReportForm={watershedReportForm} selectedParams={selectedParams} lookups={props.lookups} finalLock={props.lookups.getWatershedReportStatusInternalNameById(groupStatus.watershedReportStatusId) === "final" ? true : false} />}
                                                                {activeItem === '1_3' && <BuildWatershedForm_1_3 setActiveItem={setActiveItem} watershedReportForm={watershedReportForm} selectedParams={selectedParams} lookups={props.lookups} finalLock={props.lookups.getWatershedReportStatusInternalNameById(groupStatus.watershedReportStatusId) === "final" ? true : false} />}
                                                                {activeItem === '1_3a' && <BuildWatershedForm_1_3a setActiveItem={setActiveItem} watershedReportForm={watershedReportForm} selectedParams={selectedParams} lookups={props.lookups} finalLock={props.lookups.getWatershedReportStatusInternalNameById(groupStatus.watershedReportStatusId) === "final" ? true : false} />}
                                                                {activeItem === '1_4' && <BuildWatershedForm_1_4 setActiveItem={setActiveItem} watershedReportForm={watershedReportForm} selectedParams={selectedParams} lookups={props.lookups} finalLock={props.lookups.getWatershedReportStatusInternalNameById(groupStatus.watershedReportStatusId) === "final" ? true : false} />}
                                                                {activeItem === '1_5' && <BuildWatershedForm_1_5 setActiveItem={setActiveItem} watershedReportForm={watershedReportForm} selectedParams={selectedParams} lookups={props.lookups} finalLock={props.lookups.getWatershedReportStatusInternalNameById(groupStatus.watershedReportStatusId) === "final" ? true : false} />}
                                                            </>
                                                        }
                                                        {activeItem === '1_6' && selectedView === SelectedView.GroupLead &&
                                                            <>
                                                                {!watershedReportForm &&
                                                                    <Message warning>
                                                                        None of the jurisdictions in the selected watershed group have started their report forms for the selected reporting period.
                                                                        Please select a different watershed group and reporting period combination to check its reporting status.
                                                                    </Message>
                                                                }
                                                                {watershedReportForm && watershedReportForm.id > 0 &&
                                                                    <WGReportingOutputs
                                                                        lookups={props.lookups}
                                                                        setActiveItem={setActiveItem}
                                                                        selectedParams={selectedParams}
                                                                        jurisdictionGroups={jurisdictionWatershedGroupList}
                                                                        finalLock={props.lookups.getWatershedReportStatusInternalNameById(groupStatus.watershedReportStatusId) === "final" ? true : false}
                                                                        watershedReportForm={watershedReportForm}
                                                                        setSidebarProjectInfoObj={props.setSidebarProjectInfoObj}
                                                                        dispatchSidebar={props.dispatchSidebar}
                                                                        scrollFunction={scrollFunction}
                                                                    />
                                                                }
                                                            </>
                                                        }
                                                        {activeItem === '1_6' && selectedView === SelectedView.Jurisdiction && watershedReportForm && watershedReportForm.id > 0 &&
                                                            <JurisReportingOutputs
                                                                lookups={props.lookups}
                                                                setActiveItem={setActiveItem}
                                                                selectedParams={selectedParams}
                                                                jurisdictionGroup={activeJurisdictionGroup}
                                                            />
                                                        }
                                                        {selectedView === SelectedView.GroupLead && selectedParams.watershedGroupId > 0 &&
                                                            <WatershedReportPublicFacingConfigComponent
                                                                selectedWmgId={selectedParams.watershedGroupId}
                                                                activeItem={activeItem}
                                                                setActiveItem={setActiveItem}
                                                                lookups={props.lookups}
                                                                setSidebarProjectInfoObj={props.setSidebarProjectInfoObj}
                                                                dispatchSidebar={props.dispatchSidebar}
                                                                scrollFunction={scrollFunction}
                                                            />
                                                        }
                                                    </div>
                                                </>
                                            }
                                        </>
                                    }
                                </>
                            );
                        }}
                    </GlobalAuthInfoContext.Consumer>
                )}
            </Translation>
        </div>
    )
}
