import { useCallback } from "react";
import { IProjectEditPageProps } from "../../sidebars/editProjectPage/EditProjectPage";
import BmpGeneralAttributes from "../../../models/BmpGeneralAttributes";
import { AddonInputComponent } from "../../../paradigmLib/domElements/addonInputComponent/AddonInputComponent";
import "./ProjectEditPages.scss";
import { BmpClassId } from "../../../models/lutModels/LutBmpClass";

export const ProjAttributes = (props: IProjectEditPageProps) => {
    const setAttributes = useCallback((newAttributes: BmpGeneralAttributes) => {
        props.setProject({
            ...props.project,
            bmpGeneralAttributes: newAttributes,
        });
    },[props]);

    return (
        <div className="edit-proj-attributes pt-point5">
            {props.project && props.project.bmpClassId && (
                <>
                    {(props.project.bmpClassId === BmpClassId.WatershedControlMeasure || props.project.bmpClassId === BmpClassId.NewAndRedevelopment) && (
                        <AddonInputComponent
                            label="Project Footprint"
                            placeHolder="5.7"
                            value={props.project.bmpGeneralAttributes.footprintAc ?? ""}
                            setValue={(value) => setAttributes({...props.project.bmpGeneralAttributes, footprintAc: value !== "" ? value : null})}
                            inputType="number"
                            theme="primary"
                            addonSide="right"
                            addonContent="acres"
                        />
                    )}

                    {props.project.bmpClassId === BmpClassId.WatershedControlMeasure &&
                        <AddonInputComponent
                            label="Native Soil Infiltration Rate"
                            placeHolder="3.2"
                            value={props.project.bmpGeneralAttributes.soilInfiltrationRateInHr ?? ""}
                            setValue={(value) => setAttributes({...props.project.bmpGeneralAttributes, soilInfiltrationRateInHr: value !== "" ? value : null})}
                            inputType="number"
                            theme="primary"
                            addonSide="right"
                            addonContent="in/hr"
                        />
                    }

                    {(props.project.bmpClassId === BmpClassId.WatershedControlMeasure || props.project.bmpClassId === BmpClassId.NewAndRedevelopment) &&
                        <>
                            <AddonInputComponent
                                label="Drainage Area"
                                placeHolder="5"
                                value={props.project.bmpGeneralAttributes.drainageAreaAc ?? ""}
                                setValue={(value) => setAttributes({...props.project.bmpGeneralAttributes, drainageAreaAc: value !== "" ? value : null})}
                                inputType="number"
                                theme="primary"
                                addonSide="right"
                                addonContent="acres"
                            />
                            <AddonInputComponent
                                label="% Imperviousness of Drainage Area"
                                placeHolder="12"
                                value={props.project.bmpGeneralAttributes.drainagePercentImperviousPercent ?? ""}
                                setValue={(value) => setAttributes({...props.project.bmpGeneralAttributes, drainagePercentImperviousPercent: value !== "" ? value : null})}
                                inputType="number"
                                theme="primary"
                                addonSide="right"
                                addonContent="%"
                            />
                        </>
                    }
                    {props.project.bmpClassId === BmpClassId.WatershedControlMeasure &&
                        <AddonInputComponent
                            label="Projected Project Storage Capacity in WMP"
                            placeHolder="2.3"
                            value={props.project.bmpGeneralAttributes.projectedStorageCapacityAcft ?? ""}
                            setValue={(value) => setAttributes({...props.project.bmpGeneralAttributes, projectedStorageCapacityAcft: value !== "" ? value : null})}
                            inputType="number"
                            theme="primary"
                            addonSide="right"
                            addonContent="ac-ft"
                        />
                    }
                    {(props.project.bmpClassId === BmpClassId.WatershedControlMeasure || props.project.bmpClassId === BmpClassId.NewAndRedevelopment) &&
                            <AddonInputComponent
                            label="Actual Project Storage Capacity"
                            placeHolder="3.6"
                            value={props.project.bmpGeneralAttributes.actualStorageCapacityAcft ?? ""}
                            setValue={(value) => setAttributes({...props.project.bmpGeneralAttributes, actualStorageCapacityAcft: value !== "" ? value : null})}
                            inputType="number"
                            theme="primary"
                            addonSide="right"
                            addonContent="ac-ft"
                        />
                    }

                    {props.project.bmpClassId === BmpClassId.WatershedControlMeasure &&
                        <AddonInputComponent
                            label="24-hour Project Storage Capacity"
                            placeHolder="3.6"
                            value={props.project.bmpGeneralAttributes.dailyStorageCapacityAcft ?? ""}
                            setValue={(value) => setAttributes({...props.project.bmpGeneralAttributes, dailyStorageCapacityAcft: value !== "" ? value : null})}
                            inputType="number"
                            theme="primary"
                            addonSide="right"
                            addonContent="ac-ft"
                        />
                    }
                    {props.project.bmpClassId === BmpClassId.WatershedControlMeasure && props.project.bmpTypeId &&
                        <AddonInputComponent
                            label="Infiltration Well Drawdown Rate"
                            placeHolder="3.6"
                            value={props.project.bmpGeneralAttributes.greenStreetInfiltrationWellDrawdownRateCfs ?? ""}
                            setValue={(value) => setAttributes({...props.project.bmpGeneralAttributes, greenStreetInfiltrationWellDrawdownRateCfs: value !== "" ? value : null})}
                            inputType="number"
                            theme="primary"
                            addonSide="right"
                            addonContent="cfs"
                        />
                    }

                    {props.project.bmpClassId === BmpClassId.WatershedControlMeasure &&
                        <>
                            <AddonInputComponent
                                label="If present, what is diversion structure design rate? If inline, leave blank"
                                placeHolder="3.6"
                                value={props.project.bmpGeneralAttributes.regionalDiversionDesignRateCfs ?? ""}
                                setValue={(value) => setAttributes({...props.project.bmpGeneralAttributes, regionalDiversionDesignRateCfs: value !== "" ? value : null})}
                                inputType="number"
                                theme="primary"
                                addonSide="right"
                                addonContent="cfs"
                            />
                            <AddonInputComponent
                                label="Drawdown Rate for Use or Treatment and Release"
                                placeHolder="3.6"
                                value={props.project.bmpGeneralAttributes.regionalDrawdownRateCfs ?? ""}
                                setValue={(value) => setAttributes({...props.project.bmpGeneralAttributes, regionalDrawdownRateCfs: value !== "" ? value : null})}
                                inputType="number"
                                theme="primary"
                                addonSide="right"
                                addonContent="cfs"
                            />
                            <AddonInputComponent
                                label="Green Street Length"
                                placeHolder="3.6"
                                value={props.project.bmpGeneralAttributes.greenStreetLengthFt ?? ""}
                                setValue={(value) => setAttributes({...props.project.bmpGeneralAttributes, greenStreetLengthFt: value !== "" ? value : null})}
                                inputType="number"
                                theme="primary"
                                addonSide="right"
                                addonContent="ac-ft"
                            />
                        </>
                    }
                </>
            )}
        </div>
    );
};
