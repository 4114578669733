import { BaseModel } from "./BaseModel";

export default class MapData extends BaseModel {



    bmp_class: string;
    built: boolean;
    drainage_area_ac: number;
    funding_source_id: number;
    id: number;
    jurisdiction: string;
    location_latitude: number;
    location_longitude: number;
    location_street: string;
    name: string;
    retained_acft: number;
    rw_id: number;
    treated_acft: number;
    type: string;
    volume_managed_acft: number;
    watersheds_id: number;
    active: number;

    constructor() {
        super("Bmp", "local")
    }

}