import { t } from "i18next";
import WelcomeViewModel from "../../../../../models/viewModels/WelcomeViewModel";
import { WaterGauge } from "../../../../waterGauge/WaterGauge";
import "./DashboardBubbles.scss";

export interface IDashboardBubblesProps {
    bubbleInfo: WelcomeViewModel;
    showReducesHeatIslandEffect: boolean,
    showNeighborhoodBeautification: boolean,
    showWaterSupplyAugmentation: boolean,
    showBenefitsToDacs: boolean,
    showProvidesParksAndGreenSpaces: boolean,
    showCleanStreets: boolean,
    showReducesFlooding: boolean,
    showJobCreation: boolean
}

export const DashboardBubbles = (props: IDashboardBubblesProps) => {
    return (
        <div className="dashboard-bubbles">
            <WaterGaugeContainer
                // Reduces Heat Island Effect
                visible={props.showReducesHeatIslandEffect}
                percentValue={props.bubbleInfo.numberHeatIslandBenefit / props.bubbleInfo.numberOfProjects * 100}
                displayValue={props.bubbleInfo.numberHeatIslandBenefit}
                color={"#906c51"}
                text={t('welcomeCoBenefit2')}
            />
            <WaterGaugeContainer
                // Neighborhood Beautification
                visible={props.showNeighborhoodBeautification}
                percentValue={props.bubbleInfo.numberBeautificationBenefit / props.bubbleInfo.numberOfProjects * 100}
                displayValue={props.bubbleInfo.numberBeautificationBenefit}
                color={"#cd7532"}
                text={t('welcomeCoBenefit3')}
            />
            <WaterGaugeContainer
                // Water Supply Augmentation
                visible={props.showWaterSupplyAugmentation}
                percentValue={props.bubbleInfo.numberWaterSupplyBenefit / props.bubbleInfo.numberOfProjects * 100}
                displayValue={props.bubbleInfo.numberWaterSupplyBenefit}
                color={"#A82431"}
                text={t('welcomeCoBenefit5')}
            />
            <WaterGaugeContainer
                // Benefits to DACs
                visible={props.showBenefitsToDacs}
                percentValue={props.bubbleInfo.numberDacBenefit / props.bubbleInfo.numberOfProjects * 100}
                displayValue={props.bubbleInfo.numberDacBenefit}
                color={"#EC9BA9"}
                text={t('welcomeCoBenefit6')}
            />
            <WaterGaugeContainer
                // Provides Parks & Green Spaces
                visible={props.showProvidesParksAndGreenSpaces}
                percentValue={props.bubbleInfo.numberParkBenefit / props.bubbleInfo.numberOfProjects * 100}
                displayValue={props.bubbleInfo.numberParkBenefit}
                color={"#4B7F52"}
                text={t('welcomeCoBenefit1').replace("and", "&")}
            />
            <WaterGaugeContainer
                // Clean Streets
                visible={props.showCleanStreets}
                percentValue={props.bubbleInfo.numberCleanStreetBenefit / props.bubbleInfo.numberOfProjects * 100}
                displayValue={props.bubbleInfo.numberCleanStreetBenefit}
                color={"#4B7F52"}
                text={t('welcomeCoBenefit8')}
            />
            <WaterGaugeContainer
                // Reduces Flooding
                visible={props.showReducesFlooding}
                percentValue={props.bubbleInfo.numberReduceFloodingBenefit / props.bubbleInfo.numberOfProjects * 100}
                displayValue={props.bubbleInfo.numberReduceFloodingBenefit}
                color={"#39A9DA"}
                text={t('welcomeCoBenefit7')}
            />
            <WaterGaugeContainer
                // Job Creation
                visible={props.showJobCreation}
                percentValue={props.bubbleInfo.numberJobCreationBenefit / props.bubbleInfo.numberOfProjects * 100}
                displayValue={props.bubbleInfo.numberJobCreationBenefit}
                color={"#9A9A5B"}
                text={t('welcomeCoBenefit4')}
            />
        </div>
    )
}

export const WaterGaugeContainer = (props: {
    visible: boolean;
    displayValue?: number;
    percentValue?: number;
    color: string;
    text: string;
}) => {
    return (
        <>
            {props.visible &&
                <div className='water-gauge-container'>
                    <WaterGauge
                        value={props.percentValue ?? 0}
                        size={40}
                        color={props.color}
                        displayValue={props.displayValue ? Math.round(props.displayValue) : 0}
                        offsetX={0}
                        offsetY={8}
                        numberSize={1} //size of number inside bubble
                        metricColor={props.color}
                    />
                    <div className="water-gauge-text" style={{ color: props.color }}>
                        <>{props.text}</>
                    </div>
                </div>
            }
        </>
    )
}
