import { useContext, useEffect, useMemo, useState } from "react";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import { Input } from "semantic-ui-react";
import useDebounce from "../../../core/UseDebounce";
import LookupFactory from "../../../lookups/LookupFactory";
import WatershedManagementGroup from "../../../models/lutModels/WatershedManagementGroup";
import ReceivingWaters from "../../../models/ReceivingWaters";
import { MultiSelectComponent } from "../../../paradigmLib/domElements/multiSelectComponent/MultiSelectComponent";
import { GlobalAuthInfoContext } from "../../mainWrapper/MainWrapper";
import "./FilterComponent.scss"
import { BmpClassId } from "../../../models/lutModels/LutBmpClass";

export class ManageProjectFilter {
    statuses: string[] = [];
    bmpClasses: string[] = [];
    bmpTypes: string[] = [];
    watershedManagementGroups: string[] = [];
    receivingWaters: string[] = [];
    keywords: string[] = [];
    minimumCompletionDate?: Date = null;
    maximumCompletionDate?: Date = null;
    minimumCreationDate?: Date = null;
    maximumCreationDate?: Date = null;
    minimumtLastModificationDate?: Date = null;
    maximumtLastModificationDate?: Date = null;
    jurisdictions: string[];
}

export interface IFilterComponentProps {
    onChange: (filter: ManageProjectFilter) => void;
    lookups: LookupFactory
    currentJurisdictionId: number;
}

export const FilterComponent: React.FC<IFilterComponentProps> = (props: IFilterComponentProps) => {
    const globalAuthInfoContext = useContext(GlobalAuthInfoContext);
    const [filter, setFilter] = useState<ManageProjectFilter>(new ManageProjectFilter());
    const [inputReset, setInputReset] = useState<Date>(new Date());

    function handleDateChange(e: any, dateValue: any) {
        let { name, value } = dateValue ?? e.target;
        setFilter({
            ...filter,
            [name]: value,
        });
    };

    const handleMultiselectChange = (name: string, list: any[], _: any) => {
        setFilter({
            ...filter,
            [name]: list.map(l => l.internalName)
        });
    };

    const [keywords, setKeywords] = useState<string[]>();

    const handleKeywordChange = (_: any, data: any) => {
        setKeywords(data.value.split(" ").filter((kw: string) => kw !== ""));
    }

    const debouncedKeywords = useDebounce(keywords, 500);

    useEffect(() => {
        setFilter((prevFilter) => ({
            ...prevFilter,
            keywords: debouncedKeywords
        }));
    }, [debouncedKeywords]);

    const onChange = useMemo(() => props.onChange, [props.onChange]);

    useEffect(() => {
        onChange(filter);
    }, [filter]);

    useEffect(() => {
        //reset filters on jurisdiction change
        setFilter(new ManageProjectFilter());
        setInputReset(new Date());
    }, [props.currentJurisdictionId]);

    const [watershedGroups, setWatershedGroups] = useState<WatershedManagementGroup[]>([]);
    useEffect(() => {
        if (props.lookups?.lutWatershedManagementGroup == null) {
            return;
        }
        var groupIds = props.lookups.jurisdictionGroups
            .filter(jg => jg.jurisdictionId === props.currentJurisdictionId)
            .map(jg => jg.watershedManagementGroupId);

        if (props.currentJurisdictionId === 1087) {
            groupIds = props.lookups.lutWatershedManagementGroup.map(g => g.id);
        }

        const groups = props.lookups.lutWatershedManagementGroup
            .filter(g => groupIds.some(gi => gi === g.id));

        setWatershedGroups(groups);
    }, [props.currentJurisdictionId, props.lookups.jurisdictionGroups, props.lookups.lutWatershedManagementGroup]);


    const receivingWaters: ReceivingWaters[] = useMemo(() => {
        return props.lookups.lutReceivingWaters
            .filter(rw => rw.wsGroupRWaterJurisdictionMapping
                .some(mapping => mapping.jurisdictionId === props.currentJurisdictionId));
    }, [props.currentJurisdictionId, props.lookups.lutReceivingWaters])

    return (
        props.lookups != null &&
        <div className="filter-container" key={`${inputReset}`}>
            <div className="outer-wrapper">
                <div className="filter-wrapper">
                    <MultiSelectComponent
                        name="statuses"
                        label="Project Status"
                        options={props.lookups.lutBmpStatus}
                        onChange={handleMultiselectChange}
                        placeholder="Select from list"
                        displayValue="displayName" />
                    <MultiSelectComponent
                        name="bmpClasses"
                        label="Project Type"
                        options={props.lookups.lutBmpClass.filter(bmpClass => bmpClass.id !== BmpClassId.LowFlowDiversion)}
                        onChange={handleMultiselectChange}
                        placeholder="Select from list"
                        displayValue="displayName" />
                    <MultiSelectComponent
                        name="watershedManagementGroups"
                        label="Watershed Group"
                        options={watershedGroups}
                        onChange={handleMultiselectChange}
                        placeholder="Select from List"
                        displayValue="displayName" />
                </div>
                <div className="filter-wrapper">
                    <MultiSelectComponent
                        name="receivingWaters"
                        label="Receiving Water / Subwatershed"
                        options={receivingWaters}
                        onChange={handleMultiselectChange}
                        placeholder="Select from List"
                        displayValue="displayName" />
                    <MultiSelectComponent
                        name="bmpTypes"
                        label="BMP Type"
                        options={props.lookups.lutBmpType}
                        onChange={handleMultiselectChange}
                        placeholder="Select from List"
                        displayValue="displayName" />
                    <div>
                        <div className="basic-input-label-wrapper">
                            <label className="basic-input-label">Keywords</label>
                        </div>
                        <Input
                            onChange={handleKeywordChange}
                            name="keywords"
                            focus
                            placeholder=""
                            icon="search" />
                    </div>
                </div>
            </div>
            <div className="dates-wrapper">
                <div>
                    <div className="basic-input-label-wrapper">
                        <label className="basic-input-label">Completion Date Between</label>
                    </div>
                    <div className="date-input-wrapper">
                        <SemanticDatepicker
                            name="minimumCompletionDate"
                            onChange={handleDateChange}
                            placeholder="Start Date" />
                        <SemanticDatepicker
                            name="maximumCompletionDate"
                            onChange={handleDateChange}
                            placeholder="End Date" />
                    </div>
                </div>
                <div>
                    <div className="basic-input-label-wrapper">
                        <label className="basic-input-label">Creation Date Between</label>
                    </div>
                    <div className="date-input-wrapper">
                        <SemanticDatepicker
                            name="minimumCreationDate"
                            onChange={handleDateChange}
                            placeholder="Start Date" />
                        <SemanticDatepicker
                            name="maximumCreationDate"
                            onChange={handleDateChange}
                            placeholder="End Date" />
                    </div>
                </div>
                <div>
                    <div className="basic-input-label-wrapper">
                        <label className="basic-input-label">Modification Date Between</label>
                    </div>
                    <div className="date-input-wrapper">
                        <SemanticDatepicker
                            name="minimumLastModificationDate"
                            onChange={handleDateChange}
                            placeholder="Start Date" />
                        <SemanticDatepicker
                            name="maximumLastModificationDate"
                            onChange={handleDateChange}
                            placeholder="End Date" />
                    </div>
                </div>
            </div>
        </div>
    );
}
