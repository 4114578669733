export const programMessageDescription: string = "The WRAMPS Watershed Management Program (WMP) Dashboard is a data visualization tool that allows interested parties to explore a WMP's control measures and progress. Individuals can view summaries on projects planned and implemented in each subwatershed and the stormwater and community benefits they provide."

export const programSummaryVisualizationDescription: string = "The sunburst chart below visually summarizes projects implemented and planned by this Watershed Management Program (WMP) using metrics like count, area managed, and project footprint. Project metrics are tallied by categories such as subwatershed, completion status, and project type. Please use the buttons below to explore the WMP progress metrics and click on the slices of the chart for more resolution!"

export const programSummaryMultiBenefitsDescription: string = "Community Co-Benefits are the additional benefits resulting from Watershed Control Measures implemented to improve water quality. The 2021 Los Angeles Regional Municipal Separate Storm Sewer Systems (MS4s) Permit (Order No. R4-2021-0105) asks that Permittees report information for Community Co-Benefits such as reduces heat island effect, neighborhood beautification, water supply augmentation, benefits to Disadvantaged Communities (DACs), and provides parks and green spaces. Each Community Co-Benefit bubble below summarizes the number of Watershed Control Measures implemented after December 28th, 2012 by participating Watershed Management Programs that provide that Community Co-Benefit. To learn more about participating Watershed Management Programs, please visit their Progress Reporting page, accessible through the Home page."

export const programProgressDescription: string = "The Program Progress chart visually summarizes a Watershed Management Program’s (WMP) progress over time using metrics like count, area managed, and footprint from projects implemented after December 28th, 2012. Project completion dates are used to inform the time component of the chart. Displayed data may include both Structural Watershed Control Measures (WCMs) and New & Redevelopment Projects, or Structural WCMs only. Please use the buttons and dropdowns below to explore WMP progress metrics. Hover on the chart for even more resolution!"

export const programSummaryTableDescription: string = "The table below summarizes metrics for projects, “rolled up” by subwatershed. The projects tallied within this summary can be seen in the map and more detailed table below."

export const projectDetailsMapDescription: string = "The interactive Project Details Map displays locations of individual projects implemented by the Watershed Management Program. Explore the legend for more basemaps related to the watersheds and water quality and click on the map to learn more about each Structural Watershed Control Measure (WCM)!"

export const projectDetailsTableDescription: string = "The Project Details Table lists each Watershed Control Measure (WCM) implemented by the Watershed Management Program (WMP). Details like Project Name, Type, and Status are visible in the table. Click on the 'details' button in each row to view additional information for that project!"

export const additionalInfoDescription: string = "Please explore the following links for additional information relevant to this Watershed Management Program."