import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Map, { GeoJSON, Layer, MapProps, WMS } from "@paradigmh2o/react-map";
import LookupFactory from '../../lookups/LookupFactory';

import Config from '../../config/Config';
import createCapMapConfig from './captureMapConfig';
import { Legend } from '../reporting/buildWatershedForm/wmgPublicDashboard/Map/Legend';
import CompleteWCMMarker from '../../images/CompleteWCMMarker.png';

import lfdMarker from '../../images/LowFlowDiversionMarker.png';
import spreadingGroundsMarker from '../../images/SpreadingGroundMarker.png';
import "./CaptureDashboardMapComponent.scss";
import { CaptureBenefitFilterProjectType, CaptureBenefitFilterType } from '../../services/CaptureBenefitService';
import qs from 'qs';

interface CaptureDashboardMapProps {
    onBmpClick: (bmpId: number) => Promise<void>;
    onLfdClick: (lfdId: number) => Promise<void>;
    onSgClick: (sgId: number) => Promise<void>;
    lookups: LookupFactory;
    selectedFilterType: CaptureBenefitFilterType | null;
    selectedFilterIds: number[];
    filterStartDate: Date;
    filterEndDate: Date;
    isMobile: boolean;
}

const subLayers: WMS[] = [
    { name: "Safe Clean Water Program Watershed Areas", viz: true },
    { name: "Jurisdictions", viz: true },
    { name: "Watershed Management Groups (WMGs)", viz: true },
    { name: "Subwatersheds", viz: false },
    { name: "Major Watersheds", viz: false },
    { name: "Channels(Drainage)", viz: true },
    { name: "Storm Drains", viz: false }
].map((x, i) => {
    return new WMS(
        i.toString(),
        x.name,
        Config.map_connection_url,
        x.viz,
        [x.name],
    );
});

enum LayerNames {
    LowFlowDiversions = "lowFlowDiversions",
    Bmps = "completedWCMs",
    SpreadingGrounds = "spreadingGroundsPoints"
}

const createLfdLayer = (selectedFilterType: CaptureBenefitFilterType | null, selectedFilterIds: number[], filterStartDate: Date, filterEndDate: Date, geoJsonClick: (e: __esri.HitTestResult) => void) => {
    return new GeoJSON(
        LayerNames.LowFlowDiversions,
        "Low Flow Diversions",
        `${Config.api_endpoint}GeoJson/LfdMapData?${qs.stringify({ type: selectedFilterType, ids: selectedFilterIds, startDate: filterStartDate, endDate: filterEndDate })}`,
        true,
        true,
        {
            type: "simple",
            symbol: {
                type: "picture-marker",
                url: lfdMarker,
                width: "38px",
                height: "38px"
            }
        },
        geoJsonClick
    );
}

const createBmpLayer = (selectedFilterType: CaptureBenefitFilterType | null, selectedFilterIds: number[], filterStartDate: Date, filterEndDate: Date, geoJsonClick: (e: __esri.HitTestResult) => void) => {
    return new GeoJSON(
        LayerNames.Bmps,
        "Watershed Control Measures",
        `${Config.api_endpoint}GeoJson/CaptureDashboardBmps?${qs.stringify({ type: selectedFilterType, ids: selectedFilterIds, startDate: filterStartDate, endDate: filterEndDate })}`,
        true,
        true,
        {
            type: "simple",
            symbol: {
                type: "picture-marker",
                url: CompleteWCMMarker,
                width: "38px",
                height: "38px"
            }
        },
        geoJsonClick
    );
}

const createSpreadingGroundsLayer = (selectedFilterType: CaptureBenefitFilterType | null, selectedFilterIds: number[], filterStartDate: Date, filterEndDate: Date, geoJsonClick: (e: __esri.HitTestResult) => void) => {
    return new GeoJSON(
        LayerNames.SpreadingGrounds,
        "Spreading Grounds",
        `${Config.api_endpoint}SpreadingGrounds/GeoJson/Point?${qs.stringify({ type: selectedFilterType, ids: selectedFilterIds, startDate: filterStartDate, endDate: filterEndDate })}`,
        true,
        true,
        {
            type: "simple",
            symbol: {
                type: "picture-marker",
                url: spreadingGroundsMarker,
                width: "38px",
                height: "38px"
            }
        },
        geoJsonClick
    );
}

export const CaptureDashboardMap: React.FC<CaptureDashboardMapProps> = (props) => {
    // destructured to avoid re-rendering when all the props are updated
    const { onBmpClick, onLfdClick, onSgClick } = props;

    const [layers, setLayers] = useState<Layer[]>([...subLayers]);

    const geoJsonClick = useCallback(async (e: __esri.HitTestResult) => {
        if (!e || !e.results || e.results.length === 0) {
            return;
        }

        switch (e.results[0]?.layer.id) {
            case LayerNames.SpreadingGrounds:
            await onSgClick((e.results[0] as __esri.GraphicHit)?.graphic.attributes.id);
                break;
            case LayerNames.LowFlowDiversions:
                await onLfdClick((e.results[0] as __esri.GraphicHit)?.graphic.attributes.id);
                break;
            case LayerNames.Bmps:
                await onBmpClick((e.results[0] as __esri.GraphicHit)?.graphic.attributes.bmpGeneralId);
                break;
        }
    }, [onSgClick, onLfdClick, onBmpClick]);

    const toggleLayerVisibility = useCallback((layer: Layer) => {
        layers
            .filter(l => l === layer)
            .forEach(l => l.visible = !l.visible);
        setLayers([...layers]);
    }, [layers, setLayers]);

    useEffect(() => {
        const bmpLayerVisible =
            // Always show Bmps except when the selected filter is Project Type and the selected filter doesn't include BMP
            !(props.selectedFilterType === CaptureBenefitFilterType.ProjectType && !props.selectedFilterIds.includes(CaptureBenefitFilterProjectType.Bmp))

        const lfdLayerVisible =
            // Show LFDs only when the selected filter is Project Type and the selected filter is Low Flow Diversion or the selected filter is Jurisdiction and the selected filter is LACFCD or there is no filter
            (props.selectedFilterType === CaptureBenefitFilterType.ProjectType && props.selectedFilterIds.includes(CaptureBenefitFilterProjectType.LowFlowDiversion))
                || (props.selectedFilterType === CaptureBenefitFilterType.Jurisdiction && props.selectedFilterIds.includes(1089))
                || (props.selectedFilterType === CaptureBenefitFilterType.Watershed)
                || (props.selectedFilterType === null);

        const spreadingGroundsLayerVisible =
            // Show Spreading Grounds only when the selected filter is Project Type and the selected filter is Spreading Grounds or the selected filter is Jurisdiction and the selected filter is LACFCD or there is no filter
            (props.selectedFilterType === CaptureBenefitFilterType.ProjectType && props.selectedFilterIds.includes(CaptureBenefitFilterProjectType.SpreadingGround))
                || (props.selectedFilterType === CaptureBenefitFilterType.Jurisdiction && props.selectedFilterIds.includes(1089))
                || (props.selectedFilterType === CaptureBenefitFilterType.Watershed)
                || (props.selectedFilterType === null);

        setLayers((existingLayers) => {
            // add or remove layers if needed
            if (!(lfdLayerVisible) && existingLayers.some(l => l.id === LayerNames.LowFlowDiversions)) {
                // exists and shouldn't exist
                existingLayers = existingLayers.filter(l => l.id !== LayerNames.LowFlowDiversions);
            }
            else if (lfdLayerVisible && !existingLayers.some(l => l.id === LayerNames.LowFlowDiversions)) {
                // doesn't exist and should exist
                existingLayers.push(createLfdLayer(props.selectedFilterType, props.selectedFilterIds, props.filterStartDate, props.filterEndDate, geoJsonClick));
            }
            else if (lfdLayerVisible && existingLayers.some(l => l.id === LayerNames.LowFlowDiversions)) {
                // exists but needs refresh
                const lfdLayerIndex = existingLayers.findIndex(l => l.id === LayerNames.LowFlowDiversions);
                existingLayers[lfdLayerIndex] = createLfdLayer(props.selectedFilterType, props.selectedFilterIds, props.filterStartDate, props.filterEndDate, geoJsonClick);
            }

            if (!spreadingGroundsLayerVisible && existingLayers.some(l => l.id === LayerNames.SpreadingGrounds)) {
                existingLayers = existingLayers.filter(l => l.id !== LayerNames.SpreadingGrounds);
            }
            else if (spreadingGroundsLayerVisible && !existingLayers.some(l => l.id === LayerNames.SpreadingGrounds)) {
                existingLayers.push(createSpreadingGroundsLayer(props.selectedFilterType, props.selectedFilterIds, props.filterStartDate, props.filterEndDate, geoJsonClick));
            }
            else if (spreadingGroundsLayerVisible && existingLayers.some(l => l.id === LayerNames.SpreadingGrounds)) {
                // exists but needs refresh
                const sgLayerIndex = existingLayers.findIndex(l => l.id === LayerNames.SpreadingGrounds);
                existingLayers[sgLayerIndex] = createSpreadingGroundsLayer(props.selectedFilterType, props.selectedFilterIds, props.filterStartDate, props.filterEndDate, geoJsonClick);
            }

            if (!bmpLayerVisible && existingLayers.some(l => l.id === LayerNames.Bmps)) {
                existingLayers = existingLayers.filter(l => l.id !== LayerNames.Bmps);
            }
            else if (bmpLayerVisible && !existingLayers.some(l => l.id === LayerNames.Bmps)) {
                existingLayers.push(createBmpLayer(props.selectedFilterType, props.selectedFilterIds, props.filterStartDate, props.filterEndDate, geoJsonClick));
            }
            else if (bmpLayerVisible && existingLayers.some(l => l.id === LayerNames.Bmps)) {
                // exists but needs refresh
                const bmpLayerIndex = existingLayers.findIndex(l => l.id === LayerNames.Bmps);
                existingLayers[bmpLayerIndex] = createBmpLayer(props.selectedFilterType, props.selectedFilterIds, props.filterStartDate, props.filterEndDate, geoJsonClick);
            }

            return existingLayers;
        });
    }, [geoJsonClick, props.filterEndDate, props.filterStartDate, props.selectedFilterIds, props.selectedFilterType]);

    const mapProps: MapProps = useMemo(() => {
        // memoized map props. But excludes the layer and Legend props because
        // when layers is memoized, the react-map or esri doesn't recognize changes for some reason
        return (
            {...createCapMapConfig(props.lookups),
                onMapViewCreated:() => { },
                zoomWidgetPosition:"top-left"
            } as MapProps
        )
    }, [props.lookups])


    return (
        <>
            <div className="map-box">
                <div className="map" style={{ height: ` ${ props.isMobile ? "480px" :"750px"}`, width: "100%" }}
                    >
                    <Map
                        {...mapProps}
                        layers={[...layers]}
                        legend={(layers: Array<Layer>) =>
                            <Legend
                                layers={layers}
                                toggleVisibility={toggleLayerVisibility}
                                topUnhiddenLayers={layers.length - subLayers.length}
                            />}
                    />
                </div>
            </div>
        </>
    );
}
