import { ChangeEvent, useEffect, useState } from 'react';
import { Button, Form, Modal } from 'semantic-ui-react';
import { IUploadGroupOutputModal } from './iUploadGroupOutputModal';
import Wave from "../../../images/modalWave.svg";
import Wave2 from "../../../images/modalWave2.svg";
import './uploadGroupOutputModal.scss'
import { enumUploadStatus, uploadOptions } from '../../../customTypes/stringLiterals';
import { BaseModel } from '../../../models/BaseModel';
import { ButtonComponent } from '../../../paradigmLib/domElements/buttonComponent/ButtonComponent';
import DropdownLutH2O from '../../../paradigmLib/dropdownLut/DropdownLut';
import { usePDFPost } from '../../../core/UseAPILocal'
import { useAuth } from 'react-oidc-context';

export const UploadGroupOutputModal: any = (props: IUploadGroupOutputModal) => {
    const auth = useAuth();
    const [fileSelected, setFileSelected] = useState(false);
    const [chooseFile, setChooseFile] = useState('No file chosen...');
    const [formData, setFormData] = useState<FormData>();
    const [error, setError] = useState<enumUploadStatus>(enumUploadStatus.Ready);
    const [deleteModel, setDeleteModel] = useState<BaseModel | null>(null);
    const [fileUploaded, setFileUploaded] = useState<uploadOptions>(uploadOptions.Waiting);
    const [selectedLanguage, setSelectedLanguage] = useState<number>(null);

    const cancel = () => {
        props.setOpen(false);

        setFileUploaded(uploadOptions.Waiting);

        setDeleteModel(null);
        setError(enumUploadStatus.Ready);
        setSelectedLanguage(null);
        setChooseFile('No file chosen...');
    };

    useEffect(() => {
        if (!props.open) {
            cancel();
        }
    }, [props]);

    let attachment = props.getAttachmentModel();

    const [attachmentModel, setAttachmentModel] = useState<BaseModel>(attachment);
    const [updateAttachmentModel, setUpdateAttachmentModel] = useState<boolean>(false);

    usePDFPost(
        auth.user?.access_token,
        attachmentModel,
        setAttachmentModel,
        formData,
        updateAttachmentModel,
        setUpdateAttachmentModel,
        (result: BaseModel) => {
            props.refetchData();
            setFileSelected(false);
            setFormData(new FormData());
            setSelectedLanguage(null);
            setAttachmentModel(props.getAttachmentModel())
        });

    useEffect(() => {
        attachment.lutLanguageId = selectedLanguage;
        attachment.lutWatershedGroupOutputTypeId = props.groupOutputTypeId
        setAttachmentModel(attachment);
    }, [selectedLanguage, props.groupOutputTypeId])

    function clearErrors() {
        setError(enumUploadStatus.Ready);
        setFileUploaded(uploadOptions.Waiting);
    }

    function handleFileSelect(evt: ChangeEvent<HTMLInputElement>) {
        let formData: FormData = new FormData();
        let file: File = evt.target.files[0];

        if (!file) {
            setError(enumUploadStatus.SelectFile);
            return;
        }
        formData.append("data", file);
        setError(enumUploadStatus.Ready);
        setFormData(formData);
        setFileSelected(true);
        setChooseFile(file.name);
    };

    function uploadSelectedFile() {
        if (!fileSelected) {
            setError(enumUploadStatus.SelectFile);
            return;
        }

        if (!selectedLanguage && props.hasDropdown) {
            setError(enumUploadStatus.FieldsRequired);
            return;
        }

        clearErrors();

        if (props.singleUpload && props.objToDelete.length > 0) {
            setDeleteModel(props.objToDelete[0]);
        } else {
            setUpdateAttachmentModel(true);
            if (props.uploadAndClose === true) {
                cancel();
            }
        }
    };

    return (
        <Modal className="upload-modal-component"
            onClose={() => props.setOpen(false)}
            onOpen={() => props.setOpen(true)}
            open={props.open}>
            <Modal.Header>
                <div className="upload-modal-title">{props.headerText}</div>
                <div className="wave-div">
                    <img src={Wave} alt="wave" />
                </div>
            </Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    <div className="uploader mb-1">
                        {
                            error === enumUploadStatus.SelectFile &&
                            <span className="text-danger ml-2 mb-1">***Please select a file to upload</span>
                        }
                        {
                            fileUploaded === uploadOptions.PDFExpected &&
                            <span className="text-danger ml-2 mb-1">***Please a attach PDF file only</span>
                        }
                        {
                            fileUploaded === uploadOptions.ShapeExpected &&
                            <span className="text-danger ml-2 mb-1">***Please a attach Shape file only</span>
                        }
                        <div className="file-upload">
                            <div className="file-select">
                                <div className="file-select-button" id="fileName">Choose File</div>
                                <div className="file-select-name" id="chooseFile">{chooseFile}</div>
                                <input type="file" accept={props.allowExcelAndPdf ? ".xlsx,application/pdf" : "application/pdf"} name="chooseFile" id="chooseFile" onChange={handleFileSelect} />
                            </div>
                        </div>
                        <div>
                            <Form>
                                {props.hasDropdown &&
                                    <Form.Field className='lan-dropdown-container'>
                                        <DropdownLutH2O
                                            //map to get the displayName from displaynameEnglish
                                            dropdownItems={props.languagelookup ? props.languagelookup
                                                .map(x => ({ ...x, displayName: x.displayNameEnglish })) : []}
                                            dropdownTitle={"Language"}
                                            isMultiselect={false}
                                            nullLabel={null}
                                            onSelect={(e, d) => { setSelectedLanguage(d.value) }}
                                            ddSlim={true}
                                            parentId={selectedLanguage}
                                        />
                                    </Form.Field>
                                }
                            </Form>
                        </div>
                    </div>
                </Modal.Description>
            </Modal.Content>
            <div className="action-div">
                <div className="wave-div">
                    <img src={Wave2} alt="wave" className="wave" />
                </div>
                {
                    fileUploaded === uploadOptions.Success &&
                    <Modal.Actions>
                        <div>
                            Upload Successful
                        </div>
                        <div>
                            <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                            </svg>
                        </div>
                        <Button color="grey" onClick={cancel}>Close</Button>
                    </Modal.Actions>
                }
                {
                    fileUploaded === uploadOptions.Failed &&
                    <Modal.Actions className="pdf-modal-actions">
                        <div className="error-div">
                            There was a issue with your upload
                        </div>
                        <ButtonComponent
                            onBtnClick={uploadSelectedFile}
                            label="Try Agian"
                            theme={"primaryBtn"}
                        />
                        <Button color="grey" onClick={cancel}>Cancel</Button>
                    </Modal.Actions>
                }
                {
                    ((fileUploaded === uploadOptions.Waiting || fileUploaded === uploadOptions.PDFExpected || fileUploaded === uploadOptions.ShapeExpected)) &&
                    <Modal.Actions className="pdf-modal-actions">
                        <ButtonComponent
                            onBtnClick={uploadSelectedFile}
                            label="Upload"
                            disabled={error !== enumUploadStatus.Ready}
                            theme={"primaryBtn"}
                        />
                        <Button color="grey" onClick={cancel}>Cancel</Button>
                    </Modal.Actions>
                }
            </div>
        </Modal>
    )
}
