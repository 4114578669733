import { useContext, useMemo, useState, useEffect, useCallback, Dispatch, SetStateAction } from "react";
import { toast, ToastContainer } from "react-toastify";
import { compareConfigs, WatershedReportPublicFacingConfig, WatershedReportPublicFacingConfigLinkInfo } from "../../../models/viewModels/WatershedReportPublicFacingConfigViewModel";
import { WatershedReportPublicFacingConfigService } from "../../../services/WatershedReportPublicFacingConfigService";
import { GlobalAuthInfoContext } from "../../mainWrapper/MainWrapper";
import { PreviewPublicElements } from "./previewPublic/PreviewPublicElements";
import * as defaultDescriptions from "./ConfigMessages";
import { PublicConfigElements } from "./publicConfig/PublicConfigElements";
import LookupFactory from "../../../lookups/LookupFactory";
import { WatershedReportSnapshot, WatershedReportSnapshotBmp } from "../../../models/WatershedReportSnapshot";
import { ManageProjectFilter } from "../filterComponent/FilterComponent";
import { BmpSnapshotService } from "../../../services/BmpSnapshotService";
import { PublishingControls } from "./publishingControls/PublishingControls";
import { MessageModal } from "../../modals/MessageModal/MessageModal";
import BmpGeneral from "../../../models/BmpGeneral";
import { useAuth } from "react-oidc-context";

export interface IWatershedReportPublicFacingConfigProps {
    selectedWmgId: number;
    activeItem: string;
    setActiveItem: React.Dispatch<React.SetStateAction<string>>;
    lookups: LookupFactory;
    scrollFunction: Function;
    setSidebarProjectInfoObj: Dispatch<SetStateAction<BmpGeneral>>;
    dispatchSidebar: Function;
}

export const WatershedReportPublicFacingConfigComponent = (props: IWatershedReportPublicFacingConfigProps) => {
    const auth = useAuth();
    const globalAuthInfoContext = useContext(GlobalAuthInfoContext);
    const publicFacingConfigService = useMemo(() => new WatershedReportPublicFacingConfigService(auth.user?.access_token), [auth]);
    const bmpSnapshotService = useMemo(() => new BmpSnapshotService(auth.user?.access_token), [auth]);
    const [openConfigSections, setOpenConfigSections] = useState<number[]>([0]);

    const [draftLoaded, setDraftLoaded] = useState<boolean>(false)
    const [finalLoaded, setFinalLoaded] = useState<boolean>(false)
    const [localConfig, setLocalConfig] = useState<WatershedReportPublicFacingConfig | undefined>(undefined)
    const [draftConfig, setDraftConfig] = useState<WatershedReportPublicFacingConfig | undefined>(undefined)
    const [finalConfig, setFinalConfig] = useState<WatershedReportPublicFacingConfig | undefined>(undefined)
    const [activeSavedConfig, setActiveSavedConfig] = useState<WatershedReportPublicFacingConfig | undefined>(undefined)
    const [localLinks, setLocalLinks] = useState<Array<WatershedReportPublicFacingConfigLinkInfo>>(new Array<WatershedReportPublicFacingConfigLinkInfo>());
    const [draftLinks, setDraftLinks] = useState<Array<WatershedReportPublicFacingConfigLinkInfo>>(new Array<WatershedReportPublicFacingConfigLinkInfo>());
    const [finalLinks, setFinalLinks] = useState<Array<WatershedReportPublicFacingConfigLinkInfo>>(new Array<WatershedReportPublicFacingConfigLinkInfo>());
    const [activeSavedLinks, setActiveSavedLinks] = useState<Array<WatershedReportPublicFacingConfigLinkInfo>>(new Array<WatershedReportPublicFacingConfigLinkInfo>());

    const defaultConfig: WatershedReportPublicFacingConfig = useMemo(() => {
        return new WatershedReportPublicFacingConfig(
            props.selectedWmgId,
            defaultDescriptions.programMessageDescription,
            defaultDescriptions.programSummaryVisualizationDescription,
            defaultDescriptions.programSummaryMultiBenefitsDescription,
            defaultDescriptions.programProgressDescription,
            defaultDescriptions.programSummaryTableDescription,
            defaultDescriptions.projectDetailsMapDescription,
            defaultDescriptions.projectDetailsTableDescription,
            defaultDescriptions.additionalInfoDescription)
    }, [props.selectedWmgId])

    const setOpenConfigSectionsFromConfig = useCallback((config: WatershedReportPublicFacingConfig) => {
        setOpenConfigSections(
            [
                config.programMessageEnabled ? 0 : null,
                config.programSummaryVisualizationEnabled ? 1 : null,
                config.programSummaryMultiBenefitsEnabled ? 2 : null,
                config.programProgressEnabled ? 3 : null,
                config.programSummaryTableEnabled ? 4 : null,
                config.projectDetailsMapEnabled ? 5 : null,
                config.projectDetailsTableEnabled ? 6 : null,
                config.additionalInfoEnabled ? 7 : null,
            ]
        );
    }, [setOpenConfigSections])

    useEffect(() => {
        if (draftLoaded && finalLoaded) {
            setLoadedConfigs();
        }
    }, [draftLoaded, finalLoaded])

    const setLoadedConfigs = useCallback(() => {
        if (!(draftLoaded && finalLoaded)) { return; }
        // Set proper config state when the draft and final is loaded
        if (draftConfig && !finalConfig) {
            // Only draft exists
            setLocalConfig({ ...draftConfig });
            setLocalLinks(Array.from(draftLinks));
            setActiveSavedConfig({ ...draftConfig });
            setActiveSavedLinks(Array.from(draftLinks));
            setOpenConfigSectionsFromConfig(draftConfig);
        }
        else if (!draftConfig && finalConfig) {
            // Only final exists
            setLocalConfig({ ...finalConfig });
            setLocalLinks(Array.from(finalLinks));
            setActiveSavedConfig({ ...finalConfig });
            setActiveSavedLinks(Array.from(finalLinks));
            setOpenConfigSectionsFromConfig(finalConfig);
        }
        else if (draftConfig && finalConfig) {
            // both final and draft exists - use draft
            setLocalConfig({ ...draftConfig });
            setLocalLinks(Array.from(draftLinks));
            setActiveSavedConfig({ ...draftConfig });
            setActiveSavedLinks(Array.from(draftLinks));
            setOpenConfigSectionsFromConfig(draftConfig)
        }
        else if (!draftConfig && !finalConfig) {
            // Neither exist
            setLocalConfig({ ...defaultConfig });
            setLocalLinks([]);
            setActiveSavedConfig(undefined);
            setActiveSavedLinks(undefined);
            setOpenConfigSections([0, 1, 2, 3, 4, 5, 6, 7]); //open all
            if (props.activeItem === "1_8" || props.activeItem === "1_9") {
                props.setActiveItem("1_7") // cant be in preview or publish if no configs
            }
        }

    }, [defaultConfig, draftConfig, draftLinks, draftLoaded, finalConfig, finalLinks, finalLoaded, setOpenConfigSectionsFromConfig])

    useEffect(() => {
        // Get Draft Config on load
        (async () => {
            try {
                let viewModel = await publicFacingConfigService.getDraftConfig(props.selectedWmgId!);
                if (!viewModel || (!viewModel.watershedReportPublicFacingConfig && viewModel.watershedReportPublicFacingConfigLinkInfo.length === 0)) {
                    setDraftConfig(undefined);
                    setDraftLinks(new Array<WatershedReportPublicFacingConfigLinkInfo>());
                    setDraftLoaded(true);
                    return;
                }
                if (viewModel.watershedReportPublicFacingConfig) {
                    setDraftConfig(viewModel.watershedReportPublicFacingConfig);
                    setDraftLoaded(true);
                }
                if (viewModel.watershedReportPublicFacingConfigLinkInfo) {
                    setDraftLinks(viewModel.watershedReportPublicFacingConfigLinkInfo);
                }
                if (!viewModel.watershedReportPublicFacingConfig && viewModel.watershedReportPublicFacingConfigLinkInfo) {
                    // This happened to susie where links existed without a config but not sure how it happened
                    toast.error("Invalid Configuration State!", { autoClose: false });
                }
            }
            catch (response: any) {
                toast.error("Error Retrieving Draft Configuration", { autoClose: false });
            }
        })();

        // Get Final Config on load
        (async () => {
            try {
                let finalViewModel = await publicFacingConfigService.getFinalizedConfig(props.selectedWmgId!);
                if (!finalViewModel || (!finalViewModel.watershedReportPublicFacingConfig && finalViewModel.watershedReportPublicFacingConfigLinkInfo.length === 0)) {
                    setFinalConfig(undefined);
                    setFinalLinks(new Array<WatershedReportPublicFacingConfigLinkInfo>());
                    setFinalLoaded(true);
                    return;
                }
                if (finalViewModel.watershedReportPublicFacingConfig) {
                    setFinalConfig(finalViewModel.watershedReportPublicFacingConfig);
                    setFinalLoaded(true);
                }
                if (finalViewModel.watershedReportPublicFacingConfigLinkInfo) {
                    setFinalLinks(finalViewModel.watershedReportPublicFacingConfigLinkInfo);
                    setFinalLoaded(true);
                }
                if (!finalViewModel.watershedReportPublicFacingConfig && finalViewModel.watershedReportPublicFacingConfigLinkInfo) {
                    toast.error("Invalid Configuration State!", { autoClose: false });
                }
            }
            catch (e: any) {
                toast.error("Error Retrieving Finalized Config", { autoClose: false });
                console.error("final watershed public info caught error" + e)
            }
        })()
    }, [publicFacingConfigService, props.selectedWmgId]);

    useEffect(() => {
        // Reset configs when wmgId changes
        setDraftLoaded(false);
        setFinalLoaded(false);
        setDraftConfig(undefined);
        setFinalConfig(undefined);
        setLocalConfig(undefined);
        setActiveSavedConfig(undefined);
        setDraftLinks([]);
        setFinalLinks([]);
        setLocalLinks([]);
        setActiveSavedLinks([])

    }, [defaultConfig])

    const savePublicInfoConfigDraft = useCallback(() => {
        (async () => {
            try {
                let res = await publicFacingConfigService.saveDraft({ ...localConfig, watershedManagementGroupId: props.selectedWmgId }, localLinks);
                if (res) {
                    toast.success("Draft Saved");
                    setDraftConfig({ ...localConfig });
                    setDraftLinks(Array.from(localLinks));
                    setActiveSavedConfig({ ...localConfig });
                    setActiveSavedLinks(Array.from(localLinks));
                }
            }
            catch (response: any) {
                toast.error("Error Saving Draft Configuration", { autoClose: false });
            }
        })()
    }, [props.selectedWmgId, publicFacingConfigService, localConfig, localLinks])

    const finalizeDraftConfig = useCallback(() => {
        (async () => {
            try {
                publicFacingConfigService.saveFinal(draftConfig, draftLinks).then(() => {
                    toast.success("Saved Finalized Config!");
                    setLocalConfig({ ...draftConfig, isDraft: false, updated: new Date() });
                    setLocalLinks(draftLinks.map(link => { return { ...link, isDraft: false } }));
                    setFinalConfig({ ...draftConfig, isDraft: false, updated: new Date() });
                    setFinalLinks(draftLinks.map(link => { return { ...link, isDraft: false } }));
                    setDraftConfig(undefined);
                    setDraftLinks([]);
                    setActiveSavedConfig({ ...draftConfig, isDraft: false, updated: new Date() })
                    setActiveSavedLinks(draftLinks.map(link => { return { ...link, isDraft: false } }))
                })
                    .catch(() => {
                        toast.error("Error Finalizing Config!");
                    });
            }
            catch (response: any) {
                toast.error("Error Finalizing Configuration", { autoClose: false });
            }
        })()
    }, [props.selectedWmgId, publicFacingConfigService, draftConfig, draftLinks])

    const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
    const [openNoConfigModal, setOpenNoConfigModal] = useState<boolean>(false);

    const localConfigUnchanged = useCallback(() => {
        return compareConfigs(localConfig, activeSavedConfig, localLinks, activeSavedLinks);
    }, [activeSavedConfig, activeSavedLinks, localConfig, localLinks])

    useEffect(() => {
        if (props.activeItem === "1_8_open_modal" || props.activeItem === "1_9_open_modal") {
            if (!activeSavedConfig) {
                setOpenNoConfigModal(true);
                return;
            }

            if (localConfigUnchanged()) {
                // No changes, continue
                props.setActiveItem(props.activeItem.replace("_open_modal", ""));
            }
            else {
                // Changed, open modal
                setOpenConfirmModal(true);
            }
        }
    }, [localConfigUnchanged, props.activeItem, props, activeSavedConfig])

    const [snapshot, setSnapshot] = useState<WatershedReportSnapshot | null>(null);
    const [currentData, setCurrentData] = useState<Array<WatershedReportSnapshotBmp>>(new Array<WatershedReportSnapshotBmp>());
    const [snapshotLoadingFinished, setSnapshotLoadingFinished] = useState<boolean>(false);
    const [currentLoadingFinished, setCurrentLoadingFinished] = useState<boolean>(false);
    const [activeData, setActiveData] = useState<"snapshot" | "current">("snapshot");

    const getPublishedData = useCallback(() => {
        //Get Bmp Snapshot Data
        setSnapshotLoadingFinished(false);
        (async () => {
            try {
                let snapshotModel = await bmpSnapshotService.getSnapshot(props.selectedWmgId);
                if (!snapshotModel) {
                    // No Snapshot exists yet
                    setActiveData("current");
                }
                setSnapshot(snapshotModel);
                setSnapshotLoadingFinished(true);
            }
            catch (response: any) {
                console.error(response);
                toast.error("Error Retrieving Published Data", { autoClose: false });
            }
        })()
    }, [props.selectedWmgId, bmpSnapshotService, setSnapshotLoadingFinished])

    const getCurrentData = useCallback(() => {
        setCurrentLoadingFinished(false);
        let filter = new ManageProjectFilter();
        filter.watershedManagementGroups.push(props.lookups.lutWatershedManagementGroup.find(x => x.id === props.selectedWmgId).internalName);

        (async () => {
            try {
                let currentSnapshot = await bmpSnapshotService.getCurrentAsSnapshots(filter);
                if (!currentSnapshot) {
                    // No Current Data Found
                    toast.warn("No Current Bmps Found");
                }
                setCurrentData(currentSnapshot);
                setCurrentLoadingFinished(true);
            }
            catch (response: any) {
                console.error(response);
                toast.error("Error Retrieving Current Data", { autoClose: false });
            }
        })();
    }, [props.lookups.lutWatershedManagementGroup, props.selectedWmgId, bmpSnapshotService, setCurrentLoadingFinished]);

    // Run on load
    useEffect(() => {
        // Get Published and Current Data on load (stored on parent component so that they arent loaded each time tabs are switched)
        getPublishedData();
        getCurrentData();
    }, [getCurrentData, getPublishedData, props.selectedWmgId])

    return (
        <>
            <ToastContainer position="top-center" />
            {
                props.activeItem === '1_7' && props.selectedWmgId && draftLoaded && finalLoaded && localConfig &&
                <PublicConfigElements
                    setActiveItem={props.setActiveItem}
                    wmgPublicInfo={localConfig}
                    setWmgPublicInfo={setLocalConfig}
                    wmgPublicInfoLinks={localLinks}
                    setWmgPublicInfoLinks={setLocalLinks}
                    savePublicInfoConfigDraft={savePublicInfoConfigDraft}
                    activeIndexes={openConfigSections}
                    setActiveIndexes={setOpenConfigSections}
                    setOpenConfirmModal={setOpenConfirmModal}
                    scrollFunction={props?.scrollFunction}
                />
            }
            {
                props.activeItem === '1_8' && props.selectedWmgId && draftLoaded && finalLoaded && activeSavedConfig &&
                <PreviewPublicElements
                    wmgId={props.selectedWmgId}
                    wmgPublicInfo={activeSavedConfig}
                    setActiveItem={props.setActiveItem}
                    wmgPublicInfoLinks={activeSavedLinks}
                    savePublicInfoConfigDraft={savePublicInfoConfigDraft}
                    lookups={props.lookups}
                    snapshot={snapshot}
                    currentData={currentData}
                    snapshotLoadingFinished={snapshotLoadingFinished}
                    currentLoadingFinished={currentLoadingFinished}
                    activeData={activeData}
                    setActiveData={setActiveData}
                    scrollFunction={props?.scrollFunction}
                    setSidebarProjectInfoObj={props.setSidebarProjectInfoObj}
                    dispatchSidebar={props.dispatchSidebar}
                />
            }
            {
                props.activeItem === '1_9' && props.selectedWmgId && draftLoaded && finalLoaded && activeSavedConfig &&
                <PublishingControls
                    wmgId={props.selectedWmgId}
                    wmgPublicInfo={activeSavedConfig}
                    setActiveItem={props.setActiveItem}
                    wmgPublicInfoLinks={activeSavedLinks}
                    savePublicInfoConfigDraft={savePublicInfoConfigDraft}
                    finalizeConfiguration={finalizeDraftConfig}
                    lookups={props.lookups}
                    snapshot={snapshot}
                    currentData={currentData}
                    getSnapshot={getPublishedData}
                    snapshotLoadingFinished={snapshotLoadingFinished}
                    currentLoadingFinished={currentLoadingFinished}
                    activeData={activeData}
                    setActiveData={setActiveData}
                    draftConfig={draftConfig}
                    finalConfig={finalConfig}
                />
            }

            <MessageModal
                messageText="You have unsaved changes to this configuration, are you sure you want to continue?"
                modalOpen={openConfirmModal}
                setModalOpen={setOpenConfirmModal}
                unsavedOrNoConfig="unSaved"
                activeItem={props.activeItem}
                setActiveItem={props.setActiveItem}
                tab="1_7" />
            <MessageModal
                messageText="You have not saved your configuration yet, please go back and save."
                modalOpen={openNoConfigModal}
                setModalOpen={setOpenNoConfigModal}
                unsavedOrNoConfig="noConfig"
                setActiveItem={props.setActiveItem}
                tab="1_7" />
        </>
    )
};
