import { useCallback, useContext, useEffect, useState } from "react";
import { Translation } from "react-i18next";
import { GlobalAuthInfoContext, IGlobalAuthInfo } from "../../mainWrapper/MainWrapper";
import LookupFactory from "../../../lookups/LookupFactory";
import './BuildAnnReportForm.scss';
import DropdownLutH2O from "../../../paradigmLib/dropdownLut/DropdownLut";
import { PermissionsStatusComponent } from "../../permissionsStatusComponent/PermissionsStatusComponent";
import { ButtonComponent } from "../../../paradigmLib/domElements/buttonComponent/ButtonComponent";
import Config from "../../../config/Config";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Download from "../../../core/DownloadUtil";
import moment from "moment";
import { UploadSectionAnnual } from "../../uploadSectionAnnual/uploadSectionAnnual";
import { Menu } from "semantic-ui-react";
import { useAuth } from "react-oidc-context";

export interface IBuildAnnReportFormProps {
    dispatchSidebar: Function;
    visible: boolean;
    setVisible: Function;
    lookups: LookupFactory;
}

export type SelectedParams = {
    annualReportingPeriodId: number;
    jurisdictionId: number;
}

export const BuildAnnReportForm: any = (props: IBuildAnnReportFormProps) => {
    const globalAuthInfoContext = useContext(GlobalAuthInfoContext);
    const auth = useAuth();
    const [showAccessAlertMsg, setShowAccessAlertMsg] = useState<boolean>(false);
    const [wordOutputEndoint, setWordOutputEndpoint] = useState<string>();

    const [selectedParams, setSelectedParams] = useState<SelectedParams>({
        annualReportingPeriodId: -1,
        jurisdictionId: -1
    });

    useEffect(() => {
        if (globalAuthInfoContext && globalAuthInfoContext.visibleComponents.indexOf("buildAnnReportForm") < 0 && globalAuthInfoContext.roles.indexOf("admin") < 0) {
            setShowAccessAlertMsg(true);
        }
    }, [globalAuthInfoContext])

    useEffect(() => {
        if (props.lookups.lutAnnualReportingPeriod && globalAuthInfoContext.jurisdictionList) {
            //Set default dropdown values to first Jurisdiction and current reporting period
            let newParams: SelectedParams = Object.assign({}, selectedParams);

            newParams.jurisdictionId = globalAuthInfoContext.jurisdictionList[0]?.id ?? -1
            let today = new Date()
            today.setMonth(today.getMonth() - 6); // users will use the previous period until the end of the calendar year
            newParams.annualReportingPeriodId = props.lookups.lutAnnualReportingPeriod.find(x => new Date(x.startDate) <= today && new Date(x.endDate) >= today)?.id ?? -1;
            setSelectedParams(newParams);
        }

    }, [auth, props.lookups])

    const onSelect = (sel: string, val: number) => {
        let paramCopy: SelectedParams = Object.assign({}, selectedParams);
        let saveEdit: boolean = false;
        if (sel === 'jur') {
            paramCopy.jurisdictionId = val;
            saveEdit = true;
        } else if (sel === 'rep') {
            paramCopy.annualReportingPeriodId = val;
            saveEdit = true;
        }

        if (saveEdit) {
            setSelectedParams(paramCopy);
        }
    }

    useEffect(() => {
        setWordOutputEndpoint(Config.api_endpoint
            + "report/wordAnnual/jurisdiction/"
            + selectedParams.jurisdictionId + "/"
            + selectedParams.annualReportingPeriodId);
    }, [selectedParams]);

    const downloadFile = useCallback(async (endpoint: string, docType: string, errorMessage?: string) => {
        if (selectedParams.annualReportingPeriodId === -1 || selectedParams.jurisdictionId === -1) {
            return;
        }
        const toastId = toast.info("Downloading...");
        let tempFileName = props.lookups.getJurisdictionById(selectedParams.jurisdictionId).replaceAll("/", "-")
            + " - " + props.lookups.getAnnualReportingPeriodById(selectedParams.annualReportingPeriodId).replaceAll("/", "-")
            + " - " + moment().format("MM-DD-YYYY") + docType;

        try {
            await Download(endpoint, auth.user?.access_token, tempFileName);
        } catch (err) {
            toast.error(errorMessage ?? "Failed to download file");
        } finally {
            toast.dismiss(toastId);
        }
    }, [auth, selectedParams]);

    return (
        <div className="build-ann-report-wrapper">
            <div className="build-ann-report-from-page">
                <ToastContainer position="top-center" />

                <Translation>
                    {(t) => (
                        <GlobalAuthInfoContext.Consumer>
                            {(authInfo: IGlobalAuthInfo) => {
                                return (
                                    <>
                                        <PermissionsStatusComponent
                                            hasPermissions={!showAccessAlertMsg}
                                        />
                                        {showAccessAlertMsg === false && (
                                            <>
                                                <div className="dropdown-segment">
                                                    {
                                                        <div className="reportWrap jurisdiction">
                                                            <DropdownLutH2O
                                                                dropdownItems={
                                                                    globalAuthInfoContext.jurisdictionList
                                                                        ? globalAuthInfoContext.jurisdictionList
                                                                        : []
                                                                }
                                                                dropdownTitle={
                                                                    "Jurisdiction"
                                                                }
                                                                isMultiselect={
                                                                    false
                                                                }
                                                                // nullLabel="Select a jurisdiction"
                                                                nullLabel={null}
                                                                onSelect={(
                                                                    e,
                                                                    d
                                                                ) => {
                                                                    onSelect(
                                                                        "jur",
                                                                        d.value
                                                                    );
                                                                }}
                                                                ddSlim={true}
                                                                search={true}
                                                                parentId={
                                                                    selectedParams.jurisdictionId
                                                                }
                                                            />
                                                        </div>
                                                    }
                                                    <div className="reportWrap ARP">
                                                        <DropdownLutH2O
                                                            dropdownItems={
                                                                props.lookups !==
                                                                    null
                                                                    ? props.lookups
                                                                        .lutAnnualReportingPeriod
                                                                    : []
                                                            }
                                                            dropdownTitle={
                                                                "Annual Reporting Period"
                                                            }
                                                            isMultiselect={false}
                                                            // nullLabel="Select a reporting period"
                                                            nullLabel={null}
                                                            onSelect={(e, d) => {
                                                                onSelect(
                                                                    "rep",
                                                                    d.value
                                                                );
                                                            }}
                                                            ddSlim={true}
                                                            parentId={
                                                                selectedParams.annualReportingPeriodId
                                                            }
                                                        />
                                                    </div>
                                                </div>

                                                <div className="header-segment">
                                                    <div className="left-div"></div>

                                                    <div className="center-div">
                                                        <div className="jurisdiction-title">
                                                            {/* THIS CONTROLS TITLE AT TOP OF PAGE UNDER FILTERS - EITHER JURISDICTION OR WATERSHED GROUP NAME */}
                                                            {props.lookups !==
                                                                null && (
                                                                    <span>
                                                                        {props.lookups.getJurisdictionById(
                                                                            selectedParams.jurisdictionId
                                                                        )}
                                                                    </span>
                                                                )}
                                                            <hr />
                                                        </div>
                                                    </div>
                                                    <div className="right-div"></div>
                                                </div>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent:
                                                            "center",
                                                    }}
                                                >
                                                    <Menu
                                                        pointing
                                                        secondary
                                                        style={{
                                                            width: "100%",
                                                            marginBottom: "0.5rem"
                                                        }}
                                                    >
                                                        <Menu.Item
                                                            name="outputs"
                                                            content="Reporting Outputs"
                                                            active={true}
                                                        ></Menu.Item>
                                                    </Menu>
                                                </div>
                                                <div className="subpage-segment">
                                                    <div
                                                        style={{
                                                            color: "#2697d3",
                                                            textShadow: "1px 1px 1px #c2c2c2 !important",
                                                            fontWeight: "700",
                                                            fontSize: "1.2em",
                                                            margin: "3rem 0 1rem",
                                                        }}
                                                    >
                                                        Generate Outputs
                                                    </div>
                                                    <div className="subpage-container">
                                                        <div className="subpage-header-container">
                                                            <div className="subpage-header-2">
                                                                <div className="text">
                                                                    <div>
                                                                        WRAMPS will compile
                                                                        key outputs for
                                                                        Annual Report
                                                                        items{" "}
                                                                        <span style={{ fontWeight: "bold", }}>6.4a</span>{" "}
                                                                        through {" "}
                                                                        <span style={{ fontWeight: "bold", }}>6.4f</span>
                                                                        ,
                                                                        regarding Priority Development
                                                                        Projects and Alternative
                                                                        Compliance Measures, for this
                                                                        jurisdiction. Please
                                                                        review the outputs and
                                                                        revise the corresponding
                                                                        data entries as needed.
                                                                        The output generated below
                                                                        can then be manually inserted
                                                                        into the Word document on your
                                                                        desktop.
                                                                    </div>
                                                                    <div style={{ width: "100%", height: "10px" }} />
                                                                    <div>
                                                                        <span style={{ fontWeight: "bold", }}>Note:</span>{"  "}
                                                                        “Completed” projects includes
                                                                        projects with a completed
                                                                        status and a completion
                                                                        date that occurred during
                                                                        the reporting period.
                                                                        “In Progress” projects
                                                                        includes all in progress
                                                                        status projects as well
                                                                        as “completed” projects
                                                                        whose completion date
                                                                        occurred after the
                                                                        selected reporting period.
                                                                    </div>
                                                                </div>
                                                                <div className="btn-div">
                                                                    <ButtonComponent
                                                                        onBtnClick={() => {
                                                                            downloadFile(
                                                                                wordOutputEndoint,
                                                                                ".docx"
                                                                            );
                                                                        }}
                                                                        label="Generate Output"
                                                                        theme="primaryBtn"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {selectedParams.annualReportingPeriodId !== -1 && selectedParams.jurisdictionId !== -1 && (
                                                        <div>
                                                            <div className="subpage-header-2 pdf-upload-div mt-3">
                                                                <UploadSectionAnnual
                                                                    annualReportPeriodId={selectedParams.annualReportingPeriodId}
                                                                    jurisdictionId={selectedParams.jurisdictionId}
                                                                    lookups={props.lookups}
                                                                    disabled={false}
                                                                    downloadFile={downloadFile}
                                                                />
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </>
                                        )}
                                    </>
                                );
                            }}
                        </GlobalAuthInfoContext.Consumer>
                    )}
                </Translation>
            </div>
        </div>
    )
}
