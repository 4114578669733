import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Translation } from "react-i18next";
import { Form, Message } from "semantic-ui-react";
import { ButtonComponent } from "../../../../paradigmLib/domElements/buttonComponent/ButtonComponent";
import { WatershedReportPublicFacingConfig, WatershedReportPublicFacingConfigLinkInfo } from "../../../../models/viewModels/WatershedReportPublicFacingConfigViewModel";
import { WatershedReportSnapshot, WatershedReportSnapshotBmp } from "../../../../models/WatershedReportSnapshot";
import { BmpSnapshotService } from "../../../../services/BmpSnapshotService";
import { GlobalAuthInfoContext } from "../../../mainWrapper/MainWrapper";
import { toast } from "react-toastify";
import WatershedManagementGroupIsPublic from "../../../../models/WatershedManagementGroupIsPublic";
import { useAPIFetchAllLocal, usePutPostLocalOnlyWhenTrue } from "../../../../core/UseAPILocal";
import LookupFactory from "../../../../lookups/LookupFactory";
import { ConfirmModal } from "../../../modals/confirmModal/ConfirmModal";
import "./PublishingControls.scss"
import { useAuth } from "react-oidc-context";

export interface IPublishingControls {
    wmgId: number;
    wmgPublicInfo: WatershedReportPublicFacingConfig | null;
    setActiveItem: Function;
    wmgPublicInfoLinks: Array<WatershedReportPublicFacingConfigLinkInfo>;
    savePublicInfoConfigDraft: Function;
    finalizeConfiguration: Function;
    lookups: LookupFactory;
    snapshot: WatershedReportSnapshot;
    currentData: Array<WatershedReportSnapshotBmp>;
    getSnapshot: () => void;
    snapshotLoadingFinished: boolean;
    currentLoadingFinished: boolean;
    activeData: "snapshot" | "current"; // snapshot/published vs current data button - saved above to keep state
    setActiveData: React.Dispatch<React.SetStateAction<"snapshot" | "current">>;
    draftConfig: WatershedReportPublicFacingConfig | undefined;
    finalConfig: WatershedReportPublicFacingConfig | undefined;
}

export const PublishingControls: React.FC<IPublishingControls> = (props: IPublishingControls) => {
    const auth = useAuth();
    const globalAuthInfoContext = useContext(GlobalAuthInfoContext);
    const bmpSnapshotService = useMemo(() => new BmpSnapshotService(auth.user?.access_token), [auth]);

    const [saveDataLoading, setSaveDataLoading] = useState<boolean>(false);
    const [showUpdateDataConfirmModal, setShowUpdateDataConfirmModal] = useState<boolean>(false);

    const openConfigTab = () => {
        props.setActiveItem('1_7')
    }

    const generatePublishedData = useCallback(() => {
        //Generate Bmp Snapshot
        (async () => {
            setSaveDataLoading(true);
            try {
                await bmpSnapshotService.generateSnapshot(props.wmgId);
                toast.success("Updated Data Saved");
                props.getSnapshot();
                setSaveDataLoading(false);
            }
            catch (response: any) {
                console.error(response);
                toast.error("Error Saving Updated Data", { autoClose: false });
                setSaveDataLoading(false);
            }
        })()
    }, [props.wmgPublicInfo, bmpSnapshotService, props.wmgId, props.getSnapshot])

    const [watershedManagementGroupIsPublic, setWatershedManagementGroupIsPublic] = useState<WatershedManagementGroupIsPublic>(new WatershedManagementGroupIsPublic());
    const [updateWatershedManagementGroupIsPublic, setUpdateWatershedManagementGroupIsPublic] = useState<boolean>(false);

    const handleTogglePublic = useCallback(() => {
        if (props.wmgPublicInfo.isDraft) {
            toast.error("To make public, first click the yellow 'Confirm Current Configuration & Push to Public Facing Dashboard' button above.", { autoClose: 4000 })
            return;
        }

        if (!props.snapshot) {
            toast.error("To make public, first save data using the 'Update' button in Step 2 below.", { autoClose: 4000 })
            return;
        }

        if (!watershedManagementGroupIsPublic.id || watershedManagementGroupIsPublic.id <= 0) {
            //group doesnt exist yet on isActive table
            watershedManagementGroupIsPublic.active = 1;
            watershedManagementGroupIsPublic.watershedGroupId = props.wmgId;
        }

        watershedManagementGroupIsPublic.isPublicDashboard = !!watershedManagementGroupIsPublic.isPublicDashboard ? 0 : 1;
        setWatershedManagementGroupIsPublic(watershedManagementGroupIsPublic);
        setUpdateWatershedManagementGroupIsPublic(true);
    }, [props.wmgPublicInfo, props.snapshot, watershedManagementGroupIsPublic])

    useAPIFetchAllLocal(
        auth.user?.access_token,
        new WatershedManagementGroupIsPublic(),
        true,
        () => {},
        setWatershedManagementGroupIsPublic,
        { "WatershedGroupId": props.wmgId, "active": 1 },
        (data: WatershedManagementGroupIsPublic[]) => {
            if (data.length > 0) {
                setWatershedManagementGroupIsPublic(data[0]);
            }
            else {
                setWatershedManagementGroupIsPublic(new WatershedManagementGroupIsPublic());
            }
        });

    usePutPostLocalOnlyWhenTrue(auth.user?.access_token, watershedManagementGroupIsPublic, (data) => {
        setUpdateWatershedManagementGroupIsPublic(false);
        setWatershedManagementGroupIsPublic(data);
    }, [updateWatershedManagementGroupIsPublic]);

    const publicSettingsData = [
        {
            name: "Program Message",
            location: "programMessageEnabled"
        },
        {
            name: "Program Summary Visuals",
            location: "programSummaryVisualizationEnabled"
        },
        {
            name: "Program Summary Multiple Benefits",
            location: "programSummaryMultiBenefitsEnabled"
        },
        {
            name: "Program Progress",
            location: "programProgressEnabled"
        },
        {
            name: "Program Summary Table",
            location: "programSummaryTableEnabled"
        },
        {
            name: "Project Details Map",
            location: "projectDetailsMapEnabled"
        },
        {
            name: "Project Details Table",
            location: "projectDetailsTableEnabled"
        },
        {
            name: "Additional Info",
            location: "additionalInfoEnabled"
        },
    ]

    return (
        <div className="build-watershed-form-subpage  publishing-controls-wrapper">
            <Translation>
                {
                    t => <>
                        <div className="subpage-container">
                            <div className="publishing-controls">
                                {
                                    (!props.wmgPublicInfo || (
                                        !props.wmgPublicInfo.programMessageEnabled &&
                                        !props.wmgPublicInfo.programSummaryTableEnabled &&
                                        !props.wmgPublicInfo.programSummaryVisualizationEnabled &&
                                        !props.wmgPublicInfo.programSummaryMultiBenefitsEnabled &&
                                        !props.wmgPublicInfo.programProgressEnabled &&
                                        !props.wmgPublicInfo.projectDetailsMapEnabled &&
                                        !props.wmgPublicInfo.projectDetailsTableEnabled &&
                                        !props.wmgPublicInfo.additionalInfoEnabled)) &&
                                    <div className="no-public-sections">*** You have not selected any information to be displayed to the public. Please return to the Configure Public Elements tab to select elements. ***</div>
                                }

                                <div className="steps">
                                    <div className="steps-header subpage-header-1">Step 1: Review Public Element Configuration</div>
                                    <div className="steps-text">Please review the selected elements from the Configure Public Elements tab below. To revise the selection, return to the Configure Public Elements tab.</div>
                                    <ul className="on-off-list">
                                        {publicSettingsData.map(item =>
                                            <li key={item.name}>
                                                {props.wmgPublicInfo[item.location]
                                                    ? <div className="on-off on">ON</div>
                                                    : <div className="on-off off">OFF</div>
                                                }
                                                <span className={props.wmgPublicInfo[item.location] ? "" : "de-emphasize"}>
                                                    {item.name}
                                                </span>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                                <div className="steps">
                                    <div className="steps-header subpage-header-1">Step 2: Review Dashboard Data</div>
                                    <div className="steps-text">Please review and confirm the data used to populate elements on the Public Facing Dashboard.
                                        The Public Facing Dashboard is not "live" and does not always show the most current data in the WRAMPS2 database. Instead, the WMG Lead specifies when to update the data shown on the Dashboard. Below you can specify when to update the view on the Dashboard. On the Preview Public Dashboard tab, you can test the effect of updating the time stamp of the Dashboard view.
                                        Updating the Dashboard view will replace the existing one, meaning you will not be able to revert to an earlier version.
                                    </div>
                                    <div className="publish-data-button">
                                        <div className="data-timestamp-button">
                                            <span>Would you like to update your Dashboard view?</span>
                                            <ButtonComponent
                                                theme="yellowBtn"
                                                onBtnClick={() => setShowUpdateDataConfirmModal(true)}
                                                label="Update Data"
                                                loading={saveDataLoading}
                                            />
                                        </div>
                                        <div className="update-data-modal">
                                            <ConfirmModal
                                                confirmFunc={generatePublishedData}
                                                confirmText={<>
                                                    <div>Are you sure you want to update the data used in the Public Facing Dashboard?</div>
                                                    <div className="modal-warning mt-1" style={{ color: "red" }}>This action cannot be undone!! </div>
                                                    <div className="mt-1">You will still be able to select whether or not to share the info, however, once this snapshot of data is saved, you cannot revert the dashboard back to data from previous timestamps.</div>
                                                </>}
                                                openModal={showUpdateDataConfirmModal}
                                                setOpenModal={setShowUpdateDataConfirmModal} />
                                        </div>
                                        <div className="data-timestamp">
                                            Current Dashboard Timestamp:
                                            <span>
                                                {props?.snapshot?.snapshotDate &&
                                                    `${(new Date(props?.snapshot?.snapshotDate)).toLocaleString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', timeZone: 'UTC' })}`
                                                }
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="steps">
                                    <div className="steps-header subpage-header-1">Step 3: Confirm Configuration And Publish Dashboard</div>
                                    <div className="steps-text">
                                        After reviewing the Preview Public Elements tab and confirming your selections above, please use the yellow confirmation button below to implement the selected configuration. Use the toggle to indicate if the Watershed Management Group elects to use WRAMPS2 to publicly post their Public Facing Dashboard. The Public Facing Dashboard may be hidden at any time using the same toggle. To make changes before publishing, return to the <span className="return-text" onClick={openConfigTab}>Configure Public Elements tab</span>.
                                    </div>
                                    {/* JG - copied from ToggleComponent to use onClick without messy datahandler */}
                                    <div className="publishing-selections-wrapper">
                                        <div className="publishing-button-wrapper">
                                            <div className="publish-button">
                                                {props.draftConfig ?
                                                    <ButtonComponent
                                                        theme="yellowBtn"
                                                        onBtnClick={() => props.finalizeConfiguration()}
                                                        label={"Confirm Current Configuration & Push to Public Facing Dashboard"}
                                                        disabled={!props.draftConfig}
                                                    />
                                                    :
                                                    <Message color='blue'>
                                                        Your published configuration is up-to-date with your current configuration
                                                    </Message>
                                                }
                                                {props.finalConfig &&
                                                    <div className="last-saved">Last Saved:
                                                        <span>
                                                            {new Date(Date.parse(props.finalConfig.updated as string)).toLocaleString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' })}
                                                        </span>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div className="toggle-component-basic">
                                            <div className="public-toggle-wrapper">
                                                <Form.Field>
                                                    <div className="ui form toggle-container">
                                                        <div className="toggle-div">
                                                            <div
                                                                className={`field primary`}
                                                                id={"isPublicReports"}
                                                                onClick={handleTogglePublic}
                                                            >
                                                                <div className="toggle-wrapper">
                                                                    <div className="ui checkbox toggle">
                                                                        <input
                                                                            type="checkbox"
                                                                            className="hidden"
                                                                            id={"isPublicReports"}
                                                                            checked={!!watershedManagementGroupIsPublic.isPublicDashboard}
                                                                            onChange={() => {}}
                                                                        />
                                                                        <label></label>
                                                                    </div>
                                                                    <div
                                                                        className={`toggle-label ${watershedManagementGroupIsPublic && watershedManagementGroupIsPublic.isPublicDashboard ? "public" : "hidden"}`}
                                                                    >{watershedManagementGroupIsPublic && watershedManagementGroupIsPublic.isPublicDashboard ? "Public" : "Hidden"}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Form.Field>
                                                <div className="public-toggle-text">
                                                    Publish Public Facing Dashboard to wramps2.org on behalf of the Watershed Management Group
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </Translation >
        </div >
    )
}
