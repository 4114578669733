import { linearGradientDef } from '@nivo/core';
import { ResponsiveLine, Serie } from '@nivo/line';
import { customFormat } from '../../../../core/NumberFormat';
import { useMemo, useState } from 'react';
import { Button, Dropdown } from 'semantic-ui-react';
import LookupFactory from '../../../../lookups/LookupFactory';
import ReceivingWaters from '../../../../models/ReceivingWaters';
import { ClassDisplayOptions, WatershedReportPublicFacingConfig } from '../../../../models/viewModels/WatershedReportPublicFacingConfigViewModel';
import { WatershedReportSnapshotBmp } from '../../../../models/WatershedReportSnapshot';
import { BmpClassId } from '../../../../models/lutModels/LutBmpClass';

export interface IProgramProgressGraphProps {
    wmgId: number;
    bmpData: Array<WatershedReportSnapshotBmp>;
    publicConfig: WatershedReportPublicFacingConfig;
    graphHeight: number;
    lookups: LookupFactory;
    snapshotDate?: Date;
}

export type MetricOption = {
    name: string;
    internalName: string;
    propertyName: keyof WatershedReportSnapshotBmp;
    unit: string
}

export class GraphMetrics {
    static Count: MetricOption = { name: "Count", internalName: "count", propertyName: "bmpGeneralId", unit: "projects" };
    static AreaManaged: MetricOption = { name: "Area Managed (ac)", internalName: "areaManaged", propertyName: "drainageAreaAc", unit: "acres" };
    static ProjectFootprint: MetricOption = { name: "Project Footprint (ac)", internalName: "projectFootprint", propertyName: "footprintAc", unit: "acres" };
    static TwentyFourHrCapacity: MetricOption = { name: "24 Hour Capacity (ac-ft)", internalName: "24HrCapacity", propertyName: "dailyStorageCapacityAcft", unit: "acre-feet" };
}

class MonthGroup {
    year: number;
    month: number;
    bmps: WatershedReportSnapshotBmp[];

    constructor(year: number, month: number, bmps: WatershedReportSnapshotBmp[]) {
        this.year = year;
        this.month = month;
        this.bmps = bmps
    }
}

export const ProgramProgressGraph = (props: IProgramProgressGraphProps) => {
    const [selectedMetric, setSelectedMetric] = useState<MetricOption>(GraphMetrics.Count); // default count
    const [selectedSubwatershedId, setSelectedSubwatershedId] = useState<number>(-1);

    const receivingWaters: Array<ReceivingWaters> = useMemo(() => {
        return props.lookups.lutReceivingWaters.filter(rw => rw.wsGroupRWaterJurisdictionMapping.some(m => m.watershedManagementGroupId === props.wmgId));
    }, [props.lookups, props.wmgId]);

    const bmpDataForGraph: Array<Serie> = useMemo(() => {
        if (props.bmpData.length === 0) {
            return [];
        }
        let data = new Array<Serie>();

        let filteredBmps = props.bmpData
            .filter(x => x.bmpCompletionDate != null
                && x.lutBmpStatusId === 1024 //complete
                && (x.lutBmpClassId === BmpClassId.WatershedControlMeasure || x.lutBmpClassId === BmpClassId.NewAndRedevelopment) // No Non Structurals or LFDs
                && (props.publicConfig.programProgressClass === ClassDisplayOptions.WCMOnly ? x.lutBmpClassId === BmpClassId.WatershedControlMeasure : true)
                && (selectedSubwatershedId !== -1 ? x.receivingWatersId === selectedSubwatershedId : true) // show all subwatersheds if none selected
            );

        if (filteredBmps.length === 0) {
            return [];
        }
        var serie = generateGraphDataFromMonthGroup("Complete", groupByMonth(filteredBmps), selectedMetric);
        if (props.snapshotDate) {
            // if a snapshot date is available then use it to be the last datum
            serie.data.push({ x: props.snapshotDate, y: serie.data[serie.data.length - 1].y })
        }
        data.push(serie);

        return data;
    }, [props.bmpData, props.publicConfig.programProgressClass, props.snapshotDate, selectedMetric, selectedSubwatershedId]);

    return (
        <div className="program-progress">
            <div className="program-progress-inner-wrapper">
                <div className="button-group-dropdown-wrapper">
                    <div className="dashboard-button-group">
                        <div className="dashboard-button-group-title">Progress Metric</div>
                        <Button.Group>
                            <Button
                                onClick={() => { setSelectedMetric(GraphMetrics.Count) }}
                                className={selectedMetric === GraphMetrics.Count ? "active" : ""}
                            >Count</Button>
                            <Button
                                onClick={() => { setSelectedMetric(GraphMetrics.AreaManaged) }}
                                className={selectedMetric === GraphMetrics.AreaManaged ? "active" : ""}
                            >Area Managed</Button>
                            <Button
                                onClick={() => { setSelectedMetric(GraphMetrics.ProjectFootprint) }}
                                className={selectedMetric === GraphMetrics.ProjectFootprint ? "active" : ""}
                            >Project Footprint</Button>
                        </Button.Group>
                    </div>
                    <div className="dropdown-element-wrapper">
                        <div className="dropdown-title">Subwatershed</div>
                        <Dropdown className="dropdown-element"
                            options={[{ key: -1, text: "All Subwatersheds", value: -1 }, ...receivingWaters.map((m, i) => { return { key: m.id, text: m.displayName, value: m.id } })]}
                            value={selectedSubwatershedId}
                            onChange={(_event, data) => setSelectedSubwatershedId(data.value as number)}
                            selection
                        />
                    </div>
                </div>
                <div className="program-progress-graph">
                    {
                        bmpDataForGraph.length > 0 &&
                        <>
                            <div className="responsive-line-graph"
                                style={{ height: `${props.graphHeight}px` }}>
                                <ResponsiveLine
                                    margin={{ top: 20, right: 30, bottom: 70, left: 70 }}
                                    animate={true}
                                    enableSlices='x'
                                    colors="#006ba5"
                                    sliceTooltip={({ slice }) => {
                                        return (
                                            <div
                                                className="graph-tooltip"
                                                style={{
                                                    background: 'white',
                                                    padding: '9px 12px 8px',
                                                    border: '1px solid #ccc',
                                                    borderRadius: '5px',
                                                    boxShadow: '0.5px 0.5px 3px 0px #a5a5a5'
                                                }}>
                                                <div
                                                    className="x-value">
                                                    {(slice.points[0].data.x as Date).toLocaleString('default', { month: 'long', year: 'numeric' })}
                                                </div>
                                                <div className="y-value"
                                                    style={{ color: slice.points[0].serieColor, padding: '3px 0' }}>
                                                    {`${selectedMetric.name} : ${customFormat(slice.points[0].data.y as number)}`}
                                                </div>
                                            </div>
                                        )
                                    }}
                                    enableArea={true}
                                    defs={[
                                        linearGradientDef('current', [
                                            { offset: 0, color: 'inherit' },
                                            { offset: 1000, color: 'inherit', opacity: 0 },
                                        ]),
                                    ]}
                                    fill={[{ match: '*', id: 'current' }]}
                                    data={bmpDataForGraph}
                                    xScale={{
                                        type: 'time',
                                        format: '%Y-%m',
                                        useUTC: false,
                                        precision: 'month',
                                        max: props.snapshotDate
                                    }}
                                    xFormat="time:%Y-%m"
                                    yScale={{
                                        type: 'linear',
                                        stacked: true,
                                    }}
                                    axisLeft={{
                                        legend: selectedMetric.name,
                                        legendOffset: 12,
                                    }}
                                    axisBottom={{
                                        format: "%B '%y",
                                        tickValues: 12,
                                        tickRotation: 45,
                                        legend: 'Month',
                                        legendOffset: -15,
                                    }}
                                    curve="monotoneX"
                                    pointSize={0}
                                    useMesh={true}
                                />
                            </div>
                        </>
                    }
                    {bmpDataForGraph.length === 0 &&
                        <div className='graph-no-data'>
                            No Graph Data
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

function groupByMonth(array: Array<WatershedReportSnapshotBmp>): MonthGroup[] {
    let groups = new Array<MonthGroup>();
    array.forEach(bmp => {
        let month: MonthGroup | undefined = groups.find(x => x.month === new Date(bmp.bmpCompletionDate!).getMonth() && x.year === new Date(bmp.bmpCompletionDate!).getFullYear());
        if (month) {
            month.bmps.push(bmp);
        }
        else {
            groups.push(new MonthGroup(new Date(bmp.bmpCompletionDate!).getFullYear(), new Date(bmp.bmpCompletionDate!).getMonth(), [bmp]));
        }
    });
    return groups;
}

function getCumulativeByMetric(groups: Array<MonthGroup>, currentDate: Date, metric: MetricOption): number {
    if (metric === GraphMetrics.Count) {
        return groups
            .filter(z => new Date(z.year, z.month) <= currentDate)
            .reduce((accumulator, currentValue) => accumulator + currentValue.bmps.length, 0);
    }
    else {
        return groups
            .filter(z => new Date(z.year, z.month) <= currentDate)
            .reduce((accumulator, currentValue) => accumulator + currentValue.bmps.reduce((groupSum, groupCurrent) => groupSum + (groupCurrent[metric.propertyName] as number), 0), 0);
    }
}

function generateGraphDataFromMonthGroup(seriesName: string, groups: Array<MonthGroup>, metric: MetricOption): Serie {
    return {
        id: seriesName,
        data: groups
            .map(x => {
                return { x: new Date(x.year, x.month), y: getCumulativeByMetric(groups, new Date(x.year, x.month), metric) }
            })
            .sort((a, b) => a.x.getTime() - b.x.getTime())
    }
}
