import { useContext, useEffect, useState } from "react";
import { Translation } from "react-i18next";
import { Loader, Menu } from "semantic-ui-react";
import { usePutPostLocalWithSet } from "../../../core/UseAPILocal";
import { ButtonComponent } from "../../../paradigmLib/domElements/buttonComponent/ButtonComponent";
import { ProjAdditional } from "../../reporting/projectEditPages/ProjAdditional";
import { ProjAttributes } from "../../reporting/projectEditPages/ProjAttributes";
import { ProjCobenefits } from "../../reporting/projectEditPages/ProjBenefits";
import { ProjFinancial } from "../../reporting/projectEditPages/ProjFinancial";
import { ProjFundamentals } from "../../reporting/projectEditPages/ProjFundamentals";
import { ProjOAndM } from "../../reporting/projectEditPages/ProjOAndM";
import { ProjPlanningStatus } from "../../reporting/projectEditPages/ProjPlanningStatus";
import { ProjNewAndRedevelopment } from "../../reporting/projectEditPages/ProjNewAndRedevelopment";
import { ProjGisData } from "../../reporting/projectEditPages/ProjGisData";
import Wave from "../../../images/wave3.svg";
import GisQueryAdHocModel from "../../../models/viewModels/GisQueryAdHocModel";
import { useCallback } from "react";
import { Post } from "../../../core/DownloadUtil";
import BmpGeneralLocation from "../../../models/BmpGeneralLocation";
import BmpGeneral from "../../../models/BmpGeneral";
import { toast, ToastContainer } from "react-toastify";
import "./EditProjectPage.scss";
import LookupFactory from "../../../lookups/LookupFactory";
import { useAuth } from "react-oidc-context";
import { BmpClassId } from "../../../models/lutModels/LutBmpClass";

export type MenuSections =
    | "Project Fundamentals"
    | "Planning & Status"
    | "Financial"
    | "Attributes"
    | "Local Community Co-Benefits"
    | "O&M Costs"
    | "Additional"
    | "NewAndRedevelopment"
    | "GisData";

export interface IProjectEditPageProps {
    project: BmpGeneral;
    setProject: (project: BmpGeneral) => void;
    activeItem?: MenuSections;
    gisQueryResult?: boolean;
    gisQueryError?: string;
    lookups: LookupFactory;
    setRefreshTable?: Function;
}

const isValidTabForProject = (bmpClassId: number, activeItem: MenuSections) => {
    const structuralTabs: MenuSections[] = ["Project Fundamentals", "Planning & Status", "Financial", "Attributes", "Local Community Co-Benefits", "O&M Costs", "Additional", "GisData"];
    const nAndRTabs: MenuSections[] = ["Project Fundamentals", "Planning & Status", "Attributes", "Additional", "NewAndRedevelopment", "GisData"];
    const nonStructuralTabs: MenuSections[] = ["Project Fundamentals", "Planning & Status", "Financial", "Local Community Co-Benefits"];

    switch (bmpClassId) {
        case BmpClassId.WatershedControlMeasure:
            return structuralTabs.indexOf(activeItem) > -1;
        case BmpClassId.NewAndRedevelopment:
            return nAndRTabs.indexOf(activeItem) > -1;
        case BmpClassId.NonStructural:
            return nonStructuralTabs.indexOf(activeItem) > -1;
        default:
            return false;
    }
}

export const EditProjectPage: React.FC<IProjectEditPageProps> = (props: IProjectEditPageProps) => {
    const auth = useAuth();

    const [activeItem, setActiveItem] = useState<MenuSections>("Project Fundamentals");
    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [tempLocation, setTempLocation] = useState<BmpGeneralLocation>(new BmpGeneralLocation());
    const [gisQueryAdHoc, setGisQueryAdHoc] = useState<GisQueryAdHocModel>(new GisQueryAdHocModel());
    const [updateGisQueryAdHoc, setUpdateGisQueryAdHoc] = useState<boolean>(false);
    usePutPostLocalWithSet(auth.user?.access_token, gisQueryAdHoc, setGisQueryAdHoc, updateGisQueryAdHoc, setUpdateGisQueryAdHoc);

    useEffect(() => {
        setTempLocation(props.project.bmpGeneralLocation);
        if (!isValidTabForProject(props.project.bmpClassId, activeItem)) {
            setActiveItem("Project Fundamentals");
        }
    }, [activeItem, props.project]);

    const locationChanged = useCallback(() => {
        return props.project.bmpGeneralLocation.latitude !== tempLocation.latitude ||
            props.project.bmpGeneralLocation.longitude !== tempLocation.longitude;
    }, [props.project, tempLocation]);

    const runGisQuery = useCallback(async () => {
        let gisQueryData = new GisQueryAdHocModel();

        gisQueryData.active = 1;
        gisQueryData.bmpGeneralId = props.project.id;
        gisQueryData.latitude = tempLocation.latitude;
        gisQueryData.longitude = tempLocation.longitude;

        gisQueryData = await Post(gisQueryData.endpoint, auth.user?.access_token, gisQueryData);

        if (gisQueryData.gisQueryMsg) {
            if (gisQueryData.msgIsError) {
                toast.error(gisQueryData.gisQueryMsg, { containerId: "edit-project-sidebar-toast-container" });
            } else {
                toast.warning(gisQueryData.gisQueryMsg, { containerId: "edit-project-sidebar-toast-container" });
            }
        }

        return gisQueryData;
    }, [auth.user?.access_token, props.project.id, tempLocation?.latitude, tempLocation?.longitude]);

    const saveChanges = useCallback(async () => {
        setIsLoading(true);

        let copy = Object.assign({}, props.project);

        // TODO : Move this to C# so we can wait for it to finish in order to
        // run the Cloud Engine calls afterwards
        if ((props.project.bmpClassId === BmpClassId.WatershedControlMeasure || props.project.bmpClassId === BmpClassId.NewAndRedevelopment) && locationChanged()) { // Don't do location for Non-structurals
            let gisModel: GisQueryAdHocModel = await runGisQuery();

            if (!gisModel.gisData) {
                // GisQuery failed so dont save
                setIsLoading(false);
                return;
            }

            copy.bmpGeneralGisData = gisModel.gisData;
            copy.bmpGeneralLocation.latitude = tempLocation.latitude;
            copy.bmpGeneralLocation.longitude = tempLocation.longitude;
        }

        let result = await Post(BmpGeneral.endpoint, auth.user?.access_token, copy);
        props.setProject(result);
        props.setRefreshTable(true);
        setIsLoading(false);
    }, [props, locationChanged, auth.user?.access_token, runGisQuery, tempLocation?.latitude, tempLocation?.longitude]);

    return (
        <div className="edit-project-sidebar-wrapper">
            <Translation>
                {_ => (
                    <>
                        <ToastContainer enableMultiContainer position="top-center" containerId={"edit-project-sidebar-toast-container"} />
                        <div className="edit-project-inner-wrapper">
                            <div className="header-div">
                                {props.project.name}
                                <hr />
                                <ButtonComponent
                                    onBtnClick={saveChanges}
                                    label="Save Changes"
                                    theme="primaryBtn"
                                    loading={isUpdating}
                                    disabled={isUpdating}
                                />
                                {!gisQueryAdHoc && !gisQueryAdHoc.gisQueryMsg && gisQueryAdHoc.gisQueryMsg !== "ok" &&
                                    <div style={{ color: "#db2828", fontSize: "0.7em" }}>Error on the GIS Query: {gisQueryAdHoc.gisQueryMsg}</div>
                                }

                            </div>

                            <Loader active={isLoading} />

                            <div className="menu-items-wrapper">
                                <Menu pointing secondary>
                                    <Menu.Item
                                        name="Project Fundamentals"
                                        active={activeItem === "Project Fundamentals"}
                                        onClick={() => setActiveItem("Project Fundamentals")}
                                        content="Project Fundamentals"
                                        style={{ color: "#ff0000" }}
                                    />
                                    <Menu.Item
                                        name="Planning &amp; Status"
                                        active={activeItem === "Planning & Status"}
                                        onClick={() => setActiveItem("Planning & Status")}
                                        content="Planning &amp;  Status"
                                    />

                                    {props.project && (props.project.bmpClassId === BmpClassId.WatershedControlMeasure || props.project.bmpClassId === BmpClassId.NonStructural) &&
                                        <Menu.Item
                                            name="Financial"
                                            active={activeItem === "Financial"}
                                            onClick={() => setActiveItem("Financial")}
                                            content="Financial"
                                        />
                                    }
                                    {props.project && (props.project.bmpClassId === BmpClassId.WatershedControlMeasure || props.project.bmpClassId === BmpClassId.NewAndRedevelopment) &&
                                        <Menu.Item
                                            name="Attributes"
                                            active={activeItem === "Attributes"}
                                            onClick={() => setActiveItem("Attributes")}
                                            content="Attributes"
                                        />
                                    }
                                    {props.project && (props.project.bmpClassId === BmpClassId.WatershedControlMeasure || props.project.bmpClassId === BmpClassId.NonStructural) &&
                                        <Menu.Item
                                            name="Local Community Co-Benefits"
                                            active={activeItem === "Local Community Co-Benefits"}
                                            onClick={() => setActiveItem("Local Community Co-Benefits")}
                                            content="Community Co-Benefits"
                                        />
                                    }
                                    {props.project && props.project.bmpClassId === BmpClassId.WatershedControlMeasure &&
                                        <Menu.Item
                                            name="O&amp;M Costs"
                                            active={activeItem === "O&M Costs"}
                                            onClick={() => setActiveItem("O&M Costs")}
                                            content="O&amp;M Costs"
                                        />
                                    }
                                    {props.project && (props.project.bmpClassId === BmpClassId.WatershedControlMeasure || props.project.bmpClassId === BmpClassId.NewAndRedevelopment) &&
                                        <Menu.Item
                                            name="Additional"
                                            active={activeItem === "Additional"}
                                            onClick={() => setActiveItem("Additional")}
                                            content="Additional"
                                        />
                                    }
                                    {props.project && props.project.bmpClassId === BmpClassId.NewAndRedevelopment &&
                                        <Menu.Item
                                            name="Optional fields for Section VII.F.3.c."
                                            active={activeItem === "NewAndRedevelopment"}
                                            onClick={() => setActiveItem("NewAndRedevelopment")}
                                            content="Optional fields for Section VII.F.3.c."
                                        />
                                    }
                                    {props.project && (props.project.bmpClassId === BmpClassId.WatershedControlMeasure || props.project.bmpClassId === BmpClassId.NewAndRedevelopment) &&
                                        <Menu.Item
                                            name="Gis Data"
                                            active={activeItem === "GisData"}
                                            onClick={() => setActiveItem("GisData")}
                                            content="Gis Data"
                                        />
                                    }
                                </Menu>
                            </div>
                            <div className="edit-subpage-container">
                                {activeItem === "Project Fundamentals" && isValidTabForProject(props.project.bmpClassId, activeItem) && (
                                    <ProjFundamentals
                                        project={props.project}
                                        setProject={props.setProject}
                                        lookups={props.lookups}
                                        activeItem={activeItem}
                                        tempLocation={tempLocation}
                                        setTempLocation={setTempLocation}
                                    />
                                )}
                                {activeItem === "Planning & Status" && isValidTabForProject(props.project.bmpClassId, activeItem) && (
                                    <ProjPlanningStatus
                                        project={props.project}
                                        setProject={props.setProject}
                                        lookups={props.lookups}
                                    />
                                )}
                                {activeItem === "Financial" && isValidTabForProject(props.project.bmpClassId, activeItem) && (
                                    <ProjFinancial
                                        project={props.project}
                                        setProject={props.setProject}
                                        lookups={props.lookups}
                                    />
                                )}
                                {activeItem === "Attributes" && isValidTabForProject(props.project.bmpClassId, activeItem) && (
                                    <ProjAttributes
                                        project={props.project}
                                        setProject={props.setProject}
                                        lookups={props.lookups}
                                    />
                                )}
                                {activeItem === "Local Community Co-Benefits" && isValidTabForProject(props.project.bmpClassId, activeItem) && (
                                    <ProjCobenefits
                                        project={props.project}
                                        setProject={props.setProject}
                                        lookups={props.lookups}
                                    />
                                )}
                                {activeItem === "O&M Costs" && isValidTabForProject(props.project.bmpClassId, activeItem) && (
                                    <ProjOAndM
                                        project={props.project}
                                        setProject={props.setProject}
                                        lookups={props.lookups}
                                    />
                                )}
                                {activeItem === "Additional" && isValidTabForProject(props.project.bmpClassId, activeItem) && (
                                    <ProjAdditional
                                        project={props.project}
                                        setProject={props.setProject}
                                        lookups={props.lookups}
                                    />
                                )}
                                {activeItem === "NewAndRedevelopment" && isValidTabForProject(props.project.bmpClassId, activeItem) && (
                                    <ProjNewAndRedevelopment
                                        project={props.project}
                                        setProject={props.setProject}
                                        lookups={props.lookups}
                                    />
                                )}
                                {activeItem === "GisData" && isValidTabForProject(props.project.bmpClassId, activeItem) && (
                                    <ProjGisData
                                        project={props.project}
                                        setProject={props.setProject}
                                        lookups={props.lookups}
                                    />
                                )}
                            </div>
                        </div>
                        <div className="wave-div">
                            <img src={Wave} alt="wave" className="wave" />
                        </div>
                    </>
                )}
            </Translation>
        </div>
    );
};
