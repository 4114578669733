import React from "react";
import './ConfirmDeletePDFModal.scss';
import { Button, Icon, Modal } from "semantic-ui-react";
import { IConfirmDeletePdfProps } from "./IConfirmDeletePDFProps";


export const ConfirmDeletePDFModal: any = (props: IConfirmDeletePdfProps) => {
    
    const [open, setOpen] = React.useState(false);

    return (
        <div className="confirm-delete-modal"> 
            <Modal
                size="mini"
                centered={true}
                open={open}
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                trigger={<Icon className={`${props.icon} trash-icon`} name="delete" />}
                >
                <Modal.Header>Are you sure you want to delete</Modal.Header>
                <Modal.Content>
                    {props.itemToDelete.attachmentFileName}
                </Modal.Content>
           
                <Modal.Actions>
                    <Button color="red" onClick={() => props.confirmDeleteFunc(props.itemToDelete)}>Delete</Button>
                    <Button color="grey" onClick={() => setOpen(false)}>Cancel</Button>
                </Modal.Actions>
            </Modal>
        </div>
    )
}
