import axios from "axios";
import { useContext, useRef, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { Button } from "semantic-ui-react";
import Config from "../../config/Config";
import { InputComponent } from "../../paradigmLib/domElements/inputComponent/InputComponent";
import "./InviteUserComponent.scss"
import 'react-toastify/dist/ReactToastify.css';
import { useAuth } from "react-oidc-context";

export interface IInviteUserComponentProps {
    onInvitationFailed: () => void;
    onUserInvited: (addedUserId: string) => void;
    role: string;
}

class NewUser {
    firstName: string;
    lastName: string;
    email: string;
    role: string;
}

export const InviteUserComponent: React.FC<IInviteUserComponentProps> = (props: IInviteUserComponentProps) => {
    const [newUser, setNewUser] = useState<NewUser>(new NewUser());

    const [invitingUser, setInvitingUser] = useState<boolean>(false);

    const toastId = useRef(null);

    // TODO: Role
    var auth = useAuth();

    const inviteUser = () => {
        toastId.current = toast.info("Inviting user...", {
            autoClose: false
        });
        const inviteEndpoint = Config.authInfo.server + "/Invite/Index";
        setInvitingUser(true);

        var form = new FormData();
        form.append("firstName", newUser.firstName);
        form.append("lastName", newUser.lastName);
        form.append("email", newUser.email);
        form.append("role", props.role);

        axios.post<string>(inviteEndpoint, form, {
            headers: {
                "Authorization": `Bearer ${auth.user?.access_token}`
            }
        })
            .then((response) => {
                props.onUserInvited(response.data);
            })
            .catch(() => {
                toast.error("Failed to invite user, please contact WRAMPS support");
                props.onInvitationFailed();
            }).finally(() => {
                toast.dismiss(toastId.current);
                setInvitingUser(false);
            });
    }

    return (
        <div>
            <ToastContainer />
            <InputComponent
                value={newUser.firstName}
                setValue={(value) => setNewUser({ ...newUser, firstName: value })}
                disabled={invitingUser}
                inputType="text"
                label="First Name"
                placeHolder="First Name" />
            <InputComponent
                value={newUser.lastName}
                setValue={(value) => setNewUser({ ...newUser, lastName: value })}
                disabled={invitingUser}
                inputType="text"
                label="Last Name"
                placeHolder="Last Name" />
            <InputComponent
                value={newUser.email}
                setValue={(value) => setNewUser({ ...newUser, email: value })}
                disabled={invitingUser}
                inputType="text"
                label="Email"
                placeHolder="Email" />
            <div className="pull-right mt-1">
                <Button
                    disabled={invitingUser}
                    className="button-component-btn"
                    onClick={inviteUser}>Send Invitation</Button>
            </div>
        </div>
    );
}
