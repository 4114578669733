import { useContext, useEffect, useState } from "react";
import './BuildWatershedForm.scss';
import { Translation } from "react-i18next";
import { useAPIGetByIdLocal, usePutPostLocal } from "../../../core/UseAPILocal";
import { TextAreaComponent } from "../../../paradigmLib/domElements/textAreaComponent/TextAreaComponent";
import { ButtonComponent } from "../../../paradigmLib/domElements/buttonComponent/ButtonComponent";
import WatershedReportForm1_2a from "../../../models/WatershedReportForm1_2a";
import { IBuildWatershedFormProps } from "./BuildWatershedForm";
import { UploadSection } from "../../uploadSection/uploadSection";
import { AllGroupsModal, WatershedReportType } from "./AllGroupsModal";
import { useAuth } from "react-oidc-context";

export const BuildWatershedForm_1_2a: any = (props: IBuildWatershedFormProps) => {
    const auth = useAuth();
    const [watershedReportForm, setWatershedReportForm] = useState<WatershedReportForm1_2a>(new WatershedReportForm1_2a());
    const [fetchingPageObj, setFetchingPageObj] = useState<boolean>(false);
    const [updatePageObj, setUpdatePageObj] = useState<boolean>(false);
    const [allGroupsModalOpen, setAllGroupsModalOpen] = useState<boolean>(false);

    useEffect(() => {
        if (props.watershedReportForm && props.watershedReportForm.id !== undefined) {
            setFetchingPageObj(true);
        }
    }, [props.watershedReportForm]);

    useAPIGetByIdLocal(
        auth.user?.access_token,
        new WatershedReportForm1_2a(),
        fetchingPageObj,
        setFetchingPageObj,
        setWatershedReportForm,
        { 'watershedReportFormId': props.watershedReportForm.id, "active": 1 },
        (data) => {
            if (!data) {
                let stateCopy: WatershedReportForm1_2a = new WatershedReportForm1_2a();
                stateCopy.watershedReportFormId = props.watershedReportForm.id;
                stateCopy.active = 1;
                setWatershedReportForm(stateCopy);
            }
        });

    // This hook will put or post the object on save
    usePutPostLocal(auth.user?.access_token, watershedReportForm, (data) => {
        setWatershedReportForm(data);
        // inside the call back here on successful save to db it will push you to the next tab
        props.setActiveItem("1_3");
    }, [updatePageObj]);

    const saveAndContinue = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        setUpdatePageObj(true);
    }

    return (
        <div className="build-watershed-form-subpage">
            <Translation>
                {
                    t =>
                        <>
                            <div className="subpage-container">
                                <AllGroupsModal
                                    open={allGroupsModalOpen}
                                    setOpen={setAllGroupsModalOpen}
                                    watershedReportType={WatershedReportType.Report1_2a}
                                    watershedReportFormId={props.watershedReportForm.id}
                                    summaryText={watershedReportForm.text}
                                    setActiveTab={props.setActiveItem}
                                    nextTab="1_3"
                                />
                                <div className="subpage-header-container">
                                    <div className="subpage-header-1">1.2a &nbsp; - &nbsp;
                                        Additional Information</div>
                                    <div className="subpage-header-2">
                                        Provide additional information regarding the Watershed Control Measures completed (e.g., other compliance metrics and a list of (a) Permittees and non-Permittees collaborated with for achievement of milestones, (b) funding sought, (c) funding obtained, (d) technical assistance received (e.g., through the Safe Clean Water Program Watershed Area Steering Committee), (e) additional local community co-benefits such as clean streets (including, without limitation, street sweeping, litter abatement, etc.), more parks and green spaces, reduced heat island effect, reduced flooding, water supply augmentation, neighborhood beautification, and job creation, and (f) other co-benefits and resources accruing to disadvantaged communities as identified on CalEnviroScreen).
                                    </div>
                                </div>

                                <div className="input-div">
                                    <TextAreaComponent
                                        label="Summary"
                                        placeHolder="Summary"
                                        value={watershedReportForm.text}
                                        setValue={(value) => setWatershedReportForm(Object.assign({}, watershedReportForm, { text: value }))}
                                        rows={10}
                                        disabled={props.finalLock}
                                    />
                                </div>
                                <div className="subpage-header-container">
                                    {/* <div className="subpage-header-1 pt-point5">1.2a&nbsp; - &nbsp;Additional Information</div> */}
                                    <div className="subpage-header-2 pdf-upload-div mt-3">
                                        <UploadSection
                                            formName={"1_2a"}
                                            watershedReportForm={props.watershedReportForm}
                                            lookups={props.lookups}
                                            disabled={props.finalLock}
                                            fileType={".pdf"}
                                        />
                                    </div>
                                </div>
                                {props.watershedReportForm.watershedReportStatusId !== props.lookups.getWatershedReportStatusByName("Final") &&
                                    <div className="save-btn-div">
                                        {props.lookups.jurisdictionGroups.filter(x => x.jurisdictionId === props.watershedReportForm.jurisdictionId).length > 1 &&
                                            <span style={{ marginRight: "1rem" }}>
                                                <ButtonComponent
                                                    onBtnClick={() => setAllGroupsModalOpen(true)}
                                                    label="Save To All Groups &amp; Continue"
                                                    theme="primaryBtn"
                                                    disabled={props.finalLock}
                                                />
                                            </span>
                                        }
                                        <ButtonComponent
                                            onBtnClick={() => saveAndContinue()}
                                            label="Save &amp; Continue"
                                            theme="primaryBtn"
                                            disabled={props.finalLock}
                                        />
                                    </div>
                                }
                            </div>
                        </>
                }
            </Translation>
        </div>
    )
}

