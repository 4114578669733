import { useContext, useEffect, useState } from "react";
import { Form } from "semantic-ui-react";
import { Translation } from "react-i18next";
import { useAPIGetByIdLocal, usePutPostLocal } from "../../../core/UseAPILocal";
import { TextAreaComponent } from "../../../paradigmLib/domElements/textAreaComponent/TextAreaComponent";
import WatershedReportForm1_1 from "../../../models/WatershedReportForm1_1";
import { ButtonComponent } from "../../../paradigmLib/domElements/buttonComponent/ButtonComponent";
import { IBuildWatershedFormProps } from "./BuildWatershedForm";
import { UploadSection } from "../../uploadSection/uploadSection";
import { BuildWatershedForm_1_1Table } from "./BuildWatershedForm_1_1Table";
import './BuildWatershedForm.scss';
import { AllGroupsModal, WatershedReportType } from "./AllGroupsModal";
import { useAuth } from "react-oidc-context";

export const BuildWatershedForm_1_1: any = (props: IBuildWatershedFormProps) => {
    const auth = useAuth();

    const [watershedReportForm, setWatershedReportForm] = useState<WatershedReportForm1_1>(new WatershedReportForm1_1());
    const [fetchingPageObj, setFetchingPageObj] = useState<boolean>(false);
    const [updatePageObj, setUpdatePageObj] = useState<boolean>(false);
    const [inputReset, setInputReset] = useState<Date>(new Date());
    const [allGroupsModalOpen, setAllGroupsModalOpen] = useState<boolean>(false);

    useEffect(() => {
        if (props.watershedReportForm && props.watershedReportForm.id !== undefined) {
            setFetchingPageObj(true);
        }
    }, [props.watershedReportForm]);

    // This hook will fetch the obj from the db with the params needed
    useAPIGetByIdLocal(auth.user?.access_token, new WatershedReportForm1_1(), fetchingPageObj, setFetchingPageObj, setWatershedReportForm, { 'watershedReportFormId': props.watershedReportForm.id }, (data) => {
        if (!data) {
            let stateCopy: WatershedReportForm1_1 = new WatershedReportForm1_1();
            stateCopy.watershedReportFormId = props.watershedReportForm.id;
            stateCopy.active = 1;
            setWatershedReportForm(stateCopy);
        }
        setInputReset(new Date());

    });

    // This hook will put or post the object on save
    usePutPostLocal(auth.user?.access_token, watershedReportForm, (data) => {
        setWatershedReportForm(data);
        props.setActiveItem("1_2");
    }, [updatePageObj]);

    const saveAndContinue = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        setUpdatePageObj(true);
    }

    return (
        <div className="build-watershed-form-subpage">
            <Translation>
                {
                    t =>
                        <>
                            <div className="subpage-container">
                                <AllGroupsModal
                                    open={allGroupsModalOpen}
                                    setOpen={setAllGroupsModalOpen}
                                    watershedReportType={WatershedReportType.Report1_1}
                                    watershedReportFormId={props.watershedReportForm.id}
                                    summaryText={watershedReportForm.text}
                                    setActiveTab={props.setActiveItem}
                                    nextTab="1_2"
                                />
                                <div className="subpage-header-container">
                                    <div className="subpage-header-1">1.1&nbsp; - &nbsp;Watershed Control Measure Milestone Progress</div>
                                    <div className="subpage-header-2">
                                        Summarize the progress on all Watershed Control Measure requirements and dates for their achievement (milestones) identified in your WMP that were required to be achieved by the end of this Reporting Period. The milestones for specific projects may be reported as cumulative number of projects to be implemented (e.g., “Recipes for Compliance”; installation of prescribed volume of BMP capacity by a certain date; Percent Load Reduction of bacteria pollutant by a certain date), cumulative storm volume addressed  by control measures (e.g., LID, new/re-development projects, regional projects), or other metric. However, progress must be reported as percent completion of the selected milestone metric. If any milestones were not achieved, give a clear description of the action/milestone, explain the delay in control measure implementation, and provide the revised action/milestone. The summary must also include a list of (a) Permittees and non-Permittees collaborated with for achievement of milestones, (b) funding sought, (c) funding obtained, (d) technical assistance received (e.g., through the Safe Clean Water Program Watershed Area Steering Committee), (e) additional local community co-benefits such as clean streets (including, without limitation, street sweeping, litter abatement, etc.), more parks and green spaces, reduced heat island effect, reduced flooding, water supply augmentation, neighborhood beautification, and job creation, and (f) other co-benefits and resources accruing to disadvantaged communities as identified on CalEnviroScreen . The format for this item is a text box but you are encouraged to provide this information in an appropriate format as an attachment with spreadsheets, graphs, and/or other elements that would concisely convey the required information.
                                    </div>
                                </div>
                                <BuildWatershedForm_1_1Table selectedParams={props.selectedParams} />
                                <Form key={`${inputReset}`}>
                                    <div className="input-div mt-1">
                                        <TextAreaComponent
                                            label="Summary"
                                            placeHolder="Summary"
                                            value={watershedReportForm.text}
                                            setValue={(value) => setWatershedReportForm(Object.assign({}, watershedReportForm, { text: value }))}
                                            rows={10}
                                            disabled={props.finalLock}
                                        />
                                    </div>
                                </Form>
                                <div className="subpage-header-container">
                                    <div className="subpage-header-2 pdf-upload-div mt-3">
                                        <UploadSection
                                            formName={"1_1"}
                                            watershedReportForm={props.watershedReportForm}
                                            lookups={props.lookups}
                                            disabled={props.finalLock}
                                            fileType={".pdf"}
                                        />
                                    </div>
                                </div>
                                {props.watershedReportForm.watershedReportStatusId !== props.lookups.getWatershedReportStatusByName("Final") &&
                                    <div className="save-btn-div">
                                        {props.lookups.jurisdictionGroups.filter(x => x.jurisdictionId === props.watershedReportForm.jurisdictionId).length > 1 &&
                                            <span style={{ marginRight: "1rem" }}>
                                                <ButtonComponent
                                                    onBtnClick={() => setAllGroupsModalOpen(true)}
                                                    label="Save To All Groups &amp; Continue"
                                                    theme="primaryBtn"
                                                    disabled={props.finalLock}
                                                />
                                            </span>
                                        }
                                        <ButtonComponent
                                            onBtnClick={() => saveAndContinue()}
                                            label="Save &amp; Continue"
                                            theme="primaryBtn"
                                            disabled={props.finalLock}
                                        />
                                    </div>
                                }
                            </div>
                        </>
                }
            </Translation>
        </div>
    )
}
