import { useState, useMemo, useEffect, useCallback } from "react";
import { useAuth } from "react-oidc-context";
import { Button, Dimmer, Loader, Icon } from "semantic-ui-react";
import axios from "axios";
import BmpGeneral from "../../../models/BmpGeneral";
import LookupFactory from "../../../lookups/LookupFactory";
import { toast } from "react-toastify";

import LfdsUITable from "./LFDsUITable";
import { DeleteLFDModal } from "../../modals/LFDModals/DeleteLFDModal";
import { EditLFDModal } from "../../modals/LFDModals/EditLFDModal";

import "./ManageProjectData.scss";
import Config from "../../../config/Config";
import { LowFlowDiversion } from "../../../models/LowFlowDiversion";
import { LowFlowDiversionService } from "../../../services/LowFlowDiversionService";

export type LowFlowDiversionTabProps = {
    lookups: LookupFactory;
}

export const LowFlowDiversionTab = (props: LowFlowDiversionTabProps) => {
    const auth = useAuth();

    const [lfdList, setLfdList] = useState<LowFlowDiversion[]>([]);
    const [showLFDModal, setShowLFDModal] = useState<boolean>(false);
    const [showDeleteLFDModal, setShowDeleteLFDModal] = useState<boolean>(false);
    const [selectedLFDRowValues, setSelectedLfdRowValues] = useState<LowFlowDiversion | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const lfdService = useMemo(() => new LowFlowDiversionService(auth.user?.access_token), [auth]);

    const handleGetLFD = useCallback((): void => {
        setIsLoading(true);
        lfdService.getAll().then((lfd) => {
            setLfdList(lfd);
        })
            .catch((e) => {
                console.error('error getting all lfds', e)
            });
            setIsLoading(false);
    }, [lfdService]);

    useEffect(() => {
        if (!lfdList.length) {
            handleGetLFD();
        }
    }, [handleGetLFD, lfdList.length])

    const handleCreateLFDModal = (): void => {
        setShowLFDModal(true);
    };

    const handleCreateLFD = async (ldValues: LowFlowDiversion): Promise<void> => {
        setIsLoading(true);
        try {
            await lfdService.upsertLowFlowDiversion(ldValues);
            handleGetLFD();
            toast.info('Created LFD Successfully', { containerId: "manage-project-data-toast-container", autoClose: 5000, });
        } catch (err) {
            console.error("Failed to create LFD", err);
            toast.error('Failed to create LFD ', { containerId: "manage-project-data-toast-container", autoClose: 5000, });
        }
        setIsLoading(false);
    };

    const handleEditLFDModal = (ldValues: LowFlowDiversion): void => {
        setSelectedLfdRowValues(ldValues);
        setShowLFDModal(true);
    };

    const handleEditLFD = async (ldValues: LowFlowDiversion): Promise<void> => {
        setIsLoading(true);
        try {
            await lfdService.upsertLowFlowDiversion(ldValues);
            handleGetLFD();
            toast.info('Edited LFD Successfully', { containerId: "manage-project-data-toast-container", autoClose: 5000,});

        } catch (err) {
            console.error("Failed to edit LFD", err);
            toast.error('Failed to edit LFD', { containerId: "manage-project-data-toast-container", autoClose: 5000,});
        }
        setIsLoading(false);
    };

    const resetSelectedLFDRowValues = (): void => {
        setSelectedLfdRowValues(null);
    }

    const handleDeleteLFDModal = (ldValues: LowFlowDiversion): void => {
        setSelectedLfdRowValues(ldValues);
        setShowDeleteLFDModal(true);
    };

    const handleDeleteLFD = async (id: number) => {
        setIsLoading(true);
        try {
            await axios.post<BmpGeneral>(Config.api_endpoint + "bmp/delete",
                [id],
                {
                    headers: {
                        "Authorization": `Bearer ${auth.user?.access_token}`,
                        method: 'POST'
                    }
                })
                .then((response) => {
                    handleGetLFD();
                });
            toast.info('Deleted LFD Successfully ', { containerId: "manage-project-data-toast-container", autoClose: 5000, });

        } catch (err) {
            console.error("Failed to delete LFD ", err);
            toast.error('Failed to delete LFD ', { containerId: "manage-project-data-toast-container" ,autoClose: 5000, });
        }
        setIsLoading(false);
        resetSelectedLFDRowValues();
    };

    return (
        <div className="manage-project-data-page__LFD-container manage-project-data-page__fade-in ">
            <div className=" manage-project-data-page__LFD-container--header-container gradient">
                <h1 className="manage-project-data-page__LFD-container--header" >Low Flow Diversion Projects</h1>
            </div>
            <div className="manage-project-data-page__shadow capture-dashboard__widget-container">

                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "1em" }}>
                    <h2 style={{ fontSize: "1.9em", textAlign: "left" }}>Project Details</h2>
                    <Button
                        style={{ display: "flex", justifyContent: "flex-end", marginBottom: "1em" }}
                        onClick={handleCreateLFDModal}
                        className="manage-project-data-page__button"
                    >
                        <p>
                            <Icon name="plus circle" size='large' style={{ color: 'white' }} /> Create New LFD
                        </p>
                    </Button>
                </div>

                <Dimmer inverted active={isLoading}>
                    <Loader active={isLoading} />
                </Dimmer>
                <LfdsUITable
                    lfds={lfdList}
                    lookups={props.lookups}
                    editLfd={handleEditLFDModal}
                    deleteLfd={handleDeleteLFDModal}
                />
            </div>

            {showLFDModal && <EditLFDModal
                isOpen={showLFDModal}
                onClose={() => setShowLFDModal(false)}
                lfdName={selectedLFDRowValues?.name}
                lfdValues={selectedLFDRowValues}
                onSubmitEdit={handleEditLFD}
                onSubmitCreate={handleCreateLFD}
                lookups={props.lookups}
                resetValues={resetSelectedLFDRowValues}
                />}

            {showDeleteLFDModal &&
                <DeleteLFDModal
                lfdId={selectedLFDRowValues.id}
                isOpen={showDeleteLFDModal}
                onClose={() => setShowDeleteLFDModal(false)}
                lfdName={selectedLFDRowValues?.name}
                onDelete={handleDeleteLFD}
                resetValues={resetSelectedLFDRowValues}
                />
            }
        </div>
    );
};
