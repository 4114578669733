import "./MapPopup.scss";
import LookupFactory from '../../lookups/LookupFactory';

import { Label } from 'semantic-ui-react'

export interface IMapPopupProps {
    feature: any;
    lookups?: LookupFactory;
}

const MapPopup = (props: IMapPopupProps) => {

    return (
        props.lookups ?
            <>
                {props.feature.attributes.name &&
                    <div className="popup-container-wrapper">
                        <div className="popup-container">
                            <div className="popup-ico" style={{ textAlign: "center", position: "absolute", right: 15, top: -24 }} >
                                <Label as='a' color='blue' ribbon='right'>
                                    <p>
                                    {props.feature.attributes?.jurisdiction ? props.feature.attributes?.jurisdiction : props.lookups?.getJurisdictionById(1089)}
                                    </p>
                                </Label>
                            </div>
                            <div className="popup-text">
                                {props?.feature?.attributes?.name}
                            </div>
                        </div>
                        <div className="bottom-arrow"></div>
                    </div>
                }
            </>
            :
            <>
                {props.feature.attributes.name &&
                    <div className="popup-container-wrapper">
                        <div className="popup-container">
                            {props.feature.attributes.totalCapacity > 0 &&
                                <div className="popup-icon" >
                                    <div className="popup-value">{props.feature.attributes.totalCapacity?.toFixed(2)}</div>

                                    <div className="popup-unit">ac-ft</div>
                                </div>
                            }
                            <div className="popup-text">
                                {props?.feature?.attributes?.name}
                            </div>
                        </div>
                        <div className="bottom-arrow"></div>
                    </div>
                }
            </>
    );
};

export default MapPopup;