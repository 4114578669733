import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Icon } from "semantic-ui-react";
import { WatershedReportPublicFacingConfigLinkInfo } from "../../../models/viewModels/WatershedReportPublicFacingConfigViewModel";
import { ButtonComponent } from "../../../paradigmLib/domElements/buttonComponent/ButtonComponent";
import { InputComponent } from "../../../paradigmLib/domElements/inputComponent/InputComponent";
import { URLInputComponent } from "../../../paradigmLib/domElements/inputComponent/URLInputComponent";
import "./PublicConfigLinks.scss";

export interface IPublicConfigLinksProps {
    wmgId: number;
    wmgPublicInfoLinks: Array<WatershedReportPublicFacingConfigLinkInfo>;
    setWmgPublicInfoLinks?: React.Dispatch<React.SetStateAction<WatershedReportPublicFacingConfigLinkInfo[]>>;
    editable: boolean;
}

export const PublicConfigLinks = (props: IPublicConfigLinksProps) => {
    const [tempLink, setTempLink] = useState<WatershedReportPublicFacingConfigLinkInfo>(new WatershedReportPublicFacingConfigLinkInfo(props.wmgId))
    const [urlError, setUrlError] = useState(false);

    const handleAddLink = () => {
        let errors = [
            ...(tempLink.linkUrl ? [] : ["Link URL"]),
            ...(tempLink.linkText ? [] : ["Link Name"]),
            ...(tempLink.linkDescription ? [] : ["Link Description"])
        ];

        if (errors.length > 0) {
            // .replace adds commas between 2 or more items
            toast.error(`Please enter a ${errors.join(", ").replace(/, ((?:.(?!, ))+)$/, ' and $1')}`, { autoClose: 3000 });
            return;
        }

        // Regular expression for URL validation
        const urlRegex = /^(http|https):\/\/[^ "]+$/;

        if (urlRegex.test(tempLink.linkUrl)) {
            // if user's url starts with http:// or https:// = valid url
            setUrlError(false);
            props.wmgPublicInfoLinks.push({ ...tempLink });
            props.setWmgPublicInfoLinks!(props.wmgPublicInfoLinks);
            // this resets the values in the add link input fields after user presses "add link"
            setTempLink(new WatershedReportPublicFacingConfigLinkInfo(props.wmgId));
        } else {
            // user's url is invalid and doesnt start with http:// or https://
            setUrlError(true);
        }
    }

    const handleDeleteLink = (id: number) => {
        props.setWmgPublicInfoLinks(props.wmgPublicInfoLinks.filter(x => x.id !== id && x.tempId !== id));
    }

    useEffect(() => {
        setUrlError(false);
    }, [tempLink.linkUrl]);

    return (
        <>
            <div className="additional-info-links">
                <div className="link-preview">
                    {props.wmgPublicInfoLinks
                        .sort((a, b) => a.id - b.id)
                        .map((item, index) => {
                            return (
                                <div key={index} className="link-card">
                                    <div className="link-card-url">
                                        <a href={item.linkUrl} target="_blank" rel="noreferrer">
                                            {item.linkText}
                                        </a>
                                        {props.editable &&
                                            <div
                                                className="link-card-delete"
                                                onClick={() => handleDeleteLink(item.id ?? item.tempId)}>
                                                <Icon name="close" className="close-link-icon" />
                                            </div>
                                        }
                                    </div>
                                    <div className="link-card-description">
                                        {item.linkDescription}
                                    </div>
                                </div>
                            )
                        })}
                </div>
                {props.editable &&
                    <>
                        <div className="links-title">Add Links</div>
                        <div className="link-display-input-wrapper">
                            <div className="link-display-input">
                                <InputComponent
                                    value={tempLink.linkText}
                                    setValue={(value) => setTempLink({ ...tempLink, linkText: value })}
                                    inputType="text"
                                    label="Name To Display For Link"
                                    placeHolder="This is how the link will display to the public"
                                    maxCharacters={30}
                                />
                            </div>
                            <div className="link-display-input">
                                <InputComponent
                                    value={tempLink.linkDescription}
                                    setValue={(value) => setTempLink({ ...tempLink, linkDescription: value })}
                                    inputType="text"
                                    label="Descriptive blurb about link"
                                    placeHolder="Short description - max 200 characters"
                                    maxCharacters={120}
                                />
                            </div>
                            <div className="link-display-input">
                                <URLInputComponent
                                    pageObj={tempLink}
                                    setPageObj={setTempLink}
                                    objKey="linkUrl"
                                    label="URL for Link"
                                    placeHolder={"http://..."}
                                    urlError={urlError}
                                />
                            </div>
                            <div className="link-display-btn">
                                <ButtonComponent
                                    theme="primaryBtn"
                                    onBtnClick={handleAddLink}
                                    label={"+ Add This Link"} />
                            </div>
                        </div>
                    </>
                }
            </div>
        </>
    )
};
