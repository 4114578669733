import { ISearchParams } from "../components/paradigmFilter/filterTypes/filterParams";
import ExpressionBuilder from "../ExpressionBuilder/ExpressionBuilder";

export default class QueryBase {

    private keywordProperties: string [];
    private alaSqlPrefix: boolean;
    expressionBuilder: ExpressionBuilder;

    // pass in the names of all properties that will be included in the general keyword search.
    constructor (keywordProperties: string[], alaSqlPrefix: boolean) {
        this.keywordProperties = keywordProperties;
        this.expressionBuilder = new ExpressionBuilder(this);
        this.alaSqlPrefix = alaSqlPrefix;
    };
    
    getKeywordProperties = (): string[] => {
        return this.keywordProperties;
    };

    getBoolsFromString = (stringList: string[] ): boolean[] => {
        var boolList: boolean[] = stringList.map(function (val) { 
            if (val === 'true'){return true;}
            if (val === 'false') {return false;}
            else {return null;}
        });

        return boolList;
    }

    // add new if condition for watershed-areas
    buildNewExpression = (searchParams: ISearchParams) => {
        this.expressionBuilder.clearExpression();

        Object.keys(this).forEach((key: string) => {
            
            for (let paramsKey in searchParams) {
                if (paramsKey === key) {

                    if (searchParams[paramsKey].paramType === "multi-select") {
                        this.expressionBuilder.addIDs(searchParams[paramsKey].value, key);
                    };
                    if (searchParams[paramsKey].paramType === "multi-toggle") {
                        this.expressionBuilder.addIDs(searchParams[paramsKey].value, key);
                    };
                    if (searchParams[paramsKey].paramType === "range-slider") {
                        
                        if (searchParams[paramsKey].value[0] === searchParams[paramsKey].rangeSliderConfig.rangeFloor && searchParams[paramsKey].value[1] < searchParams[paramsKey].rangeSliderConfig.rangeCiel) {
                            this.expressionBuilder.addRange(searchParams[paramsKey].value[0], searchParams[paramsKey].value[1], key);
                        } else if (searchParams[paramsKey].value[0] > searchParams[paramsKey].rangeSliderConfig.rangeFloor && searchParams[paramsKey].value[1] === searchParams[paramsKey].rangeSliderConfig.rangeCiel) {
                            this.expressionBuilder.addRange(searchParams[paramsKey].value[0], searchParams[paramsKey].value[1], key);
                        } else if (searchParams[paramsKey].value[0] > searchParams[paramsKey].rangeSliderConfig.rangeFloor && searchParams[paramsKey].value[1] < searchParams[paramsKey].rangeSliderConfig.rangeCiel) {
                            this.expressionBuilder.addRange(searchParams[paramsKey].value[0], searchParams[paramsKey].value[1], key);
                        }

                    };
                    if (searchParams[paramsKey].paramType === "checkbox-section") {
                        if (searchParams[paramsKey].value.length !== searchParams[paramsKey].checkBoxConfig.dataArray.length) {
                            if (searchParams[paramsKey].isBoolean){
                                this.expressionBuilder.addIDs(this.getBoolsFromString(searchParams[paramsKey].value), key)
                            } else {
                                this.expressionBuilder.addIDs(searchParams[paramsKey].value, key)
                            }
                        };
                    };
                    if (searchParams[paramsKey].paramType === "search-input") {

                    };
                    if (searchParams[paramsKey].paramType === "watershed-areas") {
                        this.expressionBuilder.addWatersheds(searchParams[paramsKey].value);
                    };



                    
                };
            };
        });
        for (let paramsKey in searchParams) {
            if (paramsKey === "keywords") {
                this.expressionBuilder.addKeywords(searchParams[paramsKey].value[0]);
            };
        };
        

        if (this.alaSqlPrefix) {
            this.expressionBuilder.addAlaSqlPrefix();
        };
        // console log this.expressionBuilder.getQuery(); to see what the sql query being built looks like.
        return this.expressionBuilder.getQuery();
    };
}