import { BaseModel } from "./BaseModel";


export default class BmpGeneralFinancial extends BaseModel {

    id: number;
    bmpGeneralId: number;
    projectCapitalCost: number | null;
    fundingSought: number | null;
    fundingObtained: number | null;
    active: number;
    editor: string | null;
    created: Date | string | null;
    updated: Date | string | null;


    constructor() {
        super("BmpGeneralFinancial", "local")
    }
}