import { Dispatch, SetStateAction, useContext, useState } from "react";
import { Translation } from "react-i18next";
import { Button, Dimmer, Icon, Loader, Message, Segment } from "semantic-ui-react";
import { ButtonComponent } from "../../../../paradigmLib/domElements/buttonComponent/ButtonComponent";
import { WatershedReportPublicFacingConfig, WatershedReportPublicFacingConfigLinkInfo } from "../../../../models/viewModels/WatershedReportPublicFacingConfigViewModel";
import { WMGPublicDashboard } from "../wmgPublicDashboard/WMGPublicDashboard";
import { WatershedReportSnapshot, WatershedReportSnapshotBmp } from "../../../../models/WatershedReportSnapshot";
import { GlobalAuthInfoContext } from "../../../mainWrapper/MainWrapper";
import WatershedManagementGroupIsPublic from "../../../../models/WatershedManagementGroupIsPublic";
import { useAPIFetchAllLocal, usePutPostLocalOnlyWhenTrue } from "../../../../core/UseAPILocal";
import LookupFactory from "../../../../lookups/LookupFactory";
import BmpGeneral from "../../../../models/BmpGeneral";
import "./PreviewPublicElements.scss"
import { useAuth } from "react-oidc-context";

export interface IPreviewPublicElements {
    wmgId: number;
    wmgPublicInfo: WatershedReportPublicFacingConfig | null;
    setActiveItem?: Function;
    wmgPublicInfoLinks: Array<WatershedReportPublicFacingConfigLinkInfo>;
    savePublicInfoConfigDraft?: Function;
    lookups: LookupFactory;
    snapshot: WatershedReportSnapshot;
    currentData: Array<WatershedReportSnapshotBmp>;
    snapshotLoadingFinished: boolean;
    currentLoadingFinished: boolean;
    activeData: "snapshot" | "current"; // snapshot/published vs current data button - saved above to keep state
    setActiveData: React.Dispatch<React.SetStateAction<"snapshot" | "current">>;
    scrollFunction: Function;
    setSidebarProjectInfoObj: Dispatch<SetStateAction<BmpGeneral>>;
    dispatchSidebar: Function;
}

export const PreviewPublicElements: React.FC<IPreviewPublicElements> = (props: IPreviewPublicElements) => {
    const auth = useAuth();
    const globalAuthInfoContext = useContext(GlobalAuthInfoContext);

    const openConfigTab = () => {
        props.scrollFunction();
        props.setActiveItem('1_7');
    }

    const Continue = () => {
        props.scrollFunction();
        props.setActiveItem('1_9');
    }

    const [watershedManagementGroupIsPublic, setWatershedManagementGroupIsPublic] = useState<WatershedManagementGroupIsPublic>(new WatershedManagementGroupIsPublic());
    const [updateWatershedManagementGroupIsPublic, setUpdateWatershedManagementGroupIsPublic] = useState<boolean>(false);

    useAPIFetchAllLocal(
        auth.user?.access_token,
        new WatershedManagementGroupIsPublic(),
        true,
        () => {},
        setWatershedManagementGroupIsPublic,
        { "WatershedGroupId": props.wmgId, "active": 1 },
        (data: WatershedManagementGroupIsPublic[]) => {
            if (data.length > 0) {
                setWatershedManagementGroupIsPublic(data[0]);
            }
            else {
                setWatershedManagementGroupIsPublic(new WatershedManagementGroupIsPublic());
            }
        });

    usePutPostLocalOnlyWhenTrue(auth.user?.access_token, watershedManagementGroupIsPublic, (data) => {
        setUpdateWatershedManagementGroupIsPublic(false);
        setWatershedManagementGroupIsPublic(data);
    }, [updateWatershedManagementGroupIsPublic]);

    return (
        <div className="build-watershed-form-subpage  preview-public-elements">
            <Translation>
                {
                    t => <>
                        <div className="subpage-container">
                            <div className="preview-section-wrapper">
                                <div className="preview-section-top">
                                    <div className="view-wrapper">
                                        <div className="dashboard-button-group">
                                            <Button.Group
                                            >
                                                <Button
                                                    id="0"
                                                    className={props.activeData === "snapshot" ? "active" : ""}
                                                    onClick={() => props.setActiveData("snapshot")}
                                                    disabled={!(props.snapshotLoadingFinished && props.currentLoadingFinished)}
                                                >Current View
                                                </Button>
                                                <Button
                                                    id="1"
                                                    className={props.activeData === "current" ? "active" : ""}
                                                    onClick={() => props.setActiveData("current")}
                                                    disabled={!(props.snapshotLoadingFinished && props.currentLoadingFinished)}
                                                >Updated View
                                                </Button>
                                            </Button.Group>
                                        </div>
                                        <Message color="olive" className="view-description">
                                            <Icon name="hand point left" />
                                            <div className="view-description-text">The button selection to the left is for your preview only. If you would like to update the timestamp and use the updated view as the publicly available data, you can do so in Step 2 on the Publishing Controls tab.
                                            </div>
                                        </Message>
                                    </div>
                                    <Message color='blue'>
                                        <div className="preview-header">
                                            <Icon name="arrow down" />&nbsp;
                                            Below is a preview of your current configuration&nbsp;&nbsp;
                                            <Icon name="arrow down" /><br />
                                            (This is exactly how it will be shown on the publicly facing WRAMPS2 Welcome page dashboard tab)
                                        </div>
                                    </Message>
                                </div>
                            </div>
                            <div className="dashed-line">&nbsp;</div>

                            {(!props.snapshotLoadingFinished || !props.currentLoadingFinished) &&
                                !(!props.wmgPublicInfo ||
                                    (!props.wmgPublicInfo.programMessageEnabled &&
                                        !props.wmgPublicInfo.programSummaryTableEnabled &&
                                        !props.wmgPublicInfo.programSummaryVisualizationEnabled &&
                                        !props.wmgPublicInfo.programSummaryMultiBenefitsEnabled &&
                                        !props.wmgPublicInfo.programProgressEnabled &&
                                        !props.wmgPublicInfo.projectDetailsMapEnabled &&
                                        !props.wmgPublicInfo.projectDetailsTableEnabled &&
                                        !props.wmgPublicInfo.additionalInfoEnabled)
                                ) &&
                                <Segment style={{ height: "8rem" }}>
                                    <Dimmer active inverted>
                                        <Loader size='big'>Loading</Loader>
                                    </Dimmer>
                                </Segment>
                            }

                            {
                                (!props.wmgPublicInfo || (
                                    !props.wmgPublicInfo.programMessageEnabled &&
                                    !props.wmgPublicInfo.programSummaryTableEnabled &&
                                    !props.wmgPublicInfo.programSummaryVisualizationEnabled &&
                                    !props.wmgPublicInfo.programSummaryMultiBenefitsEnabled &&
                                    !props.wmgPublicInfo.programProgressEnabled &&
                                    !props.wmgPublicInfo.projectDetailsMapEnabled &&
                                    !props.wmgPublicInfo.projectDetailsTableEnabled &&
                                    !props.wmgPublicInfo.additionalInfoEnabled)) &&
                                <div className="no-public-sections">*** You have not selected any information to be displayed to the public. Please return to the Configure Public Elements tab to select elements. ***</div>
                            }

                            {props.wmgPublicInfo && props.snapshotLoadingFinished && props.currentLoadingFinished &&
                                <WMGPublicDashboard
                                    wmgId={props.wmgId}
                                    wmgPublicData={props.activeData === "snapshot" ? props.snapshot?.watershedReportBmpSnapshots ?? new Array<WatershedReportSnapshotBmp>() : props.currentData}
                                    wmgPublicInfo={props.wmgPublicInfo}
                                    wmgPublicInfoLinks={props.wmgPublicInfoLinks}
                                    lookups={props.lookups}
                                    setSidebarProjectInfoObj={props.setSidebarProjectInfoObj}
                                    dispatchSidebar={props.dispatchSidebar}
                                    useLiveData={props.activeData === "current"}
                                    publicOrInternal="internal"
                                    snapshotDate={props.snapshot ? new Date(props.snapshot.snapshotDate) : undefined}
                                />
                            }
                        </div>
                        <div className="preview-bottom">
                            <div className="preview-bottom-text" onClick={openConfigTab} >
                                To Make Changes, Return To Configure Public Elements tab
                            </div>
                            <div className="preview-bottom-btn">
                                <ButtonComponent
                                    theme="primaryBtn"
                                    onBtnClick={() => Continue()}
                                    label={"Continue To Publishing Controls"} />
                            </div>
                        </div>
                    </>
                }
            </Translation >
        </div >
    )
}
