import axios, { AxiosRequestConfig } from "axios";
import { ManageProjectFilter } from "../components/reporting/filterComponent/FilterComponent";
import Config from "../config/Config";
import { WatershedReportSnapshot, WatershedReportSnapshotBmp } from "../models/WatershedReportSnapshot";
import qs from "qs";

export class BmpSnapshotService {
    private authToken?: string;

    constructor(authToken?: string) {
        this.authToken = authToken;
    }

    async getSnapshot(wmgId: number): Promise<WatershedReportSnapshot | null> {

        return await axios
        .get<WatershedReportSnapshot | null>(Config.api_endpoint + `BmpSnapshot/GetSnapshot/${wmgId}`)
        .then((result) => {
            return result.data;
        })
        .catch((e) => {
            throw new Error("Failed to get bmp snapshot" + e);
        });
    }

    async generateSnapshot(wmgId: number): Promise<boolean> {
        if (!this.authToken){
            return false;
        }

        let requestConfig: AxiosRequestConfig = {
            headers: {
                "Authorization": "Bearer " + this.authToken
            },
        };

        return await axios
        .post<boolean>(Config.api_endpoint + `BmpSnapshot/GenerateSnapshot/${wmgId}`, null, requestConfig)
        .then(() => {
            return true;
        })
        .catch((e) => {
            throw new Error("Failed to generate bmp snapshot" + e);
        });
    }

    /**
     * Will need to pass in the wmgId to a new filter and get the internal name of it
     * @param filter 
     * @returns List of Snapshot objects that are from LIVE/CURRENT data
     */
    async getCurrentAsSnapshots(filter: ManageProjectFilter): Promise<Array<WatershedReportSnapshotBmp>> {
        return await axios
        .get<Array<WatershedReportSnapshotBmp>>(Config.api_endpoint + `BmpSnapshot/GetCurrentAsSnapshots/`, 
            { 
                params: filter, 
                paramsSerializer: (params: any) => { return qs.stringify(params); } 
            })  
        .then((response) => {
            return response.data;
        })
        .catch((e) => {
            throw new Error("Failed to get Live Data as Snapshot Objects" + e);
        });
    }

}
