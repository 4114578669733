import { useCallback, useMemo, useState } from "react";
import { Button, Icon, Popup, Table } from "semantic-ui-react";
import LookupFactory from "../../../../lookups/LookupFactory";
import { ClassDisplayOptions, StatusDisplayOptions, WatershedReportPublicFacingConfig } from "../../../../models/viewModels/WatershedReportPublicFacingConfigViewModel";
import { WatershedReportSnapshotBmp } from "../../../../models/WatershedReportSnapshot";
import { formatWithCommas } from "../../../../core/NumberFormat";
import { BmpClassId } from "../../../../models/lutModels/LutBmpClass";

export interface IDashboardSummaryTableProps {
    publicConfig: WatershedReportPublicFacingConfig;
    wmgPublicData: Array<WatershedReportSnapshotBmp>;
    lookups: LookupFactory;
}

export class SummaryTableSums {
    name: string;
    lutBmpClassId: number; // wcm/nonstruct/new&redev represented by 1003/1006/1005
    receivingWatersId: number;
    projectCount: number;
    drainageAreaAc: number;
    projectCapitalCost: number;
    footprintAc: number;
    totalCapture: number;
    twentyFourHourCapacity: number;

    constructor() {
        this.name = "";
        this.lutBmpClassId = 0;
        this.receivingWatersId = 0;
        this.projectCount = 0;
        this.drainageAreaAc = 0;
        this.projectCapitalCost = 0;
        this.footprintAc = 0;
        this.totalCapture = 0;
        this.twentyFourHourCapacity = 0;
    }
}

function sumSummarySums(sums: Array<SummaryTableSums | undefined>, name: string, bmpClassId: BmpClassId | 999, rwId: number): SummaryTableSums {
    let sum = (sums.filter(x => !!x) as Array<SummaryTableSums>).reduce((acc, x) => {
        acc.projectCapitalCost += x.projectCapitalCost;
        acc.drainageAreaAc += x.drainageAreaAc;
        acc.footprintAc += x.footprintAc;
        acc.projectCount += x.projectCount;
        acc.totalCapture += x.totalCapture;
        acc.twentyFourHourCapacity += x.twentyFourHourCapacity;
        return acc;
    }, new SummaryTableSums());

    sum.name = name;
    sum.lutBmpClassId = bmpClassId;
    sum.receivingWatersId = rwId;
    return sum;
}

const TableRow = (props: { bmpClassName: string, index: number, rowSpan: number, item: SummaryTableSums, selectedStatus: number, dashedColumns: (keyof SummaryTableSums)[]}) => {

    return (
        <Table.Row
            key={props.index + 100000}
            className={props.item.name === "Subtotal" ? "subtotal" : "regular"}
        >
            {props.index === 0 &&
                <Table.Cell
                    rowSpan={props.rowSpan}
                    className="project-type"
                >
                    {props.bmpClassName}
                </Table.Cell>
            }
            <Table.Cell
                className="subwatershed"
                style={{
                    textAlign: props.item.name === "Subtotal" ? "right" : "left",
                }}
            >
                {props.item.name}
            </Table.Cell>

            {/* adding titles helps when text is shortened so user can hover to see full amount */}
            <Table.Cell title={!props.dashedColumns.includes("projectCount") ? formatWithCommas(Number(props.item.projectCount.toFixed(2))) : "--"}>
                {!props.dashedColumns.includes("projectCount") ? formatWithCommas(Number(props.item.projectCount.toFixed(2))) : "--"}
            </Table.Cell>
            <Table.Cell title={!props.dashedColumns.includes("footprintAc") ? formatWithCommas(Number(props.item.footprintAc.toFixed(2))) : "--"}>
                {!props.dashedColumns.includes("footprintAc") ? formatWithCommas(Number(props.item.footprintAc.toFixed(2))) : "--"}
            </Table.Cell>
            <Table.Cell title={!props.dashedColumns.includes("drainageAreaAc") ? formatWithCommas(Number(props.item.drainageAreaAc.toFixed(2))) : "--"}>
                {!props.dashedColumns.includes("drainageAreaAc") ? formatWithCommas(Number(props.item.drainageAreaAc.toFixed(2))) : "--"}
            </Table.Cell>
            <Table.Cell title={!props.dashedColumns.includes("twentyFourHourCapacity") ? formatWithCommas(Number(props.item.twentyFourHourCapacity.toFixed(2))) : "--"}>
                {!props.dashedColumns.includes("twentyFourHourCapacity") ? formatWithCommas(Number(props.item.twentyFourHourCapacity.toFixed(2))) : "--"}
            </Table.Cell>
            {props?.selectedStatus === StatusDisplayOptions.CompleteOnly &&
                <>
                    <Table.Cell title={!props.dashedColumns.includes("projectCapitalCost") ? formatWithCommas(Number(props.item.projectCapitalCost.toFixed(2))) : "--"}>
                        {!props.dashedColumns.includes("projectCapitalCost") ? formatWithCommas(Number(props.item.projectCapitalCost.toFixed(2))) : "--"}
                    </Table.Cell>
                    <Table.Cell title={!props.dashedColumns.includes("totalCapture") ? formatWithCommas(Number(props.item.totalCapture.toFixed(2))) : "--"}>
                        {!props.dashedColumns.includes("totalCapture") ? formatWithCommas(Number(props.item.totalCapture.toFixed(2))) : "--"}
                    </Table.Cell>
                </>
            }
        </Table.Row>
    )
}

const TableRowTotals = (props: { bmpClassName: string, index: number, rowSpan: number, item: SummaryTableSums, selectedStatus: number }) => {
    return (
        <Table.Row key={props.index + 100000}
            className="total"
        >
            <Table.Cell className="project-type">
            </Table.Cell>
            {props.index === 0 &&
                <Table.Cell
                    rowSpan={props.rowSpan}
                    className="project-type total"
                    style={{ textAlign: "right" }}
                >
                    {props.bmpClassName}
                </Table.Cell>
            }
            <Table.Cell
                className="total"
                title={formatWithCommas(Number(props.item.projectCount.toFixed(2)))}
            >
                {formatWithCommas(Number(props.item.projectCount.toFixed(2)))}
            </Table.Cell>
            <Table.Cell
                className="total"
                title={formatWithCommas(Number(props.item.footprintAc.toFixed(2)))}
            >
                {formatWithCommas(Number(props.item.footprintAc.toFixed(2)))}
            </Table.Cell>
            <Table.Cell
                className="total"
                title={formatWithCommas(Number(props.item.drainageAreaAc.toFixed(2)))}
            >
                {formatWithCommas(Number(props.item.drainageAreaAc.toFixed(2)))}
            </Table.Cell>
            <Table.Cell
                className="total"
                title={formatWithCommas(Number(props.item.twentyFourHourCapacity.toFixed(2)))}
            >
                {formatWithCommas(Number(props.item.twentyFourHourCapacity.toFixed(2)))}
            </Table.Cell>
            {props?.selectedStatus === StatusDisplayOptions.CompleteOnly &&
                <>
                    <Table.Cell
                        className="total"
                        title={formatWithCommas(Number(props.item.projectCapitalCost.toFixed(2)))}
                    >
                        {formatWithCommas(Number(props.item.projectCapitalCost.toFixed(2)))}
                    </Table.Cell>
                    <Table.Cell
                        className="total"
                        title={formatWithCommas(Number(props.item.totalCapture.toFixed(2)))}
                    >
                        {formatWithCommas(Number(props.item.totalCapture.toFixed(2)))}
                    </Table.Cell>
                </>
            }
        </Table.Row>
    )
}

// START OF SUMMARY TABLE COMPONENT ******************
export const ProgramSummaryTable = (props: IDashboardSummaryTableProps) => {
    const [selectedStatus, setSelectedStatus] = useState<StatusDisplayOptions>(StatusDisplayOptions.CompleteOnly);

    // filters and saves projects based on bmpType (wcm/new&redev)
    const summaryTableFilter = useCallback((bmpClassId: BmpClassId): SummaryTableSums[] => {
        let tempProjectList = props.wmgPublicData
            .filter((project) => project.lutBmpClassId === bmpClassId
                && ((props.publicConfig.programSummaryTableStatus === StatusDisplayOptions.CompleteOnly || selectedStatus === StatusDisplayOptions.CompleteOnly)
                    ? project.lutBmpStatusTypeId === 1001 // complete
                    : project.lutBmpStatusTypeId === 1000 // in-progress
                ));

        const receivingWaterArray = tempProjectList
            .map(x => x.receivingWatersId)
            .filter((value, index, self) => { return self.indexOf(value) === index; });

        let aggregateDataArray: SummaryTableSums[] = [];
        receivingWaterArray.forEach((rwId) => {
            let summaryTableItem = new SummaryTableSums();
            summaryTableItem.receivingWatersId = rwId;
            summaryTableItem.lutBmpClassId = bmpClassId;
            tempProjectList.filter((project) => project.receivingWatersId === rwId).map((currentValue) => {
                summaryTableItem.name = props.lookups.getReceivingWatersById(currentValue.receivingWatersId);
                summaryTableItem.projectCount++;
                summaryTableItem.drainageAreaAc += currentValue.drainageAreaAc ?? 0;
                summaryTableItem.projectCapitalCost += currentValue.projectCapitalCost ?? 0;
                summaryTableItem.footprintAc += currentValue.footprintAc ?? 0;
                summaryTableItem.totalCapture += currentValue.totalCapture ?? 0;
                summaryTableItem.twentyFourHourCapacity += currentValue.dailyStorageCapacityAcft ?? 0;
            });
            if (summaryTableItem.receivingWatersId) {
                aggregateDataArray.push(summaryTableItem);
            }
        })

        // Sort by Subwatershed
        aggregateDataArray = aggregateDataArray.sort((a, b) => a.name.localeCompare(b.name));

        // Subtotals;  dont do subtotals if its just WCMs, total will take care of it
        if (props.publicConfig.programSummaryTableClass === ClassDisplayOptions.All && aggregateDataArray.length > 0) {
            aggregateDataArray.push(sumSummarySums(aggregateDataArray, "Subtotal", bmpClassId, -1));
        }

        return aggregateDataArray;
    }, [props.wmgPublicData, props.publicConfig.programSummaryTableClass, props.publicConfig.programSummaryTableStatus, props.lookups, selectedStatus]);

    const summedWCMs: SummaryTableSums[] = useMemo(() => {
        return summaryTableFilter(BmpClassId.WatershedControlMeasure);
    }, [summaryTableFilter]);

    const summedNewRedev: SummaryTableSums[] = useMemo(() => {
        if (props.publicConfig.programSummaryTableClass === ClassDisplayOptions.WCMOnly) {
            return [];
        }
        return summaryTableFilter(BmpClassId.NewAndRedevelopment);
    }, [props.publicConfig.programSummaryTableClass, summaryTableFilter]);

    const summedTotals: SummaryTableSums = useMemo(() => {
        let subtotalWcm: SummaryTableSums | undefined = summedWCMs.length > 0 ? summedWCMs.find(x => x.name === "Subtotal") ?? sumSummarySums(summedWCMs, "", BmpClassId.WatershedControlMeasure, -1) : undefined; // no subtotal is there if OnlyWCM is selected so have to calculate sums here
        let subtotalNaR: SummaryTableSums | undefined = summedNewRedev.length > 0 ? {...summedNewRedev.find(x => x.name === "Subtotal"), projectCapitalCost: 0} : undefined; // dont include capital cost for new and redev
        return sumSummarySums([subtotalWcm, subtotalNaR], "", 999, 999)
    }, [summedNewRedev, summedWCMs]);

    return (
        <>
            <div className="program-summary-table">
                {/* this button group only shows if user chose "All Statuses" from the button group on the config tab */}
                {props.publicConfig.programSummaryTableStatus === StatusDisplayOptions.All &&
                    <div className="dashboard-button-group">
                        <div className="dashboard-button-group-title">Project Status</div>
                        <Button.Group >
                            <Button
                                id="status2"
                                onClick={() => setSelectedStatus(StatusDisplayOptions.CompleteOnly)}
                                className={selectedStatus === StatusDisplayOptions.CompleteOnly ? "active no-wrap" : "no-wrap"}
                            >Completed
                            </Button>
                            <Button
                                id="status1"
                                onClick={() => setSelectedStatus(StatusDisplayOptions.PlannedInProgressOnly)}
                                className={selectedStatus === StatusDisplayOptions.PlannedInProgressOnly ? "active no-wrap" : "no-wrap"}
                            >Planned &amp; In-Progress
                            </Button>
                        </Button.Group>
                    </div>
                }
                <div className="summary-table">
                    <Table celled fixed unstackable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell width="2" textAlign="center">Project Type</Table.HeaderCell>
                                <Table.HeaderCell width="2" textAlign="center">Subwatershed</Table.HeaderCell>
                                <Table.HeaderCell
                                    width="1"
                                    textAlign="center"
                                    className="rotate">
                                    Project Count
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                    width="1"
                                    textAlign="center"
                                    className="rotate">
                                    Project Footprint<br />(acre)
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                    width="1"
                                    textAlign="center"
                                    className="rotate">
                                    Drainage Area<br />(acre)
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                    width="1"
                                    textAlign="center"
                                    className="rotate">
                                    24 Hour Capacity<br />(acre-feet)
                                </Table.HeaderCell>
                                {selectedStatus === StatusDisplayOptions.CompleteOnly &&
                                    <>
                                        <Table.HeaderCell
                                            width="1"
                                            textAlign="center"
                                            className="rotate">
                                            Capital Cost ($) {props.publicConfig.programSummaryTableClass === ClassDisplayOptions.All && <Popup content="Capital Cost information is not available for New and Redevelopment projects." trigger={<Icon name="info circle"/>}/>}
                                        </Table.HeaderCell>
                                        <Table.HeaderCell
                                            width="1"
                                            textAlign="center"
                                            className="rotate">
                                            Cumulative Volume<br />Addressed<br />(ac-ft)
                                        </Table.HeaderCell>
                                    </>
                                }
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {/* no results */}
                            {
                                (summedWCMs.length === 0 && summedNewRedev.length === 0) &&
                                <Table.Row>
                                    <Table.Cell
                                        warning={true}
                                        colSpan={selectedStatus === StatusDisplayOptions.PlannedInProgressOnly ? "5" : "7"}
                                        className="no-results">
                                        No results
                                    </Table.Cell>
                                </Table.Row>
                            }
                            {/* WCM Projects */}
                            {summedWCMs.map((item, index: number) => {
                                return (
                                    <TableRow
                                        bmpClassName="Watershed Control Measure (Structural)"
                                        index={index}
                                        rowSpan={summedWCMs.length}
                                        item={item}
                                        selectedStatus={selectedStatus}
                                        dashedColumns={[]}
                                    />
                                );
                            })
                            }
                            {/* New & Redevelopment Projects */}
                            {props.publicConfig.programSummaryTableClass === ClassDisplayOptions.All &&
                                summedNewRedev.map((item, index: number) => {
                                    return (
                                        <TableRow
                                            bmpClassName="New &amp; Redevelopment"
                                            index={index}
                                            rowSpan={summedNewRedev.length}
                                            item={item}
                                            selectedStatus={selectedStatus}
                                            dashedColumns={["projectCapitalCost", "twentyFourHourCapacity"]} // dashes for the Capital Cost column
                                        />
                                    );
                                })
                            }
                            {/* Totals */}
                            <TableRowTotals
                                bmpClassName={"Total"}
                                index={0}
                                rowSpan={1}
                                item={summedTotals}
                                selectedStatus={selectedStatus}
                            />
                        </Table.Body>
                    </Table>
                </div>
            </div>
        </>
    )
}
