import { Translation } from "react-i18next";
import { useContext, useEffect, useMemo, useState } from 'react';
import { Table } from 'semantic-ui-react';
import LookupFactory from '../../lookups/LookupFactory';
import Jurisdiction from '../../models/Jurisdiction';
import JurisdictionUser from '../../models/JurisdictionUser';
import WatershedGroupUser from '../../models/WatershedGroupUser';
import WatershedManagementGroup from '../../models/lutModels/WatershedManagementGroup';
import { InputComponent } from '../../paradigmLib/domElements/inputComponent/InputComponent';
import { ButtonComponent } from '../../paradigmLib/domElements/buttonComponent/ButtonComponent';
import DropdownLutH2O from '../../paradigmLib/dropdownLut/DropdownLut';
import UserListViewModel from '../../models/viewModels/UserListViewModel';
import { getAxiosApiBase, useAPIFetchAllLocal, useUpdateManyLocal } from '../../core/UseAPILocal';
import { UserManagementModal } from '../modals/userMgmtModal/UserManagementModal';
import { JurisdictionOwner, WatershedManagementGroupOwner } from "../../models/OwnerModels";
import { AxiosResponse } from "axios";
import { GlobalAuthInfoContext } from "../mainWrapper/MainWrapper";
import './UserManagement.scss';
import { useAuth } from "react-oidc-context";
import { OwnerManagementModal } from "../modals/ownerManagementModal/OwnerManagementModal";

export interface IUserManagementProps {
    lookups: LookupFactory;
}

export default class AdminUserFiltered {
    userName: string = "";
    jurisdictionId: number = -1;
    watershedGroupId: number = -1;
}

export const UserManagement: any = (props: IUserManagementProps) => {
    const auth = useAuth();

    const globalAuthInfoContext = useContext(GlobalAuthInfoContext);

    const [filteredParams, setFilteredParams] = useState<AdminUserFiltered>(new AdminUserFiltered());

    const [filteredJuris, setFilteredJuris] = useState<Jurisdiction[]>(new Array<Jurisdiction>());

    const [filteredWSGroup, setFilteredWSGroup] = useState<WatershedManagementGroup[]>(new Array<WatershedManagementGroup>());

    const [userList, setUserList] = useState<UserListViewModel[]>(new Array<UserListViewModel>());

    const [fetchingUserList, setFetchingUserList] = useState<boolean>(true);

    const [jurisdictionUser, setJurisdictionUser] = useState<JurisdictionUser[]>(new Array<JurisdictionUser>()); // joiner for jurisdiction and user 
    const [fetchingJurisdictionUser, setFetchingJurisdictionUser] = useState<boolean>(true);

    const [updateJurisdictionUsers, setUpdateJurisdictionUsers] = useState<JurisdictionUser[]>(new Array<JurisdictionUser>());
    const [postJurisdictionUsers, setPostJurisdictionUsers] = useState<boolean>(false);

    const [watershedGroupUser, setWatershedGroupUser] = useState<WatershedGroupUser[]>(new Array<WatershedGroupUser>()); // joiner for watershedgroup and user 
    const [fetchingWatershedGroupUser, setFetchingWatershedGroupUser] = useState<boolean>(true);

    const [updateWatershedGroupUsers, setUpdateWatershedGroupUsers] = useState<WatershedGroupUser[]>(new Array<WatershedGroupUser>());
    const [postWatershedGroupUsers, setPostWatershedGroupUsers] = useState<boolean>(false);

    const [jurisdictionOwners, setJurisdictionOwners] = useState<JurisdictionOwner[]>([]);
    const [groupOwners, setGroupOwners] = useState<WatershedManagementGroupOwner[]>([]);
    const [fetchedJurisdictionOwners, setFetchedJurisdictionOwners] = useState<boolean>(false);
    const [fetchedGroupOwners, setFetchedGroupOwners] = useState<boolean>(false);

    useAPIFetchAllLocal(auth.user?.access_token, new UserListViewModel(), fetchingUserList, setFetchingUserList, setUserList, {}, (_) => {
    });

    useAPIFetchAllLocal(auth.user?.access_token, new JurisdictionUser(), fetchingJurisdictionUser, setFetchingJurisdictionUser, setJurisdictionUser, {}, (_) => {
    });

    useUpdateManyLocal(auth.user?.access_token, updateJurisdictionUsers, setUpdateJurisdictionUsers, postJurisdictionUsers, setPostJurisdictionUsers, (_) => {
        setFetchingJurisdictionUser(true);
    });

    useAPIFetchAllLocal(auth.user?.access_token, new WatershedGroupUser(), fetchingWatershedGroupUser, setFetchingWatershedGroupUser, setWatershedGroupUser, {}, (_) => {
    });

    useUpdateManyLocal(auth.user?.access_token, updateWatershedGroupUsers, setUpdateWatershedGroupUsers, postWatershedGroupUsers, setPostWatershedGroupUsers, (_) => {
        setFetchingWatershedGroupUser(true);
    });

    useEffect(() => {
        getAxiosApiBase(auth.user?.access_token)
            .get<JurisdictionOwner[]>("owners/jurisdiction")
            .then((response: AxiosResponse<JurisdictionOwner[]>) => {
                setJurisdictionOwners(response.data);
                setFetchedJurisdictionOwners(true);
            });
    }, [auth, userList]);

    useEffect(() => {
        getAxiosApiBase(auth.user?.access_token)
            .get<WatershedManagementGroupOwner[]>("owners/group")
            .then((response: AxiosResponse<WatershedManagementGroupOwner[]>) => {
                setGroupOwners(response.data);
                setFetchedGroupOwners(true);
            });
    }, [auth, userList]);

    function getGroupOwnerName(group: WatershedManagementGroup): string {
        const groupOwner = groupOwners.find(jo => jo.watershedManagementGroupId === group.id);

        if (!groupOwner) {
            return "None";
        }

        const user = userList.find(u => u.id === groupOwner.userId);

        if (!user) {
            return "None";
        }

        return user.email;
    }

    function getJurisdictionOwnerName(jurisdiction: Jurisdiction): string {
        const jurisdictionOwner = jurisdictionOwners.find(jo => jo.jurisdictionId === jurisdiction.id);

        if (!jurisdictionOwner) {
            return "None";
        }

        const user = userList.find(u => u.id === jurisdictionOwner.userId);

        if (!user) {
            return "None";
        }

        return user.email;
    }

    useEffect(() => {
        if (globalAuthInfoContext.jurisdictionOwnerList && globalAuthInfoContext.watershedGroupOwnerList) {

            filterTables();
        }
    }, [globalAuthInfoContext.jurisdictionOwnerList, globalAuthInfoContext.watershedGroupOwnerList]);

    const filterTables = () => {
        let filteredJList: Jurisdiction[] = globalAuthInfoContext.jurisdictionOwnerList;
        let filteredWSList: any[] = globalAuthInfoContext.watershedGroupOwnerList;

        if (filteredParams.jurisdictionId > 0) {
            filteredJList = globalAuthInfoContext.jurisdictionOwnerList.filter((jurisdiction: Jurisdiction) => { return jurisdiction.id === filteredParams.jurisdictionId });
        }

        if (filteredParams.watershedGroupId > 0) {
            filteredWSList = globalAuthInfoContext.watershedGroupOwnerList.filter((watershedManagementGroup: WatershedManagementGroup) => { return watershedManagementGroup.id === filteredParams.watershedGroupId });
        }

        if (filteredParams.userName !== "") {
            // look at the userlist need to filter down to only the users that match the search filed
            let userIds: Array<string> = userList.filter((item: any) => { return (item.email.toLowerCase().includes(filteredParams.userName.toLowerCase()) || item.firstName.toLowerCase().includes(filteredParams.userName.toLowerCase()) || item.lastName.toLowerCase().includes(filteredParams.userName.toLowerCase())); }).map((item: any) => item.id);

            // Jurisdiction
            // then we need to filter down the joiner table to only have the ones that match the user list
            let jurisIds: Array<number> = jurisdictionUser.filter((user: JurisdictionUser) => { return userIds.includes(user.userID); }).map((item: JurisdictionUser) => item.jurisdictionId);

            // then we user that list to filter the final list
            filteredJList = filteredJList.filter((item: Jurisdiction) => { return jurisIds.includes(item.id); });

            // Watershed Group
            let wgIds: Array<number> = watershedGroupUser.filter((user: WatershedGroupUser) => { return userIds.includes(user.userID); }).map((item: WatershedGroupUser) => item.watershedGroupId);

            filteredWSList = filteredWSList.filter((item: WatershedManagementGroup) => { return wgIds.includes(item.id); });

            // filteredJList = globalAuthInfoContext.jurisdictionOwnerList.filter((jurisdiction: Jurisdiction) => {return jurisdiction.userName === filteredParams.userName});
            // filteredWSList = props.lookups.lutWatershedManagementGroup.filter((watershedManagementGroup: WatershedManagementGroup) => {return watershedManagementGroup.id === filteredParams.watershedGroupId});
        }

        filteredWSList = filteredWSList.sort((a, b) => {
            if (a.displayName.toLowerCase() < b.displayName.toLowerCase()) { return -1; }
            if (a.displayName.toLowerCase() > b.displayName.toLowerCase()) { return 1; }
            return 0
        })

        filteredJList = filteredJList.sort((a, b) => {
            if (a.displayName.toLowerCase() < b.displayName.toLowerCase()) { return -1; }
            if (a.displayName.toLowerCase() > b.displayName.toLowerCase()) { return 1; }
            return 0
        })

        setFilteredWSGroup(filteredWSList);
        setFilteredJuris(filteredJList);
    }

    const onJurisDropdownSelect = (e: any, d: any) => {
        let stateCopy: AdminUserFiltered = filteredParams;
        filteredParams.jurisdictionId = d.value;
        setFilteredParams(stateCopy);
    }

    const onWatershedDropdownSelect = (e: any, d: any) => {
        let stateCopy: AdminUserFiltered = filteredParams;
        filteredParams.watershedGroupId = d.value;
        setFilteredParams(stateCopy);
    }

    const updateJurisdictionUser = (id: number, addList: Array<string>, remList: Array<string>) => {
        if (id > 0) {
            let updateList: Array<JurisdictionUser> = new Array<JurisdictionUser>();
            if (addList !== undefined) {
                for (let i = 0; i < addList.length; i++) {
                    let usr: JurisdictionUser = new JurisdictionUser();
                    usr.jurisdictionId = id;
                    usr.userID = addList[i];
                    usr.active = 1;
                    updateList.push(usr)
                }
            } if (remList !== undefined) {
                for (let j = 0; j < remList.length; j++) {
                    let usr: JurisdictionUser = jurisdictionUser.find((item: JurisdictionUser) => { return item.jurisdictionId === id && item.userID === remList[j] });
                    if (usr !== undefined) {
                        usr.active = 0;
                        updateList.push(usr)
                    }
                }
            }

            if (updateList.length > 0) {
                setUpdateJurisdictionUsers(updateList);
                setPostJurisdictionUsers(true);
            }
        }
    }

    const updateWatershedGroupUser = (id: number, addList: Array<string>, remList: Array<string>) => {
        if (id > 0) {
            let updateList: Array<WatershedGroupUser> = new Array<WatershedGroupUser>();
            if (addList !== undefined) {
                for (let i = 0; i < addList.length; i++) {
                    let usr: WatershedGroupUser = new WatershedGroupUser();
                    usr.watershedGroupId = id;
                    usr.userID = addList[i];
                    usr.active = 1;
                    updateList.push(usr)
                }
            } if (remList !== undefined) {
                for (let j = 0; j < remList.length; j++) {
                    let usr: WatershedGroupUser = watershedGroupUser.find((item: WatershedGroupUser) => { return item.watershedGroupId === id && item.userID === remList[j] });
                    if (usr !== undefined) {
                        usr.active = 0;
                        updateList.push(usr)
                    }
                }
            }

            if (updateList.length > 0) {
                setUpdateWatershedGroupUsers(updateList);
                setPostWatershedGroupUsers(true);
            }
        }
    }

    const getJurisdictionUserCnt = (id: number): string => {
        if (jurisdictionUser !== undefined) {
            return jurisdictionUser.filter((jurisUser: JurisdictionUser) => { return jurisUser.jurisdictionId === id }).length.toString();
        }
        return "-1";
    }

    const getWatershedGroupUserCnt = (id: number): string => {
        if (watershedGroupUser !== undefined) {
            return watershedGroupUser.filter((wsUser: WatershedGroupUser) => { return wsUser.watershedGroupId === id }).length.toString();
        }
        return "-1";
    }

    return (
        <div className="user-mgmt-wrapper">
            <Translation>
                {
                    t =>
                        <>
                            <div className="user-mgmt">
                                <div className="page-subheader">User Management</div>
                                <div className='user-mgmt-flex'>
                                    <div className="filterbox">
                                        <DropdownLutH2O
                                            dropdownItems={props.lookups ? props.lookups.lutJurisdiction : []}
                                            dropdownTitle={"Jurisdiction"}
                                            isMultiselect={false}
                                            nullLabel="Select a jurisdiction"
                                            onSelect={onJurisDropdownSelect}
                                            ddSlim={true}
                                        />
                                    </div>
                                    <div className="filterbox">
                                        <DropdownLutH2O
                                            dropdownItems={props.lookups ? props.lookups.lutWatershedManagementGroup : []}
                                            dropdownTitle={"Watershed Group"}
                                            isMultiselect={false}
                                            nullLabel="Select a watershed group"
                                            onSelect={onWatershedDropdownSelect}
                                            ddSlim={true}
                                        />
                                    </div>
                                    <div className='filterbox'>
                                        <InputComponent
                                            value={filteredParams.userName}
                                            setValue={(value) => setFilteredParams({ ...filteredParams, userName: value })}
                                            label="User Name Filter"
                                            placeHolder="Filter by user name"
                                            inputType="text"
                                            inputSlim={true}
                                        />
                                    </div>

                                    <div className='filterbox'>
                                        <ButtonComponent
                                            onBtnClick={filterTables}
                                            label="Filter"
                                            theme={"tertiaryBtn"} // primaryBtn secondaryBtn tertiaryBtn
                                            btnHeight={"btnHeightSlim"}
                                        />
                                    </div>
                                </div>

                                <div className="user-content-div">
                                    <div className="user-table-div mr-1">
                                        <Table celled fixed singleLine>
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell width='4'>&nbsp; Jurisdiction</Table.HeaderCell>
                                                    <Table.HeaderCell width='1' textAlign='center' className='small-font'># of Users</Table.HeaderCell>
                                                    <Table.HeaderCell width='1' textAlign='center' className='small-font'>Edit Users</Table.HeaderCell>
                                                    <Table.HeaderCell width='3' textAlign='center'>Owner</Table.HeaderCell>
                                                    <Table.HeaderCell width='1' textAlign='center' className='small-font'>Edit Owner</Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>
                                            <Table.Body>
                                                {
                                                    !fetchedJurisdictionOwners &&
                                                    <Table.Row key={0}>
                                                        <Table.Cell colSpan="4" textAlign='center'>Loading...</Table.Cell>
                                                    </Table.Row>
                                                }
                                                {
                                                    fetchedJurisdictionOwners &&
                                                    filteredJuris.map((juris: Jurisdiction, index: number) => {
                                                        return (
                                                            <Table.Row key={index}>
                                                                <Table.Cell>{juris.displayName}</Table.Cell>
                                                                <Table.Cell textAlign='center'>{getJurisdictionUserCnt(juris.id)}</Table.Cell>
                                                                <Table.Cell textAlign='center'>
                                                                    <UserManagementModal
                                                                        theme=''
                                                                        itemId={juris.id}
                                                                        allUsers={userList}
                                                                        userJoiner={jurisdictionUser.filter((item: JurisdictionUser) => { return item.jurisdictionId === juris.id })}
                                                                        displayName={juris.displayName}
                                                                        updateUsers={updateJurisdictionUser}
                                                                        refreshUsers={() => { setFetchingUserList(true); }}
                                                                        role="jurisdiction" />
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    <span>{getJurisdictionOwnerName(juris)}</span>
                                                                </Table.Cell>
                                                                <Table.Cell textAlign='center'>
                                                                    <OwnerManagementModal
                                                                        itemId={juris.id}
                                                                        displayName={juris.displayName}
                                                                        allUsers={userList.filter(u => { return jurisdictionUser.find(ju => ju.jurisdictionId === juris.id && ju.userID === u.id); })}
                                                                        itemType="jurisdiction"
                                                                        refreshUsers={() => { setFetchingUserList(true); }}
                                                                    />
                                                                </Table.Cell>
                                                            </Table.Row>
                                                        )
                                                    })
                                                }
                                            </Table.Body>
                                        </Table>
                                    </div>

                                    <div className="user-table-div ml-1">
                                        <Table celled fixed singleLine>
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell width='4'>&nbsp;Watershed Group</Table.HeaderCell>
                                                    <Table.HeaderCell width='1' textAlign='center' className='small-font'># of Users</Table.HeaderCell>
                                                    <Table.HeaderCell width='1' textAlign='center' className='small-font'>Edit Users</Table.HeaderCell>
                                                    <Table.HeaderCell width='3' textAlign='center'>Owner</Table.HeaderCell>
                                                    <Table.HeaderCell width='1' textAlign='center' className='small-font'>Edit Owner</Table.HeaderCell>

                                                </Table.Row>
                                            </Table.Header>

                                            <Table.Body>
                                                {
                                                    !fetchedGroupOwners &&
                                                    <Table.Row key={0}>
                                                        <Table.Cell colSpan="4" textAlign='center'>Loading...</Table.Cell>
                                                    </Table.Row>
                                                }
                                                {
                                                    fetchedGroupOwners &&
                                                    filteredWSGroup.map((wsgroup: WatershedManagementGroup, index: number) => {
                                                        return (
                                                            <Table.Row key={index}>
                                                                <Table.Cell>{wsgroup.displayName}</Table.Cell>
                                                                <Table.Cell textAlign='center'>{getWatershedGroupUserCnt(wsgroup.id)}</Table.Cell>
                                                                <Table.Cell textAlign='center'>
                                                                    <UserManagementModal
                                                                        theme=''
                                                                        role="watershedgroup"
                                                                        itemId={wsgroup.id}
                                                                        allUsers={userList}
                                                                        userJoiner={watershedGroupUser.filter((item: WatershedGroupUser) => { return item.watershedGroupId === wsgroup.id })}
                                                                        displayName={wsgroup.displayName}
                                                                        updateUsers={updateWatershedGroupUser}
                                                                        refreshUsers={() => { setFetchingUserList(true); }} />
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    <span>{getGroupOwnerName(wsgroup)}</span>
                                                                </Table.Cell>
                                                                <Table.Cell textAlign='center'>
                                                                    <OwnerManagementModal
                                                                        itemId={wsgroup.id}
                                                                        displayName={wsgroup.displayName}
                                                                        allUsers={userList.filter(u => { return watershedGroupUser.find(gu => gu.watershedGroupId === wsgroup.id && gu.userID === u.id); })}
                                                                        itemType="group"
                                                                        refreshUsers={() => { setFetchingUserList(true); }}
                                                                    />
                                                                </Table.Cell>
                                                            </Table.Row>
                                                        )
                                                    })
                                                }
                                            </Table.Body>
                                        </Table>
                                    </div>
                                </div>
                            </div>
                        </>
                }
            </Translation>
        </div>
    )
}
