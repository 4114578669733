import Multiselect from "multiselect-react-dropdown";

export interface IMultiselectComponentProps {
    options: any[];
    selectedValues?: any | any[];
    onChange?: (name: string, list: any, item: any) => void;
    displayValue: string;
    label: string;
    name: string;
    placeholder?: string;
    disable?: boolean;
}

export const MultiSelectComponent: React.FC<IMultiselectComponentProps> = (props: IMultiselectComponentProps) => {
    return (
        <div>
            <div className="basic-input-label-wrapper">
                <label className="basic-input-label">{props.label}</label>
            </div>
            <Multiselect {...props}
                placeholder={props.placeholder ?? props.label}
                closeOnSelect={true}
                onSelect={(l, i) => { props.onChange(props.name, l, i); }}
                onRemove={(l, i) => { props.onChange(props.name, l, i); }} />
        </div>
    );
}
